import { Component, Inject, Input} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesItemStatusEnum } from 'src/app/core/enums/CitesItemStatusEnum';
import { CitesReleaseSpeciesItem } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { BaseItemListComponent } from 'src/app/shared/base/BaseItemListComponent';
import { CitesReleaseSpeciesWorkflowService } from '../cites-release-species-workflow.service';

@Component({
  selector: 'app-cites-release-species-item-list',
  templateUrl: './cites-release-species-item-list.component.html',
})

export class CitesReleaseSpeciesItemListComponent extends BaseItemListComponent {
  @Input() titleKey: string = 'Consignment Items';
  @Input() items: CitesReleaseSpeciesItem[];
  @Input() enableSelection: boolean;
  @Input() maxItems: number;

  currentPage: number = 1;
  itemsPerPage: number = 4;

  alerts: AlertOptions[] = [];

  public get isAllSelected(): boolean{
    return this.items?.filter(i=>i.isSelected).length == 
            this.items?.filter(i=>!i.isReleased).length;
  }

  public get visibleItems(): CitesReleaseSpeciesItem[] {
    return this.items?.filter(i=>i.show);
  }

  public get selectedItems(): CitesReleaseSpeciesItem[] {
    return this.items?.filter(i=>i.isSelected);
  }

  public get isFalcon(): boolean {
    return this.items?.filter(i=>this.workflowService.isFalcon(i.class.code)).length == 
            this.items?.length;
  }

  constructor(
    @Inject('WorkflowService')
    public workflowService: CitesReleaseSpeciesWorkflowService,
    translateService: TranslateService) {
    super(translateService);
    translateService.get("trCitesReleaseDisclaimer").subscribe(message =>{
      this.alerts.push({
        text: message,
        display: true
      });
    });
  }

  onEmitEdit($event: any,item: any) {
    this.emitEdit(item);
    $event.stopPropagation();
  }

  onSelectAllChange($event:any){
    this.items.map(i=> {
      if(!i.isReleased){
        i.isSelected = $event.target.checked;
        i.status = i.isSelected ? CitesItemStatusEnum.Selected : CitesItemStatusEnum.Imported;
      }
    });
  }
  
  onSelectItemChange($event:any,item: CitesReleaseSpeciesItem){
    item.isSelected = $event.target.checked;
    item.status = item.isSelected ? CitesItemStatusEnum.Selected : CitesItemStatusEnum.Imported;
  }

  emitDelete(item: any) {
    if((this.items.length-1) % this.itemsPerPage == 0){
      this.currentPage--;
    }
    super.emitDelete(item);
  }
}