import { ChangeDetectorRef, Component, EventEmitter, Input, IterableDiffers, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ItemFormActions } from 'src/app/core/enums/ItemFormActions.enum';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { ItemEmitHolder } from './ActionEmitDataHolder';

@Component({ template: '' })

export abstract class BaseItemListComponent extends BaseComponent {
  @Input()
  items: any[];

  @Input()
  formGroup: FormGroup = new FormGroup({});

  @Output()
  edit: EventEmitter<ItemEmitHolder> = new EventEmitter();

  @Output()
  delete: EventEmitter<ItemEmitHolder> = new EventEmitter();

  @Input()
  enableEditing = true;



  public isCollapsed: boolean[] = [];

  searchText: string = '';

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  emitEdit(item: any) {
    this.edit.emit({ data: item, action: ItemFormActions.edit });
  }

  emitDelete(item: any) {
    this.delete.emit({ data: item, action: ItemFormActions.delete  });
  }

  updateSearchText($event: any) {
    this.searchText = $event.searchText;
  }
}
