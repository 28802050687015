<div class="row">
  <div
    *ngFor="let package of dashBoardPackages; let i = index"
    class="col-lg-3 col-md-6"
  >
    <div
      [className]="
        showDetailClicked && currentSelectedPackageId == package.ID
          ? 'card-1 cursor-pointer  Shadow top_arrow dashboardPackageBox'
          : 'card-1 cursor-pointer  dashboardPackageBox'
      "
      (click)="homeComponent.toggleServiceDetails(package.ID.toString())"
    >
      <div class="row border-bottom">
        <div class="col-sm-12">
          <div class="card-title" ngbTooltip="{{ isEnglish ? package.NameEN : package.NameAR }}">
            <strong>
              {{ isEnglish ? package.NameEN : package.NameAR }}
            </strong>
          </div>
          <ng-container *ngIf="isEnglish">
            <div
              *ngIf="package.AboutToExpireLicensesCount > 0"
              class="license-expiry text-right setting bold"
            >
              {{ package.AboutToExpireLicensesCount }}
              <img
                [ngbTooltip]="
                  package.AboutToExpireLicensesCount > 1
                    ? 'More than one licenses are about to expire'
                    : 'One license is about to expire'
                "
                container="body"
                tooltipClass="tooltip-bg-reddish"
                src="assets/Images/Icons/danger-sign.png"
              />
            </div>
          </ng-container>
          <ng-container *ngIf="isArabic">
            <div
              *ngIf="package.AboutToExpireLicensesCount > 0"
              class="license-expiry text-right setting bold"
            >
              {{ package.AboutToExpireLicensesCount }}
              <img
                [ngbTooltip]="
                  package.AboutToExpireLicensesCount > 1
                    ? 'لديك أكثر من ترخيص على وشك الانتهاء'
                    : 'لديك ترخيص واحد علي وشك الأنتهاء'
                "
                container="body"
                tooltipClass="tooltip-bg-reddish"
                src="assets/Images/Icons/danger-sign.png"
              />
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-10">
          <div class="row">
            <div class="col-10 direction_rtl alignment_right">
              <ng-container *ngIf="package.ID != 5">
                <h6 class="color-1">{{ 'ActiveLicenses' | translate }}</h6>
              </ng-container>
    
              <ng-container *ngIf="package.ID == 5">
                <h6 class="color-1">{{ 'Material' | translate }}</h6>
              </ng-container>
            </div>
            <div class="col-2 direction_rtl alignment_right">
              <label class="font-weight-bold">{{ package.ActiveLicensesCount }}</label>
            </div>
            <div class="col-10 direction_rtl alignment_right">
              <ng-container *ngIf="package.ID != 5">
                <h6 class="color-1">{{ 'ExpiredLicenses' | translate }}</h6>
              </ng-container>
    
              <ng-container *ngIf="package.ID == 5">
                <h6 class="color-1">{{ 'Cites' | translate }}</h6>
              </ng-container>
            </div>
            <div class="col-2 direction_rtl alignment_right">
              <label class="color-2 font-weight-bold">{{ package.ExpiredLicensesCount }}</label>
            </div>
          </div>
        </div>
        
        <!-- <div class="col-md-6 direction_rtl alignment_right">
          <ng-container *ngIf="package.ID != 5">
            <h6 class="color-1">{{ 'ActiveLicenses' | translate }}</h6>
          </ng-container>

          <ng-container *ngIf="package.ID == 5">
            <h6 class="color-1">{{ 'Material' | translate }}</h6>
          </ng-container>

          <label class="font-weight-bold">{{ package.ActiveLicensesCount }}</label>
        </div>
        <div class="col-md-6 direction_rtl alignment_right">
          <ng-container *ngIf="package.ID != 5">
            <h6 class="color-1">{{ 'ExpiredLicenses' | translate }}</h6>
          </ng-container>

          <ng-container *ngIf="package.ID == 5">
            <h6 class="color-1">{{ 'Cites' | translate }}</h6>
          </ng-container>

          <label class="color-2 font-weight-bold">{{ package.ExpiredLicensesCount }}</label>
        </div> -->
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="dashBoardPackages.length > 0 && currentSelectedPackage">
  <app-cities-balance-and-falcon-passport
    *ngIf="
      currentSelectedPackageId == PackageTypeEnum.CitiesBalanceAndFalconPassport
    "
    [packageObject]="currentSelectedPackage"
  >
  </app-cities-balance-and-falcon-passport>

  <app-my-agricultural-and-animal-posses
    *ngIf="
      currentSelectedPackageId ==
      PackageTypeEnum.MyAgriculturalAndAnimalPossessions
    "
    [packageObject]="currentSelectedPackage"
  >
  </app-my-agricultural-and-animal-posses>

  <app-my-licenses-for-fishing-boats
    *ngIf="
      currentSelectedPackageId == PackageTypeEnum.MyLicensesForFishingBoats
    "
    [packageObject]="currentSelectedPackage"
  >
  </app-my-licenses-for-fishing-boats>

  <app-my-licenses-for-practicing-profess
    *ngIf="
      currentSelectedPackageId ==
      PackageTypeEnum.MyLicensesForPracticingProfessional
    "
    [packageObject]="currentSelectedPackage"
  >
  </app-my-licenses-for-practicing-profess>
</ng-container>
