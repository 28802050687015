import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rendering-html',
  templateUrl: './rendering-html.component.html',
  styleUrls: ['./rendering-html.component.scss']
})
export class RenderingHtmlComponent implements OnInit, OnChanges{

  @Input('dynamicHtml') dynamicHtmlString: string;
  dynamicHtml:any;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dynamicHtml = this.sanitizer.bypassSecurityTrustHtml(this.dynamicHtmlString);
  }

}
