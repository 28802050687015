import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { CitesReleaseSpeciesTransaction } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { CitesViewBaseComponent } from '../../shared/base/CitesViewBaseComponent';
import { CitesReleaseSpeciesWorkflowService } from '../cites-release-species-workflow.service';

@Component({
  selector: 'app-cites-release-species-request-view',
  templateUrl: './cites-release-species-request-view.component.html',
  styleUrls: ['./../../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CitesReleaseSpeciesViewComponent extends CitesViewBaseComponent {
  transaction$: Observable<CitesReleaseSpeciesTransaction>;

  constructor(
    translate: TranslateService,
    route: ActivatedRoute,
    @Inject('WorkflowService')
    public workflowService: CitesReleaseSpeciesWorkflowService
  ) {
    super(translate, route, workflowService);
  }

  public downloadVerificationDocument($event: any) {
    this.workflowService.DownloadVerificationDocument($event);
  }

  ngOnInit(): void {
    this.subscribeToSizeSettings();
  }
}
