export class FlowButtonOptions {
  titleKey: string;
  isVisible: boolean;
  behaviors?: FlowButtonActions[];
}


export enum FlowButtonActions {
  LOAD_ATTACHMENTS,
  SAVE_REQUEST,
  RESUBMIT_REQUEST,
  VALIDATE_MAIN_FORM,
  BIND_TRANSACTION,
  NO_NEXT,
  EMIT_SERVICE_DETAILS,
  VALIDATE_APPLICANT
}
