<div class="row">
  <div
    class="col-2 d-none d-sm-block"
    [class.pl-0]="isArabic"
    [class.pr-0]="isEnglish"
  >
    <app-section-indexer></app-section-indexer>
  </div>

  <div class="col-12 col-sm-10">
    <div
      class="forms-container"
      *ngIf="transaction$ | async as transaction; else loading"
    >
      <div *ngIf="serviceConfig$ | async as config; else loading">
        <app-es-license-details
          [licenseDetails]="config.licenseDetails"
          contClasses="form-section"
        ></app-es-license-details>
      </div>

      <div class="form-section" *appFormSection="serviceRequestFormSection">
        <app-es-service-request-details
          [requestDetails]="transaction.serviceRequestTransactionData"
          [applicant]="transaction.applicant"
        >
        </app-es-service-request-details>
      </div>

      <router-outlet (activate)="onActivate($event)"></router-outlet>

      <div class="form-section" *ngIf="fees$ | async as fees">
        <app-es-fee-list [fees]="fees"></app-es-fee-list>
      </div>

      <div class="form-section" *ngIf="showCommentsSection">
        <app-es-comments
          [comments]="transaction.comments"
          [requestId]="requestId"
        ></app-es-comments>
      </div>

      <div class="form-section" *ngIf="showTermsAndConditionsSection">
        <app-es-terms-and-conditions
          [form]="form"
          [metaData]="serviceMetaData$ | async"
        ></app-es-terms-and-conditions>
      </div>

      <div class="form-section">
        <app-es-main-buttons
          [form]="form"
          (action)="actionHandler($event)"
          [buttons]="buttons"
        ></app-es-main-buttons>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <p [innerText]="'trLoading' | translate"></p>
</ng-template>
