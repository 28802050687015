import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { EsServiceAttachments } from 'src/app/core/models/shared/EsServiceAttachments';
import {
  ILookup,
  RequestPurposeLkp,
  TypeLkp,
} from 'src/app/core/models/shared/Lookups';
import { MainStepsConfigurations } from './MainStepsConfigurations';
import { CongratulationsObjectType } from 'src/app/services/shared-workflow-object/workflow.service';
import { EsApplicantStateObjectType } from 'src/app/services/shared-workflow-object/EsApplicantStateObjectType';
import { ExportReviewObjectType } from 'src/app/services/export/shared/base/ExportReviewObjectType';
import { EsReviewObjectType } from 'src/app/services/es-shared/base/EsReviewObjectType';
import { FlowButtons } from './FlowButtons';
import { EsMainAttachmentsStateObjectType } from './EsMainAttachmentsStateObjectType';
import { FlowButtonActions } from './ButtonOptions';
import { EsRequestStatusCodesEnum } from 'src/app/core/models/shared/MainWorkflowStepStatusCodesEnum';
import { EsAttachmentFileDto } from 'src/app/core/models/shared/EsAttachmentFileDto';
import { AttachmentConfigurationResponse } from 'src/app/core/api-services/shared/AttachmentConfigurationResponse';
import { EsMainTemplateService } from 'src/app/core/api-services/shared/es-main-template.service';
import { AdditionalDataDto } from 'src/app/core/models/shared/AdditionalDataDto';
import { WorkflowSteps } from './WorkflowSteps';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { BehaviorSubject, Subject } from 'rxjs';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { ServiceDetailByDigitalNumberService } from 'src/app/core/api-services/portal/service-detail-by-digital-number.service';
import { ServiceBaseMapper } from './ServiceBaseMapper';
import { FormMode } from './FormMode';
import { FieldOptions } from './FieldOptions';
import { SectionOptions } from './SectionOptions';
import { TypeLookupDto } from 'src/app/core/models/shared/LookupDto';
import { RequestPurposeTypeEnum } from 'src/app/core/enums/RequestPurposeTypeEnum';
import { TranslateService } from '@ngx-translate/core';
import { equalTrue } from '../input-components/validators/equalTrue.validator';
import { UtilService } from 'src/app/core/utilities/util.service';
import { BaseServiceRequestStore } from './BaseServiceRequestStore';
import { TemplateModeEnum } from './TemplateModeEnum';
import { ActivatedRoute, Router } from '@angular/router';
import { EsUserAccessibilityToService } from 'src/app/core/models/shared/EsUserAccessibilityToService';
import { ServiceAccessResult } from './ServiceAccessResult';
import { ToastrService } from 'ngx-toastr';
import { RequestActions } from './RequestActions';
import { MoveFlowStepDataHandler } from './MoveFlowStepDataHandler';
import { MainStepOptions } from './MainStepOptions';
import { ServiceRequestDto } from 'src/app/core/models/shared/ServiceRequestDto';
import { EsApplicantDto } from 'src/app/core/models/shared/EsApplicantDto';
import { ActionEmitDataHolder } from './ActionEmitDataHolder';
import { emiratesId as emiratesIdFormat } from '../input-components/validators/emiratesIdFormat';
import { EsApplicant } from 'src/app/core/models/shared/EsApplicant';
import {
  RegisteredUser,
  RegisteredUserTypesEnum,
} from 'src/app/core/models/profile/RegisteredUser';
import { EsApplicantIdentityType } from 'src/app/core/models/shared/EsApplicantIdentityType';
import { EsFees } from 'src/app/core/models/shared/EsFees';
import {
  EsFeesDto,
  InitiateRequestResponseDto,
} from 'src/app/core/models/shared/InitiateRequestResponseDto';
import { GetServiceConfigurationResponse } from 'src/app/core/api-services/shared/GetServiceDetailsResponse';
import { InitiateRequestResponse } from 'src/app/core/api-services/shared/InitiateRequestResponse';
import { Platform } from '@angular/cdk/platform';
import { FieldOptionsBuilder } from './FieldOptionsBuilder';
import { InitiateRequestRequestDto } from 'src/app/core/models/shared/InitiateRequestRequestDto';
import { MainFormEventEmitterData } from 'src/app/services/fishers/shared/FishersMainFormChangeEmitterData';
import { BackendSystemEnum } from 'src/app/services/shared-services-steps-module/congratulations/congratulations.component';
import {
  SaveAsDraftDto,
  SaveAsDraftRequestDto,
} from 'src/app/core/api-services/shared/save-as-draft/SaveAsDraftRequest';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import {
  AddOrUpdateTemplateServicesResponse,
  ParticipantTemplateDto,
} from 'src/app/core/api-services/export/shared/template-services/TemplateServicesResponse';
import { environment } from 'src/environments/environment';
import { PayResponse } from 'src/app/core/api-services/shared/PayResponse';
import { EsClientIPService } from 'src/app/core/api-services/shared/es-client-ip.service';
import { AppInjector } from 'src/app/app.module';

declare let TimeMe: any;

export abstract class WorkflowBaseService {
  renderAutoFocusElement = false;
  currentStep: WorkflowSteps = WorkflowSteps.ApplicantConfiguration;

  protected lang: string;
  protected formMode: FormMode;

  readonly EMIRATES_ID_LENGTH = 18; ///With dashes

  get isArabic(): boolean {
    return this.lang == 'ar';
  }

  protected get fob(): typeof FieldOptionsBuilder {
    return FieldOptionsBuilder;
  }

  protected get userProfile(): RegisteredUser {
    return this.utilService.getUserProfile();
  }

  public abstract onMainFormChange(
    $event: MainFormEventEmitterData
  ): Promise<void>;
  abstract getServiceUrlKeyWord(): string;
  public abstract move($event: ActionEmitDataHolder): Promise<void>;
  protected abstract createRequestData(): any;
  protected abstract createDraftRequestData(): any;
  protected abstract createDraftRequestDetailsData(): any;
  protected abstract loadServiceRequest(): Promise<void>;

  globalObject: {
    ApplicantConfiguration: EsApplicantStateObjectType;
    ServiceDetailsConfiguration: BaseServiceRequestStore;
    AttachmentsConfiguration: EsMainAttachmentsStateObjectType;
    ReviewConfiguration: EsReviewObjectType;
    Congratulations: CongratulationsObjectType;
  };

  public pageNumber = 1;

  protected abstract bindMainFormTransaction(): void;
  abstract steps: WorkflowSteps[];

  private get hasRequestId(): boolean {
    return this.globalObject.ServiceDetailsConfiguration.id > 0;
  }

  private get isTemplateEditing(): boolean {
    return (
      this.globalObject.ServiceDetailsConfiguration.templateMode ==
      TemplateModeEnum.Edit
    );
  }

  private get isTemplateCloning(): boolean {
    return (
      this.globalObject.ServiceDetailsConfiguration.templateMode ==
      TemplateModeEnum.Clone
    );
  }

  private clientIPService: EsClientIPService =
    AppInjector.get(EsClientIPService);

  constructor(
    protected translateService: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected metadataService: ServiceDetailByDigitalNumberService,
    protected api: EsMainTemplateService,
    protected commonApiService: EsCommonService,
    protected mapper: ServiceBaseMapper,
    protected utilService: UtilService,
    protected document: Document,
    protected platform: Platform
  ) {
    this.subscribeToLanguageChange();
  }

  private subscribeToLanguageChange() {
    this.lang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(
      (langChangeEvent) => (this.lang = langChangeEvent.lang)
    );
  }

  protected createRequest(): InitiateRequestRequestDto<any> {
    const mainForm = this.globalObject.ServiceDetailsConfiguration.form;

    return {
      Applicant: this.createApplicantRequestData(),
      RequestData: this.createRequestData(),
      Attachments: this.createAttachmentRequest(),
      Remarks: mainForm.controls.notes?.value,
      AddtionalData: this.createAdditionalDataRequest(),
    };
  }

  protected createDraftRequest(): SaveAsDraftRequestDto {
    const mainForm = this.globalObject.ServiceDetailsConfiguration.form;

    const draftRequest = this.createDraftRequestData();
    const draftRequestDetails = this.createDraftRequestDetailsData();

    return {
      DraftInstanceId:
        this.globalObject.ServiceDetailsConfiguration.draftRequestId,
      Applicant: this.createApplicantRequestData(),
      DraftRequest: JSON.stringify(draftRequest),
      DraftRequestDetails: JSON.stringify(draftRequestDetails),
      ProcedureId: this.globalObject.ServiceDetailsConfiguration.procedureId,
      Remarks: mainForm.controls.notes?.value,
      AddtionalData: this.createAdditionalDataRequest(),
      Attachments: this.createAttachmentRequest(),
    };
  }

  protected createTemplateRequest(): ParticipantTemplateDto {
    const draftRequest = this.createDraftRequestData();
    const draftRequestDetails = this.createDraftRequestDetailsData();

    return {
      ID: this.globalObject.ServiceDetailsConfiguration.templateId,
      Template: JSON.stringify(draftRequest),
      TemplateDetails: JSON.stringify(draftRequestDetails),
      ProcedureID: this.globalObject.ServiceDetailsConfiguration.procedureId,
      TemplateName: this.requestTemplateName.value,
    };
  }

  private get requestTemplateName(): AbstractControl {
    return this.globalObject.ServiceDetailsConfiguration.formRequestTemplate
      .controls.name;
  }

  protected async saveAsDraft() {
    if (
      this.currentStep == WorkflowSteps.ServiceDetailsConfiguration ||
      this.currentStep == WorkflowSteps.AttachmentsConfiguration
    ) {
      const draftRequest = this.createDraftRequest();

      const response = await this.commonApiService
        .saveAsDraft(draftRequest)
        .toPromise();

      const result = response.Data;

      if (response.IsSuccess) {
        this.showInitiateDraftSuccessMessage(result);

        this.setInitiateDetailsAsDraft(result);
      }
    }
  }

  public async saveRequestTemplate() {
    const templateRequest = this.createTemplateRequest();

    const response = await this.commonApiService
      .saveTemplate(templateRequest)
      .toPromise();

    const result = response.Data;

    if (response.IsSuccess) {
      this.showRequestTemplateSuccessMessage(templateRequest.TemplateName);

      this.setInitiateDetailsAsRequestTemplate(result);
    }
  }

  private get hasDraftProcedureInstance(): boolean {
    return this.globalObject.ServiceDetailsConfiguration.draftRequestId > 0;
  }

  protected async cancelOriginalDraftRequest() {
    if (this.hasDraftProcedureInstance) {
      const originalDraftRequest =
        this.globalObject.ServiceDetailsConfiguration.draftRequestId;

      await this.commonApiService
        .cancelDraftRequest(originalDraftRequest.toString())
        .toPromise();
    } else {
      console.warn('No draft request id found');
    }
  }

  protected showInitiateDraftSuccessMessage(result: SaveAsDraftDto) {
    const title = this.translateService.instant(
      this.globalObject.ServiceDetailsConfiguration.draftRequestId
        ? 'trDraftRequestUpdatedSuccessfully'
        : 'trDraftRequestSavedSuccessfully'
    );

    this.toastr.success(result.ProcedureInstanceNumber, title);
  }

  protected showRequestTemplateSuccessMessage(name: string) {
    const title = this.translateService.instant(
      this.globalObject.ServiceDetailsConfiguration.templateId
        ? 'trTemplatedUpdatedSuccessfully'
        : 'trTemplatedSavedSuccessfully'
    );

    this.toastr.success(name, title);
  }

  private setInitiateDetailsAsDraft(draftRequest: SaveAsDraftDto) {
    this.setRequestIdDraft(draftRequest.ProcedureInstanceId);

    this.setDraftRequestTransactionData(draftRequest);
  }

  private setInitiateDetailsAsRequestTemplate(
    template: ParticipantTemplateDto
  ) {
    this.setTemplateId(template.ID);

    // this.setTemplateRequestTransactionData(draftRequest);
  }

  private setDraftRequestTransactionData(draftResponseDto: SaveAsDraftDto) {
    const serviceDetails =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails;

    const draftLocalization = this.translateService.instant('trDraft');

    if (serviceDetails.serviceRequestTransactionData) {
      serviceDetails.serviceRequestTransactionData.statusAr = draftLocalization;
      serviceDetails.serviceRequestTransactionData.statusEn = draftLocalization;
      serviceDetails.serviceRequestTransactionData.statusCode =
        EsRequestStatusCodesEnum.Draft;
      serviceDetails.serviceTransaction.procedureInstanceId =
        draftResponseDto.ProcedureInstanceId;
    } else {
      serviceDetails.serviceRequestTransactionData = {
        id: draftResponseDto.ProcedureInstanceId,
        serviceRequestNumber: draftResponseDto.ProcedureInstanceNumber,
        statusAr: draftLocalization,
        statusEn: draftLocalization,
        statusCode: EsRequestStatusCodesEnum.Draft,
      };

      serviceDetails.serviceTransaction.procedureInstanceId =
        draftResponseDto.ProcedureInstanceId;
    }
  }

  private setRequestIdDraft(draftRequestId: number) {
    this.globalObject.ServiceDetailsConfiguration.draftRequestId =
      draftRequestId;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        draftRequestId: draftRequestId,
        templateId: null,
        cloneTemplateId: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private setTemplateId(templateId: number) {
    this.globalObject.ServiceDetailsConfiguration.templateId = templateId;

    this.router.navigate(['/home']);
  }

  public get backendSystem(): BackendSystemEnum {
    return BackendSystemEnum.EServices;
  }

  public setCurrentStep(step: WorkflowSteps) {
    this.currentStep = step;
  }

  /// TODO: move this to a utilities service
  protected fromFormGroup(formGroup: FormGroup, except?: string[]): any {
    const result: any = {};

    Object.keys(formGroup.controls).forEach((key) => {
      if (except && except.indexOf(key) >= 0) return;

      result[key] = (formGroup.controls as any)[key].value;
    });

    return result;
  }

  /// TODO: move this to a utilities service
  protected assignForm(
    destination: FormGroup,
    source: any,
    except?: string[]
  ): void {
    Object.keys(destination.controls).forEach((key) => {
      if (except && except.indexOf(key) >= 0) return;

      const control = destination.controls[key] as AbstractControl;

      if (source[key] instanceof Date) {
        control.setValue(this.mapper.convertToDisplayDate(source[key]));
        return;
      }

      control.setValue(source[key]);
    });
  }

  /// Form control name must match the name of property of the field option to be synced
  protected syncControlsFromFelidsOptions(
    controls: { [key: string]: AbstractControl },
    fields: any
  ) {
    Object.keys(fields).forEach((fieldName) => {
      if ((fields as any)[fieldName] instanceof FieldOptions) {
        if (controls[fieldName]) {
          const options = (fields as any)[fieldName] as FieldOptions;
          const control = controls[fieldName];

          if (options.isRequired != undefined) {
            options.isRequired
              ? control.addValidators(Validators.required)
              : control.removeValidators(Validators.required);
            control.updateValueAndValidity();
          }

          if (options.isDisabled != undefined) {
            if (
              options.isDisabledToggleOnly &&
              options.isDisabled == control.disabled
            ) {
              ///skip enable/disable as disabled state is same
            } else {
              options.isDisabled ? control.disable() : control.enable();
            }
          }

          if (options.value != undefined || options.value === null) {
            if (options.value instanceof Date) {
              control.setValue(this.mapper.convertToDisplayDate(options.value));
            } else {
              if (fieldName == 'veterinarianIdentity') {
                console.log(options.value);
              }
              control.setValue(options.value);
            }
          }

          if (options.isReset) control.reset();

          if (options.markAsTouched) control.markAsTouched();
        }
      } else if ((fields as any)[fieldName] instanceof SectionOptions) {
        const options = (fields as any)[fieldName] as SectionOptions;

        this.syncControlsFromFelidsOptions(controls, options.fields);
      }
    });
  }

  protected mapLookup(lookupArray: any[]): ILookup[] {
    if (lookupArray == null || lookupArray == undefined) {
      console.warn('lookupArray parameter can not be null or undefined.');

      lookupArray = lookupArray || [];
    }

    return lookupArray.map((i) => {
      return { nameAr: i.NameAr, nameEn: i.NameEn, id: i.Id, code: i.Code };
    });
  }

  protected mapTypesLookup(lookupArray: TypeLookupDto[]): TypeLkp[] {
    if (lookupArray == null || lookupArray == undefined) {
      console.warn('lookupArray parameter can not be null or undefined.');

      lookupArray = lookupArray || [];
    }

    return lookupArray.map((i: TypeLookupDto) => {
      return {
        nameAr: i.NameAr,
        nameEn: i.NameEn,
        id: i.Id,
        scientificName: i.ScientificName,
        code: i.Code,
        validateMaxQuantity: i.MaximumNumberAllowedForImporting,
        maxQuantity: i.MaximumNumber,
        unitId: i.UnitId,
      };
    });
  }

  protected ReviewStateObject: ExportReviewObjectType = {
    form: new FormGroup({
      acceptTerms: new FormControl(null, [Validators.required, equalTrue()]),
    }),
    enableStep: false,
    url: '/services/' + this.getServiceUrlKeyWord() + '/review',
    flowButtons: this.buildReviewFlowButtons(),
    isValidationSkipped: false,
  };

  private buildReviewFlowButtons(): FlowButtons {
    const sendingBack: boolean = this.isSendBack;
    const titleKey: string = sendingBack ? 'trReSubmit' : 'trSubmitRequest';
    const behaviors = sendingBack
      ? [FlowButtonActions.RESUBMIT_REQUEST]
      : [
          FlowButtonActions.SAVE_REQUEST,
          FlowButtonActions.NO_NEXT,
          FlowButtonActions.EMIT_SERVICE_DETAILS,
        ];

    return {
      next: { titleKey, behaviors, isVisible: true },
      back: { titleKey: 'Back', isVisible: true },
    };
  }

  protected CongratulationsStateObject: CongratulationsObjectType = {
    form: {
      valid: false,
    },
    url: '/services/' + this.getServiceUrlKeyWord() + '/congrats',
  };

  protected AttachmentsStateObject: EsMainAttachmentsStateObjectType = {
    IsAttachmentRetrieved: false,
    form: new FormGroup({
      Attachments: new FormArray([]),
    }),
    enableStep: false,
    url: '/services/' + this.getServiceUrlKeyWord() + '/attachments',
    attachments: [],
    flowButtons: this.buildAttachmentFlowButtons(),
  };

  protected buildAttachmentFlowButtons(): FlowButtons {
    const behaviors = [FlowButtonActions.BIND_TRANSACTION];

    return {
      next: {
        titleKey: 'Next',
        behaviors,
        isVisible: true,
      },
      back: { titleKey: 'Back', isVisible: true },
      draft: { titleKey: 'SaveAsDraft', isVisible: false },
    };
  }

  protected async getAttachmentConfigurationsFromApi(): Promise<AttachmentConfigurationResponse> {
    const request = this.createRequest();

    return await this.api
      .attachments<AttachmentConfigurationResponse>(request)
      .toPromise();
  }

  public get isSendBack(): boolean {
    if (this.isNotInitialized()) return false;

    const isSendBack =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails
        ?.serviceRequestTransactionData?.statusCode ==
      EsRequestStatusCodesEnum.InitiatorSendBack;

    return isSendBack;
  }

  public get isPendingOnInitiatorUploading(): boolean {
    if (this.isNotInitialized()) return false;

    const isSendBack =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails
        ?.serviceRequestTransactionData?.statusCode ==
      EsRequestStatusCodesEnum.PendingOnInitiatorUploading;

    return isSendBack;
  }

  protected get isNewRequest(): boolean {
    const requestId = this.globalObject.ServiceDetailsConfiguration.id;
    const requestIdExist = requestId > 0;

    return requestIdExist == false;
  }

  public isPendingOnPayment(): boolean {
    if (this.isNotInitialized()) return false;

    return (
      this.globalObject.ServiceDetailsConfiguration.serviceDetails
        ?.serviceRequestTransactionData?.statusCode ==
      EsRequestStatusCodesEnum.PendingOnPayment
    );
  }

  public isPendingOnSecondPayment(): boolean {
    if (this.isNotInitialized()) return false;

    return (
      this.globalObject.ServiceDetailsConfiguration.serviceDetails
        ?.serviceRequestTransactionData?.statusCode ==
      EsRequestStatusCodesEnum.PendingOnSecondPayment
    );
  }
  //#region Attachments

  protected createAttachmentRequest(): EsAttachmentFileDto[] {
    return this.globalObject.AttachmentsConfiguration.attachments;
  }

  public addAttachment(attachment: EsAttachmentFileDto): void {
    this.globalObject.AttachmentsConfiguration.attachments.push(attachment);
  }

  public removeAttachment(fileId: string): void {
    this.globalObject.AttachmentsConfiguration.attachments =
      this.globalObject.AttachmentsConfiguration.attachments.filter(
        (a: EsAttachmentFileDto) => a.FileId != fileId
      );
  }

  public getAttachment(attachmentId: number): EsAttachmentFileDto {
    return this.globalObject.AttachmentsConfiguration.attachments.find(
      (i: any) => i.ProcedureAttachmentId == attachmentId
    );
  }

  async loadAttachmentsConfigurations() {
    const attachmentsResponse = await this.getAttachmentConfigurationsFromApi();

    if (attachmentsResponse.Data == null) {
      attachmentsResponse.Data = [];
    }

    let attachments = this.mapper.mapServiceAttachments(
      attachmentsResponse.Data
    );

    attachments = attachments.sort((a, b) => {
      return (b.isRequired as any) - (a.isRequired as any);
    });

    this.globalObject.ServiceDetailsConfiguration.serviceConfig.attachments =
      attachments;

    if (
      this.globalObject.ServiceDetailsConfiguration.serviceConfig.attachments
        ?.length <= 0
    ) {
      this.removeAttachmentStep();
    }
  }

  protected removeAttachmentStep() {
    const indexOfAttachmentStep = this.steps.indexOf(
      WorkflowSteps.AttachmentsConfiguration
    );

    if (indexOfAttachmentStep >= 0) this.steps.splice(indexOfAttachmentStep, 1);
  }

  public getAllAttachments(): EsServiceAttachments[] {
    return this.globalObject.ServiceDetailsConfiguration.serviceConfig
      .attachments;
  }

  //#endregion

  //#region create dtos

  protected createApplicantRequestData(): EsApplicantDto {
    const state = this.globalObject.ApplicantConfiguration;

    const applicant = state.form.get('applicant').value as EsApplicant;

    return this.mapper.mapApplicantDto(
      applicant,
      this.getTimeTakenByCustomer()
    );
  }

  protected getTimeTakenByCustomer(): number {
    const key = this.getServiceUrlKeyWord();

    const result = Math.floor(TimeMe.getTimeOnPageInSeconds(key) || 0);

    return result;
  }

  //#endregion

  //#region utils

  protected assignObjectKeys(destination: any, source: any) {
    const keys = Object.keys(source);

    for (const key of keys) (destination as any)[key] = (source as any)[key];
  }

  //#endregion

  private resolvePlatformChannel(): string {
    let channel = 'FullPortal';

    if (this.platform.IOS || this.platform.ANDROID) {
      channel = 'MobileApp';
    }

    return channel;
  }

  protected createAdditionalDataRequest(): AdditionalDataDto[] {
    const additionalData: AdditionalDataDto[] = [];

    additionalData.push({
      Name: 'Channel',
      Value: this.resolvePlatformChannel(),
    });

    const requestPurpose =
      this.globalObject.ApplicantConfiguration.form.controls
        .selectedRequestPurposeType?.value;

    if (requestPurpose)
      additionalData.push({
        Name: 'RequestPurposeID',
        Value: requestPurpose.id,
      });

    return additionalData;
  }

  protected buildServiceConfigurationFlowButtons(): FlowButtons {
    return {
      next: {
        titleKey: 'Next',
        isVisible: true,
        behaviors: [
          FlowButtonActions.VALIDATE_MAIN_FORM,
          FlowButtonActions.LOAD_ATTACHMENTS,
        ],
      },
      back: { titleKey: 'Back', isVisible: true },
      draft: { titleKey: 'SaveAsDraft', isVisible: false },
      saveTemplate: { titleKey: 'trUpdateTemplate', isVisible: false },
      addTemplate: { titleKey: 'trAddTemplate', isVisible: false },
    };
  }

  protected buildApplicantFlowButtons(): FlowButtons {
    return {
      next: {
        titleKey: 'Next',
        isVisible: true,
        behaviors: [FlowButtonActions.VALIDATE_APPLICANT],
      },
    };
  }

  protected isNotInitialized() {
    return this.globalObject == undefined;
  }

  public getFees(): Subject<EsFees> {
    return this.globalObject.ServiceDetailsConfiguration.fees$;
  }

  protected subscribeToServiceConfiguration() {
    const store = this.globalObject.ServiceDetailsConfiguration;

    const serviceDetails = new EsServiceRequestConfiguration();
    serviceDetails.accessibility = new EsUserAccessibilityToService();

    store.serviceConfig$ = new BehaviorSubject(serviceDetails);
    store.serviceConfig$.subscribe((sd: EsServiceRequestConfiguration) =>
      this.onServiceConfigurationLoad(sd)
    );
  }

  protected onServiceConfigurationLoad(
    serviceConfig: EsServiceRequestConfiguration
  ): void {
    this.globalObject.ServiceDetailsConfiguration.serviceConfig = serviceConfig;

    this.refreshFlowButtonsVisibility();
  }

  protected refreshFlowButtonsVisibility() {
    const isDraftAllowed =
      this.globalObject.ServiceDetailsConfiguration.serviceConfig
        .isDraftAllowed;

    this.globalObject.ServiceDetailsConfiguration.flowButtons.draft.isVisible =
      isDraftAllowed && this.hasRequestId == false;
    this.globalObject.AttachmentsConfiguration.flowButtons.draft.isVisible =
      isDraftAllowed && this.hasRequestId == false;

    this.globalObject.ServiceDetailsConfiguration.flowButtons.saveTemplate.isVisible =
      isDraftAllowed && this.isTemplateEditing;

    this.globalObject.ServiceDetailsConfiguration.flowButtons.addTemplate.isVisible =
      isDraftAllowed &&
      this.isTemplateCloning == false &&
      this.isTemplateEditing == false &&
      this.hasDraftRequestId == false;

    if (this.isTemplateEditing) {
      this.globalObject.ServiceDetailsConfiguration.flowButtons.next = null;
      this.globalObject.ServiceDetailsConfiguration.flowButtons.back = null;
      this.globalObject.ServiceDetailsConfiguration.flowButtons.draft = null;
    }
  }

  private get hasDraftRequestId(): boolean {
    return this.globalObject.ServiceDetailsConfiguration.draftRequestId > 0;
  }

  protected showRequestTemplateForm() {
    this.globalObject.ServiceDetailsConfiguration.isShowRequestTemplateForm =
      true;
  }

  protected subscribeToMetadata() {
    const store = this.globalObject.ServiceDetailsConfiguration;

    const serviceDetails = new ServiceMetaData();

    store.serviceMetaData$ = new BehaviorSubject(serviceDetails);
  }

  protected async loadMetadata(): Promise<void> {
    const metadataResponse = await this.metadataService
      .getServiceDetailByDigitalNumber(
        this.globalObject.ServiceDetailsConfiguration.procedureId.toString()
      )
      .toPromise();

    const requestTypeCode =
      this.route.snapshot.queryParamMap.get('requestTypeCode');

    const serviceMetaData = this.mapper.mapMetadata(
      metadataResponse.responseData,
      requestTypeCode
    );

    this.globalObject.ServiceDetailsConfiguration.serviceMetaData$.next(
      serviceMetaData
    );
  }

  protected async initializeForEditing(): Promise<void> {
    if (this.isCustomerAllowedToEditRequest()) {
      this.bindMainFormTransaction();
    } else {
      this.router.navigate([this.viewUrl], { queryParamsHandling: 'preserve' });
      this.toastr.info(
        this.translateService.instant('trNotAllowedToModifyRequest')
      );
    }
  }

  protected async initializeForEditingDraft(): Promise<void> {
    this.bindMainFormTransaction();
  }

  protected async initializeForTemplate(): Promise<void> {
    this.bindMainFormTransaction();
  }

  protected async initializeForViewing(): Promise<void> {}

  protected isCustomerAllowedToEditRequest() {
    const serviceDetails =
      this.globalObject?.ServiceDetailsConfiguration.serviceDetails;

    if (serviceDetails) return serviceDetails.isLocked == false;

    return true;
  }

  public async startWorkflow(
    requestId: string,
    draftRequestId: string,
    templateId: string,
    cloneTemplateId: string,
    procedureId: string,
    formMode: FormMode
  ) {
    this.globalObject.ServiceDetailsConfiguration.id =
      Number.parseInt(requestId);
    this.globalObject.ServiceDetailsConfiguration.draftRequestId =
      Number.parseInt(draftRequestId);
    this.globalObject.ServiceDetailsConfiguration.procedureId =
      Number.parseInt(procedureId);

    if (draftRequestId) {
      ///Ignore templateId and cloneTemplateId in case of draftRequestId
    } else if (templateId) {
      this.globalObject.ServiceDetailsConfiguration.templateMode =
        TemplateModeEnum.Edit;
      this.globalObject.ServiceDetailsConfiguration.templateId =
        Number.parseInt(templateId);
    } else if (cloneTemplateId) {
      this.globalObject.ServiceDetailsConfiguration.templateMode =
        TemplateModeEnum.Clone;
      this.globalObject.ServiceDetailsConfiguration.templateId =
        Number.parseInt(cloneTemplateId);
    }

    this.formMode = formMode;

    this.initializeFlowAndSubscribe();

    this.loadMetadata();

    if (formMode != FormMode.VIEW) {
      const serviceConfigResponse = await this.loadServiceConfigurations();

      const accessibility = this.mapper.mapUserServiceAccessibility(
        serviceConfigResponse.Data.UserAccessibilityToService
      );

      const serviceAccessResult = await this.resolveServiceAccessConfigurations(
        accessibility,
        formMode
      );

      if (serviceAccessResult.isRedirect) {
        this.router.navigate([serviceAccessResult.url], {
          state: serviceAccessResult.state,
        });
        return;
      }

      const config = this.mapper.mapGetServiceConfigurationResponse(
        serviceConfigResponse
      );

      this.startTimer();

      this.emitServiceConfiguration(config);
    }

    if (draftRequestId) {
      await this.loadDraftServiceRequest();
    } else if (this.globalObject.ServiceDetailsConfiguration.templateId) {
      await this.loadRequestTemplate();
    } else if (requestId) {
      await this.loadServiceRequest();
      if (
        this.isSendBack ||
        this.isPendingOnPayment() ||
        this.isPendingOnSecondPayment() ||
        this.isPendingOnInitiatorAction()
      )
        this.ReviewStateObject.form.removeControl('acceptTerms');
    }

    if (formMode == FormMode.EDITING) {
      await this.validateFlow();
    }

    if (
      formMode == FormMode.EDITING &&
      this.globalObject.ServiceDetailsConfiguration.templateId
    ) {
      await this.initializeForTemplate();
    } else if (formMode == FormMode.EDITING && draftRequestId) {
      await this.initializeForEditingDraft();
    } else if (formMode == FormMode.EDITING && requestId) {
      await this.initializeForEditing();
    }

    if (formMode == FormMode.VIEW && requestId) {
      await this.initializeForViewing();
    }
  }

  private async loadRequestTemplate(): Promise<void> {
    const templateId =
      this.globalObject.ServiceDetailsConfiguration.templateId.toString();

    const response = await this.commonApiService
      .getTemplate(templateId)
      .toPromise();

    const mapped = this.mapper.mapTemplateRequestResponse(response.Data);

    this.fillRequestTemplateForm(response.Data);

    if (this.isTemplateEditing) {
      this.showRequestTemplateForm();
    }

    this.globalObject.ServiceDetailsConfiguration.serviceDetails$.next(mapped);
  }

  private fillRequestTemplateForm(templateDto: ParticipantTemplateDto) {
    const requestTemplateForm =
      this.globalObject.ServiceDetailsConfiguration.formRequestTemplate;

    requestTemplateForm.controls.name.setValue(templateDto.TemplateName);
  }

  private async loadDraftServiceRequest(): Promise<void> {
    const draftRequestId =
      this.globalObject.ServiceDetailsConfiguration.draftRequestId.toString();

    const response = await this.commonApiService
      .getDraftRequest(draftRequestId)
      .toPromise();

    const mapped = this.mapper.mapUltimateDraftResponse(response.Data);

    this.setPureApplicant(mapped.applicant, mapped.requestPurpose);

    this.globalObject.AttachmentsConfiguration.attachments =
      this.mapper.mapAttachmentsInstances(response.Data.AttachmentDetails);

    this.globalObject.ServiceDetailsConfiguration.serviceDetails$.next(mapped);
  }

  private setPureApplicant(
    applicant: EsApplicant,
    requestPurpose: RequestPurposeLkp
  ) {
    this.globalObject.ApplicantConfiguration.form.controls.applicant.setValue(
      applicant
    );

    if (requestPurpose) {
      this.globalObject.ApplicantConfiguration.form.controls.selectedRequestPurposeType?.setValue(
        requestPurpose
      );
      this.globalObject.ApplicantConfiguration.form.controls.selectedRequestPurposeType?.disable();
    }
  }

  private startTimer(): void {
    TimeMe.initialize({
      currentPageName: this.getServiceUrlKeyWord(), // current page
      idleTimeoutInSeconds: 600,
    });

    TimeMe.startTimer();
  }

  protected isView() {
    return this.formMode == FormMode.VIEW;
  }

  protected async loadServiceConfigurations(): Promise<GetServiceConfigurationResponse> {
    let requestId = this.globalObject.ServiceDetailsConfiguration.id;

    if (Number.isNaN(requestId)) {
      requestId = 0;
    }

    return await this.api
      .getServiceConfiguration<GetServiceConfigurationResponse>(requestId)
      .toPromise();
  }

  private get serviceNotAllowedUrl(): string {
    return `/services/serviceAccessibility`;
  }

  private async resolveServiceAccessConfigurations(
    config: EsUserAccessibilityToService,
    formMode: FormMode
  ): Promise<ServiceAccessResult> {
    const result = new ServiceAccessResult();

    if (
      formMode != FormMode.VIEW &&
      config.isUserAllowedToUseService == false
    ) {
      result.isRedirect = true;
      result.url = this.serviceNotAllowedUrl;
      result.state = config;
    }

    return result;
  }

  protected emitServiceConfiguration(
    serviceConfig: EsServiceRequestConfiguration
  ) {
    this.globalObject.ServiceDetailsConfiguration.serviceConfig$.next(
      serviceConfig
    );
  }

  resetPaymentAmount(code: RequestPurposeTypeEnum) {}

  protected get viewUrl(): string {
    return `/services/${this.getServiceUrlKeyWord()}/view`;
  }

  getPostDataResponseObject() {
    return {
      id: this.globalObject.ServiceDetailsConfiguration.id,
    };
  }

  isWaitingForCustomerAction(): boolean {
    return false;
  }

  protected setCongrats() {
    this.globalObject.Congratulations.registerUserId = this.utilService
      .getUserProfile()
      .id.toString();

    this.globalObject.Congratulations.isFeedbackDone =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails.serviceRequestTransactionData.isFeedbackDone;

    this.globalObject.Congratulations.transactionId =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails.serviceRequestTransactionData.id.toString();
    this.globalObject.Congratulations.serviceRequestNumber =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails.serviceRequestTransactionData.serviceRequestNumber;
    this.globalObject.Congratulations.statusNameAr =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails.serviceRequestTransactionData.statusAr;
    this.globalObject.Congratulations.statusNameEn =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails.serviceRequestTransactionData.statusEn;
  }

  protected async validateFlow(): Promise<void> {
    const result = WorkflowSteps.ApplicantConfiguration;

    if (result != this.currentStep) this.goToStep(result, this.router);
  }

  public getPossibleStepAfterLastValidStep(curStep: string): string {
    const curIndex = this.steps.indexOf((<any>WorkflowSteps)[curStep]);
    if (
      // check if this is the first step
      curIndex <= 0 ||
      // check if previous step is valid and entered
      ((<any>this.globalObject)[this.steps[curIndex - 1]].form.valid == true &&
        (<any>this.globalObject)[this.steps[curIndex - 1]].enableStep ==
          true) ||
      // check if the current step is valid and entered
      ((<any>this.globalObject)[this.steps[curIndex]].form.valid &&
        (<any>this.globalObject)[this.steps[curIndex]].enableStep == true) ||
      (<any>this.globalObject)[this.steps[curIndex - 1]].isValidationSkipped ==
        true
    ) {
      return curStep;
    } else {
      return this.getPossibleStepAfterLastValidStep(this.steps[curIndex - 1]);
    }
  }

  protected goToStep(stepKey: string, router: Router) {
    if (this.globalObject.Congratulations.form.valid == false) {
      const step = (<any>this.globalObject)[stepKey];
      const url = step.url;
      if (url && step.enableStep) {
        router.navigate([url], { queryParamsHandling: 'preserve' });
      } else console.warn('step is not enabled');
    }
  }

  public async saveOrUpdate() {
    const request = this.createRequest();

    if (this.globalObject.ServiceDetailsConfiguration.id) {
      const result = await this.api
        .updateRequest<InitiateRequestResponse>(request)
        .toPromise();

      this.showUpdateSuccessMessage(result);

      this.setInitiateDetails(result.Data);

      if (result.IsSuccess) {
        this.cancelOriginalDraftRequest();
      }
    } else {
      const clientIPInfo = await this.clientIPService.getClientIpInfo();
      if (clientIPInfo) {
        request.AddtionalData.push(clientIPInfo);
      }

      const result = await this.api
        .initiateRequest<InitiateRequestResponse>(request)
        .toPromise();

      this.showInitiateSuccessMessage(result);

      this.setInitiateDetails(result.Data);

      if (result.IsSuccess && result.Data) {
        await this.commonApiService
          .generateTransactionEntry(result.Data.ProcedureInstanceID.toString())
          .toPromise();
      }

      if (result.IsSuccess) {
        this.cancelOriginalDraftRequest();
      }
    }

    this.setCongrats();
  }

  protected showInitiateSuccessMessage(result: InitiateRequestResponse) {
    const title = this.translateService.instant('trRequestSavedSuccessfully', {
      status: this.isArabic
        ? result.Data.LocalizedStatusCodeAr
        : result.Data.LocalizedStatusCode,
    });

    this.toastr.success(result.Data.ProcedureInstanceNumber, title);
  }

  protected showUpdateSuccessMessage(result: InitiateRequestResponse) {
    const title = this.translateService.instant(
      'trRequestUpdatedSuccessfully',
      {
        status: this.isArabic
          ? result.Data.LocalizedStatusCodeAr
          : result.Data.LocalizedStatusCode,
      }
    );

    this.toastr.success(result.Data.ProcedureInstanceNumber, title);
  }

  protected setInitiateDetails(
    initiateResponseDto: InitiateRequestResponseDto
  ) {
    this.setRequestId(initiateResponseDto.ProcedureInstanceID);

    this.refreshFlowButtonsVisibility();

    this.emitFees(initiateResponseDto.Fees);

    this.globalObject.ServiceDetailsConfiguration.isPaymentRequired =
      initiateResponseDto.IsPaymentRequired;

    this.setRequestTransactionData(initiateResponseDto);
  }

  private emitFees(feesDto: EsFeesDto) {
    this.globalObject.ServiceDetailsConfiguration.fees$.next(
      this.mapper.mapFees(feesDto)
    );
  }

  private setRequestTransactionData(
    initiateResponseDto: InitiateRequestResponseDto
  ) {
    const serviceDetails =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails;

    if (serviceDetails.serviceRequestTransactionData) {
      serviceDetails.serviceRequestTransactionData.statusAr =
        initiateResponseDto.LocalizedStatusCodeAr;
      serviceDetails.serviceRequestTransactionData.statusEn =
        initiateResponseDto.LocalizedStatusCode;
      serviceDetails.serviceRequestTransactionData.statusCode =
        initiateResponseDto.TaskName as EsRequestStatusCodesEnum;
      serviceDetails.serviceRequestTransactionData.serviceRequestNumber =
        initiateResponseDto.ProcedureInstanceNumber;
      serviceDetails.serviceTransaction.procedureInstanceId =
        initiateResponseDto.ProcedureInstanceID;
    } else {
      serviceDetails.serviceRequestTransactionData = {
        id: initiateResponseDto.ProcedureInstanceID,
        serviceRequestNumber: initiateResponseDto.ProcedureInstanceNumber,
        statusAr: initiateResponseDto.LocalizedStatusCodeAr,
        statusEn: initiateResponseDto.LocalizedStatusCode,
        statusCode: initiateResponseDto.TaskName as EsRequestStatusCodesEnum,
      };

      serviceDetails.serviceTransaction.procedureInstanceId =
        initiateResponseDto.ProcedureInstanceID;
    }
  }

  private setRequestId(requestId: number) {
    this.globalObject.ServiceDetailsConfiguration.id = requestId;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        requestId: requestId,
        draftRequestId: null, ///Remove the draft parameter
        templateId: null, ///Remove the template parameter
        cloneTemplateId: null, ///Remove the template parameter
      },
      queryParamsHandling: 'merge',
    });
  }

  async resubmit(): Promise<void> {
    const result = await this.api
      .sendBackRequest(this.createRequest())
      .toPromise();
    this.setInitiateDetails(result.Data as InitiateRequestResponseDto);
    this.setCongrats();
  }

  public moveFlow($event: MoveFlowStepDataHandler) {
    switch ($event.side) {
      case RequestActions.Back:
        this.moveBack(this.router);
        break;
      case RequestActions.Next:
        this.moveForward(this.router);
        break;
      default:
        this.goToStep($event.step, this.router);
    }
  }

  protected moveBack(router: Router, isSkipped = false) {
    const curStep = this.currentStep;
    const curIndex = this.steps.indexOf(curStep);
    if (curIndex > 0) {
      const url = (<any>this.globalObject)[this.steps[curIndex - 1]].url;
      if (url) {
        if (!isSkipped) this.pageNumber--;
        router.navigate([url], { queryParamsHandling: 'preserve' });
        this.setCurrentStep(this.steps[curIndex - 1]);
        this.emitFlowStepsChange();
      }
    }
  }

  protected moveForward(router: Router, isSkipped = false) {
    const curIndex = this.steps.indexOf(this.currentStep);
    (<any>this.globalObject)[this.steps[curIndex]].enableStep = true;
    if (curIndex < this.steps.length) {
      const url = (<any>this.globalObject)[this.steps[curIndex + 1]].url;
      const requestId = this.globalObject.ServiceDetailsConfiguration.id;

      if (url) {
        if (!isSkipped) this.pageNumber++;
        router.navigate([url], {
          queryParamsHandling: requestId ? 'merge' : 'preserve',
          queryParams: { requestId },
        });
        this.setCurrentStep(this.steps[curIndex + 1]);
        this.emitFlowStepsChange();
      }
    }
  }

  private emitFlowStepsChange() {
    const flow = this.globalObject.ServiceDetailsConfiguration.flow;

    flow.steps = flow.steps.map((s, index) => {
      const ss: MainStepOptions = s;

      ss.isValidatedAndEnabled = index <= this.pageNumber - 1;

      return ss;
    });

    flow.showForwardArrow = false;

    this.globalObject.ServiceDetailsConfiguration.flow$.next(flow);
  }

  protected scrollToFirstError() {
    setTimeout(() => {
      const invalidElement = this.document.querySelector('.is-invalid');

      if (invalidElement)
        invalidElement.closest('.form-section').scrollIntoView();
    });
  }

  protected async validateRequiredAttachments(): Promise<boolean> {
    const requiredAttachments =
      this.globalObject.ServiceDetailsConfiguration.serviceConfig.attachments.filter(
        (a) => a.isRequired
      );

    const uploaded = requiredAttachments.map((requiredAttachment) => {
      return (
        this.globalObject.AttachmentsConfiguration.attachments.filter(
          (a) =>
            a.ProcedureAttachmentId == requiredAttachment.procedureAttachmentId
        ).length > 0
      );
    });

    var isValid = uploaded.every((uploaded) => uploaded == true);

    if (isValid == false) {
      setTimeout(() => {
        const requiredAttachment = this.document.querySelector(
          '.attachment-required'
        );

        if (requiredAttachment)
          requiredAttachment.closest('.attachment-group').scrollIntoView();
      });
    }

    return isValid;
  }

  protected setApplicant(serviceRequestDto: ServiceRequestDto<any>) {
    const applicant = this.mapper.mapApplicant(serviceRequestDto.Applicant);

    this.globalObject.ApplicantConfiguration.form.controls.applicant.setValue(
      applicant
    );

    const requestPurpose = this.mapper.mapRequestPurposeId(
      serviceRequestDto.ProcedureInstanceObject
    );

    if (requestPurpose) {
      this.globalObject.ApplicantConfiguration.form.controls.selectedRequestPurposeType?.setValue(
        requestPurpose
      );
      this.globalObject.ApplicantConfiguration.form.controls.selectedRequestPurposeType?.disable();
    }
  }

  protected buildAuthorityLicenseNumber(): FieldOptions {
    const config = this.globalObject.ServiceDetailsConfiguration.serviceConfig;
    let isVisible = true;
    let isRequired = true;
    let hasCertificate = false;
    let value = undefined;
    let isDisabled = false;

    const userProfile = this.utilService.getUserProfile();

    if (config) {
      if (userProfile.registeredUserType == RegisteredUserTypesEnum.Person) {
        isRequired = isVisible = false;
      } else {
        hasCertificate =
          config.licenseDetails &&
          config.licenseDetails.certificateNumber != null &&
          config.licenseDetails.certificateNumber != '';

        if (hasCertificate) {
          isVisible = true;
          isDisabled = true;
          value = config.licenseDetails.certificateNumber;
        } else {
          isRequired = false;

          if (config.procedureId == 137) {
            //falcon ring
            isRequired = true;
          }

          if (this.getRequestPurposeCode == RequestPurposeTypeEnum.Research) {
            isVisible = false;
            isRequired = false;
          }

          if (this.getRequestPurposeCode == RequestPurposeTypeEnum.Commercial) {
            isRequired = true;
            isDisabled = false;
          }
        }
      }
    }

    const result = new FieldOptions({
      isVisible,
      isRequired,
      isDisabled,
      value,
    });

    return result;
  }

  protected get getRequestPurposeCode(): RequestPurposeTypeEnum {
    const requestPurpose: RequestPurposeLkp =
      this.globalObject.ApplicantConfiguration.form.controls
        .selectedRequestPurposeType.value;

    return requestPurpose?.code;
  }

  protected ApplicantStateObject: EsApplicantStateObjectType = {
    form: new FormGroup({
      applicant: new FormControl(null, Validators.required),
      id: new FormControl(null),
      participantId: new FormControl(null),
      emiratesId: new FormControl(null, [
        Validators.required,
        emiratesIdFormat(),
      ]),
      passport: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.email),
      preferredLanguage: new FormControl(null, Validators.required),
      selectedRequestPurposeType: new FormControl(null, Validators.required),
      identityType: new FormControl(
        EsApplicantIdentityType.ID,
        Validators.required
      ),
      otp: new FormControl(null),
    }),
    isNewApplicant: false,
    findByEmirateIdResponse: null,
    isValidEmiratesId: false,
    enableStep: true,
    showAddNewApplicantButton: true,
    showEditApplicantButton: true,
    isEditMode: false,
    showRequestPurposeType: false,
    selectedRequestPurposeTypeCode: null,
    url: '/services/' + this.getServiceUrlKeyWord() + '/applicantinformation',
    flowButtons: this.buildApplicantFlowButtons(),
    requestPurposes: this.mapper.mapRequestPurpose(),
  };

  public isPendingOnInitiatorAction(): boolean {
    const store = this.globalObject.ServiceDetailsConfiguration;
    if (this.isNotInitialized()) return false;

    return (
      store.serviceDetails?.serviceRequestTransactionData?.statusCode ==
        EsRequestStatusCodesEnum.PendingOnInitiatorAction ||
      store.serviceDetails?.serviceRequestTransactionData?.statusCode ==
        EsRequestStatusCodesEnum.ObjectionApplied
    );
  }

  async submitInitiatorAction() {
    const initiatorActionRequest = this.createInitiatorActionRequest();
    if (initiatorActionRequest) {
      const result = await this.api
        .submitInitiatorAction(initiatorActionRequest)
        .toPromise();
      this.showUpdateSuccessMessage(result);
      this.setInitiateDetails(result.Data);
    }
  }

  protected createInitiatorActionRequest(): any {}

  protected clearControl(control: AbstractControl) {
    control.reset();
  }

  protected navigateToDashboard(): void {
    this.router.navigate(['home']);
  }

  public async callPayAndRedirectToGateway(): Promise<void> {
    this.globalObject.ReviewConfiguration.isValidationSkipped = false;

    if (await this.validateTermsAndConditions()) {
      this.api
        .payRequest<PayResponse>(
          this.globalObject.ServiceDetailsConfiguration.id,
          environment.esMerchantUrl,
          this.lang
        )
        .toPromise()
        .then((res) => {
          this.document.location.href = res.Data.CheckoutUrl;
        });
    } else {
      console.warn('terms and conditions is not valid');
      return;
    }
  }

  protected async validateTermsAndConditions(): Promise<boolean> {
    const form = this.globalObject.ReviewConfiguration.form;

    if (form.valid) return form.valid;
    else {
      form.markAllAsTouched();
      this.toastr.info(
        this.translateService.instant('trAcceptTermsFld.requiredMessage')
      );

      return false;
    }
  }

  protected isValidAndEnabledStep(step: WorkflowSteps): boolean {
    const curIndex = this.steps.indexOf(step);
    if (curIndex >= 0) {
      return (
        (<any>this.globalObject)[this.steps[curIndex]].enableStep == true &&
        (<any>this.globalObject)[this.steps[curIndex]].form.valid == true
      );
    }
    return false;
  }

  public flow(): MainStepsConfigurations {
    return {
      steps: this.steps.map((s) => {
        return {
          key: s,
          isValidatedAndEnabled: this.isValidAndEnabledStep(s),
          display: s == WorkflowSteps.Congratulations ? false : true,
        };
      }),
      showForwardArrow: false,
    };
  }

  public initializeFlowAndSubscribe() {
    const config = new MainStepsConfigurations();

    config.steps = this.steps.map((s) => {
      return {
        key: s,
        isValidatedAndEnabled: false,
        display: s == WorkflowSteps.Congratulations ? false : true,
      };
    });

    this.globalObject.ServiceDetailsConfiguration.flow = config;
    this.globalObject.ServiceDetailsConfiguration.flow$ = new BehaviorSubject(
      config
    );
    this.globalObject.ServiceDetailsConfiguration.flow$.subscribe((f) =>
      this.onFlowChange(f)
    );
  }

  protected onFlowChange(f: MainStepsConfigurations): void {}

  protected payLater() {
    this.globalObject.ReviewConfiguration.isValidationSkipped = true;
    this.moveForward(this.router);
  }

  protected async updateReviewButtons(): Promise<void> {
    const one = new Promise<void>((resolve, reject) => {
      this.globalObject.ReviewConfiguration.flowButtons.isShowPay = true;
      this.globalObject.ReviewConfiguration.flowButtons.isShowPayLater = true;
      this.globalObject.ReviewConfiguration.flowButtons.next = null;
      resolve();
    });

    return one;
  }

  protected async resetReviewButtons() {
    const one = new Promise<void>((resolve, reject) => {
      const review = this.globalObject.ReviewConfiguration;
      const buttons = this.buildReviewFlowButtons();

      review.flowButtons.isShowPay = buttons.isShowPay;
      review.flowButtons.isShowPayLater = buttons.isShowPayLater;
      review.flowButtons.next = buttons.next;

      resolve();
    });

    return one;
  }

  protected async back(): Promise<void> {
    if (this.currentStep == WorkflowSteps.ReviewConfiguration) {
      if (this.globalObject.ServiceDetailsConfiguration.isPaymentRequired) {
        await this.resetReviewButtons();
      } else {
        this.globalObject.ReviewConfiguration.isValidationSkipped = false;
      }
    }

    this.moveBack(this.router);
  }

  protected validateUserProfile(): boolean {
    const userProfile = this.utilService.getUserProfile();
    return userProfile != null;
  }

  public async setFeedbackDone(): Promise<void> {
    const id = this.globalObject.ServiceDetailsConfiguration.id;
    await this.commonApiService.setIsFeedbackDone(id).toPromise();
  }
}
