<div *ngIf="showLegend">
  <legend [innerText]="'trOutputs' | translate" class="col-form-label"></legend>
</div>
<ul
  *ngIf="outputs && outputs.length; else noItemsAddedBlock"
  class="list-group ds-list-group-default"
>
  <li
    class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2"
  >
    <app-detail-display
      class="w-mm-30"
      key="trOutputCertificateNumber"
    ></app-detail-display>
    <app-detail-display
      class="w-mm-40"
      key="trOutputCertificateName"
    ></app-detail-display>
  </li>
  <li
    class="list-group-item d-flex justify-content-start align-items-center cursor-default shadow-none"
    *ngFor="let output of outputs | searchFilter: searchText | paginate: { id: 'es_output_paginate', itemsPerPage: itemsPerPage, currentPage: currentPage }"
  >
    <div class="ds-list-group-item-head d-flex w-100 p-2">
      <app-detail-display
        class="w-mm-30"
        [value]="output.certificateNumber"
      ></app-detail-display>

      <app-detail-display
        class="w-mm-40"
        [value]="isArabic ? output.titleAr : output.titleEn"
      ></app-detail-display>

      <span class="align-self-center" [class.bg-primary]="">
        <i
          [class.success]="output.colorKey == VALID"
          [class.danger]="output.colorKey == EXPIRED"
          class="fi-info"
          ngbTooltip="{{ 'trOutput.' + output.colorKey | translate }}"
        ></i>
      </span>

      <div class="list-group-item-options">
        <a
          class="ds-btn ds-btn-success ds-btn-sm"
          [href]="output.url"
          [innerText]="'trDownloadOutput' | translate"
        >
        </a>
      </div>
    </div>
  </li>
  <li class="list-group-item d-flex justify-content-start align-items-center px-2">
    <div *ngIf="outputs.length > itemsPerPage"
          class="w-mm-50 pt-1">
      <p>
        {{ ("ItemsCount" | translate) +": "+ outputs.length }} 
      </p>
    </div>
    <div class="w-mm-50 pagination_in">
      <div class="pagination">
        <pagination-controls 
          id="es_output_paginate"
          previousLabel="{{'PaginationPreviousLabel' | translate}}"
          nextLabel="{{'PaginationNextLabel' | translate }}"
          (pageChange)="currentPage = $event"
          [autoHide]="true"
          [responsive]="true"
          [maxSize]="3">
        </pagination-controls>
      </div>
    </div>
  </li>
</ul>

<ng-template #noItemsAddedBlock>
  <app-list-no-items></app-list-no-items>
</ng-template>
