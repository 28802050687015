import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base/BaseComponent';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
})
export class BreadcrumbsComponent
  extends BaseComponent
  implements OnChanges, OnInit
{
  breadcrumbs$: any;
  breadcrumbs: BreadcrumbOptions[] = [];

  @Input()
  breadcrumbsDetails: any = {};

  @Input()
  showHelpSection: boolean = true;

  @Input()
  serviceMetaData: ServiceMetaData;

  @Input()
  serviceKey: string;

  constructor(
    translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(translateService);

    ///build the breadcrumbs on refresh
    this.breadcrumbs$ = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(distinctUntilChanged())
      .pipe(map(() => this.buildBreadCrumb(this.activatedRoute.root)))
      .subscribe((breadcrumbs) => (this.breadcrumbs = breadcrumbs));
  }

  ngOnChanges() {
    /// rebuild the breadcrumbs when inputs are bounded
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit(): void {}

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<BreadcrumbOptions> = []
  ): Array<BreadcrumbOptions> {
    const breadcrumb: BreadcrumbOptions = this.buildBreadcrumbItem(route);

    let newBreadcrumbs: any[] = [];

    if (breadcrumb.labelKey) newBreadcrumbs = [...breadcrumbs, breadcrumb];
    else newBreadcrumbs = [...breadcrumbs];

    if (route.firstChild) {
      // If we are not on our current path yet,
      // there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(
        route.firstChild,
        breadcrumb.url,
        newBreadcrumbs
      );
    }

    return newBreadcrumbs;
  }

  buildBreadcrumbItem(
    route: ActivatedRoute,
    url: string = ''
  ): BreadcrumbOptions {
    let isRoot: boolean = route.routeConfig == null;

    if (
      isRoot == false &&
      route.routeConfig.data?.breadcrumb?.useBreadcrumbKey &&
      this.breadcrumbsDetails
    ) {
      let breadcrumb =
        this.breadcrumbsDetails[
          route.routeConfig.data.breadcrumb.useBreadcrumbKey
        ];

      if (breadcrumb) return breadcrumb;

      return { labelKey: '' };
    }

    let labelKey: string = isRoot
      ? 'HOME'
      : route.routeConfig.data?.breadcrumb.labelKey;

    const path = route.routeConfig ? route.routeConfig.path : '';

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = `${url}${path}/`;

    return { labelKey, url: nextUrl };
  }

  get showFaqLink(): boolean {
    return (
      this.serviceMetaData.faqAr?.length > 100 ||
      this.serviceMetaData.faqEn?.length > 100
    );
  }
}

export interface BreadcrumbOptions {
  labelKey: string;
  labelKeyResolve?: string;
  url?: string;
  params?: string;
}
