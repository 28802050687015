import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/BaseComponent';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/core/utilities/util.service';
import { FormControl, FormGroup } from '@angular/forms';
import { SearchByKeywordService } from 'src/app/core/api-services/portal/search-by-keyword.service';
import { SearchByKeyWordResponse } from 'src/app/core/models/portal/SearchByKeyWordResponse';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';

declare var rspkr: any;
declare var ReadSpeaker: any;

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
})
export class HeaderMenuComponent extends BaseComponent implements OnInit {
  isShowSearchMenu: boolean = false;

  searchByKeywordForm: FormGroup = new FormGroup({
    keyword: new FormControl(null),
  });
  searchByKeywordResult: SearchByKeyWordResponse[] = [];
  editProfileURL: string;

  readonly mainWebsiteLink = 'https://www.moccae.gov.ae/';
  readonly eServicesBaseLink = 'https://eservices.moccae.gov.ae';

  constructor(
    translateService: TranslateService,
    private languageService: LanguageService,
    private searchByKeywordService: SearchByKeywordService,
    public utilService: UtilService
  ) {
    super(translateService);

    this.editProfileURL = environment.editProfileUrl;
  }

  ngOnInit(): void {
    this.searchByKeywordForm.get('keyword').valueChanges.subscribe((value) => {
      if (value.length > 1) {
        this.searchByKeyword(value);
      } else {
        this.searchByKeywordResult = [];
      }
    });
  }

  logout(): void {
    this.utilService.logout();
  }

  showSearchBar(isShow: boolean) {
    this.isShowSearchMenu = isShow;
  }

  handleSearchResultClick(keyword: string) {
    this.searchByKeywordForm.get('keyword').setValue('');
    this.searchByKeywordResult = [];
    window.open(
      'https://www.moccae.gov.ae/ar/search.aspx?type=pages|news|events|faqs|circulars|photos|videos|service&query=' +
        keyword
    );
  }

  searchByKeyword(keyword: string) {
    this.searchByKeywordService.getSearchResult(keyword).subscribe((res) => {
      this.searchByKeywordResult = res;
    });
  }

  search() {
    if (
      this.searchByKeywordForm.get('keyword').value != null &&
      this.searchByKeywordForm.get('keyword').value.trim() != ''
    ) {
      const searchCriteria = this.searchByKeywordForm.get('keyword').value;
      window.open(
        'https://www.moccae.gov.ae/ar/search.aspx?type=pages|news|events|faqs|circulars|photos|videos|service&query=' +
          searchCriteria
      );
    }
  }

  changeLanguage(lang: string) {
    try {
      if (ReadSpeaker.hl) {
        rspkr.hl.engine.textNode.clientExtension.postProcess.fullCleanUp();
      }
    } catch (error) {}

    this.languageService.changeLanguage(lang);
  }
}
