import { Component, Inject, Injector, Input } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { InputSettingsService } from '../../../input-settings.service';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';

@Component({
  selector: 'bs-ng-text',
  templateUrl: './bs-ng-text.component.html',
  providers: [MakeProvider(BsNgTextComponent)],
})
export class BsNgTextComponent extends BsNgInputBaseComponent {
  @Input() emailMessage = '';

  @Input() maxLength: string;

  ///using the ngx-mask properties https://www.npmjs.com/package/ngx-mask/v/13.1.15
  @Input() mask: string;
  @Input() prefix: string = '';
  @Input() dropSpecialCharacters: boolean | string[];

  @Input()
  trimWhitespace: boolean = false;

  override onBlur() {
    if (this.trimWhitespace && this.value?.trim) {
      this.value = this.value.trim();
    }

    super.onBlur();
  }

  get _maxLength(): number {
    return Number(this.maxLength);
  }

  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  protected assignTranslations(trs: any) {
    super.assignTranslations(trs);
    this.emailMessage = trs.emailMessage;
  }

  get hasEmailError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.email
    );
  }

  get hasCustomError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.customError
    );
  }

  get touched() {
    return this.ngControl.control.touched;
  }
}
