import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared-module/shared-module.module';
import { EsAttachmentService } from 'src/app/core/api-services/shared/es-attachment.service';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { EsSharedModule } from '../../es-shared/es-shared.module';
import { CitesOwnerActionFormComponent } from './cites-owner-action/cites-owner-action-form.component';
import { CitesSharedMapper } from './CitesSharedMapper';
import { CitesImporterFormComponent } from './importer-form/cites-importer-form.component';
import { CitesExporterFormComponent } from './exporter-form/cites-exporter-form.component';
import { CitesExporterReviewComponent } from './exporter-review/cites-exporter-review.component';
import { CitesImporterReviewComponent } from './importer-review/cites-importer-review.component';
import { CitesOutputsComponent } from './cites-output/cites-outputs.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CitesDeliveryFormComponent } from './delivery-form/cites-delivery-form.component';
import { CitesCodesComponent } from './cites-codes/cites-codes.component';

@NgModule({
  declarations: [
    CitesOwnerActionFormComponent,
    CitesImporterFormComponent,
    CitesExporterFormComponent,
    CitesExporterReviewComponent,
    CitesImporterReviewComponent,
    CitesOutputsComponent,
    CitesDeliveryFormComponent,
    CitesCodesComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbTooltipModule,
    TranslateModule.forChild(),
    BsDatepickerModule.forRoot(),
    RouterModule,
    SharedModule,
    EsSharedModule,
    NgxPaginationModule,
  ],
  exports: [
    SharedModule,
    EsSharedModule,
    CitesOwnerActionFormComponent,
    CitesImporterFormComponent,
    CitesExporterFormComponent,
    CitesExporterReviewComponent,
    CitesImporterReviewComponent,
    CitesOutputsComponent,
    CitesDeliveryFormComponent,
    CitesCodesComponent
  ],
  providers: [EsAttachmentService, EsCommonService, CitesSharedMapper],
})
export class CitesSharedModule {}
