import { Component, Input, OnInit } from '@angular/core';
import * as dashBoardDetails from '../../../core/models/customer/DashBoardDetails';
import { TranslateService } from '@ngx-translate/core';
import { PackageTypes } from '../../../core/enums/package-types';
import { HomeComponent } from '../home.component';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'main-company-package',
  templateUrl: './main-company-package.component.html',
  styleUrls: ['./main-company-package.component.scss'],
})
export class MainCompanyPackageComponent
  extends BaseComponent
  implements OnInit
{
  @Input() dashBoardPackages: dashBoardDetails.Package[] = [];
  @Input() currentSelectedPackageId: number;
  @Input() showDetailClicked: boolean;
  @Input() currentSelectedPackage: dashBoardDetails.Package;
  constructor(
    translateService: TranslateService,
    public homeComponent: HomeComponent
  ) {
    super(translateService);
  }

  ngOnInit(): void {}
  public get PackageTypeEnum(): typeof PackageTypes {
    return PackageTypes;
  }
}
