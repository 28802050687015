import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsAttachmentService } from 'src/app/core/api-services/shared/es-attachment.service';
import {
  EsAttachment,
  EsAttachmentGroup,
} from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-attachments-details',
  templateUrl: './es-attachments-details.component.html',
})
export class EsAttachmentsDetailsComponent extends BaseComponent {
  @Input()
  showLegend = true;

  @Input()
  attachments?: EsAttachment[] = null;

  attachmentsGroups?: EsAttachmentGroup[] = [];

  public isCollapsed: boolean[] = [];

  constructor(
    translateService: TranslateService,
    private esAttachmentService: EsAttachmentService
  ) {
    super(translateService);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.attachmentsGroups = this.groupAttachments();
  }

  private groupAttachments(): EsAttachmentGroup[] {
    const result: EsAttachmentGroup[] = [];

    if (this.attachments?.length) {
      for (const attachment of this.attachments) {
        const group = result.find(
          (a) => a.procedureAttachmentId == attachment.procedureAttachmentId
        );

        if (group) {
          group.attachments.push(attachment);
        } else {
          const newGroup: EsAttachmentGroup = {
            attachmentType: {
              id: attachment.procedureAttachmentId,
              nameAr: attachment.attachmentNameAr,
              nameEn: attachment.attachmentNameEn,
              code: attachment.attachmentCode,
            },
            procedureAttachmentId: attachment.procedureAttachmentId,
            attachments: [],
          };
          newGroup.attachments.push(attachment);
          result.push(newGroup);
        }
      }
    } else {
      return null;
    }

    return result;
  }

  async download(attachment: EsAttachment): Promise<void> {
    const result = await this.esAttachmentService
      .download(attachment.path)
      .toPromise();

    const array = this.dataURItoArray(result.Data.FileData);

    const blob = new Blob([array], { type: result.Data.FileMimeType });

    const url = URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = result.Data.FileName;
    link.click();
  }

  private dataURItoArray(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return int8Array;
  }
}
