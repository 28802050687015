<form [formGroup]="formGroup">
  <legend
    class="col-form-label"
    [innerText]="'ViewServiceInformation' | translate">
  </legend>

  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <bs-ng-dropdown
        formControlName="purpose"
        identifier="purpose"
        key="trCitesPurposeFld"
        name="purpose"
        [items]="purposes"
        [bindLabel]="defaultLookupDisplayProperty"
        (change)="onPurposeChange($event)"
        [disabled]="disablePurpose"
        infoDialogId="CitesPurposeCodes">
      </bs-ng-dropdown>
    </div>
  </div>

  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <bs-ng-date
        formControlName="arrivalDate"
        identifier="arrivalDate"
        key="trCitesArrivalDateFld"
        name="arrivalDate">
      </bs-ng-date>
    </div>
  </div>

  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <bs-ng-textarea
        formControlName="specialCases"
        identifier="specialCases"
        key="trCitesSpecialCasesFld"
        name="specialCases"
        maxLength="500">
      </bs-ng-textarea>
    </div>
  </div>
</form>

<app-cites-codes 
  dialogId="CitesPurposeCodes"
  titleKey="CitesPurposeCodesDescription" 
  [items]="purposeCodes">
</app-cites-codes>