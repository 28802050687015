import { FavoriteServiceHybrid } from '../../core/api-services/shared/services/ServicesListDataService';

export class ServiceCardEventEmitterDataHolder {
  action: ServiceCardActions;
  data: FavoriteServiceHybrid;
}

export enum ServiceCardActions {
  ToggleFavorite,
  FetchMetadata,
  Collapse,
}
