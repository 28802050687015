<div class="row d-flex align-center">
  <div class="col-lg-3 col-md-12">
    <h1
      [innerHTML]="'MyDashboard' | translate"
      class="color-dashboard m-0"
    ></h1>
  </div>

  <div class="col-lg-9 col-md-12">
    <div class="ds-btns row-gap-2 row-gap-md-0 float-right">
      <button
        (click)="isShowTemplates = !isShowTemplates"
        [class.ds-btn-clicked]="isShowTemplates"
        class="ds-btn ds-btn-primary"
        ngbTooltip="{{ 'trRequestTemplates' | translate }}"
      >
        <i class="fa fa-bookmark" aria-hidden="true"></i>
      </button>

      <button
        (click)="getMessages()"
        class="ds-btn notify dropbtn-1 cursor-pointer"
        style="background: #5f5f5f"
        ngbTooltip="{{ 'HomeCommunicationMessages' | translate }}"
      >
        <img style="width: 18px" src="assets/Images/Icons/mail.png" />
        <span
          class="badge bg-danger badge-notification"
          [innerText]="messagesCount"
        ></span>
      </button>

      <div *ngIf="showMessage" class="dropdown-content-1 text-left">
        <div class="col-sm-12 pt-55 border-bottom">
          <div class="row alert_a">
            <div class="col-sm-6 alignment_right">
              <h5 class="bold">{{ 'Messages' | translate }}</h5>
            </div>
            <div class="col-sm-6 text-right pt-55 close_btn_pos">
              <button (click)="hideMessage()" class="none-1">
                <div class="custom_icon">
                  <span class="icon-cancel-24px-gold"></span>
                </div>
                <!-- <img src="assets/Images/Icons/cancel-24px (gold).png"> -->
              </button>
            </div>
          </div>
        </div>

        <div class="col-sm-12 border-bottom">
          <div
            class="card p-2 my-3 px-3"
            style="
              box-shadow: 1px 1px 8px 0 rgb(0 0 0 / 15%),
                0px 0px 20px 0 rgb(209 209 209 / 19%);
              border-radius: 10px;
            "
            *ngFor="
              let message of messagesList
                | paginate
                  : {
                      id: 'pagination_messages',
                      itemsPerPage: messagesPageSize,
                      currentPage: messagePage,
                      totalItems: messagesCount
                    }
            "
          >
            <p style="text-align: start">
              {{ message.message }}
            </p>
            <div class="" style="text-align: end">
              <button
                class="btn text-white default-bg px-4 cursor-pointer w-auto mx-1"
                (click)="
                  onClickCommunicationMessage(
                    message.statusCode,
                    message.serviceRequestTransactionId,
                    true
                  )
                "
                data-toggle="modal"
                data-target="#notificationMessageModalDialog"
              >
                {{ 'Reply' | translate }}
              </button>
              <button
                class="btn text-white default-bg px-4 cursor-pointer w-auto mx-1"
                style="background: #5d5d5d"
                (click)="dismissMessage(message.id)"
              >
                {{ 'Dismiss' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="pagination pagination_margin col-sm-12">
          <pagination-controls
            id="pagination_messages"
            previousLabel="{{ 'PaginationPreviousLabel' | translate }}"
            nextLabel="{{ 'PaginationNextLabel' | translate }}"
            (pageChange)="onNotificationMessagesPageChange($event)"
          >
          </pagination-controls>
        </div>
      </div>

      <button
        (click)="getNotification()"
        class="ds-btn notify dropbtn-1 cursor-pointer"
        ngbTooltip="{{ 'ShortcutCircleALERTS' | translate }}"
      >
        <img
          src="assets/Images/Icons/notification_important-24px (1)-W.png"
        /><span class="badge bg-danger badge-notification">{{
          notificationCount
        }}</span>
      </button>

      <div *ngIf="showNotification" class="dropdown-content-1 text-left">
        <div class="col-sm-12 pt-55">
          <div class="row alert_a">
            <div class="col-sm-6 alignment_right">
              <h5 class="bold">
                {{ 'HomePageAlerts' | translate }}
              </h5>
            </div>
            <div class="col-sm-6 text-right pt-55 close_btn_pos">
              <button (click)="hideNotification()" class="none-1">
                <div class="custom_icon">
                  <span class="icon-cancel-24px-gold"></span>
                </div>
                <!-- <img src="assets/Images/Icons/cancel-24px (gold).png"> -->
              </button>
            </div>
          </div>
        </div>

        <a
          *ngFor="
            let notification of notificationList
              | paginate
                : {
                    id: 'pagination_notification',
                    itemsPerPage: notificationPageSize,
                    currentPage: notificationPage,
                    totalItems: notificationCount
                  }
          "
          class=""
        >
          <div class="card-3" style="text-align: start">
            <div class="row bg-grey-1 direction_rtl notificatio_he">
              <div class="col-12 heading-dashboard1 p-0 notif_title">
                <p class="direction_rtl alignment_right pb-0">
                  {{
                    isEnglish
                      ? notification.serviceNameEn
                      : notification.serviceNameAr
                  }}
                </p>
              </div>
              <div class="col-12 heading-dashboard1 p-0 notif_title">
                <p class="direction_rtl alignment_right">
                  {{ notification.serviceRequestTransactionNumber }}
                </p>
              </div>
            </div>
            <ng-container *ngIf="notification.isReturnedRequest">
              <p class="alignment_right direction_rtl m_t_10">
                {{ 'HomePageYourRequestHasBeenReturnedOn' | translate }}
                <span class="highLightText">
                  {{
                    notification.workflowStepModifiedDate
                      | date : 'dd/MM/yyyy hh:mm:ss a'
                  }}</span
                >
                {{ 'HomePagePleaseTakeTheNecessaryAction' | translate }}
              </p>
              <div class="">
                <ng-container
                  *ngFor="let action of notification.requestActions"
                >
                  <ng-template #notCancel>
                    <div
                      class="d-inline-block mx-1 heading-dashboard1 pt-52 alignment_right mb_buttn cursor-pointer"
                    >
                      <a
                        (click)="goToPage(action.actionPageURL)"
                        class="text-center btn-red cursor-pointer"
                        >{{ isEnglish ? action.nameEn : action.nameAr }}</a
                      >
                    </div>
                  </ng-template>
                  <div
                    class="d-inline-block mx-1 heading-dashboard1 pt-52 alignmet_left mb_buttn cursor-pointer"
                    *ngIf="
                      action.actionPageURL == 'CancelRequest' ||
                        action.actionPageURL == 'CancelRequestEs';
                      else notCancel
                    "
                  >
                    <button
                      (click)="
                        cancelRequest(
                          notification.workflowTransactionId,
                          notification.serviceRequestTransactionId,
                          action
                        )
                      "
                      class="text-center btn-red"
                    >
                      {{ 'HomePageCancelRequest' | translate }}
                    </button>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="notification.isRenewCertificate">
              <ng-container *ngIf="isEnglish">
                <p class="pad alignment_right direction_rtl">
                  Your License
                  <span class="hell-1">{{
                    notification.certificateNumber
                  }}</span>
                  has been expired on
                  <span class="hell-1">{{
                    notification.certificateExpiryDate
                      | date : 'dd/MM/yyyy hh:mm:ss a'
                  }}</span
                  >, Please take the necessary action.
                </p>
              </ng-container>

              <ng-container *ngIf="isArabic">
                <p class="pad alignment_right direction_rtl">
                  انتهت صلاحية ترخيصك رقم
                  <span class="hell-1">{{
                    notification.certificateNumber
                  }}</span>
                  في
                  <span class="hell-1">{{
                    notification.certificateExpiryDate
                      | date : 'dd/MM/yyyy hh:mm:ss a'
                  }}</span
                  >, يرجى اتخاذ الإجراء اللازم.
                </p>
              </ng-container>

              <ng-container *ngFor="let action of notification.requestActions">
                <div
                  class="d-inline-block mx-1 heading-dashboard1 pt-52 alignment_right mb_buttn cursor-pointer"
                >
                  <a
                    (click)="goToPage(action.actionPageURL)"
                    class="text-center btn-red"
                    >{{ isEnglish ? action.nameEn : action.nameAr }}</a
                  >
                </div>
              </ng-container>
            </ng-container>
          </div>
        </a>
        <div class="pagination pagination_margin">
          <pagination-controls
            id="pagination_notification"
            previousLabel="{{ 'PaginationPreviousLabel' | translate }}"
            nextLabel="{{ 'PaginationNextLabel' | translate }}"
            (pageChange)="onNotificationPageChange($event)"
          >
          </pagination-controls>
        </div>
      </div>

      <button
        class="ds-btn btn-dashboard btn-important-links cursor-pointer"
        (click)="openImportantLinks()"
        ngbTooltip="{{ 'trImportantLinks' | translate }}"
      >
        <img
          style="width: 18px"
          src="assets/Images/Icons/important-link-24.png"
        />
      </button>

      <button
        class="ds-btn btn-dashboard btn-workspace cursor-pointer"
        (click)="navigateToEService()"
        ngbTooltip="{{ 'EServiceWorkspace' | translate }}"
      >
        <img
          style="width: 18px"
          src="assets/Images/Icons/external-link-24.png"
        />
      </button>

      <button (click)="openDashboardStatistics()" class="ds-btn ds-btn-primary">
        <img
          class="ds-mr-1"
          src="assets/Images/Icons/leaderboard-24px (1).png"
        />
        <span [innerText]="'MyStatistics' | translate"></span>
      </button>

      <div
        class="btn-group"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <a
          class="ds-btn ds-btn-success"
          [routerLink]="['/services/']"
          [innerText]="'NewRequest' | translate"
        ></a>
        <div class="btn-group" role="group">
          <button
            type="button"
            class="ds-btn ds-btn-success"
            [class.ds-btn-clicked]="isShowFavoriteList"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            (click)="toggleFavoriteServices()"
          >
            <i class="fa fa-star" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row py-3 mb-5" *ngIf="isShowFavoriteList">
  <div class="col">
    <favorite-services></favorite-services>
  </div>
</div>

<div class="row py-3 mb-5" *ngIf="isShowTemplates">
  <div class="col">
    <app-template-services></app-template-services>
  </div>
</div>
