import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InitiateRequestResponse } from '../shared/InitiateRequestResponse';
import { EsBaseTemplateService } from './es-base-template.service';
import { EsResponseModel } from './EsResponseModel';

export abstract class EsMainTemplateService extends EsBaseTemplateService {
  readonly GET_REQUEST_URI = 'Get?requestId={requestId}';

  readonly GET_SERVICE_DETAILS_URI = 'GetServiceDetails?requestId={requestId}';

  private readonly GET_FEES = 'GetFees?requestId={requestId}';
  private readonly POST_PAY = 'Pay';

  private readonly POST_SEND_BACK = 'SendBack';

  private readonly SUBMIT_INITIATOR_ACTION = 'InitiatorAction';
  private readonly POST_ATTACHMENTS = 'Attachments';

  constructor(protected httpClient: HttpClient) {
    super();
  }

  abstract initiateRequest<T>(request: any): Observable<T>;
  abstract updateRequest<T>(request: any): Observable<T>;

  public getRequest<T>(requestId: string): Observable<T> {
    const url = this.buildUrl(this.GET_REQUEST_URI).replace(
      '{requestId}',
      requestId
    );

    return this.httpClient.get<T>(url).pipe(catchError(this.handleError));
  }

  public getServiceConfiguration<T>(requestId: number): Observable<T> {
    const url = this.buildUrl(this.GET_SERVICE_DETAILS_URI).replace(
      '{requestId}',
      requestId.toString()
    );

    return this.httpClient.get<T>(url).pipe(catchError(this.handleError));
  }

  public getRequestFees<T>(requestId: number): Observable<T> {
    const url = this.buildUrl(this.GET_FEES).replace(
      '{requestId}',
      requestId.toString()
    );

    return this.httpClient.get<T>(url).pipe(catchError(this.handleError));
  }

  public payRequest<T>(
    requestId: number,
    MerchantUrl: string,
    language: string
  ): Observable<T> {
    const url = this.buildUrl(this.POST_PAY);

    return this.httpClient
      .post<T>(url, {
        requestId,
        language,
        MerchantUrl,
      })
      .pipe(catchError(this.handleError));
  }

  public sendBackRequest(request: any): Observable<EsSendBackResponse> {
    const url = this.buildUrl(this.POST_SEND_BACK);

    return this.httpClient
      .post<EsSendBackResponse>(url, request)
      .pipe(catchError(this.handleError));
  }

  public submitInitiatorAction(
    request: any
  ): Observable<InitiateRequestResponse> {
    const url = this.buildUrl(this.SUBMIT_INITIATOR_ACTION);

    return this.httpClient
      .post<InitiateRequestResponse>(url, request)
      .pipe(catchError(this.handleError));
  }

  public attachments<T>(request: any): Observable<T> {
    const url = this.buildUrl(this.POST_ATTACHMENTS);

    return this.httpClient
      .post<T>(url, request)
      .pipe(catchError(this.handleError));
  }
}

export class EsSendBackResponse extends EsResponseModel<EsSendBackDto> {}

export interface EsSendBackDto {
  TaskName: string;
  LocalizedStatusCode: string;
  LocalizedStatusCodeAr: string;
  ProcedureInstanceID: number;
}
