<!-- <div id="HideScreen" style="display: none;" [hidden]="!loading">
  <span class="loader-img">
      <img src="/assets/Images/siteimages/falcon-loader.gif" class="img-loader" /></span>
</div> -->

<div class="progress-loader" [hidden]="!loading">
  <div class="loading-spinner">
      <img src="/assets/Images/siteimages/falcon-loader.gif" class="img-loader" >

      <div  class="counting_num"> <span class="counting_per"></span><label for="">%</label></div>

  </div>
</div>
