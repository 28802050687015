import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { BreadcrumbsDetails } from 'src/app/shared/base/BreadcrumbsDetails';
import { FormMode } from 'src/app/shared/base/FormMode';
import { MainStepsConfigurations } from 'src/app/shared/base/MainStepsConfigurations';
import { MoveFlowStepDataHandler } from 'src/app/shared/base/MoveFlowStepDataHandler';
import { EsRequestPageBaseComponent } from '../../../shared/base/EsRequestPageBaseComponent';
import { CitesServiceRequestStore } from './base/CitesServiceRequestStore';
import { CitesWorkflowBaseService } from './cites-workflow-base.service';

export abstract class CitesPageBaseComponent extends EsRequestPageBaseComponent {
  serviceConfig$: Observable<EsServiceRequestConfiguration>;
  serviceMetaData$: Subject<ServiceMetaData>;
  private serviceMetadata: ServiceMetaData;

  requestId: string;
  procedureId: string;
  draftRequestId: string;
  templateId: string;
  cloneTemplateId: string;

  abstract get formMode(): FormMode;

  flow$: Observable<MainStepsConfigurations>;

  constructor(
    public workflowService: CitesWorkflowBaseService<
      CitesServiceRequestStore<any>
    >,
    protected route: ActivatedRoute,
    protected translate: TranslateService
  ) {
    super(translate);

    this.parametersLoaded(this.route.snapshot.queryParamMap);

    this.serviceConfig$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.serviceMetaData$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceMetaData$;

    this.flow$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.flow$;

    this.serviceMetaData$.subscribe((metadata) =>
      this.metadataLoaded(metadata)
    );

    this.translate.onLangChange.subscribe(() => {
      this.languageChanged();
    });
  }

  private languageChanged() {
    const breadcrumbsDetails: BreadcrumbsDetails = {
      serviceName: {
        labelKey: this.isArabic
          ? this.serviceMetadata.nameAr
          : this.serviceMetadata.nameEn,
      },
    };

    this.breadcrumbsDetails$.next(breadcrumbsDetails);
  }

  private async parametersLoaded(params: ParamMap) {
    this.requestId = params.get('requestId');
    this.procedureId = params.get('procedureId');
    this.draftRequestId = params.get('draftRequestId');
    this.templateId = params.get('templateId');
    this.cloneTemplateId = params.get('cloneTemplateId');

    this.changeLanguage(params.get('lang'));

    await this.workflowService.startWorkflow(
      this.requestId,
      this.draftRequestId,
      this.templateId,
      this.cloneTemplateId,
      this.procedureId,
      this.formMode
    );
  }

  private metadataLoaded(serviceMetadata: ServiceMetaData): void {
    this.serviceMetadata = serviceMetadata;

    const breadcrumbsDetails: BreadcrumbsDetails = {
      serviceName: {
        labelKey: this.isArabic
          ? serviceMetadata.nameAr
          : serviceMetadata.nameEn,
      },
    };

    this.breadcrumbsDetails$.next(breadcrumbsDetails);
  }

  get serviceKey(): string {
    return this.workflowService.getServiceUrlKeyWord();
  }

  moveFlow($event: MoveFlowStepDataHandler) {
    this.workflowService.moveFlow($event);
  }
}
