import { Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EsBaseServiceRequest } from 'src/app/core/models/shared/EsBaseServiceRequest';
import { FormMode } from 'src/app/shared/base/FormMode';
import { CitesPageBaseComponent } from '../CitesPageBaseComponent';
import { CitesWorkflowBaseService } from '../cites-workflow-base.service';
import { CitesServiceRequestStore } from './CitesServiceRequestStore';
import { EsFees } from 'src/app/core/models/shared/EsFees';
import { ColSpace } from 'src/app/services/es-shared/ds-col/ds-col.component';

export abstract class CitesViewBaseComponent extends CitesPageBaseComponent {
  fees$: Observable<EsFees>;
  transaction$: Observable<any>;

  get formMode(): FormMode {
    return FormMode.VIEW;
  }

  constructor(
    translate: TranslateService,
    protected route: ActivatedRoute,
    @Inject('WorkflowService')
    workflowService: CitesWorkflowBaseService<CitesServiceRequestStore<any>>
  ) {
    super(workflowService, route, translate);

    this.fees$ = this.workflowService.getFees();

    this.transaction$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceDetails$;
  }

  public isShowFeesSection(transaction: EsBaseServiceRequest): boolean {
    return false;
  }

  public isShowReceiptSection(transaction: EsBaseServiceRequest): boolean {
    return transaction.receipts?.length > 0;
  }

  public isShowOutputsSection(transaction: EsBaseServiceRequest): boolean {
    return transaction.outputs && transaction.outputs.length > 0;
  }

  protected override setSizeFull() {
    this.mainColOptions.maximumSize.colSpace = ColSpace.Full;
    this.breadcrumbsColumnOptions.maximumSize.colSpace = ColSpace.Full;
  }

  protected override setSizeNormal() {
    this.mainColOptions.maximumSize.colSpace = ColSpace.TwoThirds;
    this.breadcrumbsColumnOptions.maximumSize.colSpace = ColSpace.TwoThirds;
  }
}
