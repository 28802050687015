<div class="forms-container">
  <div class="form-section attachment-section">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="row" formArrayName="Attachments">
        <div
          class="col-12 attachment-group"
          *ngFor="let attachment of attachments; let i = index"
        >
          <label>
            <app-rendering-html
              class="d-inline-block"
              [dynamicHtml]="isArabic ? attachment.nameAr : attachment.nameEn"
            ></app-rendering-html>
            <span
              class="d-inline-block mx-1"
              *ngIf="attachment.isRequired"
              style="color: red"
            >
              *</span
            ></label
          >
          <div
            class="attachment-upload"
            [class.justify-content-center]="
              attachment.instances == null || attachment.instances.length == 0
            "
            [class.attachment-required]="
              showValidation && attachment.isRequired && 
              (attachment.instances == null || attachment.instances.length == 0)
            "
          >
            <div
              class="attachment-instance"
              *ngFor="let instance of attachment.instances; let i = index"
            >
              <div class="attachment-uploaded">
                <img
                  class="img-fluid"
                  src="./../../../../../assets/Images/go_digital.png"
                  alt="File Uploaded"
                />
              </div>
              <div [innerText]="instance.fileName"></div>
              <!-- <button
                  class="ds-btn ds-btn-secondary ds-btn-danger"
                  [innerText]="'trDeleteFile' | translate"
                  (click)="removeFileInstance(attachment, instance)"
                ></button> -->
              <div class="justify-content-center">
                <div class="list-group-item-options">
                  <a
                    class="ds-btn-icon"
                    (click)="download(instance.fileId)"
                    title="{{ 'trViewFile' | translate }}"
                  >
                    <i class="fi-view fi-large mx-2"></i>
                  </a>
                  <a
                    class="ds-btn-icon"
                    (click)="removeFileInstance(attachment, instance)"
                    title="{{ 'trDeleteFile' | translate }}"
                  >
                    <i class="fi-close fi-large mx-2"></i>
                  </a>
                </div>
              </div>
            </div>

            <ngx-file-drop
              *ngIf="attachment.instances?.length < MAX_COUNT_OF_ATTACHMENTS"
              dropZoneLabel=""
              [browseBtnLabel]="'Browse Button'"
              browseBtnClassName="browse-btn-class"
              [multiple]="false"
              accept=".jpg, .png, .jpeg, .gif, .pdf, .bmp, .doc, .docx, .xls, .xlsx"
              [showBrowseBtn]="false"
              (onFileDrop)="
                onSelectFile($event, attachment.procedureAttachmentId)
              "
            >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <button
                  type="button"
                  (click)="handleUploadFilesButtonClick(openFileSelector)"
                  class="ds-btn ds-btn-primary"
                  [innerText]="'UploadFile' | translate"
                ></button>
              </ng-template>
            </ngx-file-drop>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="form-section">
    <app-es-main-buttons
      (action)="actionHandler($event)"
      [buttons]="buttons"
    ></app-es-main-buttons>
  </div>
</div>
