<div>
  <legend
    [innerText]="'trComments' | translate"
    class="col-form-label"
  ></legend>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="comment-area reason-messages">
      <div
        class="history-msg"
        *ngIf="comments && comments.length; else noCommentsBlock"
        #scrollMe
        [scrollTop]="scrollMe.scrollHeight"
      >
        <ng-container
          *ngFor="let message of comments"
          [ngTemplateOutlet]="
            message.commentSource == PARTICIPANT
              ? participantBlock
              : ministryBlock
          "
          [ngTemplateOutletContext]="{ message }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="row commenting mt-4 d-print-none" *ngIf="allowedToComment">
  <div class="col-lg-12 ds-default-file-drop">
    <ngx-file-drop
      dropZoneLabel=""
      [browseBtnLabel]="'Browse Button'"
      browseBtnClassName="browse-btn-class"
      [multiple]="false"
      accept=".jpg, .png, .jpeg, .gif, .pdf, .bmp, .doc, .docx, .xls, .xlsx"
      [showBrowseBtn]="false"
      (onFileDrop)="onSelectFile($event)"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <div class="row w-100">
          <div class="col-lg-10">
            <bs-ng-textarea
              [(ngModel)]="comment"
              key="trCommentMessage"
              [noLabel]="true"
              [rows]="3"
              maxLength="1000"
            >
            </bs-ng-textarea>
          </div>
          <div class="col-lg-2 col-md-12 mt-2">
            <button
              type="button"
              (click)="handleUploadFilesButtonClick(openFileSelector)"
              class="ds-btn ds-btn-secondary ds-btn-clips"
            >
              <i class="icon-paperclip"></i>
            </button>
          </div>
          <div class="col-12 col-md-12">
            <div class="comment-attachments">
              <div
                class="comment-attachment d-flex"
                *ngFor="let uploaded of uploadedAttachments"
              >
                <div [innerText]="uploaded.name"></div>
                <i class="icon-attach-file-gold2x"></i>
                <i
                  class="fi-close-thick"
                  (click)="removeUploaded(uploaded)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>

<div class="row mt-2 d-print-none">
  <div class="col-lg-12">
    <button
      type="button"
      (click)="send()"
      class="ds-btn ds-btn-primary"
      [innerText]="'trSendComment' | translate"
    ></button>
  </div>
</div>

<ng-template #participantBlock let-message="message">
  <div class="comment">
    <div class="comment-avatar-img">
      <img
        class="direct-chat-img"
        src="assets/Images/Icons/user.png"
        alt="message user image"
      />
    </div>
    <div class="received-msg">
      <div class="comment-body">
        <p>{{ message.commentBody }}</p>
        <div
          class="comment-attachments"
          *ngIf="message.attachments && message.attachments.length"
        >
          <div
            class="comment-attachment"
            *ngFor="let attachment of message.attachments"
          >
            <span [innerText]="attachment.name"></span>
            <a class="ds-btn-icon" (click)="download(attachment)">
              <i class="fi-download fi-large mx-2"></i>
            </a>
          </div>
        </div>
        <span class="time-date">{{
          message.creationDate | date : 'medium'
        }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ministryBlock let-message="message">
  <div class="comment reverse-comment">
    <div class="comment-avatar-img">
      <img
        class="direct-chat-img"
        src="assets/Images/Icons/back-office.png"
        alt="ministry employee officer"
      />
    </div>
    <div class="comment-body">
      <p>{{ message.commentBody }}</p>
      <div
        class="comment-attachments"
        *ngIf="message.attachments && message.attachments.length"
      >
        <div
          class="comment-attachment"
          *ngFor="let attachment of message.attachments"
        >
          <span [innerText]="attachment.name"></span>
          <a class="ds-btn-icon" (click)="download(attachment)">
            <i class="fi-download fi-large mx-2"></i>
          </a>
        </div>
      </div>
      <span class="time-date">{{
        message.creationDate | date : 'medium'
      }}</span>
    </div>
  </div>
</ng-template>

<ng-template #noCommentsBlock>
  <ul class="list-group text-muted">
    <li
      class="list-group-item text-center"
      [innerText]="'trNoComments' | translate"
    ></li>
  </ul>
</ng-template>
