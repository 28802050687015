import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as inspections from "../../models/Inspections/LicensingInspectionResultDataDTO";
import { HttpHeaders } from '@angular/common/http';
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {LicensingInspectionResultDataDTO} from '../../models/Inspections/LicensingInspectionResultDataDTO';


@Injectable({
  providedIn: 'root'
})
export class GetInspectionsService {


  private REST_API_SERVER_PORTAL = environment.hostInspectionsURL + "GetInspections";
  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Inspection/' + "GetInspectionsByServiceRequestTransactionId";

  constructor(private httpClient: HttpClient) {
  }


  public getInspectionsData_Portal(participantId:string, lang :string) : Observable<inspections.InspectionsObject> {
    const httpOptionsDevelopment = {
      headers: new HttpHeaders(
          {
          //'Access-Control-Allow-Origin' : "*",
          'Content-Type'  : 'application/json; charset=utf-8',
          'Authorization' : 'Basic R0lTREJBUEk6UjBsVFJFSmZRVkJK'
          }
        )
    };

    //let params = "?ProcedureInstanceID=" + procedureInstanceId + "&lang=" + lang;
    let params = "?ParticipentID=" + encodeURIComponent(participantId) + "&lang=" + lang;

    if (environment.production) {
      return this.httpClient.get<inspections.InspectionsObject>(this.REST_API_SERVER + params)
                 .pipe(catchError(this.handleError));
    } else {
      return this.httpClient.get<inspections.InspectionsObject>(this.REST_API_SERVER + params, httpOptionsDevelopment)
      .pipe(catchError(this.handleError));
    }

  }

  public getInspectionsData(serviceRequestTransactionId: number) : Observable<ResponseModel<LicensingInspectionResultDataDTO[]>> {

    let params = new HttpParams();
    params = params.append('serviceRequestTransactionId', serviceRequestTransactionId);

    return this.httpClient.get<ResponseModel<LicensingInspectionResultDataDTO[]>>(this.REST_API_SERVER, {params})
      .pipe(catchError(this.handleError));


  }

  private handleError(errorResponse : HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetInspectionsService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetInspectionsService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
