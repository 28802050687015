<ng-container *ngIf="transaction$ | async as transaction">
  <div class="form-section" *appFormSection="requestDetailsFormSection">
    <app-cites-importer-review
      [importerDetails]="transaction.serviceTransaction?.importerDetails"
    >
    </app-cites-importer-review>
  </div>

  <div class="form-section" *appFormSection="requestDetailsFormSection">
    <app-cites-exporter-review
      [exporterDetails]="transaction.serviceTransaction?.exporterDetails"
    >
    </app-cites-exporter-review>
  </div>

  <div class="form-section" *appFormSection="requestDetailsFormSection">
    <app-cites-release-species-request-review-details
      [transaction]="transaction"
    >
    </app-cites-release-species-request-review-details>
  </div>

  <div class="form-section"
        *ngIf="transaction.serviceTransaction?.releaseItems && 
              transaction.serviceTransaction.releaseItems.length > 0">
    <app-cites-release-species-item-list
      [items]="transaction.serviceTransaction.releaseItems"
      [enableEditing]="false"
    >
    </app-cites-release-species-item-list>
  </div>
</ng-container>