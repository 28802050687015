import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesReleaseSpeciesTransaction } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { CitesBaseFormComponent } from '../../shared/base/CitesBaseFormComponent';

@Component({
  selector: 'app-cites-release-species-request-form',
  templateUrl: './cites-release-species-request-form.component.html',
})
export class CitesReleaseSpeciesFormComponent extends CitesBaseFormComponent {
  @Input()
  transaction: CitesReleaseSpeciesTransaction;
  
  constructor(translateService: TranslateService) {
      super(translateService);
  }

}
