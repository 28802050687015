import {
  AfterViewInit,
  EmbeddedViewRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Directive } from '@angular/core';
import { FormSectionOptions } from './FormSectionOptions';

@Directive({
  selector: '[appFormSection]',
})
export class FormSectionDirective implements AfterViewInit {
  private hasView = false;
  private isCollapsed = false;

  options: FormSectionOptions = { isCollapsible: true, render: true };

  private mainElementRef: EmbeddedViewRef<any>;
  private blurElement: any;
  private blurContentElement: any;

  private get isCollapsible(): boolean {
    return this.options.isCollapsible;
  }

  @Input()
  public set appFormSection(options: FormSectionOptions) {
    this.options = options;

    if (options.render && this.hasView == false) {
      this.mainElementRef = this.viewContainer.createEmbeddedView(
        this.templateRef
      );
      this.hasView = true;
    } else if (options.render == false && this.hasView) {
      this.viewContainer.clear();
      this.mainElementRef = null;
      this.hasView = false;
    }

    if (this.options && this.options.blur && this.options.blur.isBlur) {
      this.addBlurElement();
    } else {
      this.removeBlurElement(); // Reset reference
    }
  }

  private addBlurElement() {
    if (this.mainElement) {
      this.blurElement = this.renderer.createElement('div');
      this.renderer.addClass(this.blurElement, 'form-section-blur');

      const text = this.renderer.createText(this.options.blur.blurText);

      this.blurContentElement = this.renderer.createElement('div');
      this.renderer.addClass(
        this.blurContentElement,
        'form-section-blur-content'
      );
      this.renderer.appendChild(this.blurContentElement, text);

      this.renderer.appendChild(this.blurElement, this.blurContentElement);
      this.renderer.appendChild(this.mainElement, this.blurElement);
    }
  }

  private removeBlurElement() {
    if (this.mainElement && this.blurElement) {
      this.renderer.removeChild(this.mainElement, this.blurElement);
      this.blurElement = undefined;
    }
  }

  ///The element which will be wrapped
  private get mainElement(): HTMLElement {
    const main: HTMLElement = this.mainElementRef?.rootNodes[0];
    return main;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  private toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;

    if (this.isCollapsed) {
      this.renderer.addClass(this.mainElement, 'form-section-collapsed');
    } else {
      this.renderer.removeClass(this.mainElement, 'form-section-collapsed');
    }
  }

  public ngAfterViewInit() {
    if (this.isCollapsible) {
      if (this.mainElement) {
        this.renderer.addClass(this.mainElement, 'form-section-collapsible');
        const firstLegend: HTMLLegendElement =
          this.mainElement.getElementsByTagName('legend')[0];

        if (firstLegend) {
          this.renderer.addClass(firstLegend, 'form-section-collapsing');
          this.renderer.listen(firstLegend, 'click', () => {
            this.toggleCollapse();
          });
        }
      }
    }
  }
}
