<div
  class="modal fade"
  [id]="dialogId"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header direction_rtl alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ titleKey | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="assets/Images/Icons/cancel-24px%20(gold).png" alt="" />
        </button>
      </div>

      <div class="modal-body p-4">
        <table id="CitesPurposeTbl">
            <tr>
                <th>{{'CitesPursposeCode' | translate}}</th>
                <th>{{'CitesPurposeDescription' | translate}}</th>
            </tr>
            <tr *ngFor="let item of items">
                <td class="code">{{item.code}}</td>
                <td class="pl-2 pr-2" *ngIf="isArabic" [innerHtml]="item.nameAr"></td>
                <td class="pl-2 pr-2" *ngIf="!isArabic" [innerHtml]="item.nameEn"></td>
            </tr>
        </table>
      </div>
    </div>
  </div>
</div>
