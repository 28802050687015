<legend
  class="col-form-label"
  [innerText]="'ViewServiceInformation' | translate">
</legend>

<div class="row">
  <div class="col-md-12 col-sm-12">
    <app-bs-display
      key="trCitesPurposeFld"
      [valueText]="transaction.serviceTransaction.purpose | lookup:lang">
    </app-bs-display>
  </div>
  <div class="col-md-12 col-sm-12">
    <app-bs-display
      key="trCitesSpecialCasesFld"
      [valueText]="transaction.serviceTransaction.specialCases">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesArrivalDateFld"
      [valueText]="transaction.serviceTransaction.arrivalDate | date:'dd/MM/yyyy'">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trImportEntryPortFld"
      [valueText]="transaction.serviceTransaction.entryPort | lookup:lang">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesBillNumberFld"
      [valueText]="transaction.serviceTransaction.shippingBillNumber">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesShippingMethodFld"
      [valueText]="transaction.serviceTransaction.shippingMethod | lookup:lang">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesShippingPolicyFld"
      [valueText]="transaction.serviceTransaction.shippingPolicy">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesTankerFld"
      [valueText]="transaction.serviceTransaction.tanker">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesExportingPortFld"
      [valueText]="transaction.serviceTransaction.exportingPortForRelease">
    </app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12">
    <app-bs-display
      key="trCitesCheckinDateFld"
      [valueText]="transaction.serviceTransaction.checkinDate | date: 'dd-MM-yyyy'">
    </app-bs-display>
  </div>
</div>