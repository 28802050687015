
<legend
    class="col-form-label"
    [innerText]="'Importer Details' | translate">
</legend>

<div class="row" *ngIf="importerDetails">
    <ng-container *ngIf="fo">
        <div dsCol [colOptions]="fo.fullCol">
            <app-bs-display
                key="trCitesImporterNameFld"
                [valueText]="importerDetails.importerName">
            </app-bs-display>
        </div>
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <app-bs-display
                key="trCitesEmailFld"
                [valueText]="importerDetails.importerEmail">
            </app-bs-display>
        </div> -->
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <app-bs-display
                key="trCitesPOBoxFld"
                [valueText]="importerDetails.importerPOBox">
            </app-bs-display>
        </div> -->
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <app-bs-display
                key="trCitesPhoneFld"
                [valueText]="importerDetails.importerPhone">
            </app-bs-display>
        </div> -->
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <app-bs-display
                key="trCitesFaxFld"
                [valueText]="importerDetails.importerFax">
            </app-bs-display>
        </div> -->
        <div dsCol [colOptions]="fo.halfCol">
            <app-bs-display 
                *ngIf="importerDetails.importerCountry"
                key="trCitesImporterCountryFld"
                [valueText]="importerDetails.importerCountry | lookup:lang">
            </app-bs-display>
            <app-bs-display
                *ngIf="!importerDetails.importerCountry"
                key="trCitesImporterCountryFld"
                [valueText]="'trUAECountryName' | translate">
            </app-bs-display>
        </div>
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <app-bs-display
                key="trCitesCityFld"
                [valueText]="importerDetails.importerCityName">
            </app-bs-display>
        </div> -->
        <div dsCol [colOptions]="fo.fullCol">
            <app-bs-display
                key="trCitesImporterAddressFld"
                [valueText]="importerDetails.importerAddress">
            </app-bs-display>
        </div>
    </ng-container>
    <ng-container *ngIf="!fo">
        <div class="col-md-12 col-sm-12">
            <app-bs-display
                key="trCitesImporterNameFld"
                [valueText]="importerDetails.importerName">
            </app-bs-display>
        </div>
        <!-- <div class="col-md-6 col-sm-12">
            <app-bs-display
                key="trCitesEmailFld"
                [valueText]="importerDetails.importerEmail">
            </app-bs-display>
        </div> -->
        <!-- <div class="col-md-6 col-sm-12">
            <app-bs-display
                key="trCitesPOBoxFld"
                [valueText]="importerDetails.importerPOBox">
            </app-bs-display>
        </div> -->
        <!-- <div class="col-md-6 col-sm-12">
            <app-bs-display
                key="trCitesPhoneFld"
                [valueText]="importerDetails.importerPhone">
            </app-bs-display>
        </div> -->
        <!-- <div class="col-md-6 col-sm-12">
            <app-bs-display
                key="trCitesFaxFld"
                [valueText]="importerDetails.importerFax">
            </app-bs-display>
        </div> -->
        <div class="col-md-12 col-sm-12">
            <app-bs-display 
                *ngIf="importerDetails.importerCountry"
                key="trCitesImporterCountryFld"
                [valueText]="importerDetails.importerCountry | lookup:lang">
            </app-bs-display>
            <app-bs-display
                *ngIf="!importerDetails.importerCountry"
                key="trCitesImporterCountryFld"
                [valueText]="'trUAECountryName' | translate">
            </app-bs-display>
        </div>
        <!-- <div class="col-md-6 col-sm-12">
            <app-bs-display
                key="trCitesCityFld"
                [valueText]="importerDetails.importerCityName">
            </app-bs-display>
        </div> -->
        <div class="col-md-12 col-sm-12">
            <app-bs-display
                key="trCitesImporterAddressFld"
                [valueText]="importerDetails.importerAddress">
            </app-bs-display>
        </div>
    </ng-container>
</div>