<legend
  [innerText]="'trExportRequestDetails' | translate"
  class="col-form-label"
></legend>
<div class="row">
  <div class="col-md-6 col-sm-12 col-print-6">
    <app-bs-display
      key="trExportServiceRequestNumber"
      [valueText]="requestDetails?.serviceRequestNumber"
    ></app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12 col-print-6" [class.success]="">
    <div class="form-group">
      <label
        [innerText]="('trExportServiceRequestStatusCode' | translate).labelText"
      ></label>
      <div class="detail-text">
        <span
          class="badge bg-light-shadow dark text-wrap"
          [innerText]="
            isArabic ? requestDetails?.statusAr : requestDetails?.statusEn
          "
        >
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="profile">
  <div class="col-md-6 col-sm-12 col-print-6">
    <app-bs-display
      [key]="participantKey"
      [valueText]="isArabic ? profile?.nameAr : profile?.nameEn"
    ></app-bs-display>
  </div>

  <div class="col-md-6 col-sm-12 col-print-6">
    <app-bs-display
      key="trParticipantNumber"
      [valueText]="profile?.registeredUserNumber"
    ></app-bs-display>
  </div>
</div>

<div class="row">
  <div class="col-md-6 col-sm-12 col-print-6">
    <app-bs-display
      key="trApplicantName"
      [valueText]="applicant?.name"
    ></app-bs-display>
  </div>
  <div class="col-md-6 col-sm-12 col-print-6">
    <app-bs-display
      key="trExportServiceRequestCreatedDate"
      [valueText]="requestDetails?.createdDate | date"
    ></app-bs-display>
  </div>
</div>
