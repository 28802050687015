import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { CitesImportSpeciesTransaction } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { CitesReviewFlowStepBaseComponent } from '../../shared/base/CitesReviewFlowStepBaseComponent';
import { CitesImportSpeciesWorkflowService } from '../cites-import-species-workflow.service';

@Component({
  selector: 'app-cites-import-species-request-review',
  templateUrl: './cites-import-species-request-review.component.html',
})
export class CitesImportSpeciesReviewComponent
  extends CitesReviewFlowStepBaseComponent<CitesImportSpeciesWorkflowService>
  implements OnInit, AfterViewInit
{
  transaction$: Observable<CitesImportSpeciesTransaction>;

  constructor(
    @Inject('WorkflowService')
    workflowService: CitesImportSpeciesWorkflowService,
    translate: TranslateService,
    router: Router
  ) {
    super(workflowService, router, translate);
  }

  async ngOnInit(): Promise<void> {}

  async ngAfterViewInit(): Promise<void> {}
}
