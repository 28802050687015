import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo',
})
export class YesNoPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: any,
    yesKey: string = 'trYes',
    noKey: string = 'trNo'
  ): string {
    if (value) return this.translateService.instant(yesKey);
    else return this.translateService.instant(noKey);
  }
}
