import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as serviceDetailByDigitalNumberResponse from '../../../core/models/portal/ServiceDetailByDigitalNumberResponse';
import { HttpHeaders } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {SearchByKeyWordResponse} from '../../models/portal/SearchByKeyWordResponse';

@Injectable({
  providedIn: 'root'
})
export class SearchByKeywordService {

  private REST_API_SERVER = environment.portalSearchUrl;

  constructor(private httpClient: HttpClient, private translateService: TranslateService) {
  }

  public getSearchResult(keyWord: string): Observable<SearchByKeyWordResponse[]> {

    const arabicRegex = /[\u0600-\u06FF]/;
    // Test according selected
    // let lang = this.translateService.currentLang === 'en' ? 1 : 2;
    // Test according written
    const lang = arabicRegex.test(keyWord) ? 2 : 1;

    let headers = new HttpHeaders();
    headers = headers.append('isPublic', 'isPublic');

    let params = new HttpParams();
    params = params.append('category', 'LoadSuggestionsWordsForSearch');
    params = params.append('title', keyWord);
    params = params.append('languageId', lang.toString());


    return this.httpClient.get<SearchByKeyWordResponse[]>(this.REST_API_SERVER , {params, headers})
      .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('[SearchByKeywordService] Client Side Error' , errorResponse.error.message);
    } else {
      console.error('[SearchByKeywordService] Server Side Error' , errorResponse.error.message);
    }

    return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }


}
