import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CitesReleaseSpeciesServiceRequestConfiguration } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesServiceRequestConfiguration';
import { CitesReleaseSpeciesTransaction } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { CitesRequestBaseComponent } from '../../shared/base/CitesRequestBaseComponent';
import { CitesMainFormChangeEmitterData } from '../../shared/cites-main-form/CitesMainFormChangeEmitterData';
import { CitesItemFormChangeEmitterData } from '../../shared/CitesItemFormChangeEmitterData';
import { CitesReleaseSpeciesWorkflowService } from '../cites-release-species-workflow.service';

@Component({
  selector: 'app-cites-release-species-request',
  templateUrl: './cites-release-species-request.component.html',
})

export class CitesReleaseSpeciesRequestComponent
  extends CitesRequestBaseComponent<any>
  implements OnInit
{  
  serviceConfig$: Observable<CitesReleaseSpeciesServiceRequestConfiguration>;
  transaction$: Observable<CitesReleaseSpeciesTransaction>;
  constructor(
    @Inject('WorkflowService')
    public workflowService: CitesReleaseSpeciesWorkflowService,
    router: Router,
    translateService: TranslateService
  ) {
    super(workflowService, router, translateService);
  }

  ngOnInit(): void {
    this.subscribeToFormsTemplatesSettings();
    this.workflowService.setCurrentStep(
      WorkflowSteps.ServiceDetailsConfiguration
    );
  }

  async mainFormChange($event: CitesMainFormChangeEmitterData) {
  }

  itemsFormChange($event: CitesItemFormChangeEmitterData) {
  }
}
