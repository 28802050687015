<div id="collapse2" class="panel-collapse mobile_popup direction_rtl mt-3">
  <div class="panel-body panelstatpads show-details stat-1">
    <div class="container" [class.arrow_first_ar]="isArabic">
      <div class="row direction_rtl">
        <div class="col-sm-10 alignment_right">
          <h4
            class="bold my-1"
            [innerText]="isArabic ? packageObject.NameAR : packageObject.NameEN"
          ></h4>
        </div>

        <div class="col-xs-2 alignmet_left">
          <div class="text-right my-1 cursor-pointer alignmet_left">
            <a (click)="closeServiceDetails()"
              ><img src="assets/Images/Icons/cancel-24px (white).png"
            /></a>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-12"
          *ngFor="
            let certificateDetail of certificateDetailsList
              | paginate
                : {
                    id: 'pagination2',
                    itemsPerPage: certificateDetailPageSize,
                    currentPage: certificateDetailPageIndex,
                    totalItems: certificateDetailTotalItems
                  }
          "
        >
          <div class="card-2">
            <div class="row">
              <div class="col-xl-2 col-md-3 my-auto">
                <h5 class="color-9 bold alignment_right">
                  {{ certificateDetail.certificateNumber }}
                </h5>
                <label
                  class="page-color-1 alignment_right arbic_width_100"
                  [innerText]="
                    currentLang == 'en'
                      ? certificateDetail.certificateCatalogNameEn
                      : certificateDetail?.certificateCatalogNameAr
                  "
                >
                </label>
              </div>

              <div class="col-xl-1 col-lg-2 col-md-2 col-12 my-auto">
                <p
                  *ngIf="certificateDetail.isExpired"
                  class="pt-11 expired bold position"
                  [innerText]="'ExpiredLicenses' | translate"
                ></p>

                <p
                  *ngIf="!certificateDetail.isExpired"
                  class="pt-11 active bold position"
                  [innerText]="'ActiveLicenses' | translate"
                ></p>
              </div>

              <div class="col-xl-4 col-lg-6 col-md-6 col my-auto">
                <div class="row mb-2">
                  <div
                    class="col-xl-4 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label
                      class="label-color font-12 bold"
                      [innerText]="'ServiceDetailsPracticingDate' | translate"
                    >
                    </label>
                    <p
                      class="bold"
                      [innerText]="
                        certificateDetail.certificatePractisingDate
                          | date : 'dd/MM/yyyy'
                      "
                    ></p>
                  </div>
                  <div
                    class="col-xl-4 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold"
                      >{{ 'ServiceDetailsIssuedDate' | translate }}
                    </label>
                    <p
                      class="bold"
                      [innerText]="
                        certificateDetail.certificateIssueDate
                          | date : 'dd/MM/yyyy'
                      "
                    ></p>
                  </div>
                  <div
                    class="col-xl-4 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold"
                      >{{ 'ServiceDetailsExpiredDate' | translate }}
                    </label>
                    <p
                      class="bold"
                      [innerText]="
                        certificateDetail.certificateExpiryDate
                          | date : 'dd/MM/yyyy'
                      "
                    ></p>
                  </div>
                </div>
              </div>

              <div class="col-xl-5 col-lg-12 pt-3 my_custom_tooltip">
                <div
                  class="row border-bottom mb-2"
                  style="font-size: 7px; font-weight: 700; padding-bottom: 10px"
                >
                  <ng-container
                    *ngFor="
                      let action of certificateDetail.certificateActions
                        | slice : 0 : 4
                    "
                  >
                    <ng-container
                      *ngIf="
                        action.actionPageURL == 'ViewCertificate';
                        else notCertificate
                      "
                    >
                      <a
                        (click)="
                          utilService.viewCertificate(
                            certificateDetail.certificateCatalogCode,
                            certificateDetail.serviceRequestTransactionId
                          )
                        "
                        target="_blank"
                        class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                      >
                        <span
                          [ngClass]="action.frontOfficeIconName"
                          class="d-block"
                          style="font-size: 22px"
                          container="body"
                          tooltipClass="golden_tooltip"
                          ngbTooltip="{{
                            currentLang == 'en' ? action.nameEn : action.nameAr
                          }}"
                        ></span
                        >{{
                          currentLang == 'en' ? action.nameEn : action.nameAr
                        }}
                      </a>
                    </ng-container>
                    <ng-template #notCertificate>
                      <a
                        [href]="action.actionPageURL"
                        target="_blank"
                        class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                      >
                        <span
                          [ngClass]="action.frontOfficeIconName"
                          class="d-block"
                          style="font-size: 22px"
                          container="body"
                          tooltipClass="golden_tooltip"
                          [ngbTooltip]="
                            isArabic ? action.nameAr : action.nameEn
                          "
                        >
                        </span
                        >{{ isArabic ? action.nameAr : action.nameEn }}
                      </a>
                    </ng-template>
                  </ng-container>
                </div>
                <div
                  class="row"
                  style="font-size: 7px; font-weight: 700"
                  *ngIf="certificateDetail.certificateActions.length > 4"
                >
                  <ng-container
                    *ngFor="
                      let action of certificateDetail.certificateActions
                        | slice : 4
                    "
                  >
                    <ng-container
                      *ngIf="
                        action.actionPageURL == 'ViewCertificate';
                        else notCertificate
                      "
                    >
                      <a
                        (click)="
                          utilService.viewCertificate(
                            certificateDetail.certificateCatalogCode,
                            certificateDetail.serviceRequestTransactionId
                          )
                        "
                        class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                      >
                        <span
                          [ngClass]="action.frontOfficeIconName"
                          class="d-block"
                          style="font-size: 22px"
                          container="body"
                          tooltipClass="golden_tooltip"
                          ngbTooltip="{{
                            currentLang == 'en' ? action.nameEn : action.nameAr
                          }}"
                        ></span
                        >{{
                          currentLang == 'en' ? action.nameEn : action.nameAr
                        }}
                      </a>
                    </ng-container>

                    <ng-template #notCertificate>
                      <a
                        [href]="action.actionPageURL"
                        target="_blank"
                        class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                      >
                        <span
                          [ngClass]="action.frontOfficeIconName"
                          class="d-block"
                          style="font-size: 22px"
                          container="body"
                          tooltipClass="golden_tooltip"
                          ngbTooltip="{{
                            currentLang == 'en' ? action.nameEn : action.nameAr
                          }}"
                        ></span
                        >{{
                          currentLang == 'en' ? action.nameEn : action.nameAr
                        }}
                      </a>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>

        <div
          class="d-flex align-items-center w-100"
          *ngIf="certificateDetailsList.length == 0"
        >
          <div class="col-md-12 col-xs-12">
            <h3
              class="text-center arbic_width_100 direction_rtl no_records_fond"
            >
              {{ 'ServiceDetailsThereIsNoRecordsFound' | translate }}
            </h3>
          </div>
        </div>

        <div class="row fr">
          <div class="col-md-12 col-xs-12">
            <pagination-controls
              id="pagination2"
              previousLabel="{{ 'PaginationPreviousLabel' | translate }}"
              nextLabel="{{ 'PaginationNextLabel' | translate }}"
              (pageChange)="onCertificatePageChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
