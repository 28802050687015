import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { RedirectService } from 'src/app/shared/authentication/redirect.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private loaderService: LoaderService,
    public auth: AuthService,
    public redirectService: RedirectService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.requests.push(req);

    this.showLoadingElement();

    return next.handle(req).pipe(finalize(() => this.removeRequest(req)));
  }

  private removeRequest(request: HttpRequest<any>): void {
    const i = this.requests.indexOf(request);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    // to show the loader in the fast response.
    setTimeout(() => this.hideLoadingElement(), 200);
  }

  private showLoadingElement() {
    this.loaderService.isLoading.next(true);
  }

  private hideLoadingElement(): void {
    return this.loaderService.isLoading.next(this.requests.length > 0);
  }
}
