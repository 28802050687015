<form [formGroup]="currentStateObject.form">
  <input
    readonly
    type="text"
    #autofocus
    id="autofocus"
    style="opacity: 0; height: 0; width: 0"
    name="autofocus"
    autofocus
  />
  <div class="row">
    <div class="col-12">
      <label class="text-left" [innerText]="'ServiceFees' | translate"> </label>
      <p
        style="background-color: lightgray"
        class="text-left bold p-2"
        [innerText]="serviceFees"
      ></p>

      <div
        class="total_fine_payment"
        *ngIf="workflowService.feesFromPreviousYears"
      >
        <label class="text-left">{{ 'PreviousYearsFees' | translate }} </label>
        <p style="background-color: lightgray" class="text-left bold p-2">
          {{ workflowService.feesFromPreviousYears }}
        </p>
      </div>

      <span *ngIf="showAcceptTerms" class="terms_conditions">
        <div class="page__custom-settings">
          <div class="page__toggle">
            <label class="toggle">
              <input
                formControlName="AcceptTerms"
                class="toggle__input"
                type="checkbox"
                name="TermsAndConditions"
                id="TermsAndConditions"
              />
              <span class="toggle__label">
                <span class="toggle__text"
                  ><span class="mendatory">*</span
                  >{{ 'IAgreeToTheDataConditions' | translate }}
                  <!-- <a
                    data-toggle="modal"
                    data-target="#termsAndConditionsModal"
                    class="cursor-pointer"
                    ><u> {{ 'ServiceTermsAndConditions' | translate }} </u>
                  </a> -->
                </span>
              </span>
            </label>
          </div>
        </div>
      </span>

      <span *ngIf="showAutoRenewal" class="terms_conditions">
        <div class="page__custom-settings">
          <div class="page__toggle">
            <label class="toggle">
              <input
                formControlName="AutoRenewal"
                class="toggle__input"
                type="checkbox"
                id="autoRenewal"
              />
              <span class="toggle__label">
                <span class="toggle__text"
                  ><span class="mendatory">*</span
                  >{{ 'IAgreeToTheDataConditions' | translate }}
                  <!-- <a
                    data-toggle="modal"
                    data-target="#autoRenewalModal"
                    class="cursor-pointer"
                    ><u>
                      {{ 'ShowAutoRenewalTermsAndConditions' | translate }}
                    </u>
                  </a> -->
                </span>
              </span>
            </label>
          </div>
        </div>
      </span>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-sm-6">
      <button (click)="moveBack()" class="ds-btn ds-btn-secondary">
        {{ 'Back' | translate }}
      </button>
    </div>

    <div class="col-sm-6 text-right">
      <ng-container
        *ngIf="
          !workflowService.isWaitingForCustomerAction();
          else WaitingForCustomerAction
        "
      >
        <div class="ds-btns">
          <button
            [disabled]="form.invalid"
            *ngIf="showPayNow"
            class="ds-btn ds-btn-primary"
            (click)="submit(CustomerRequestActionsEnum.PayLater.toString())"
            [innerText]="'PayLater' | translate"
          ></button>

          <button
            [disabled]="form.invalid"
            *ngIf="showPayNow"
            class="ds-btn ds-btn-primary"
            (click)="submit(CustomerRequestActionsEnum.PayNow.toString())"
            [innerText]="'PayNow' | translate"
          ></button>

          <button
            *ngIf="showSubmit"
            [disabled]="form.invalid"
            class="ds-btn ds-btn-primary"
            (click)="
              submit(CustomerRequestActionsEnum.SubmitWithoutPayment.toString())
            "
            [innerText]="'Submit' | translate"
          ></button>
        </div>
      </ng-container>

      <ng-template #WaitingForCustomerAction>
        <button
          [disabled]="form.invalid"
          class="ds-btn ds-btn-primary"
          (click)="submit(workflowService.submittedActionCode.toString())"
        >
          {{ 'Submit' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</form>

<div
  class="modal fade my_tms_model"
  id="termsAndConditionsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header direction_rtl alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ 'ServiceTermsAndConditions' | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="assets/Images/Icons/cancel-24px%20(gold).png" alt="" />
        </button>
      </div>
      <div class="modal-body p-4">
        <app-rendering-html
          [dynamicHtml]="
            isArabic
              ? workflowService.eServiceResponseDataArabic?.termsAndConditions
              : workflowService.eServiceResponseDataEnglish?.termsAndConditions
          "
        ></app-rendering-html>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade my_tms_model"
  id="autoRenewalModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header direction_rtl alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ 'ServiceTermsAndConditions' | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="assets/Images/Icons/cancel-24px%20(gold).png" alt="" />
        </button>
      </div>
      <div class="modal-body p-4">
        <app-rendering-html
          [dynamicHtml]="
            isArabic
              ? workflowService.requestTypeObject
                  ?.autoRenewalCommitmentMessageAr
              : workflowService.requestTypeObject
                  ?.autoRenewalCommitmentMessageEn
          "
        ></app-rendering-html>
      </div>
    </div>
  </div>
</div>
