import { Component, OnInit } from '@angular/core';
import { UtilService } from '../core/utilities/util.service';
import { AuthService } from '../shared/authentication/auth.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  public isTokenExists: boolean;
  public isParticipantIdExists: boolean;
  public isUserProfileExists: boolean;

  constructor(private utilService: UtilService, public auth: AuthService) {}

  ngOnInit(): void {
    this.isTokenExists = this.utilService.isTokenExists();
    this.isParticipantIdExists = this.utilService.isParticipantIdExists();
    this.isUserProfileExists = this.utilService.isUserProfileExists();
  }
}
