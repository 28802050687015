import { Lookup } from "../../common/Lookup";
import { EsOutputs } from "../../shared/EsOutputs";
import { UserDetails } from "../../shared/UserDetails";
import { CitesImportSpeciesItem, CitesImportSpeciesTransactionData } from "../cites-import-species/CitesImportSpeciesTransaction";
import { CitesBaseServiceRequest } from "../shared/CitesBaseServiceRequest";

export class CitesReleaseSpeciesTransaction 
    extends CitesBaseServiceRequest<CitesReleaseSpeciesTransactionData> {
        userDetails?: UserDetails;
        enablePartialRelease?: boolean;
        citesOutputs?: CitesOutput[];
}

export class CitesReleaseSpeciesTransactionData 
    extends CitesImportSpeciesTransactionData {
        shippingBillNumber?: string;
        shippingMethod?: Lookup;
        shippingPolicy?: string;
        tanker?: string;
        exportingPortForRelease?: string;
        checkinDate?: Date;
        items?: CitesReleaseSpeciesItem[];
        releaseItems?: CitesReleaseSpeciesItem[] = [];
}

export class CitesReleaseSpeciesItem
    extends CitesImportSpeciesItem{
        status?: string;
        isIdentifiable?: boolean;
        isImported?: boolean;
        isSelected?: boolean;
        isReleased?: boolean;
        show?: boolean;
}

export interface CitesOutput extends EsOutputs{
    isVerified: boolean;
    verifiedFileURL?: string; 
}