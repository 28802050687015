
///important! be carful once you use prettier or any formatting tools, because it changes the expressions

export enum InputPatterns {
  OnlyDigits = '\\d*',
  /**
   * CommaSeparatedDigits comma separated digits without floating point
   */
  CommaSeparatedDigits = '\\d+(,\\d+)*',

  /**
   * CommaSeparatedFloats comma separated floating numbers
   */
  CommaSeparatedFloats = '([0-9]*\.?[0-9]+)(,[0-9]*\.?[0-9]+)*$',
  AlphaNumeric = '[a-zA-Z0-9_]*',
  AlphaNumericWithSpecialCharacters = '[a-zA-Z0-9\u0621-\u064A .,_-]*',
  Email = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{1,}",
  OnlyEnglishWithSpecialChars = "([a-zA-Z0-9 |.|)|(|\r|\n|\+\-|\/|:|,|'|_|@|%|*|&|^|$|!|#]|\s)*",
  Composition = "[a-zA-Z0-9\u0621-\u064A \r\n\;\,\&\`\'\"\°\“\.\/\\\\|\$\%\^\*\_\+\=\:\=\~\!\@\#\\-\(\)\\[\\]\\{\\}]*",


  /**
   * The pattern .* allows any character(s) before and after the non-whitespace character (\S+).
   *  The + quantifier ensures that at least one non-whitespace character is required.
   */
  AtLeastOneNoneWhiteSpace = '.*\\S+.*',
  Coordinates = "^-?([1-9]?[0-9])\\.{1}\\d{1,12}"
}
