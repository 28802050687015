<legend
    class="col-form-label"
    [innerText]="'Exporter Details' | translate">
</legend>
<div class="row" *ngIf="exporterDetails">
<ng-container *ngIf="fo">
    <div dsCol [colOptions]="fo.fullCol">
        <app-bs-display
            key="trCitesExporterNameFld"
            [valueText]="exporterDetails.exporterName">
        </app-bs-display>
    </div>
    <!-- <div dsCol [colOptions]="fo.halfCol">
        <app-bs-display
            key="trCitesEmailFld"
            [valueText]="exporterDetails.exporterEmail">
        </app-bs-display>
    </div> -->
    <!-- <div dsCol [colOptions]="fo.halfCol">
        <app-bs-display
            key="trCitesPOBoxFld"
            [valueText]="exporterDetails.exporterPOBox">
        </app-bs-display>
    </div> -->
    <!-- <div dsCol [colOptions]="fo.halfCol">
        <app-bs-display
            key="trCitesPhoneFld"
            [valueText]="exporterDetails.exporterPhone">
        </app-bs-display>
    </div> -->
    <!-- <div dsCol [colOptions]="fo.halfCol">
        <app-bs-display
            key="trCitesFaxFld"
            [valueText]="exporterDetails.exporterFax">
        </app-bs-display>
    </div> -->
    <div dsCol [colOptions]="fo.fullCol">
        <app-bs-display 
            *ngIf="exporterDetails.exporterCountry"
            key="trCitesExporterCountryFld"
            [valueText]="exporterDetails.exporterCountry | lookup:lang">
        </app-bs-display>
        <!-- <app-bs-display
            *ngIf="!exporterDetails.exporterCountry"
            key="trCitesExporterCountryFld"
            [valueText]="'trUAECountryName' | translate"
            [templateOptions]="fo.inputsTemplateOptions">
        </app-bs-display> -->
    </div>
    <!-- <div dsCol [colOptions]="fo.halfCol">
        <app-bs-display
            key="trCitesCityFld"
            [valueText]="exporterDetails.exporterCityName">
        </app-bs-display>
    </div> -->
    <div dsCol [colOptions]="fo.fullCol">
        <app-bs-display
            key="trCitesExporterAddressFld"
            [valueText]="exporterDetails.exporterAddress">
        </app-bs-display>
    </div>
</ng-container>
<ng-container  *ngIf="!fo">
    <div class="col-md-12 col-sm-12">
        <app-bs-display
            key="trCitesExporterNameFld"
            [valueText]="exporterDetails.exporterName">
        </app-bs-display>
    </div>
    <!-- <div class="col-md-6 col-sm-12">
        <app-bs-display
            key="trCitesEmailFld"
            [valueText]="exporterDetails.exporterEmail">
        </app-bs-display>
    </div> -->
    <!-- <div class="col-md-6 col-sm-12">
        <app-bs-display
            key="trCitesPOBoxFld"
            [valueText]="exporterDetails.exporterPOBox">
        </app-bs-display>
    </div> -->
    <!-- <div class="col-md-6 col-sm-12">
        <app-bs-display
            key="trCitesPhoneFld"
            [valueText]="exporterDetails.exporterPhone">
        </app-bs-display>
    </div> -->
    <!-- <div class="col-md-6 col-sm-12">
        <app-bs-display
            key="trCitesFaxFld"
            [valueText]="exporterDetails.exporterFax">
        </app-bs-display>
    </div> -->
    <div class="col-md-6 col-sm-12">
        <app-bs-display 
            *ngIf="exporterDetails.exporterCountry"
            key="trCitesExporterCountryFld"
            [valueText]="exporterDetails.exporterCountry | lookup:lang">
        </app-bs-display>
        <!-- <app-bs-display
            *ngIf="!exporterDetails.exporterCountry"
            key="trCitesExporterCountryFld"
            [valueText]="'trUAECountryName' | translate">
        </app-bs-display> -->
    </div>
    <!-- <div class="col-md-6 col-sm-12">
        <app-bs-display
            key="trCitesCityFld"
            [valueText]="exporterDetails.exporterCityName">
        </app-bs-display>
    </div> -->
    <div class="col-md-12 col-sm-12">
        <app-bs-display
            key="trCitesExporterAddressFld"
            [valueText]="exporterDetails.exporterAddress">
        </app-bs-display>
    </div>
</ng-container>
</div>