<circle-progress
  [percent]="percentage"
  [radius]="70"
  [outerStrokeWidth]="5"
  [innerStrokeWidth]="5"
  [outerStrokeColor]="'#008000'"
  [innerStrokeColor]="'#DAA520'"
  [animation]="true"
  [animationDuration]="300"
  [titleFontSize]="'25'"
  [unitsFontSize]="'25'"
  [subtitleFontSize]="'15'"

></circle-progress>
