<ul class="list-group text-muted">
  <li
    class="list-group-item text-center"
    [innerText]="
      key
        ? (key + '.noItemsMessage' | translate)
        : ('No items added' | translate)
    "
  ></li>
</ul>
