<div class="row">
  <div class="col-6">
    <legend
      class="col-form-label mt-3"
      [innerText]="'Consignment Items' | translate"
    ></legend>
  </div>

  <div class="col-4 offset-md-2">
    <app-list-filter (search)="updateSearchText($event)"></app-list-filter>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <ul *ngIf="items && items.length" class="list-group ds-list-group-default">
      <li
        class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2">
        <app-detail-display
          class="w-mm-40"
          [value]=""
          key="trCitesClassFld">
        </app-detail-display>
        <app-detail-display
          *ngIf="!isFalcon"
          [value]="" 
          key="trRegisterTypeFld">
        </app-detail-display>
        <app-detail-display
          class="w-mm-20"
          *ngIf="isFalcon"
          [value]="" 
          key="trRegisterRingNumberFld">
        </app-detail-display>
      </li>

      <li
        *ngFor="let item of items | searchFilter: searchText 
                  | paginate: { id: 'cites_import_items_paginate', itemsPerPage: itemsPerPage, currentPage: currentPage }; 
                    let index = index;"
        class="list-group-item d-flex justify-content-start align-items-center"
        (click)="isCollapsed[index] = !isCollapsed[index]">
        <div
          class="ds-list-group-item-head d-flex w-100 p-2"
          [class.ds-list-group-item-head-open]="isCollapsed[index] == false">
          <app-detail-display
            class="w-mm-40"
            [value]="item.class | lookup: lang">
          </app-detail-display>

          <app-detail-display *ngIf="!isFalcon" [value]="item.type.scientificName">
          </app-detail-display>

          <app-detail-display *ngIf="isFalcon" [value]="item.ringNumber">
          </app-detail-display>

          <div class="list-group-item-options" >
            <a *ngIf="enableEditing" class="ds-btn-icon" 
              (click)="emitCopy($event,item)" [title]="'Copy' | translate">
              <i class="icon-copy mx-2"></i>
            </a>
            <a *ngIf="enableEditing" class="ds-btn-icon" 
              (click)="emitEdit(item,$event)" [title]="'Edit' | translate">
              <i class="fi-register fi-large mx-2"></i>
            </a>
            <a *ngIf="enableEditing" class="ds-btn-icon"
              (click)="emitDelete(item,$event)" [title]="'Delete' | translate">
              <i class="fi-close-thick mx-2"></i>
            </a>
            <a class="ds-btn-icon ds-btn-bg-bright"
              [attr.aria-expanded]="!isCollapsed[index]">
              <i class="fi-arrow-thin-down"></i>
            </a>
          </div>
        </div>

        <div
          class="list-group-item-details collapse show w-100"
          [ngbCollapse]="isCollapsed[index] ?? (isCollapsed[index] = true)">
          <app-cites-import-species-item-view [item]="item">
          </app-cites-import-species-item-view>
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-start align-items-center px-2">
        <div class="w-mm-50 pt-1"
              *ngIf="items.length > itemsPerPage">
          <p>
            {{ ("ItemsCount" | translate) +": "+ items.length }} 
          </p>
        </div>
        <div class="w-mm-50 pagination_in">
          <div class="pagination">
            <pagination-controls 
              id="cites_import_items_paginate"
              previousLabel="{{'PaginationPreviousLabel' | translate}}"
              nextLabel="{{'PaginationNextLabel' | translate }}"
              (pageChange)="currentPage = $event"
              [autoHide]="true"
              [responsive]="true"
              [maxSize]="3">
            </pagination-controls>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<app-list-validation
    name="itemsList"
    [formGroup]="formGroup"
    [items]="items"
    [min]="1"
    [max]="maxItemCount"
    [isEditable]="enableEditing"> 
</app-list-validation>
