import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {Employee, RegisteredUser} from '../../models/profile/RegisteredUser';

@Injectable({
  providedIn: 'root'
})
export class GetProfileService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'RegisteredUser/profile';
  private REST_API_SERVER_EMPLOYEE = environment.DigitalServicesHostURL + 'Employee/GetEmployeeDataWithAllowedServices';

  constructor(private httpClient: HttpClient) {
  }

  public GetProfileData(): Observable<ResponseModel<RegisteredUser>> {
    return this.httpClient.get<ResponseModel<RegisteredUser>>(this.REST_API_SERVER)
      .pipe(catchError(this.handleError));
  }

  public GetEmployeeData(employeeId: string): Observable<ResponseModel<Employee>> {
    let headers = new HttpHeaders();
    headers = headers.append('employeeId', employeeId);
    return this.httpClient.get<ResponseModel<Employee>>(this.REST_API_SERVER_EMPLOYEE, {headers})
      .pipe(catchError(this.handleErrorEmployee));
  }



  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('[GetProfileData] Client Side Error' , errorResponse.error.message);
    } else {
      console.error('[GetProfileData] Server Side Error' , errorResponse.error.message);
    }

    return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }
  private handleErrorEmployee(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('[GetEmployeeData] Client Side Error' , errorResponse.error.message);
    } else {
      console.error('[GetEmployeeData] Server Side Error' , errorResponse.error.message);
    }

    return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
