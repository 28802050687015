import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../base/BaseComponent';

@Component({
  selector: 'app-onboarding-steps',
  templateUrl: './on-boarding-steps.component.html',
  styleUrls: ['./on-boarding-steps.component.scss'],
})
export class OnBoardingStepsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input('serviceId') serviceId: string;
  slides: any;
  toggles: any;
  nextButton: any;
  previousButton: any;
  completeButton: any;
  controls: any;
  dynamicControls: any;
  activeClass: any;
  activeSlideIndex: any;

  get fileServerURL(): string {
    return `${environment.fileServerURL}/TourGuide/${this.folderName}/`;
  }

  @Input() folderName: string;
  //default values
  @Input() hideApplicantPage: boolean;
  @Input() hideServicePage: boolean;

  serviceReviewAr: string;
  serviceReviewEn: string;

  applicantInfoNameAr: string;
  applicantInfoNameEn: string;

  serviceDetailsNameAr: string;
  serviceDetailsNameEn: string;

  attachmentNameAr: string;
  attachmentNameEn: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    translateService: TranslateService
  ) {
    super(translateService);

    this.applicantInfoNameAr = 'applicant-info-ar.gif';
    this.applicantInfoNameEn = 'applicant-info-en.gif';
    this.serviceDetailsNameAr = 'service-ar.gif';
    this.serviceDetailsNameEn = 'service-en.gif';
    this.attachmentNameAr = 'attachments-ar.gif';
    this.attachmentNameEn = 'attachments-en.gif';
    this.serviceReviewAr = 'serviceReview-ar.gif';
    this.serviceReviewEn = 'serviceReview-en.gif';
  }

  ngAfterViewInit(): void {
    this.slides = document.getElementsByClassName('slide-boarding');
    this.toggles = document.getElementsByClassName('toggle');
    this.nextButton = document.getElementsByClassName('next')[0];
    this.previousButton = document.getElementsByClassName('previous')[0];
    this.completeButton = document.getElementsByClassName('complete')[0];
    this.controls = document.getElementsByClassName('controls')[0];
    this.dynamicControls =
      document.getElementsByClassName('dynamic-toggles')[0];
    this.activeClass = 'active';
    this.activeSlideIndex = 0;
    // add event listeners

    this.nextButton.addEventListener('click', () => {
      if (this.activeSlideIndex < this.slides.length - 1) {
        this.activeSlideIndex = this.activeSlideIndex + 1;
        this.toggleSlide(this.activeSlideIndex);
      }
    });

    this.previousButton.addEventListener('click', () => {
      if (this.activeSlideIndex > 0) {
        this.activeSlideIndex = this.activeSlideIndex - 1;
        this.toggleSlide(this.activeSlideIndex);
      }
    });

    this.completeButton.addEventListener('click', this.fireCompleteEvent);

    // init controls on load
    this.initToggles();
  }
  ngOnChanges(changes: SimpleChanges): void {
    // reload OnBoardingServiceObjects
  }

  ngOnInit(): void {}

  createToggle(label: any): void {
    const button = document.createElement('button');
    button.classList.add('toggle');
    button.title = 'Slide ' + (label + 1);
    button.setAttribute('data-id', label);
    button.addEventListener('click', (e) => {
      this.activeSlideIndex = parseInt(
        (e.target as HTMLButtonElement).getAttribute('data-id')
      );
      this.toggleSlide(this.activeSlideIndex);
    });
    this.dynamicControls.appendChild(button);
  }

  initToggles(): void {
    if (this.slides.length > 1) {
      for (let i = 0; i < this.slides.length; ++i) {
        this.createToggle(i);
      }
    }

    this.toggles = document.getElementsByClassName('toggle');
    this.slides[0].classList.add(this.activeClass);
    this.toggles[0].classList.add(this.activeClass);
    this.setCarouselType();
    this.toggleDisabled();
    this.toggleLastSlide();
  }

  setCarouselType(): void {
    if (this.slides.length > 1) {
      this.controls.classList.add('has-nav');
    }
  }

  toggleLastSlide(): void {
    if (this.activeSlideIndex + 1 === this.slides.length) {
      this.controls.classList.add('on-last-slide');
    } else {
      this.controls.classList.remove('on-last-slide');
    }
  }

  toggleDisabled(): void {
    this.activeSlideIndex === 0
      ? (this.previousButton.disabled = true)
      : (this.previousButton.disabled = false);
    this.activeSlideIndex === this.slides.length - 1
      ? (this.nextButton.disabled = true)
      : (this.nextButton.disabled = false);
  }

  toggleSlide(slideIndex: any): void {
    for (let i = 0; i < this.slides.length; ++i) {
      this.slides[i].classList.remove(this.activeClass);
    }

    this.slides[slideIndex].classList.add(this.activeClass);
    for (let i = 0; i < this.slides.length; ++i) {
      this.toggles[i].classList.remove(this.activeClass);
    }
    this.toggles[slideIndex].classList.add(this.activeClass);
    this.toggleDisabled();
    this.toggleLastSlide();
  }

  fireCompleteEvent(): void {
    // alert('Close modal and mark this item as completed.')
  }
}
