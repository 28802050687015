<div class="form-group">
  <label
    class="form-control-label"
    [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
    [for]="identifier"
    [innerText]="key ? (key | translate).labelText : labelText"
  ></label>
  <div class="signature-container">
    <canvas
      #signatureCanvas
      class="signature-pad"
      width="500"
      height="200"
    ></canvas>
  </div>
  <div class="ds-btn-container ds-btn-container-small">
    <button
      class="ds-btn ds-btn-secondary ds-btn-small"
      type="button"
      (click)="clearSignature()"
      [innerText]="'trClearSignature' | translate"
    ></button>
    <button
      class="ds-btn ds-btn-secondary ds-btn-small"
      type="button"
      (click)="undo()"
      [innerText]="'trUndoSignature' | translate"
    ></button>
    <button
      class="ds-btn ds-btn-primary ds-btn-small"
      type="button"
      (click)="onSign()"
      [innerText]="'trSign' | translate"
    ></button>
  </div>
</div>
