import { Input, OnChanges, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input('currentStep') currentStep: string;
  @Input('steps') steps: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  // ngOnChanges(changes: SimpleChanges): void {
  //   // this.calulatePercentage();
  // }

  // calulatePercentage(): void{
  //   const currentIndex = this.steps.indexOf(this.currentStep);
  //   this.percentage = (currentIndex / (this.steps.length - 1) ) * 100;
  // }

  get percentage(): number {
    return (
      (this.steps.indexOf(this.currentStep) / (this.steps.length - 1)) * 100
    );
  }
}
