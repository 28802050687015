import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/operators';
import { EsMainTemplateService } from './es-main-template.service';

export abstract class EsTemplateService<
  TInitiateRequest
> extends EsMainTemplateService {
  readonly POST_INITIATE = 'Initiate';
  readonly POST_VALIDATE = 'Validate';
  readonly POST_UPDATE = 'Update';

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  initiateRequest<T>(request: TInitiateRequest): Observable<T> {
    const url = this.buildUrl(this.POST_INITIATE);

    return this.httpClient
      .post<T>(url, request)
      .pipe(catchError(this.handleError));
  }

  validateRequest<T>(request: TInitiateRequest): Observable<T> {
    const url = this.buildUrl(this.POST_VALIDATE);

    return this.httpClient
      .post<T>(url, request)
      .pipe(catchError(this.handleError));
  }

  updateRequest<T>(request: TInitiateRequest): Observable<T> {
    const url = this.buildUrl(this.POST_UPDATE);

    return this.httpClient
      .post<T>(url, request)
      .pipe(catchError(this.handleError));
  }
}
