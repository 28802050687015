import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsTemplateService } from 'src/app/core/api-services/shared/es-template.service';
import { EsResponseModel } from 'src/app/core/api-services/shared/EsResponseModel';
import { ExportBaseServiceRequest } from 'src/app/core/models/export/shared/ExportBaseServiceRequest';
import { EsTransactionBaseData } from 'src/app/core/models/shared/EsTransactionBaseData';
import { ServiceBaseMapper } from 'src/app/shared/base/ServiceBaseMapper';
import { ServiceRequestDto } from 'src/app/core/models/shared/ServiceRequestDto';
import { EsRequestCardExtraBaseComponent } from '../base/EsRequestCardExtraBaseComponent';

@Component({
  selector: 'es-request-card-extra',
  templateUrl: './es-request-card-extra.component.html',
})
export class EsRequestCardExtraComponent
  extends EsRequestCardExtraBaseComponent
  implements OnInit
{
  isCollapsed: boolean = false;

  serviceDetails?: ExportBaseServiceRequest<EsTransactionBaseData>;

  constructor(
    translateService: TranslateService,
    @Inject('apiService')
    public apiService: EsTemplateService<any>,
    @Inject('mapperService')
    public mapperService: ServiceBaseMapper
  ) {
    super(translateService);
  }

  ngOnInit(): void {}

  public async loadRequestDetails(): Promise<void> {
    const response = await this.apiService
      .getRequest<EsResponseModel<ServiceRequestDto<any>>>(
        this.requestId.toString()
      )
      .toPromise();

    this.serviceDetails = this.mapperService.mapBasic(response.Data);
  }
}
