<div class="row">
  <div class="col-2 pr-0 d-none d-sm-block sections-indexer-container">
    <app-section-indexer></app-section-indexer>
  </div>

  <div class="col-12 col-sm-10">
    <div
      class="forms-container"
      *ngIf="
        {
          transaction: transaction$ | async,
          config: serviceConfig$ | async
        } as both;
        else loading
      "
    >
      <app-es-license-details
        [licenseDetails]="both.config?.licenseDetails"
        contClasses="form-section"
      ></app-es-license-details>

      <router-outlet (activate)="onActivate($event)"></router-outlet>

      <div
        class="form-section request-template-form"
        *ngIf="isShowRequestTemplateForm"
      >
        <app-es-request-template-form
          [formGroup]="requestTemplateForm"
          (save)="saveRequestTemplate()"
          [formOptions]="formOptions"
        >
        </app-es-request-template-form>
      </div>

      <div class="form-section">
        <app-es-main-buttons
          [form]="form"
          (action)="actionHandler($event)"
          [buttons]="buttons"
        ></app-es-main-buttons>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <p [innerText]="'trLoading' | translate"></p>
</ng-template>
