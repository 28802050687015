<div class="page">
  <div class="sticky text-center" *ngIf="generalAlertsMessageObject">
    <p
      class="alert p-2 mb-0 text-center"
      [class.alert-warning]="generalAlertsMessageObject.generalAlertType == 'Warning'"
      [class.alert-info]="generalAlertsMessageObject.generalAlertType == 'Info'"
      [class.alert-danger]="generalAlertsMessageObject.generalAlertType == 'Danger'"
      [innerText]="
        isArabic
          ? generalAlertsMessageObject.messageNameAr
          : generalAlertsMessageObject.messageNameEn
      "
    ></p>
  </div>

  <app-header *ngIf="(isMobile$ | async).isFocusMode == false"></app-header>
  <app-loader></app-loader>

  <router-outlet></router-outlet>

  <app-footer *ngIf="(isMobile$ | async).isFocusMode == false"></app-footer>
  <app-fab
    (action)="handleFabActions($event)"
    *ngIf="(isMobile$ | async).isFocusMode == false"
  ></app-fab>
</div>

<app-sticky-menu
  (action)="handleStickyMenuActions($event)"
  *ngIf="(isMobile$ | async).isFocusMode == false"
></app-sticky-menu>

<div class="overlay_bg d-print-none" *ngIf="showRedirectingSpinner">
  <div
    class="d-flex justify-content-center text-center align-items-center spinner"
  >
    <h2>{{ 'SSORedirectingToLoginPage' | translate }}...</h2>
    <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
  </div>
</div>
