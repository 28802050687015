import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import {
  ServiceCardActions,
  ServiceCardEventEmitterDataHolder,
} from '../../../shared/service-card/ServiceCardEventEmitterDataHolder';
import {
  FavoriteServiceHybrid,
  ServicesListDataService,
} from '../../../core/api-services/shared/services/ServicesListDataService';

import {
  fadeAnimation,
  scaleY,
} from 'src/app/shared/animations/fade.animation';

@Component({
  selector: 'favorite-services',
  templateUrl: './favorite-services.component.html',
  animations: [fadeAnimation, scaleY],
})
export class FavoriteServicesComponent extends BaseComponent {
  searchText = '';
  isShowAllServices = false;

  constructor(
    private servicesListDataService: ServicesListDataService,
    translateService: TranslateService
  ) {
    super(translateService);
  }

  public get $allServices() {
    return this.servicesListDataService.$allServices;
  }

  public async toggleFavorite(service: FavoriteServiceHybrid): Promise<void> {
    await this.servicesListDataService.updateFavorite(
      service.serviceDetails.digitalNumber,
      service.isFavorite == false
    );
  }

  public async fetchServiceMetadata(
    service: FavoriteServiceHybrid
  ): Promise<void> {
    const metadata = await this.servicesListDataService.fetchServiceDetails(
      service.serviceDetails.digitalNumber
    );

    service.metadata = metadata;
    service.expanded = metadata != null;
  }

  public collapse(service: FavoriteServiceHybrid): void {
    service.expanded = false;
  }

  public onServiceCardAction($event: ServiceCardEventEmitterDataHolder) {
    switch ($event.action) {
      case ServiceCardActions.ToggleFavorite:
        this.toggleFavorite($event.data);
        break;
      case ServiceCardActions.FetchMetadata:
        this.fetchServiceMetadata($event.data);
        break;
      case ServiceCardActions.Collapse:
        this.collapse($event.data);
        break;
    }
  }
}
