import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EsBaseTemplateService } from '../shared/es-base-template.service';
import {
  AddOrUpdateTemplateServicesResponse,
  ParticipantTemplateDto,
  TemplateServicesListResponse,
  TemplateServicesResponse,
} from '../export/shared/template-services/TemplateServicesResponse';
import { EsResponseModel } from '../shared/EsResponseModel';

@Injectable()
export class TemplateServicesService extends EsBaseTemplateService {
  readonly basePath = 'ParticipantTemplate';
  private readonly GET_TEMPLATE = 'Get?templateId={templateId}';
  private readonly LIST_TEMPLATE = 'List';
  private readonly SAVE_TEMPLATE = 'AddOrUpdate';
  private readonly DELETE_TEMPLATE = 'Delete?templateId={templateId}';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public get(templateId: string): Observable<TemplateServicesResponse> {
    return this.httpClient
      .get<TemplateServicesResponse>(
        this.buildUrl(this.GET_TEMPLATE).replace('{templateId}', templateId)
      )
      .pipe(catchError(this.handleError));
  }

  public list(): Observable<TemplateServicesListResponse> {
    return this.httpClient
      .get<TemplateServicesListResponse>(this.buildUrl(this.LIST_TEMPLATE))
      .pipe(catchError(this.handleError));
  }

  public addOrUpdate(
    template: ParticipantTemplateDto
  ): Observable<AddOrUpdateTemplateServicesResponse> {
    return this.httpClient
      .post<AddOrUpdateTemplateServicesResponse>(
        this.buildUrl(this.SAVE_TEMPLATE),
        template
      )
      .pipe(catchError(this.handleError));
  }

  public delete(templateId: number): Observable<EsResponseModel<boolean>> {
    return this.httpClient
      .put<EsResponseModel<boolean>>(
        this.buildUrl(this.DELETE_TEMPLATE).replace(
          '{templateId}',
          templateId.toString()
        ),
        {}
      )
      .pipe(catchError(this.handleError));
  }
}
