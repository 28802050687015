import { FieldOptions } from './FieldOptions';

export class SectionOptions {
  isVisible?: boolean;
  isRequired?: boolean;
  fields?: { [key: string]: FieldOptions } = {};

  constructor(options: {
    isVisible?: boolean;
    isRequired?: boolean;
    fields?: { [key: string]: FieldOptions };
  }) {
    this.isVisible = options.isVisible;
    this.isRequired = options.isRequired;
    this.fields = options.fields;
  }
}
