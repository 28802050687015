import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsOutputs } from 'src/app/core/models/shared/EsOutputs';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-outputs',
  templateUrl: './es-outputs.component.html',
})
export class EsOutputsComponent extends BaseComponent {
  @Input()
  showLegend = true;

  @Input()
  outputs?: EsOutputs[] = null;

  searchText: string;
  currentPage: number = 1;
  itemsPerPage: number = 4;

  EXPIRED = 'EXPIRED';
  VALID = 'VALID';

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  async download(output: EsOutputs): Promise<void> {}
}
