import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {RequestStatuses} from '../../models/customer/RequestStatuses';

@Injectable({
  providedIn: 'root'
})
export class GetRequestStatusesService {


  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Customer/' + 'GetStatusCodes';

  constructor(private httpClient: HttpClient) {
  }

  public getRequestStatuses(): Observable<ResponseModel<RequestStatuses[]>> {

    return this.httpClient.get<ResponseModel<RequestStatuses[]>>(this.REST_API_SERVER)
    .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[Customer/GetRequestStatuses] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[Customer/GetRequestStatuses] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
