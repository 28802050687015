import { CountryCodesEnum } from '../../enums/CountryCodesEnum';
import { EmiratesCodesEnum } from '../../enums/emirate-code.enum';

export class AuthorityData {
  tradeLicenseNumber: string;
  industrialLicenseNumber: string;
  licenseType: string;
  licenseIssuingSourceId?: any;
  authorityType: string;
  id: number;

  // authority License Issuer Code
  authorityLicenseIssuerCode: string;
}

export class RegisteredUser {
  id: number;
  registeredUserNumber: string;
  emirateId: number;
  nameAr: string;
  nameEn: string;
  registeredUserAddress: string;
  fax: string;
  poBox: string;
  registeredUserType: RegisteredUserTypesEnum;
  registrationType?: any;
  lastActivationTime?: any;
  authorityData: AuthorityData;
  people?: PeopleData;
  emirateCode: EmiratesCodesEnum;
  exceptionTypesCodes: string[];
  mobile: string;
  email: string;
  encryptedUserID?: string;
}

export interface Employee {
  employeeId: number;
  nameAr: string;
  nameEn: string;
  allowedServiceIdsWithEditAccess: number[];
}

export enum RegisteredUserTypesEnum {
  Authority = 'Authority',
  Person = 'Person',
}

export class LookupCountry {
  id: number;
  code2: string;
  nameAr: string;
  nameEn: string;
}

export interface PeopleData {
  registeredUserId: number;
  countryId: number;
  countryCode: CountryCodesEnum;
  sex: string;
  idNumber: string;
  unifiedNumber?: any;
  passportNumber: string;
  birthDate: Date;
  birthPlace?: any;
  residencyStatus?: any;
  maritalStatusId?: any;
  religionId?: any;
  specializationId?: any;
  isDisableUser: boolean;
}
