import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as getCertificateDetails from "../../models/customer/CertificateDetails_Portal";
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {
  FrontOfficeCardPackageEnum,
  RegisteredUserCertificate,
  RegisteredUserCertificatesCount
} from '../../models/customer/RegisteredUserCertificate';
@Injectable({
  providedIn: 'root'
})
export class GetCertificateDetailsService {

  private REST_API_SERVER_PORTAL = environment.hostURL + environment.hostCustomer + 'GetCertificateDetails';
  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Customer/' + 'GetCertificatesByCardPackageCode';
  private REST_API_SERVER_COUNT = environment.DigitalServicesHostURL + 'Customer/' + 'GetCertificatesCountByCardPackageCode';

  constructor(private httpClient: HttpClient) {
  }

  public GetCertificateDetails_Portal(participantID: string,
                                      packageID: string,
                                      pageSize: number,
                                      pageIndex: number,
                                      packageCode?:string): Observable<getCertificateDetails.GetCertificateDetailsObject> {

    let params = new HttpParams();
    params = params.append('participantID', participantID);
    params = params.append('packageID', packageID);
    params = params.append('pageSize', pageSize + '');
    params = params.append('pageIndex', pageIndex + '');

    if (packageCode) {
      params = params.append('packageCode', packageCode);
    }

    return this.httpClient.get<getCertificateDetails.GetCertificateDetailsObject>(this.REST_API_SERVER_PORTAL,
                                                                                  {params})
                          .pipe(catchError(this.handleError));
  }
  public GetCertificateDetails(packageCode: FrontOfficeCardPackageEnum): Observable<ResponseModel<RegisteredUserCertificate[]>> {

    let params = new HttpParams();
    params = params.append('cardPackageCode', packageCode);
    return this.httpClient.get<ResponseModel<RegisteredUserCertificate[]>>(this.REST_API_SERVER, {params}).pipe(catchError(this.handleError));
  }
  public GetCertificateDetailsCount(packageCode: FrontOfficeCardPackageEnum): Observable<ResponseModel<RegisteredUserCertificatesCount>> {

    let params = new HttpParams();
    params = params.append('cardPackageCode', packageCode);
    return this.httpClient.get<ResponseModel<RegisteredUserCertificatesCount>>(this.REST_API_SERVER_COUNT, {params}).pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetCertificateDetailsService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetCertificateDetailsService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }


}
