<div class="row fee-list-legend">
  <div class="col-12">
    <legend
      class="col-form-label mt-3"
      [innerText]="'Fees' | translate"
    ></legend>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <ul
      *ngIf="fees && fees.feeGrid; else noItemsAddedBlock"
      class="list-group ds-list-group-default"
    >
      <li
        class="list-group-item ds-list-group-head-default d-flex justify-content-between align-items-center px-2"
      >
        <app-detail-display
          class="w-mm-40"
          [value]=""
          key="trFeeName"
        ></app-detail-display>
        <app-detail-display
          class="w-mm-30"
          [value]=""
          key="trFeeQuantity"
        ></app-detail-display>
        <app-detail-display
          class="w-mm-20"
          [value]=""
          key="trFeeTotal"
        ></app-detail-display>
      </li>
      <li
        *ngFor="let fee of fees.feeGrid; let index = index"
        class="list-group-item d-flex align-items-center"
      >
        <div
          class="ds-list-group-item-head justify-content-between d-flex w-100 p-2"
        >
          <app-detail-display
            class="w-mm-40"
            [value]="isArabic ? fee.nameAr : fee.nameEn"
          ></app-detail-display>
          <app-detail-display
            class="w-mm-30"
            [value]="fee.quantity"
          ></app-detail-display>
          <app-detail-display
            class="w-mm-20"
            [value]="fee.feeTotal"
          ></app-detail-display>
        </div>
      </li>
      <li
        class="list-group-item ds-list-group-footer-default d-flex justify-content-between align-items-center px-2"
      >
        <app-detail-display
          class="w-mm-40"
          [value]=""
          key="trFeeGrandTotal"
        ></app-detail-display>
        <app-detail-display
          class="w-mm-30"
          [value]=""
          key=""
        ></app-detail-display>
        <app-detail-display
          class="w-mm-20 bold"
          [value]="fees.total"
          key=""
        ></app-detail-display>
      </li>
    </ul>
  </div>
</div>

<ng-template #noItemsAddedBlock>
  <app-list-no-items></app-list-no-items>
</ng-template>
