import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgControl, ValidatorFn } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { CompareWithFn } from '@ng-select/ng-select/lib/ng-select.component';
import { valueFromItemsOnly } from '../../../validators/valueFromItemsOnly.validator';
import {
  IDropdownSettings,
  InputSettingsService,
} from '../../../input-settings.service';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';
import { InputViewModeEnum } from './InputViewModeEnum';

@Component({
  selector: 'bs-ng-dropdown',
  templateUrl: './bs-ng-dropdown.component.html',
  providers: [MakeProvider(BsNgDropdownComponent)],
})
export class BsNgDropdownComponent
  extends BsNgInputBaseComponent
  implements OnInit, AfterViewInit
{
  private _items: any[];
  private valueFormItemsOnlyValidators: ValidatorFn[] = [];

  @Input()
  set items(value: any[]) {
    this._items = value;

    if (this.selectFromItemsOnly) {
      this.assignSelectFromItemsOnlyValidator();
    }
  }
  get items(): any[] {
    return this._items;
  }

  @Input()
  searchable: boolean = true;

  @Input()
  bindLabel: string = '';

  @Input()
  bindValue: string = 'id';

  @Input()
  addTag = false;

  @Input()
  addTagText: string = 'add item';

  @Input()
  equalMessage: string;

  @Input()
  valueFromItemsOnlyMessage: string;

  @Input()
  sortProp: any = { ar: 'nameAr', en: 'nameEn', dir: true };

  @Input()
  breakSpaces = false;

  @Input()
  multiple = false;

  @Input()
  closeOnSelect = true;

  @Input()
  selectFromItemsOnly: boolean;

  @Input()
  viewMode: InputViewModeEnum = InputViewModeEnum.Input;

  @Input()
  selectFromItemsCompareWith: CompareWithFn;

  @Input()
  infoDialogId?: string = null;

  label: any = { ar: 'nameAr', en: 'nameEn' };

  dropdownLabel: string = '';

  forbiddenMessage = '';

  @ViewChild(NgSelectComponent)
  ngSelect!: NgSelectComponent;

  isArabic: boolean;

  @Output()
  search: any = new EventEmitter();
  validator: any;

  public settings: IDropdownSettings;
  public get isDisplay(): boolean {
    return this.viewMode == InputViewModeEnum.Display;
  }

  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(DOCUMENT) private document: Document,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);
    this.isArabic = translate.currentLang == 'ar';

    /// on language change need to call detectChange on the ng-select, as it uses the push change detection method
    /// https://github.com/ng-select/ng-select#change-detection
    translate.onLangChange.subscribe(() => {
      this.items = [...this.items];
      this.isArabic = translate.currentLang == 'ar';
      this.ngSelect.detectChanges();
    });

    this.setDefaultSettings(defaultSettings);
  }

  private setDefaultSettings(defaultSettings: InputSettingsService) {
    this.selectFromItemsOnly = defaultSettings.dropdown.selectFromItemsOnly;
    this.selectFromItemsCompareWith =
      defaultSettings.dropdown.selectFromItemsCompareWith;
    this.bindValue = defaultSettings.dropdown.bindValue;
    this.settings = defaultSettings.dropdown;
  }

  ngOnInit() {
    this.dropdownLabel = `${this.identifier}-dropdown`;

    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  ngAfterViewInit() {
    this.assignSelectFromItemsOnlyValidator();
  }

  ngOnDestroy() {
    this.removeValueFormItemsOnlyValidators();
  }

  public searchFunction($event: any): void {
    this.search.emit($event);
  }

  private assignSelectFromItemsOnlyValidator() {
    if (this.ngControl && this.selectFromItemsOnly) {
      this.removeValueFormItemsOnlyValidators();

      const validator = this.createSelectFromItemsOnlyValidator();
      this.valueFormItemsOnlyValidators.push(validator);
      this.ngControl.control.addValidators(validator);

      this.ngControl.control.updateValueAndValidity();
    }
  }

  private createSelectFromItemsOnlyValidator(): ValidatorFn {
    return valueFromItemsOnly(
      this._items,
      (i1, i2) => this.selectFromItemsCompareWith(i1, i2),
      {
        key: this.settings.selectFromItemsLabelKey,
        keySource: 'Pure',
      }
    );
  }

  private removeValueFormItemsOnlyValidators() {
    this.ngControl.control.removeValidators(this.valueFormItemsOnlyValidators);

    /// It makes sense to un-comment the below line to make validators list empty
    /// However, for some reasons some validations is not being removed from the control
    /// Hence, decided to keep all the registered validators and remove all of them
    /// all the time
    /// this.valueFormItemsOnlyValidators = [];
    this.ngControl.control.updateValueAndValidity();
  }

  protected assignTranslations(trs: any) {
    super.assignTranslations(trs);

    this.forbiddenMessage = trs.forbiddenMessage || '';
    this.equalMessage = trs.equalMessage;
    this.valueFromItemsOnlyMessage = trs.valueFromItemsOnlyMessage;
  }

  public open() {
    if (this.breakSpaces) {
      setTimeout(() => {
        const panel = this.document.querySelector('.ng-dropdown-panel');
        panel.classList.add('breakSpaces');
      });
    }
  }
}
