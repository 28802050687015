import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-service-disclaimers',
  templateUrl: './es-service-disclaimers.component.html',
})
export class EsServiceDisclaimersComponent extends BaseComponent {
  @Input()
  alerts?: AlertOptions[] = null;

  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
