<div class="container">
	<div class="row">
		<div class="col-lg-12">
			<div class="launching">
				<p> {{'LaunchingDigitalServices' | translate}} </p>

				<div class="launching_box">
					<a href="/home?dashboard=true">
						<img src="assets/Images/go_digital.png" alt="">
					</a>
				</div>

			</div>
		</div>
	</div>
</div>
