<div
  class="form-group"
  [ngClass]="{
    'has-error has-danger':
      ngControl.touched && !ngControl.valid && !ngControl.disabled,
    'has-success': ngControl.touched && ngControl.valid
  }"
>
  <label
    class="form-control-label"
    [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
    [for]="identifier"
    >{{ labelText }}</label
  >
  <input
    [id]="identifier"
    [name]="name"
    class="form-control"
    [ngClass]="
      hasRequiredError() || hasPhoneError()
        ? 'is-invalid'
        : touched
        ? 'is-valid'
        : ''
    "
    type="text"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    #control="ngModel"
    [required]="required == 'true' || required == '' ? '' : undefined"
    (blur)="onTouched()"
    [readonly]="readonly"
    [disabled]="disabled"
  />
  <small
    [id]="identifier + 'Help'"
    *ngIf="helpText"
    class="form-text text-muted"
  >
    {{ helpText }}</small
  >
  <div class="invalid-feedback" *ngIf="hasRequiredError()">
    {{ requiredMessage }}
  </div>
  <div class="invalid-feedback" *ngIf="hasPhoneError()">{{ phoneMessage }}</div>
</div>
