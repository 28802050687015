import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError, delay } from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as dashBoardDetails from "../../../core/models/customer/DashBoardDetails";

@Injectable({
  providedIn: 'root'
})
export class DashBoardDetailsService {

  private REST_API_SERVER = environment.hostURL + environment.hostCustomer + 'GetDashBoardDetails';

  constructor(private httpClient: HttpClient) {
  }

  public getDashBoardDetails(participantid: string): Observable<dashBoardDetails.DashBoardDetailsObject> {

    let params = new HttpParams();
    params = params.append('participantid', participantid);

    return this.httpClient.get<dashBoardDetails.DashBoardDetailsObject>(this.REST_API_SERVER,
                                                                        {params})
    .pipe(catchError(this.handleError));
  }

  public async getDashBoardDetailsSync(participantid: string): Promise<dashBoardDetails.DashBoardDetailsObject> {

    let params = new HttpParams();
    params = params.append('participantid', participantid);

    return this.httpClient.get<dashBoardDetails.DashBoardDetailsObject>(this.REST_API_SERVER,
                                                                        {params: params})
    .pipe(catchError(this.handleError)).toPromise();
  }

  private handleError(errorResponse : HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[Customer/GetDashBoardDetails] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[Customer/GetDashBoardDetails] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }
}
