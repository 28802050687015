<form [formGroup]="formGroup">
  <legend
    class="col-form-label"
    [innerText]="'trCitesFalconPassportAddressDetails' | translate"
  ></legend>

  <div class="row" *ngIf="alerts.length">
    <div class="col-sm-12">
      <app-es-service-disclaimers
        [alerts]="alerts"
      ></app-es-service-disclaimers>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12">
      <app-bs-display
        key="trCitesDeliveryCompanyFld"
        [valueText]="deliveryCompanyDetails | lookup : lang"
      >
      </app-bs-display>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12" *ngIf="fields.deliveryType.isVisible">
      <bs-ng-dropdown
        formControlName="deliveryType"
        identifier="deliveryType"
        key="trCitesDeliveryTypeFld"
        name="deliveryType"
        [items]="lookups.deliveryTypes"
        [bindLabel]="defaultLookupDisplayProperty"
        (change)="onDeliveryType($event)"
        [viewMode]="fields.deliveryType.viewMode"
      >
      </bs-ng-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12" *ngIf="fields.receiverEmirate.isVisible">
      <bs-ng-dropdown
        formControlName="receiverEmirate"
        identifier="receiverEmirate"
        key="trCitesReceiverEmirateFld"
        name="receiverEmirate"
        [items]="lookups.emirates"
        [bindLabel]="defaultLookupDisplayProperty"
        (change)="onEmirateChange($event)"
        [viewMode]="fields.receiverEmirate.viewMode"
      >
      </bs-ng-dropdown>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12" *ngIf="fields.receiverRegion.isVisible">
      <bs-ng-dropdown
        formControlName="receiverRegion"
        identifier="receiverRegion"
        key="trCitesReceiverRegionFld"
        name="receiverRegion"
        [items]="lookups.regions"
        [bindLabel]="defaultLookupDisplayProperty"
        [viewMode]="fields.receiverRegion.viewMode"
      >
      </bs-ng-dropdown>
    </div>
  </div>

  <div class="row" *ngIf="fields.mobileNumber.isVisible">
    <div class="col-md-12 col-sm-12">
      <bs-ng-text
        formControlName="mobileNumber"
        identifier="mobileNumber"
        key="trCitesMobileNumberFld"
        name="mobileNumber"
        [mask]="phoneNumberMask"
        [dropSpecialCharacters]="false"
      >
      </bs-ng-text>
    </div>
  </div>

  <div class="row" *ngIf="fields.addressDetails.isVisible">
    <div class="col-md-12 col-sm-12">
      <bs-ng-textarea
        formControlName="addressDetails"
        identifier="addressDetails"
        key="trCitesAddressDetailsFld"
        name="addressDetails"
        maxLength="500"
      >
      </bs-ng-textarea>
    </div>
  </div>
</form>
