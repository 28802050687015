<div
  class="fab fabcontainer d-print-none"
  (mouseover)="show = true"
  (mouseout)="show = false"
>
  <div class="short_overlay" *ngIf="show"></div>
  <div class="mainopShadow floatingicon">
    <div class="mainop mainpos">
      <span class="addicon icon-add_circle-24px2x"></span>
    </div>
  </div>
  <div class="fab_inner_icon">
    <div
      id="forms"
      (click)="goToNotification()"
      class="minifab op5 prelative"
      style="cursor: pointer"
    >
      <span class="badge custom_badge bg-danger badge-notification">{{
        notificationCount
      }}</span>
      <img
        class="minifabIcon"
        src="assets/Images/Icons/notification_important-24px (1)-W.png"
      />
      <span class="common_alert_pos">
        {{ 'ShortcutCircleALERTS' | translate }}</span
      >
    </div>
    <div
      id="drawings"
      (click)="goToPage('/home?isStatistics=true')"
      class="minifab op4 prelative"
      style="cursor: pointer"
    >
      <img
        class="minifabIcon"
        src="assets/Images/Icons/leaderboard-24px (1).png"
      />
      <span class="common_alert_pos">
        {{ 'ShortcutCircleSTATISTICS' | translate }}</span
      >
    </div>
    <div
      [routerLink]="['/services/']"
      id="slides"
      class="minifab op3 prelative"
      style="cursor: pointer"
    >
      <img
        class="minifabIcon"
        src="assets/Images/Icons/add_circle_outline-24px.png"
      />
      <span class="common_alert_pos rquest_con">{{
        'NewRequest' | translate
      }}</span>
    </div>
    <div
      (click)="goToPage('')"
      id="sheets"
      class="minifab op2 prelative"
      style="cursor: pointer"
    >
      <img class="minifabIcon" src="assets/Images/Icons/apps-24px@2x.png" />
      <span class="common_alert_pos">
        {{ 'ShortcutCircleDASHBOARD' | translate }}</span
      >
    </div>
  </div>
</div>
