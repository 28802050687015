import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import {
  EsAttachmentService,
  EsPostRequestAttachment,
} from 'src/app/core/api-services/shared/es-attachment.service';
import { EsAttachmentResponse } from 'src/app/core/api-services/shared/EsAttachmentResponse';
import { EsAttachmentFileDto } from 'src/app/core/models/shared/EsAttachmentFileDto';
import { EsServiceAttachments } from 'src/app/core/models/shared/EsServiceAttachments';
import { FlowButtons } from 'src/app/shared/base/FlowButtons';
import { EsAttachmentInstance } from 'src/app/shared/base/EsAttachmentInstance';
import { EsServiceAttachmentModel } from 'src/app/shared/base/EsServiceAttachmentModel';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { WorkflowBaseService } from 'src/app/shared/base/WorkflowBaseService';
import { AttachmentResultDto } from 'src/app/core/models/shared/AttachmentResultDto';
import { EsAttachment } from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-attachments',
  templateUrl: './es-attachments-page.component.html',
})
export class ImportAttachmentsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  form: FormGroup;
  attachments: EsServiceAttachmentModel[] = [];
  currentLang: string;
  buttons: FlowButtons;
  showValidation: boolean;

  readonly MAX_COUNT_OF_ATTACHMENTS = 3;
  readonly MAX_FILE_SIZE_BYTE: number = 5 * 1024 * 1024; ///5MB

  constructor(
    translate: TranslateService,
    @Inject('WorkflowService')
    public workflowService: WorkflowBaseService,
    private attachmentService: EsAttachmentService,
    private toaster: ToastrService
  ) {
    super(translate);

    this.workflowService.setCurrentStep(WorkflowSteps.AttachmentsConfiguration);

    this.form = workflowService.globalObject.AttachmentsConfiguration.form;
    this.buttons =
      workflowService.globalObject.AttachmentsConfiguration.flowButtons;
  }

  ngAfterViewInit(): void {
    let attachmentInstances =
      this.workflowService.globalObject.AttachmentsConfiguration.attachments;

    for (const a of attachmentInstances) {
      this.setAttachmentInstance(a.ProcedureAttachmentId, a);
    }
  }

  ngOnInit(): void {
    const result = this.workflowService.getAllAttachments();
    this.attachments = this.mapToDto(result);
  }

  private mapToDto(
    attachments: EsServiceAttachments[]
  ): EsServiceAttachmentModel[] {
    return attachments.map((a) => {
      return {
        attachmentCode: a.attachmentCode,
        nameAr: a.nameAr,
        nameEn: a.nameEn,
        isRequired: a.isRequired,
        procedureAttachmentId: a.procedureAttachmentId,
        attachmentId: a.procedureAttachmentId,
      };
    });
  }

  public submit(): void {}

  // check if an attachment with @attachmentId is already stored in the form or not
  public getAttachmentFromFormByAttachmentId(
    attachmentId: number
  ): EsAttachmentFileDto {
    const urlObject = this.workflowService.getAttachment(attachmentId);

    if (urlObject) {
      return urlObject;
    }

    return null;
  }

  // public isAttachmentExist(attachmentId: number): boolean {
  //   const attachment = this.workflowService.getAttachment(attachmentId);

  //   return attachment != null;
  // }

  public handleUploadFilesButtonClick(func: any): void {
    func();
  }

  public onSelectFile(
    event: NgxFileDropEntry[],
    procedureAttachmentId: number
  ): void {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          if (this.validateFileSize(file)) {
            this.attacheFile(file, procedureAttachmentId);
          } else {
            this.toaster.error(
              this.translateService.instant('trMaximumAllowedFileSize', {
                maximumAllowedFileSize:
                  this.convertByteToMegaByte(this.MAX_FILE_SIZE_BYTE) + ' MB',
              })
            );
          }
        });
      }
    }
  }

  private convertByteToMegaByte(sizeInByte: number) {
    if (sizeInByte <= 0) {
      return 0;
    }

    return sizeInByte / (1024 * 1024);
  }

  private validateFileSize(file: File) {
    return file.size <= this.MAX_FILE_SIZE_BYTE;
  }

  private async attacheFile(
    file: File,
    procedureAttachmentId: number
  ): Promise<void> {
    const result = await this.attachmentService
      .uploadAttachment(file)
      .toPromise();

    await this.uploadAttachmentSuccess(result, procedureAttachmentId);
  }

  private get requestId(): number {
    return this.workflowService.globalObject.ServiceDetailsConfiguration.id;
  }

  private async uploadAttachmentSuccess(
    res: EsAttachmentResponse,
    procedureAttachmentId: number
  ): Promise<void> {
    if (this.requestId)
      await this.addRequestAttachment(
        res,
        this.requestId,
        procedureAttachmentId
      );

    const attachment: EsAttachmentFileDto = {
      ProcedureAttachmentId: procedureAttachmentId,
      FileId: res.Data[0].FileId,
      AttchfileNames: res.Data[0].AttchfileNames,
    };

    this.workflowService.addAttachment(attachment);

    this.setAttachmentInstance(procedureAttachmentId, res.Data[0]);
  }

  private async addRequestAttachment(
    res: EsAttachmentResponse,
    requestId: number,
    procedureAttachmentId: number
  ): Promise<void> {
    const attachment = new EsPostRequestAttachment();

    attachment.AttachmentFileName = res.Data[0].AttchfileNames;
    attachment.FileGuid = res.Data[0].FileId;
    attachment.ProcAttachmentId = procedureAttachmentId;
    attachment.ProcInstanceId = requestId;

    await this.attachmentService.addRequestAttachment(attachment).toPromise();
  }

  public async removeFileInstance(
    attachment: EsServiceAttachmentModel,
    instance: EsAttachmentInstance
  ): Promise<void> {
    if (this.requestId) await this.deleteRequestAttachment(instance);

    attachment.instances = attachment.instances.filter(
      (a) => a.fileId != instance.fileId
    );

    this.workflowService.removeAttachment(instance.fileId);
  }

  private async deleteRequestAttachment(
    instance: EsAttachmentInstance
  ): Promise<void> {
    await this.attachmentService
      .deleteRequestAttachment({
        FileGuid: instance.fileId,
      })
      .toPromise();
  }

  private setAttachmentInstance(
    procedureAttachmentId: number,
    file: AttachmentResultDto
  ) {
    for (const attachment of this.attachments) {
      if (attachment.procedureAttachmentId == procedureAttachmentId) {
        const instance: EsAttachmentInstance = {
          fileName: file.AttchfileNames,
          fileId: file.FileId,
        };

        if (!attachment.instances) attachment.instances = [];

        attachment.instances.push(instance);
      }
    }
  }

  public async actionHandler($event: ActionEmitDataHolder) {
    this.showValidation = true;
    this.workflowService.move($event);
  }

  async download(fileId: string): Promise<void> {
    const result = await this.attachmentService.download(fileId).toPromise();

    const blob = new Blob([this.dataURItoArray(result.Data.FileData)], {
      type: result.Data.FileMimeType,
    });

    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  private dataURItoArray(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return int8Array;
  }
}
