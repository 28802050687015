import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicationMessageComponent } from '../communication-message/communication-message.component';
import { SendBackCommunicationMessagesComponent } from '../send-back-communication-messages/send-back-communication-messages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { RenderingHtmlComponent } from '../rendering-html/rendering-html.component';
import { LoaderComponent } from '../loader/loader.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OnBoardingStepsComponent } from '../onboarding-steps/on-boarding-steps.component';
import { DashboardStatisticsComponent } from '../dashboard-statistics/dashboard-statistics.component';
import { ChartsModule } from 'ng2-charts';
import { SafeUrlPipe } from '../../core/pipes/safe-url.pipe';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { StepsFlowComponent } from '../steps-flow/steps-flow.component';
import { FormValidationIndicatorComponent } from '../form-validation-indicator/form-validation-indicator.component';
import { StepsCaptionsComponent } from '../steps-captions/steps-captions.component';
import { ListNoItemsComponent } from '../list-no-items/list-no-items.component';
import { LookupPipe, LookupsPipe } from '../pipes/lookup.pipe';
import { DetailDisplayComponent } from '../detail-display/detail-display.component';
import { SearchFilterPipe } from '../pipes/search-filter/search-filter.pipe';
import { SectionIndexerComponent } from '../section-indexer/section-indexer.component';
import { ListFilterComponent } from '../list-filter/list-filter.component';
import { InputComponentsInvalidScrollerDirective } from '../input-components-invalid-scroller/input-components-invalid-scroller.directive';
import { BsAlertComponent } from '../bs-alert/bs-alert.component';
import { YesNoPipe } from '../pipes/yesNo.pipe';
import { ListValidationComponent } from '../list-validation/list-validation.component';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { EsCommentsComponent } from '../es-comments/es-comments.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { InputComponentsModule } from '../input-components/input-components.module';
import { EsAttachmentService } from 'src/app/core/api-services/shared/es-attachment.service';
import { FieldOptionsService } from '../field-options/field-options.service';
import { ServiceCardComponent } from '../service-card/service-card.component';
import { EsSaveAsDraftService } from 'src/app/core/api-services/shared/save-as-draft/es-save-as-draft.service';
import { TemplateServicesService } from 'src/app/core/api-services/template-services/template-services.service';
import { DashboardImportantLinksComponent } from '../dashboard-important-links/dashboard-important-links.component';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    CommunicationMessageComponent,
    SendBackCommunicationMessagesComponent,
    RenderingHtmlComponent,
    LoaderComponent,
    ProgressBarComponent,
    OnBoardingStepsComponent,
    DashboardStatisticsComponent,
    DashboardImportantLinksComponent,
    SafeUrlPipe,
    BsAlertComponent,
    BreadcrumbsComponent,
    StepsFlowComponent,
    FormValidationIndicatorComponent,
    StepsCaptionsComponent,
    ListNoItemsComponent,
    LookupPipe,
    LookupsPipe,
    YesNoPipe,
    DetailDisplayComponent,
    SearchFilterPipe,
    SectionIndexerComponent,
    ListFilterComponent,
    InputComponentsInvalidScrollerDirective,
    ListValidationComponent,
    EsCommentsComponent,
    ServiceCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule.forChild(),
    NgCircleProgressModule.forRoot(),
    ChartsModule,
    RouterModule,
    NgxFileDropModule,
    InputComponentsModule.forRoot({
      date: {
        min: '1900-01-01',
        minValidator: new Date('1900-01-01'),
        minValidatorLabelKey: 'input.global.date.minValidator',
        maxValidator: new Date('2222-01-01'),
        maxValidatorLabelKey: 'input.global.date.maxValidator',
      },
      dropdown: {
        bindValue: 'id',
        selectFromItemsCompareWith: (item1: any, item2: any) => {
          if (item1 && item2) {
            return item1 == item2;
          }

          let safe1 = item1 ?? {};
          let safe2 = item2 ?? {};

          if (safe1.id && safe2.id) {
            return safe1.id == safe2.id;
          }

          if (safe1.code && safe2.code) {
            return safe1.code == safe2.code;
          }

          return false;
        },
        selectFromItemsOnly: environment.inputs.dropdown.selectFromItemsOnly,
        selectFromItemsLabelKey:
          'input.global.dropdown.selectFromItemValidator',
      },
      templateOptions: {
        direction: 'horizontal',
      },
    }),
  ],
  exports: [
    CommunicationMessageComponent,
    SendBackCommunicationMessagesComponent,
    RenderingHtmlComponent,
    FormsModule,
    NgbModule,
    TranslateModule,
    InputComponentsModule,
    LoaderComponent,
    ProgressBarComponent,
    NgCircleProgressModule,
    OnBoardingStepsComponent,
    ReactiveFormsModule,
    DashboardStatisticsComponent,
    DashboardImportantLinksComponent,
    SafeUrlPipe,
    BsAlertComponent,
    BreadcrumbsComponent,
    StepsFlowComponent,
    FormValidationIndicatorComponent,
    StepsCaptionsComponent,
    ListNoItemsComponent,
    LookupPipe,
    LookupsPipe,
    YesNoPipe,
    DetailDisplayComponent,
    SearchFilterPipe,
    SectionIndexerComponent,
    ListFilterComponent,
    InputComponentsInvalidScrollerDirective,
    ListValidationComponent,
    EsCommentsComponent,
    ServiceCardComponent,
  ],
  providers: [
    EsCommonService,
    EsAttachmentService,
    FieldOptionsService,
    EsSaveAsDraftService,
    TemplateServicesService,
  ],
})
export class SharedModule {}
