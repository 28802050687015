import { Lookup } from "../../common/Lookup";
import { CitesSpeciesDetails } from "./CitesSpeciesDetails";

export class CitesImportExportSpeciesDetails extends CitesSpeciesDetails {
    allowedTypeDescriptions: Lookup[];
    certificateNo: string;
    actualQuantity: number;
    birthdate: Date;
    exportingCertificateDate: Date;
    lastReExportCountry: Lookup;
    lastReExportCertificateNumber: string;
    lastReExportCertificateDate: Date;
}