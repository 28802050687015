export class ResponseModel<T> {

  isSuccess: boolean;
  responseData: T;
  code: number;
  message: string;
  pagination: Pagination;
  errorResponse: ErrorResponse;
}

export class ErrorResponse {
  errorCode: string;
  errorMessageEn: string;
  errorMessageAr: string;
  errorMessageDescription: string;
  messageType: ToastMessageType;

}

export enum ToastMessageType
{
  Success= 'Success',
  Error= 'Error',
  Info= 'Info',
  Warning= 'Warning'
}
export class Pagination {
  pageNo: number;
  pageSize: number;
  totalItems?: number;
}
