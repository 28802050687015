import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { EsOtpDto } from 'src/app/core/api-services/shared/EsOtpDto';
import { EsApplicantDto } from 'src/app/core/models/shared/EsApplicantDto';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { EsApplicant } from 'src/app/core/models/shared/EsApplicant';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { RequestPurposeLkp } from 'src/app/core/models/shared/Lookups';
import { UtilService } from 'src/app/core/utilities/util.service';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { FlowButtons } from 'src/app/shared/base/FlowButtons';
import { FormMode } from 'src/app/shared/base/FormMode';
import { WorkflowBaseService } from 'src/app/shared/base/WorkflowBaseService';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { EsApplicantIdentityType } from 'src/app/core/models/shared/EsApplicantIdentityType';
import { RegisteredUserTypesEnum } from 'src/app/core/models/profile/RegisteredUser';

@Component({
  selector: 'app-es-applicant-information',
  templateUrl: './es-applicant-information.component.html',
  styleUrls: ['./../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
})
@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
export class EsApplicantInformationComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  observableSubscriptions: Subscription[] = [];

  config$: Observable<EsServiceRequestConfiguration>;

  formGroup: FormGroup;

  mode: FormMode = FormMode.VIEW;

  buttons: FlowButtons;

  emiratesIdMask = '0000-0000000-0';
  emiratesIdMaskPrefix = '784-';

  phoneNumberMask = '00000000009999999999';

  otpMobileNo: string;
  registrationId: string;

  isEmiratesIdVerified = false;

  showRequestPurposeType = false;
  requestPurposes: RequestPurposeLkp[];

  enableApplicantManagement = false;
  requiredUpdate = false;

  constructor(
    @Inject('WorkflowService') public workflowService: WorkflowBaseService,
    translateService: TranslateService,
    private commonApi: EsCommonService,
    private utilService: UtilService,
    @Inject(DOCUMENT) private document: Document,
    private toaster: ToastrService,
  ) {
    super(translateService);

    this.workflowService.setCurrentStep(WorkflowSteps.ApplicantConfiguration);

    this.config$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.formGroup =
      this.workflowService.globalObject.ApplicantConfiguration.form;

    this.buttons =
      this.workflowService.globalObject.ApplicantConfiguration.flowButtons;

    this.requestPurposes =
      this.workflowService.globalObject.ApplicantConfiguration.requestPurposes;

    this.config$.subscribe((serviceConfig) => {
      this.showRequestPurposeType =
        serviceConfig.accessibility?.userHasSpecialTypes;

      if (this.showRequestPurposeType) this.initializeRequestPurpose();

      this.enableApplicantManagement = serviceConfig.applicants?.length > 1;
      if(serviceConfig.applicants && !this.enableApplicantManagement){
        var applicant = serviceConfig.applicants[0];
        this.formGroup.controls.applicant.setValue(applicant);
        if(applicant.identityType == EsApplicantIdentityType.Passport && 
          applicant.identityNumber == ''){
            this.requiredUpdate = true;
            this.editApplicantDetails();
        }
      }
    });
  }

  /**
 * @Remarks used by the auto unsubscribe
 */
  ngOnDestroy(): void {}

  public initializeRequestPurpose() {
    if (this.workflowService.globalObject.ServiceDetailsConfiguration.id) {
      this.formGroup.controls.selectedRequestPurposeType.disable();
    } else {
      this.formGroup.controls.selectedRequestPurposeType.enable();
    }

    this.subscribeOnChangeRequestPurposeType();
  }

  ngOnInit(): void {
    this.initializeControlsState();
    if (this.showRequestPurposeType) this.initializeRequestPurpose();
  }

  private initializeControlsState() {
    this.disableAllControls();
  }

  private disableAllControls() {
    this.formGroup.controls.passport.disable();
    this.formGroup.controls.emiratesId.disable();
    this.formGroup.controls.name.disable();
    this.formGroup.controls.phone.disable();
    this.formGroup.controls.email.disable();
    this.formGroup.controls.preferredLanguage.disable();
    this.formGroup.controls.identityType.disable();
    this.formGroup.controls.selectedRequestPurposeType.disable();
  }

  get isEditing(): boolean {
    return this.mode == FormMode.EDITING;
  }

  get isView(): boolean {
    return this.mode == FormMode.VIEW;
  }

  get isAdding(): boolean {
    return this.mode == FormMode.NEW;
  }

  get isEmiratesIdControlValid(): boolean {
    return this.formGroup.controls.emiratesId.valid;
  }

  get isWaitingOtpValidation(): boolean {
    return this.otpMobileNo != null && this.otpMobileNo != '';
  }

  public async verifyEmiratesId() {
    if (this.isEmiratesIdControlValid) {
      const emiratesId = this.formGroup.controls.emiratesId.value;

      const result = await this.commonApi
        .validateEmiratesId(emiratesId)
        .toPromise();

      if (result.Data.IsValid) {
        this.isEmiratesIdVerified = true;
        this.otpMobileNo = result.Data.MobileNumber;
        this.registrationId = result.Data.RegistrationID;
      } else {
        this.isEmiratesIdVerified = false;
        this.otpMobileNo = '';
        this.registrationId = '';

        const message = this.translateService.instant(
          `trApplicantEmiratesIdErrors.${result.Data.Status}`
        );
        this.toaster.error(message);
      }
    } else this.formGroup.controls.emiratesId.markAsTouched();
  }

  public async verifyOtp() {
    const otp: string = this.formGroup.controls.otp.value;

    const otpValidation: EsOtpDto = {
      RegistrationID: this.registrationId,
      OTP: otp,
    };

    const result = await this.commonApi.validateOtp(otpValidation).toPromise();

    const applicant: EsApplicant = this.mapApplicant(result.Data);

    this.formGroup.controls.name.setValue(applicant.name);
    this.formGroup.controls.email.setValue(applicant.email);
    this.formGroup.controls.phone.setValue(applicant.phone);
  }

  private mapApplicant(esApplicantDto: EsApplicantDto): EsApplicant {
    let result: EsApplicant = new EsApplicant();

    result.email = esApplicantDto.Email;
    result.id = esApplicantDto.ID;
    result.identityNumber = esApplicantDto.IdentityNumber;
    result.identityType = esApplicantDto.IdentityType;
    result.name = esApplicantDto.Name || esApplicantDto.NameAr;
    result.participantId = esApplicantDto.ParticipantID;
    result.passport = esApplicantDto.Passport;
    result.phone = esApplicantDto.Phone;
    result.preferredLanguage = esApplicantDto.PreferedLanguage;

    return result;
  }

  public editApplicantDetails() {
    const applicant = this.formGroup.controls.applicant.value as EsApplicant;
    if (applicant) {
      this.fillFormControls(applicant);
      this.enableFormControls();
      this.disableApplicantControl();

      if(!this.enableApplicantManagement){
        this.formGroup.controls.identityType.disable();
        // this.formGroup.controls.passport.disable();
        this.formGroup.controls.emiratesId.disable();
        this.formGroup.controls.name.disable();
        if(applicant.IsDefaultAgent){
          this.formGroup.controls.phone.disable();
          this.formGroup.controls.email.disable();
        }
      }

      this.mode = FormMode.EDITING;
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  private disableApplicantControl(): void {
    this.formGroup.controls.applicant.disable();
  }

  private enableApplicantControl(): void {
    this.formGroup.controls.applicant.enable();
  }

  private enableFormControls(): void {
    if (this.isEmiratesId) {
      this.enableEmiratesIdControls();
    } else this.enablePassportControls();
  }

  get isExistingApplicant(): boolean {
    const applicant = this.formGroup.controls.applicant.value as EsApplicant;

    return applicant?.id > 0;
  }

  private fillFormControls(applicant: EsApplicant) {
    this.formGroup.controls.id.setValue(applicant.id);
    this.formGroup.controls.participantId.setValue(applicant.participantId);
    this.formGroup.controls.identityType.setValue(applicant.identityType);
    this.formGroup.controls.email.setValue(applicant.email);
    this.formGroup.controls.emiratesId.setValue(applicant.identityNumber);
    this.formGroup.controls.passport.setValue(applicant.identityNumber);
    this.formGroup.controls.name.setValue(applicant.name);
    this.formGroup.controls.phone.setValue(applicant.phone);
    this.formGroup.controls.preferredLanguage.setValue(
      applicant.preferredLanguage
    );
  }

  public addNew() {
    this.mode = FormMode.NEW;
    this.resetForm();
    this.formGroup.controls.applicant.reset();
    this.formGroup.markAsUntouched();
    this.initializeDefaultValues();
    this.enableEmiratesIdControls();
    this.disableApplicantControl();
  }

  private initializeDefaultValues() {
    this.formGroup.controls.identityType.setValue(EsApplicantIdentityType.ID);
  }

  public cancelEditing(): void {
    this.mode = FormMode.VIEW;
    if(this.enableApplicantManagement){
      this.enableApplicantControl();
    }

    this.resetForm();
    this.disableAllControls();
  }

  public identityTypeChanged($event: any) {
    if ($event.new == EsApplicantIdentityType.ID) {
      this.enableEmiratesIdControls();
    } else {
      this.enablePassportControls();
    }
  }

  private enablePassportControls() {
    this.formGroup.controls.id.enable();
    this.formGroup.controls.participantId.enable();
    this.formGroup.controls.identityType.enable();
    this.formGroup.controls.email.enable();
    this.formGroup.controls.passport.enable();
    this.formGroup.controls.name.enable();
    this.formGroup.controls.phone.enable();
    this.formGroup.controls.preferredLanguage.enable();

    this.formGroup.controls.emiratesId.disable();
  }

  private enableEmiratesIdControls() {
    this.formGroup.controls.emiratesId.enable();
    this.formGroup.controls.id.enable();
    this.formGroup.controls.participantId.enable();
    this.formGroup.controls.identityType.enable();
    this.formGroup.controls.email.enable();
    this.formGroup.controls.name.enable();
    this.formGroup.controls.phone.enable();
    this.formGroup.controls.preferredLanguage.enable();

    this.formGroup.controls.passport.disable();
  }

  public applicantChanged($event: any) {
    if (this.isEditing || this.isAdding) {
      this.resetForm();
      this.resetFormMode();
    }
  }

  private resetFormMode() {
    this.mode = FormMode.VIEW;
  }

  private resetForm() {
    this.formGroup.controls.id.reset();
    if(this.enableApplicantManagement){
      this.formGroup.controls.participantId.reset();
    }
    this.formGroup.controls.identityType.reset();
    this.formGroup.controls.email.reset();
    this.formGroup.controls.emiratesId.reset();
    this.formGroup.controls.passport.reset();
    this.formGroup.controls.name.reset();
    this.formGroup.controls.phone.reset();
    this.formGroup.controls.preferredLanguage.reset();
  }

  public async actionHandler($event: ActionEmitDataHolder) {
    if (this.isAdding || this.isEditing) {
      const applicant: EsApplicant = this.createApplicantFromForm();
      this.formGroup.controls.applicant.setValue(applicant);
      this.enableApplicantControl();
    }

    if (this.formGroup.valid) {
      this.workflowService.move($event);
    } else {
      this.formGroup.markAllAsTouched();

      setTimeout(() => {
        this.scrollToFirstError();
      });
    }
  }

  private createApplicantFromForm(): EsApplicant {
    const applicant: EsApplicant = new EsApplicant();

    applicant.id = this.formGroup.controls.id?.value;

    if (this.formGroup.controls.participantId?.value) {
      applicant.participantId = this.formGroup.controls.participantId.value;
    } else {
      applicant.participantId = this.utilService.getUserProfile()?.id;
    }

    applicant.email = this.formGroup.controls.email.value;
    applicant.identityType = this.formGroup.controls.identityType.value;
    applicant.name = this.formGroup.controls.name.value;
    applicant.passport = this.formGroup.controls.passport.value;
    applicant.phone = this.formGroup.controls.phone.value;
    applicant.preferredLanguage =
      this.formGroup.controls.preferredLanguage.value;

    if (this.isPassport)
      applicant.identityNumber = this.formGroup.controls.passport.value;

    if (this.isEmiratesId)
      applicant.identityNumber = this.formGroup.controls.emiratesId.value;

    return applicant;
  }

  public get isPassport(): boolean {
    return (
      this.formGroup.controls.identityType.value ==
      EsApplicantIdentityType.Passport
    );
  }

  public get isEmiratesId(): boolean {
    return (
      this.formGroup.controls.identityType.value == EsApplicantIdentityType.ID
    );
  }

  private scrollToFirstError() {
    const invalidElement = this.document.querySelector('.is-invalid');

    if (invalidElement) invalidElement.closest('.form-section').scrollIntoView();
  }

  get hasPreferredLanguageRequiredError(): boolean {
    return (
      this.formGroup.controls.preferredLanguage.touched &&
      this.formGroup.controls.preferredLanguage.errors &&
      this.formGroup.controls.preferredLanguage.errors.required
    );
  }

  private subscribeOnChangeRequestPurposeType(): void {
    this.formGroup.controls.selectedRequestPurposeType.valueChanges.subscribe(
      (value) => {
        const store = this.workflowService.globalObject.ApplicantConfiguration;

        store.selectedRequestPurposeTypeCode = this.requestPurposes?.find(
          (pur) => pur.id == value
        )?.code;
        this.workflowService.resetPaymentAmount(
          store.selectedRequestPurposeTypeCode
        );
      }
    );
  }

  public get otpAlert(): AlertOptions {
    const result = new AlertOptions();

    const message = this.translateService.instant('trApplicantOtpSentMessage', {
      otpMobileNo: this.otpMobileNo,
    });

    result.text = message;

    return result;
  }
}
