import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { YesNoLkp } from 'src/app/core/models/common/Lookup';

export abstract class BaseMapper {
  protected readonly yesLkp: YesNoLkp = {
    id: 0,
    nameAr: 'لا',
    nameEn: 'No',
    code: 'NO',
    value: false,
  };

  protected readonly noLkp: YesNoLkp = {
    id: 1,
    nameAr: 'نعم',
    nameEn: 'Yes',
    code: 'YES',
    value: true,
  };

  constructor(protected datePipe: DatePipe) {}

  public convertToIntegrationDate(date: Date): string {
    if (date) {
      if (date.toString() === 'Invalid Date') {
        console.warn('Date is invalid');
        return null;
      }

      return this.datePipe.transform(date, 'dd-MM-yyyy');
    }

    return null;
  }

  public convertToDisplayDate(date: Date): string {
    if (date) {
      if (date.toString() === 'Invalid Date') {
        console.warn('Date is invalid');
        return null;
      }

      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    return null;
  }

  public asDateInstance(date: Date): Date {
    //input is in string format not date
    if (date) {
      var dateInstance = new Date(date);
      if (
        dateInstance.getHours() == 0 &&
        dateInstance.getMinutes() == 0 &&
        dateInstance.getSeconds() == 0
      ) {
        //no time
        var dateStr = date.toString();
        return new Date(dateStr.toString().split('T')[0]); //ignore time zone and return new date
      } else {
        var userTimezoneOffset = dateInstance.getTimezoneOffset() * 60000;
        return new Date(dateInstance.getTime() - userTimezoneOffset);
      }
    }

    return null;
  }

  /** pure date without time zone offset
   * Note: this method is under test, and it should replace asDateInstance method
   */
  public asIsoDate(date: Date): Date {
    /// First convert to Date
    /// because Date types in contracts of apis (backend) are not getting converted into date
    /// they remain strings
    const d = new Date(date);

    const iso = d.toISOString();

    return new Date(iso);
  }

  protected asTimeInstance(dateTime: Date): string {
    if (dateTime) {
      const dateTimeInstance = this.asDateInstance(dateTime);

      const time = `${dateTimeInstance
        .getHours()
        .toString()
        .padStart(2, '0')}:${dateTimeInstance
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${dateTimeInstance
        .getSeconds()
        .toString()
        .padStart(2, '0')}`;

      return time;
    }

    return null;
  }

  public convertDateStringToDate(dateString: string): Date {
    if (dateString) {
      return moment(dateString, 'DD-MM-YYYY').toDate();
    }

    return null;
  }
}
