<div
  class="form-group"
  [ngClass]="{
    'has-error has-danger':
      ngControl.touched && !ngControl.valid && !ngControl.disabled,
    'has-success': ngControl.touched && ngControl.valid
  }"
>
  <label class="form-control-label" [for]="identifier">{{ labelText }}</label>
  <select
    [id]="identifier"
    [name]="name"
    class="form-control"
    [ngClass]="hasRequiredError() ? 'is-invalid' : touched ? 'is-valid' : ''"
    [(ngModel)]="value"
    [required]="required == 'true' || required == '' ? '' : undefined"
    #control="ngModel"
    (change)="selectedChange($event)"
    (blur)="onTouched()"
    [disabled]="disabled"
  >
    <option *ngIf="withNull" value=""></option>
    <option *ngFor="let option of options" [ngValue]="option[optionValue]">
      {{ option[optionText] }}
    </option>
  </select>
  <small
    [id]="identifier + 'Help'"
    *ngIf="helpText"
    class="form-text text-muted"
  >
    {{ helpText }}</small
  >
  <div class="invalid-feedback" *ngIf="hasRequiredError()">
    {{ requiredMessage }}
  </div>
</div>
