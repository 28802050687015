import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsApplicantInformationComponent } from '../../es-shared/es-applicant-information/es-applicant-information.component';
import { RouterModule, Routes } from '@angular/router';
import { ImportAttachmentsComponent } from '../../es-shared/es-attachments-page/es-attachments-page.component';
import { PaymentComponent } from '../../shared-services-steps-module/payment/payment.component';
import { CongratulationsComponent } from '../../shared-services-steps-module/congratulations/congratulations.component';
import { EsSharedModule } from '../../es-shared/es-shared.module';
import { EsViewComponent } from '../../es-shared/es-view-page/es-view-page.component';
import { CitesSharedModule } from '../shared/cites-shared.module';
import { CitesReleaseSpeciesComponent } from './cites-release-species.component';
import { CitesReleaseSpeciesRequestComponent } from './request-details/cites-release-species-request.component';
import { CitesReleaseSpeciesViewComponent } from './request-view/cites-release-species-request-view.component';
import { CitesReleaseSpeciesFormComponent } from './request-form/cites-release-species-request-form.component';
import { CitesReleaseSpeciesReviewDetailsComponent } from './request-review-details/cites-release-species-request-review-details.component';
import { CitesReleaseSpeciesReviewComponent } from './request-review/cites-release-species-request-review.component';
import { CitesReleaseSpeciesService } from 'src/app/core/api-services/cites/cites-release-species/cites-release-species.service';
import { CitesReleaseSpeciesMapper } from './CitesReleaseSpeciesMapper';
import { CitesReleaseSpeciesItemFormComponent } from './item-form/cites-release-species-item-form.component';
import { CitesReleaseSpeciesItemListComponent } from './item-list/cites-release-species-item-list.component';
import { CitesReleaseSpeciesViewItemComponent } from './item-view/cites-release-species-item-view.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CitesReleaseSpeciesWorkflowService } from './cites-release-species-workflow.service';
import { EsRequestPageComponent } from '../../es-shared/es-request-page/es-request-page.component';
import { EsReviewPageComponent } from '../../es-shared/es-review-page/es-review-page.component';
import { CitesReleaseSpeciesRequestCardExtraComponent } from './request-card-extra/cites-release-species-request-card-extra.component';

const approutes: Routes = [
  {
    path: '',
    component: CitesReleaseSpeciesComponent,
    children: [
      {
        path: 'applicantinformation',
        component: EsApplicantInformationComponent,
        data: { breadcrumb: { useBreadcrumbKey: 'serviceName' } },
      },
      {
        path: 'requestDetails',
        component: EsRequestPageComponent,
        children: [
          {
            path: '',
            component: CitesReleaseSpeciesRequestComponent,
          },
        ],
        data: { breadcrumb: { useBreadcrumbKey: 'serviceName' } },
      },
      {
        path: 'attachments',
        component: ImportAttachmentsComponent,
        data: { breadcrumb: { useBreadcrumbKey: 'serviceName' } },
      },
      {
        path: 'review',
        component: EsReviewPageComponent,
        children: [
          {
            path: '',
            component: CitesReleaseSpeciesReviewComponent,
          },
        ],
      },
      { path: 'payment', component: PaymentComponent },
      { path: 'congrats', component: CongratulationsComponent },
    ],
  },
  {
    path: 'view',
    component: EsViewComponent,
    children: [{ path: '', component: CitesReleaseSpeciesViewComponent }],
  },
];
@NgModule({
  declarations: [
    CitesReleaseSpeciesComponent,
    CitesReleaseSpeciesRequestComponent,
    CitesReleaseSpeciesFormComponent,
    CitesReleaseSpeciesReviewDetailsComponent,
    CitesReleaseSpeciesReviewComponent,
    CitesReleaseSpeciesViewComponent,
    CitesReleaseSpeciesItemFormComponent,
    CitesReleaseSpeciesItemListComponent,
    CitesReleaseSpeciesViewItemComponent,
    CitesReleaseSpeciesRequestCardExtraComponent,
  ],
  imports: [
    CommonModule,
    EsSharedModule,
    CitesSharedModule,
    RouterModule.forChild(approutes),
    NgxPaginationModule,
  ],
  providers: [
    {
      provide: 'WorkflowService',
      useExisting: CitesReleaseSpeciesWorkflowService,
    },
    {
      provide: 'apiService',
      useExisting: CitesReleaseSpeciesService,
    },
    {
      provide: 'mapperService',
      useExisting: CitesReleaseSpeciesMapper,
    },
    CitesReleaseSpeciesWorkflowService,
    CitesReleaseSpeciesService,
    CitesReleaseSpeciesMapper,
  ],
})
export class CitesReleaseSpeciesModule {}
