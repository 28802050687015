import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as cancelRequestResponse from "../../models/common/CancelRequestResponse";
import {ResponseModel} from '../../models/shared/CommonResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class CancelRequestService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Customer/'+ 'CancelRequest';

  private REST_API_SERVER_CANCEL_DRAFT = environment.DigitalServicesHostURL + 'Customer/' + 'CancelDraftRequest';

  constructor(private httpClient: HttpClient) {
  }


  public CancelRequest(workflowId: number): Observable<ResponseModel<number>> {

    return this.httpClient.post<ResponseModel<number>>(this.REST_API_SERVER, {workflowId})
    .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[CancelRequest] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[CancelRequest] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

  public CancelDraftRequest(serviceRequestTransactionId: number): Observable<ResponseModel<number>> {

    return this.httpClient.post<ResponseModel<number>>(this.REST_API_SERVER_CANCEL_DRAFT, {serviceRequestTransactionId})
    .pipe(catchError(this.handleErrorCancelDraft));
  }

  private handleErrorCancelDraft(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('[CancelDraftRequest] Client Side Error' , errorResponse.error.message);
    } else {
      console.error('[CancelDraftRequest] Server Side Error' , errorResponse.error.message);
    }

    return throwError('There is a problem with the service. we are notified & working on it. please try again later');
}

}
