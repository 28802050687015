<style>
  .selection-container{
    padding-top: 10px;
  }
  .selection-checkbox{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
</style>


<div class="row">
  <div class="col-6">
    <legend
      class="col-form-label mt-3"
      [innerText]="titleKey | translate"
    ></legend>
  </div>

  <div class="col-4 offset-md-2">
    <app-list-filter (search)="updateSearchText($event)"></app-list-filter>
  </div>
</div>

<app-es-service-disclaimers
  *ngIf="enableEditing && enableSelection"
  [alerts]="alerts">
</app-es-service-disclaimers>

<div class="row">
  <div class="col-12">
    <ul *ngIf="visibleItems && visibleItems.length" class="list-group ds-list-group-default">
      <li class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2">
        <div class="w-mm-10 selection-container" *ngIf="enableEditing && enableSelection">
          <input class="selection-checkbox" type="checkbox" 
            [checked]="isAllSelected"
            (change)="onSelectAllChange($event)"/>
        </div>
        <app-detail-display
          [ngClass]="{'w-mm-25': isFalcon, 'w-mm-40' : !isFalcon }"
          [value]=""
          key="trCitesClassFld">
        </app-detail-display>
        <app-detail-display
          *ngIf="!isFalcon"
          [value]="" 
          key="trRegisterTypeFld">
        </app-detail-display>
        <app-detail-display
          class="w-mm-20"
          *ngIf="isFalcon"
          [value]="" 
          key="trRegisterRingNumberFld">
        </app-detail-display>
        <app-detail-display
          *ngIf="isFalcon"
          [value]="" 
          key="trCitesImportCertificateNoFld">
        </app-detail-display>
      </li>

      <li *ngFor="let item of visibleItems | searchFilter: searchText
           | paginate: { id: titleKey + '_pagination', itemsPerPage: itemsPerPage, currentPage: currentPage };  
           let index = index"
        class="list-group-item d-flex justify-content-start align-items-center">

        <div class="ds-list-group-item-head d-flex w-100 p-2"
          [class.ds-list-group-item-head-open]="isCollapsed[index] == false">

            <div class="w-mm-10 selection-container" *ngIf="enableEditing && enableSelection">
              <input class="selection-checkbox" type="checkbox" 
                [checked]="item.isSelected"
                [disabled]="item.isReleased"
                (change)="onSelectItemChange($event,item)"/>
            </div>

            <div [ngClass]="{'w-mm-25': isFalcon, 'w-mm-40' : !isFalcon }"
              (click)="isCollapsed[index] = !isCollapsed[index]">
              <app-detail-display
                [value]="item.class | lookup: lang">
              </app-detail-display>
            </div>

            <div *ngIf="!isFalcon" (click)="isCollapsed[index] = !isCollapsed[index]">
              <app-detail-display [value]="item.type.scientificName">
              </app-detail-display>
            </div>

            <div class="w-mm-20" *ngIf="isFalcon" (click)="isCollapsed[index] = !isCollapsed[index]">
              <app-detail-display [value]="item.ringNumber">
              </app-detail-display>
            </div>

            <div *ngIf="isFalcon" (click)="isCollapsed[index] = !isCollapsed[index]">
              <app-detail-display [value]="item.certificateNo">
              </app-detail-display>
            </div>

            <div class="list-group-item-options">
              <a *ngIf="enableEditing && !item.isIdentifiable" class="ds-btn-icon" 
                (click)="emitEdit(item)" [title]="'Edit' | translate">
                <i class="fi-register fi-large mx-2"></i>
              </a>
              <a class="ds-btn-icon" *ngIf="enableEditing" (click)="emitDelete(item)" [title]="'Delete' | translate">
                <i class="fi-close-thick mx-2"></i>
              </a>
              <a class="ds-btn-icon ds-btn-bg-bright"
                [attr.aria-expanded]="!isCollapsed[index]" 
                (click)="isCollapsed[index] = !isCollapsed[index]">
                <i class="fi-arrow-thin-down"></i>
              </a>
            </div>
        </div>

        <div
          class="list-group-item-details collapse show w-100"
          [ngbCollapse]="isCollapsed[index] ?? (isCollapsed[index] = true)">
          <app-cites-release-species-item-view [item]="item">
          </app-cites-release-species-item-view>
        </div>

      </li>

      <li class="list-group-item d-flex justify-content-start align-items-center px-2">
        <div class="w-mm-50 pt-1"
              *ngIf="visibleItems.length > itemsPerPage">
          <p>
            {{ ("ItemsCount" | translate) +": "+ visibleItems.length }} 
          </p>
        </div>
        <div class="w-mm-50 pagination_in">
          <div class="pagination">
            <pagination-controls 
              id="{{titleKey + '_pagination'}}"
              previousLabel="{{'PaginationPreviousLabel' | translate}}"
              nextLabel="{{'PaginationNextLabel' | translate }}"
              (pageChange)="currentPage = $event"
              [autoHide]="true"
              [responsive]="true"
              [maxSize]="3">
            </pagination-controls>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>


<app-list-validation
    *ngIf="enableSelection"
    name="itemsList"
    [formGroup]="formGroup"
    [items]="selectedItems"
    [min]="1"
    [max]="maxItems"
    minValidationMsgKey="trCitesReleaseMinLengthValidation"
    [isEditable]="enableEditing"> 
</app-list-validation> 

<app-list-validation
    *ngIf="!enableSelection"
    name="itemsList"
    [formGroup]="formGroup"
    [items]="visibleItems"
    [min]="1"
    [max]="maxItems"
    minValidationMsgKey="trCitesReleaseMinLengthValidation"
    [isEditable]="enableEditing"> 
</app-list-validation> 
