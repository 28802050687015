import {
  Component,
  EventEmitter,
  Injector,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';
import { InputSettingsService } from '../../../input-settings.service';

@Component({
  selector: 'bs-ng-radiobox',
  templateUrl: './bs-ng-radiobox.component.html',
  providers: [MakeProvider(BsNgRadioComponent)],
})
export class BsNgRadioComponent extends BsNgInputBaseComponent {
  @Input()
  set: any;

  @Output()
  change = new EventEmitter<RadioBoxEventEmitterDataHolder>();

  get value(): any {
    return this._value;
  }
  @Input()
  set value(v: any) {
    if (v !== this._value) {
      const old = this._value;
      this._value = v;
      this.onChange(v);
      this.onTouched();
      this.change.emit({ old: old, new: v });
    }
  }

  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  onModelChange(value: any) {
    this.onTouched();
    this.onChange(value);

    const old = this._value;

    const data = new RadioBoxEventEmitterDataHolder();
    data.new = value;
    data.old = old;

    this.change.emit(data);
  }
}

export abstract class BaseInputEventEmitterDataHolder {
  new?: any;
  old?: any;
}

export class RadioBoxEventEmitterDataHolder extends BaseInputEventEmitterDataHolder {}
