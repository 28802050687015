import { Injectable, NgModuleRef, Type } from '@angular/core';
import { CitesImportSpeciesModule } from 'src/app/services/cites/cites-import-species/cites-import-species.module';
import { CitesImportSpeciesRequestCardExtraComponent } from 'src/app/services/cites/cites-import-species/request-card-extra/cites-import-species-request-card-extra.component';
import { CitesReleaseSpeciesModule } from 'src/app/services/cites/cites-release-species/cites-release-species.module';
import { CitesReleaseSpeciesRequestCardExtraComponent } from 'src/app/services/cites/cites-release-species/request-card-extra/cites-release-species-request-card-extra.component';
import { EsRequestCardExtraComponent } from 'src/app/services/es-shared/es-request-card-extra/es-request-card-extra.component';
import { EsRequestCardExtraBaseComponent } from 'src/app/services/es-shared/base/EsRequestCardExtraBaseComponent';

@Injectable()
export class RequestCardComponentResolverService {
  public resolveComponentType(
    moduleRef: NgModuleRef<unknown>
  ): Type<EsRequestCardExtraBaseComponent> {
    if (moduleRef.instance instanceof CitesReleaseSpeciesModule) {
      return CitesReleaseSpeciesRequestCardExtraComponent;
    }

    if (moduleRef.instance instanceof CitesImportSpeciesModule) {
      return CitesImportSpeciesRequestCardExtraComponent;
    }

    return EsRequestCardExtraComponent;
  }
}
