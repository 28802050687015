import { AbstractControl } from '@angular/forms';

type CompareFunction = (item1: any, item2: any) => boolean;

import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationBaseOptions } from './ValidationBaseOptions';

export function valueFromItemsOnly(
  items: any[],
  compareFn: CompareFunction,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedValue = control.value;

    ///pass valid status if the nothing was selected
    if (selectedValue == null || selectedValue == undefined) {
      return null;
    }

    ///prevents the extra check caused by bg-ng-dropdown [ngModel]!
    if (items == undefined) {
      return null;
    }

    const found = items?.some((item) => compareFn(item, selectedValue));

    if (found) {
      return null;
    } else {
      const hasOptions = options && true;

      return hasOptions ? { [options.key]: options } : { valueFromItems: true };
    }
  };
}
