<div
  class="alert"
  [class.alert-warning]="importance == 'Warning'"
  [class.alert-info]="importance == 'Info'"
  [class.alert-danger]="importance == 'Danger'"
  [class.alert-secondary]="importance == 'Secondary'"
  [class.alert-dismissible]="showCloseButton"
  *ngIf="alert.display"
>
  <button
    *ngIf="showCloseButton"
    type="button"
    class="close"
    data-dismiss="alert"
    (click)="closed($event)"
  >
    &times;
  </button>
  <h4 *ngIf="headingText" class="alert-heading" [innerHtml]="headingText"></h4>
  <p class="mb-0" [innerHtml]="renderText"></p>
  <ng-content select="[body]"></ng-content>
</div>
