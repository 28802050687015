import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, EMPTY } from "rxjs";
import { catchError } from "rxjs/internal/operators/catchError";
import { timeout } from "rxjs/operators";
import { AdditionalDataDto } from "src/app/core/models/shared/AdditionalDataDto";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class EsClientIPService{
    private readonly _serviceUrl: string = environment.ipInfoUrl;
    private _clientIPInfo: AdditionalDataDto;

    constructor(private httpClient: HttpClient){
    }

    public async getClientIpInfo(): Promise<AdditionalDataDto> {
        if(this._clientIPInfo == null){
            let response = await this.httpClient
            .get(this._serviceUrl)
            .pipe(timeout(5000))
            .pipe(catchError(this.handleError))
            .toPromise();
            
            if(response){
                this._clientIPInfo = {
                    Name: "IpLocationInfo",
                    Value: JSON.stringify(response)
                };                
            }
        }
        return this._clientIPInfo;
    }

    protected handleError(errorResponse: HttpErrorResponse): Observable<any> {
        console.log(errorResponse);
        return EMPTY;//to continue request execution even if failed to get ip info
    }
}