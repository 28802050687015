<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger':
        ngControl.touched && !ngControl.valid && !ngControl.disabled,
      'has-success': ngControl.touched && ngControl.valid
    }"
  >
    <label
      class="form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
      >{{ labelText }}</label
    >
    <input
      [id]="identifier"
      [name]="name"
      class="form-control"
      [ngClass]="
        hasRequiredError() ||
        hasMaxError() ||
        hasOnlyPositive() ||
        hasMinError ||
        hasMoreThanNumberError() ||
        hasPatternError ||
        hasNotIntegerError ||
        hasCustomError()
          ? 'is-invalid'
          : touched
          ? 'is-valid'
          : ''
      "
      type="number"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      #control="ngModel"
      [required]="required == 'true' || required == '' ? '' : undefined"
      (blur)="onBlur()"
      [readonly]="readonly"
      [disabled]="disabled"
      [min]="min || minIfOnlyPositive"
    />
    <small
      [id]="identifier + 'Help'"
      *ngIf="helpText"
      class="form-text text-muted"
    >
      {{ helpText }}</small
    >
    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMaxError()">
      {{ maxErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasOnlyPositive()">
      {{ onlyPositiveErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMoreThanNumberError()">
      {{ moreThanNumberErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMinError">
      {{ minErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasCustomError()">
      {{ customErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasPatternError">
      {{ patternErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasNotIntegerError">
      {{ notIntegerErrorMessage }}
    </div>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <label
      class="col-sm-3 form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
      >{{ labelText }}</label
    >
    <div class="col-sm-9">
      <input
        [id]="identifier"
        [name]="name"
        class="form-control"
        [ngClass]="
          hasRequiredError() ||
          hasMaxError() ||
          hasOnlyPositive() ||
          hasMinError ||
          hasMoreThanNumberError() ||
          hasPatternError ||
          hasNotIntegerError ||
          hasCustomError()
            ? 'is-invalid'
            : touched
            ? 'is-valid'
            : ''
        "
        type="number"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        #control="ngModel"
        [required]="required == 'true' || required == '' ? '' : undefined"
        (blur)="onBlur()"
        [readonly]="readonly"
        [disabled]="disabled"
        [min]="min"
      />
      <small
        [id]="identifier + 'Help'"
        *ngIf="helpText"
        class="form-text text-muted"
      >
        {{ helpText }}</small
      >
      <div class="invalid-feedback" *ngIf="hasRequiredError()">
        {{ requiredMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMaxError()">
        {{ maxErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasOnlyPositive()">
        {{ onlyPositiveErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMoreThanNumberError()">
        {{ moreThanNumberErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMinError">
        {{ minErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasCustomError()">
        {{ customErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasPatternError">
        {{ patternErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasNotIntegerError">
        {{ notIntegerErrorMessage }}
      </div>
    </div>
  </div>
</ng-template>
