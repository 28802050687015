import { extend } from "jquery";
import { INRTypeLookupDto } from "src/app/core/models/cites/cites-register-species/CitesRegisterSpeciesServiceRequestDto";
import { CitesOutPutsDto } from "src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesServiceRequestDto";
import { CitesOutput } from "src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction";
import { CitesImportExportSpeciesDetailsDto } from "src/app/core/models/cites/shared/CitesImportExportRequestDataDto";
import { CitesImportExportSpeciesDetails } from "src/app/core/models/cites/shared/CitesImportExportSpeciesDetails";
import { CitesSpeciesDetails } from "src/app/core/models/cites/shared/CitesSpeciesDetails";
import { CitesSpeciesDetailsDto } from "src/app/core/models/cites/shared/CitesSpeciesDetailsDto";
import { INRTypeLkp, SourceLkp } from "src/app/core/models/shared/Lookups";
import { BaseMapper } from "src/app/shared/base/BaseMapper";

export class CitesSharedMapper extends BaseMapper {
    public unknownLkp: SourceLkp = {
        id: null,
        nameAr: "غير معروف",
        nameEn: "Unknown",
        code: "Unknown"
    }

    public mapCitesSpeciesDetails(itemDto: CitesSpeciesDetailsDto): CitesSpeciesDetails{
        return {
            class: {
                id: itemDto.ClassID,
                nameAr: itemDto.ClassNameAr,
                nameEn: itemDto.ClassNameEn,
                code: itemDto.ClassCode
            },
            type: {
                id: itemDto.TypeID,
                nameAr: itemDto.TypeNameAr,
                nameEn: itemDto.TypeNameEn,
                scientificName: itemDto.TypeScientificName,
                appendixId: itemDto.AppendixID,
                appendixNameAr: itemDto.AppendixNameAr,
                appendixNameEn: itemDto.AppendixNameEn,
                itemId: itemDto.oldRequestItemId,
                citesItemId: itemDto.CitesIdentifiableItemID,
                relatedTypeID: itemDto.RelatedTypeId,
                unitId: itemDto.UnitID,
                unitNameAr: itemDto.UnitNameAr,
                unitNameEn: itemDto.UnitNameEn
            },
            originCountry: {
                id: itemDto.OriginCountryID,
                nameAr: itemDto.OriginCountryNameAr,
                nameEn: itemDto.OriginCountryNameEn
            },
            exportingCertificateNo: itemDto.ExportingCountryCertificateNumber,
            importingCertificateNo: itemDto.ImportingCountryCertificateNumber,
            specimenDescription: itemDto.SpecimenDescription,
            source: itemDto.SourceID? {
                id: itemDto.SourceID,
                nameAr: itemDto.SourceNameAr,
                nameEn: itemDto.SourceNameEn
            }: this.unknownLkp,
            sourceReason: itemDto.SourceUnknownReason,
            quantity: itemDto.Quantity,
            unit: itemDto.UnitID ? {
                id: itemDto.UnitID,
                nameAr: itemDto.UnitNameAr,
                nameEn: itemDto.UnitNameEn
            }: null,
            ringNumber: itemDto.RingNumber,
            chipNumber: itemDto.ChipNumber,
            gender: itemDto.SexID? {
                id: itemDto.SexID,
                nameAr: itemDto.SexNameAr,
                nameEn: itemDto.SexNameEn
            }: null,
            typeDescription: itemDto.TypeDescriptionID? {
                id: itemDto.TypeDescriptionID,
                nameAr: itemDto.TypeDescriptionNameAr,
                nameEn: itemDto.TypeDescriptionNameEn
            }: null
        };
    }

    public mapCitesSpeciesDetailsDto(item: CitesSpeciesDetails): CitesSpeciesDetailsDto{
        return{
            ClassID: item.class.id,
            TypeID: item.type.id,
            AppendixID: item.type.appendixId,
            OriginCountryID: item.originCountry.id,
            ExportingCountryCertificateNumber: item.exportingCertificateNo,
            ImportingCountryCertificateNumber: item.importingCertificateNo,
            SpecimenDescription: item.specimenDescription,
            SourceID: item.source?.id,
            SourceUnknownReason: item.sourceReason,
            Quantity: item.quantity,
            UnitID: item.unit?.id,
            RingNumber: item.ringNumber,
            ChipNumber: item.chipNumber,
            SexID: item.gender?.id,
            TypeDescriptionID: item.typeDescription?.id,
            oldRequestItemId: item.type.itemId,
            CitesIdentifiableItemID: item.type.citesItemId,
            RelatedTypeId: item.type.relatedTypeID
        }
    }

    public mapINRTypeLookup(inrTypes: INRTypeLookupDto[]): INRTypeLkp[]{
        return inrTypes.map((item)=>{
            return {
                id: item.ID || item.Id,//remove item.Id when eslam finish deploy update
                nameAr: item.NameAr,
                nameEn: item.NameEn,
                code: item.Code,
                scientificName: item.ScientificName,
                appendixId: item.CITESAppendixID,
                appendixNameAr: item.CITESAppendixNameAr,
                appendixNameEn: item.CITESAppendixNameEn,
                itemId: item.ItemID,
                relatedTypeID: item.RelatedTypeID,
                citesItemId: item.CitesIdentifiableItemID,
                unitId: item.UnitID,
                unitNameAr: item.UnitNameAr,
                unitNameEn: item.UnitNameEn
            }
        });
    }

    mapItemDetails(itemDto: CitesImportExportSpeciesDetailsDto): CitesImportExportSpeciesDetails{
        return {
          ...this.mapCitesSpeciesDetails(itemDto),
          ...{
            allowedTypeDescriptions: itemDto.AllowedTypeDescriptions?.map((item)=>{
              return {
                  id: item.Id,
                  nameAr: item.NameAr,
                  nameEn: item.NameEn
              }
            }),
            actualQuantity: itemDto.Quantity,
            certificateNo: itemDto.CertificateNumber,
            birthdate: itemDto.BirthDate ? 
                this.asDateInstance(itemDto.BirthDate) : null,
            exportingCertificateDate: itemDto.ExportingCountryCertificateDate?
            this.asDateInstance(itemDto.ExportingCountryCertificateDate) : null,
            lastReExportCountry: itemDto.LastReExportCountryID? {
              id: itemDto.LastReExportCountryID,
              nameAr: itemDto.LastReExportCountryNameAr,
              nameEn: itemDto.LastReExportCountryNameEn
            }: null,
            lastReExportCertificateDate: itemDto.LastReExportCertificateDate ?
                this.asDateInstance(itemDto.LastReExportCertificateDate) : null,
            lastReExportCertificateNumber: itemDto.LastReExportCertificateNumber,
          }
        }
    }

    mapItemDetailsDto(item: CitesImportExportSpeciesDetails): CitesImportExportSpeciesDetailsDto{
        return {
            ...this.mapCitesSpeciesDetailsDto(item),
            ...{
                CertificateNumber: item.certificateNo,
                BirthDate: item.birthdate,
                ExportingCountryCertificateDate: item.exportingCertificateDate,
                LastReExportCountryID: item.lastReExportCountry?.id,
                LastReExportCertificateNumber: item.lastReExportCertificateNumber,
                LastReExportCertificateDate: item.lastReExportCertificateDate,
            }
        }
    }

    mapCitesOutputs(outPutsDto: CitesOutPutsDto[]): CitesOutput[]{
        return outPutsDto.map(o => {
            return {
                certificateNumber: o.CertificateNumber,
                titleEn: o.TitleEn,
                titleAr: o.TitleAr,
                colorKey: o.ColorKey,
                url: o.URL,
                isVerified: o.IsVerified,
                verifiedFileURL: o.VerifiedFileURL
            };
        });
    }
}