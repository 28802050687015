import {MyLicensesForPracticingProfessComponent} from '../../dashboard/home/individual-package-details/my-licenses-for-practicing-profess/my-licenses-for-practicing-profess.component';
import {MyLicensesForFishingBoatsComponent} from '../../dashboard/home/individual-package-details/my-licenses-for-fishing-boats/my-licenses-for-fishing-boats.component';
import {MyAgriculturalAndAnimalPossesComponent} from '../../dashboard/home/individual-package-details/my-agricultural-and-animal-posses/my-agricultural-and-animal-posses.component';

export enum PackageTypes {
  // Company packages types Id's
    LicensesForPracticingActivities = 2,
    LicensesForPracticingProfessional = 3,
    RegisteredApprovedMaterials = 4,
    MaterialBalancesCities = 5,

  // Individual packages types Id's
    MyLicensesForPracticingProfessional = 6,
    MyLicensesForFishingBoats = 7,
    MyAgriculturalAndAnimalPossessions = 8,
    CitiesBalanceAndFalconPassport = 9

}


export enum PackageTypesCodes {
  // Company packages type Codes
    LicensesForPracticingActivitiesCode = 'PracticingActivity',
    LicensesForPracticingProfessionalCode = 'PracticingProfession',
    RegisteredApprovedMaterialsCode = 'RegisteredMaterials',
    MaterialBalancesCitiesCode = 'Cites',

  // Individual packages types Codes
    MyLicensesForPracticingProfessionalCode = 'MyLicensesForPracticingProfessional',
    MyLicensesForFishingBoatsCode = 'MyLicensesForFishingBoats',
    MyAgriculturalAndAnimalPossessionsCode = 'MyAgriculturalAndAnimalPossessions',
    CitiesBalanceAndFalconPassportCode = 'CitiesBalanceAndFalconPassport'
}
