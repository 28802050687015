
export class EsPaymentFee {
  id?: number;
  nameEn?: string;
  nameAr?: string;
  code?: string;
  feeAmount?: number;
  feeTotal?: number;
  quantity?: number;
}
