import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesImportSpeciesTransaction } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { CitesBaseFormComponent } from '../../shared/base/CitesBaseFormComponent';
import { PurposeCodesData } from '../../shared/cites-codes/purpose-codes.data';
import { CitesMainFormChangeType } from '../../shared/cites-main-form/CitesMainFormChangeEmitterData';
import { Lookup } from 'src/app/core/models/common/Lookup';

@Component({
  selector: 'app-cites-import-species-request-form',
  templateUrl: './cites-import-species-request-form.component.html',
})
export class CitesImportSpeciesFormComponent extends CitesBaseFormComponent {
  @Input() transaction: CitesImportSpeciesTransaction;
  @Input() disablePurpose: boolean;
  @Input() purposes: Lookup[];
  
  purposeCodes: Lookup[];
  
  constructor(translateService: TranslateService) {
      super(translateService);
      this.purposeCodes = PurposeCodesData.data;
  }

  onPurposeChange($event: any){
    this.change.emit({
      type: CitesMainFormChangeType.PurposeChange,
      value: $event.new
    });
  }
}
