import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import {
  fadeAnimation,
  scaleY,
} from 'src/app/shared/animations/fade.animation';
import { TemplateServicesService } from 'src/app/core/api-services/template-services/template-services.service';
import {
  ParticipantTemplate,
  ParticipantTemplateDto,
  TemplateServicesListResponse,
} from 'src/app/core/api-services/export/shared/template-services/TemplateServicesResponse';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-services',
  templateUrl: './template-services.component.html',
  animations: [fadeAnimation, scaleY],
})
export class TemplateServicesComponent extends BaseComponent implements OnInit {
  public isCollapsed: boolean[] = [];

  @Input()
  enableEditing = true;

  templates: ParticipantTemplate[] = [];

  searchText = '';

  constructor(
    protected router: Router,
    private templateServicesService: TemplateServicesService,
    translateService: TranslateService
  ) {
    super(translateService);
  }

  async ngOnInit(): Promise<void> {
    const response = await this.templateServicesService.list().toPromise();

    this.templates = this.map(response);
  }

  private map(response: TemplateServicesListResponse): ParticipantTemplate[] {
    if (response && response.Data) {
      return response.Data.map((t) => this.mapTemplate(t));
    }

    return [];
  }

  public mapTemplate(t: ParticipantTemplateDto): ParticipantTemplate {
    return {
      id: t.ID,
      name: t.TemplateName,
      participantId: t.ParticipantID,
      procedureId: t.ProcedureID,
      serviceNameEn: t.ServiceName,
      serviceNameAr: t.ServiceName,
      template: t.Template,
      templateDetails: t.TemplateDetails,
      url: t.ServiceURL,
    };
  }

  public confirmDelete(item: ParticipantTemplate) {
    const sweet = this.showConfirmationSweet(item);

    sweet.then(async (result: any) => {
      if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      } else if (result.value) {
        const result = await this.templateServicesService
          .delete(item.id)
          .toPromise();

        if (result.IsSuccess) {
          this.templates = this.templates.filter((t) => t.id != item.id);
        }
      }
    });
  }

  public showConfirmationSweet(
    item: ParticipantTemplate
  ): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: this.translateService.instant(
        'trDeleteTemplateConfirmationMessage',
        { templateName: item.name }
      ),
      icon: this.translateService.instant('ConfirmBoxWarning'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('ConfirmBoxYesDeleteIt'),
      cancelButtonText: this.translateService.instant('ConfirmBoxNoKeepIt'),
    });
  }
}

export class TemplateServiceListEmitHolder {
  item: ParticipantTemplate;
}
