import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class EsBaseTemplateService {
  protected BASE_API = environment.eServiceAPIHost;

  abstract basePath: string;

  protected buildUrl(endingUri: string): string {
    return `${environment.eServiceAPIHost}${this.basePath}/${endingUri}`;
  }

  protected handleError(errorResponse: HttpErrorResponse): Observable<never> {
    console.log(errorResponse);

    if (errorResponse.error instanceof ErrorEvent) {
      console.error('Client Side Error', errorResponse.error.message);
    } else {
      console.error('Server Side Error', errorResponse.error.message);
    }
    return throwError(
      'There is a problem with the service. we are notified & working on it. please try again later'
    );
  }
}
