import {CustomerApplicant} from '../customer/CustomerApplicant';
import {RequestAttachment} from './RequestAttachment';
import {ServiceRequestType} from './ServiceRequestType';
import {ServiceRequestChannelTypesEnum} from '../../enums/ServiceRequestChannelTypesEnum';
import {PaymentRequestTransactionDTO} from './payment/PaymentRequestTransactionDTO';
import {PaymentChannelTransactionDTO} from './payment/PaymentChannelTransactionDTO';
import {FileAttachment} from './FileAttachment';
import {CancellationReasonLkp} from './CancellationReasonLkp';
import {ServiceExternalIntegrationData} from './ExternalIntegration/ServiceExternalIntegrationData';

export class DepartmentData {
  code: string;
  nameAr: string;
  nameEn: string;
  isActive: boolean;
  id: number;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
}




export class CertificateCatalogData {
  certificatePageURL: string;
  certificateURL: string;
  code: CertificateCatalogCodesEnum;
  departmentID: number;
  departmentData: DepartmentData;
  serviceCatalogId: number;
  certificateValidityPeriod: number;
  notifyBeforeExpirationInDays: number;
  skipExpirationDate: boolean;
  validityLeftInMonths: number;
  nameAr: string;
  nameEn: string;
  isActive: boolean;
  id: number;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
}

export class Certificate {
  serviceCustomerCenterId: number;
  cancellationReasonId: number;
  certificateCatalogId: number;
  cancellationReasonData: CancellationReasonLkp;
  firstLicenseIssueDate: Date;
  expiryDate: Date;
  certificateCatalogData: CertificateCatalogData;
  certificateNumber: string;
  oldCertificateNumber: string;
  oldCertificateId: number;
  registeredUserId: number;
  serviceRequestTransactionId: number;
  certificateStatusCode: CertificateStatusCodeEnum;
  id: number;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
}

export enum CertificateCatalogCodesEnum
{
  AnimalCare= 'AnimalCare'
}

export enum CertificateStatusCodeEnum
{
  Issued= 'Issued',
  Cancelled= 'Cancelled',
  Hidden= 'Hidden'
}

export class ServiceMainTransactionData {
  id: number;
  createdBy: string;
  createdDate: Date;
  serviceId: number;
  customerRequestAction: CustomerRequestActionsEnum;
  registeredUserId: number;
  serviceRequestNumber: string;
  statusCode: WorkflowStepStatusCodesEnum;
  registrationType: string;
  remarks: string;
  serviceApplicantId: number;
  serviceApplicantData: CustomerApplicant;
  completDate: Date;
  isDraft: boolean;
  customerServiceCenterId: number;
  requestAttachments: RequestAttachment[];
  reasonOfRejection: string;
  reasonOfReturn: string;
  isFromPeopleOfDetermination: boolean;
  serviceRequestTypeId: number;
  requestTypeData: ServiceRequestType;
  cancellationReasonId: number;
  frontOfficeProcessPageUrl: string;
  frontOfficeViewPageUrl: string;
  serviceRequestChannelType: ServiceRequestChannelTypesEnum;
  paymentChannelTransactionDTO: PaymentChannelTransactionDTO;
  paymentRequestTransactionData: PaymentRequestTransactionDTO[];
  serviceExternalIntegrationData: ServiceExternalIntegrationData;
  certificates: Certificate[];
  language: string;
  customerNote: string;
  cancellationReasonData: CancellationReasonLkp;
  workFlowCustEmpChatMessagesData: WorkFlowTransactionCustomerEmpChatMessages[];
  requestPurposeTypeId: number;
  buyerRegisteredUserId: number;
  employeeId : number;

  elapsedTime: ServiceRequestElapsedTime;
}

export class WorkFlowTransactionCustomerEmpChatMessages {
  message: string;
  fileAttachmentId: number;
  fileAttachment: FileAttachment;
  workFlowStepTransactionID: number;
  mainWorkFlowTransactionID: number;
  serviceRequestTransactionId: number;
  statusCode: WorkflowStepStatusCodesEnum;
  employeeUserID: number;
  isSMSRequired: boolean;
  actionId: number;
  customerID: number;
  id: number;
  transitionActionData: WorkFlowActionLkp;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
}
export interface WorkFlowActionLkp {
  actionCode: WorkflowActionsCodesEnum;
  isRequiredMessage: boolean;
  requiredMessageLabelAr: string;
  requiredMessageLabelEn: string;
  colorCode: string;
  actionPageURL: string;
  frontOfficeIconName: string;
  displayOrderNumber: number;
  hasModalDialogBox: boolean;
  nameAr: string;
  nameEn: string;
  isActive: boolean;
  id: number;
  createdDate: Date;
  createdBy: string;
  updatedDate: Date;
  updatedBy: string;
}
export enum WorkflowActionsCodesEnum {
  Approve= 'Approve',
  RejectRequest= 'RejectRequest',
  CancelRequest= 'CancelRequest',
  ApplyInspection= 'ApplyInspection',
  CancelPayment= 'CancelPayment',
  ConfirmSuccessPayment= 'ConfirmSuccessPayment',
  PayNow= 'PayNow',
  ReturnToCustomer= 'ReturnToCustomer',
  ReSubmitBYCustomer= 'ReSubmitBYCustomer',
  GoBackToPreviousStep= 'GoBackToPreviousStep',
  ChangeExamDate = 'ChangeExamDate'
}


export enum CustomerRequestActionsEnum
{
  None= 'None',
  OnDraft= 'OnDraft',
  PayLater= 'PayLater',
  SubmitWithoutPayment= 'SubmitWithoutPayment',
  PayNow= 'PayNow',
  ReSubmitBYCustomer = 'ReSubmitBYCustomer'
}

export enum WorkflowStepStatusCodesEnum
{
  NewRequest = 'NewRequest',
  InitialPayment= 'InitialPayment',
  UnderReview = 'UnderReview',
  UnderInspection = 'UnderInspection',
  WaitingCustomerAction = 'WaitingCustomerAction',
  WaitingCustomerPayment = 'WaitingCustomerPayment',
  CustomerUnderPayment = 'CustomerUnderPayment',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Draft = 'Draft',
}





export class ServiceRequestElapsedTime {
  time: number;
}
