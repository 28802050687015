<!-- // -->

<div id="collapse2" class="panel-collapse mobile_popup direction_rtl mt-3">
  <div class="panel-body panelstatpads show-details stat-1">
    <div
      class="container"
      [ngClass]="{
        arrow_third: isEnglish,
        arrow_second_ar: isArabic
      }"
    >
      <div class="row direction_rtl">
        <div class="col-sm-10 alignment_right">
          <h4 class="bold my-1">
            {{
              currentLang == 'en' ? packageObject.NameEN : packageObject.NameAR
            }}
          </h4>
        </div>
        <div class="col-xs-2 alignmet_left">
          <div class="text-right my-1 cursor-pointer alignmet_left">
            <a (click)="closeServiceDetails()">
              <!-- <div class="custom_icon">
                <span class="icon-cancel-24px-gold" style="color:#fff;"></span>
              </div> -->
              <img src="assets/Images/Icons/cancel-24px (white).png" />
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-sm-12"
          *ngFor="
            let certificateDetail of certificateDetailsObjectDataList
              | paginate
                : {
                    id: 'pagination2',
                    itemsPerPage: certificateDetailPageSize,
                    currentPage: certificateDetailPageIndex,
                    totalItems: certificateDetailTotalItems
                  }
          "
        >
          <div class="card-2">
            <div class="row">
              <div class="col-xl-3">
                <h5 class="color-9 bold alignment_right">
                  {{ certificateDetail.CertificateNumber }}
                </h5>
                <ng-container
                  *ngIf="
                    certificateDetail.ProfessionalName == null ||
                    certificateDetail.ProfessionalName == ''
                  "
                >
                  <label class="page-color-1 alignment_right arbic_width_100">{{
                    currentLang == 'en'
                      ? certificateDetail?.CertificateType?.NameEn
                      : certificateDetail?.CertificateType?.NameAr
                  }}</label>
                </ng-container>

                <ng-container
                  *ngIf="
                    certificateDetail.ProfessionalName != null &&
                    certificateDetail.ProfessionalName != ''
                  "
                >
                  <label
                    class="page-color-1 alignment_right arbic_width_100 11"
                    >{{ certificateDetail.ProfessionalName }}</label
                  >
                  <label
                    *ngIf="isEnglish && certificateDetail?.LicenseType?.NameEN"
                    [innerText]="'-' + certificateDetail?.LicenseType?.NameEN"
                  >
                  </label>
                  <label
                    *ngIf="isArabic && certificateDetail?.LicenseType?.NameAR"
                    [innerText]="'-' + certificateDetail?.LicenseType?.NameAR"
                  >
                  </label>

                  <ng-container
                    *ngIf="
                      certificateDetail.LicenseType != null &&
                      certificateDetail.LicenseType.Code ==
                        'AgriculturalEngineer'
                    "
                  >
                    <label>
                      {{ '-' }}
                      {{ 'ServiceDetailsAgriculturalEngineering' | translate }}
                    </label>
                  </ng-container>
                </ng-container>
              </div>

              <div
                class="col-xl-1 col-lg-2 col-md-2"
                *ngIf="
                  certificateDetail != null && certificateDetail.Status != null
                "
              >
                <p
                  *ngIf="
                    certificateDetail.Status.toLocaleLowerCase() == 'expired'
                  "
                  class="pt-11 expired bold position"
                >
                  {{ 'ExpiredLicenses' | translate }}
                </p>

                <p
                  *ngIf="
                    certificateDetail.Status.toLocaleLowerCase() == 'active'
                  "
                  class="pt-11 active bold position"
                >
                  {{ 'ActiveLicenses' | translate }}
                </p>
              </div>
              <div class="col-lg-2 pt-55 direction_rtl alignment_right">
                <label class="label-color font-12 bold"
                  >{{ 'ServiceDetailsProductName' | translate }}
                </label>
                <p class="bold">{{ certificateDetail.MaterialName }}</p>
              </div>

              <div class="col-lg-2 pt-55 direction_rtl alignment_right">
                <label class="label-color font-12 bold"
                  >{{ 'ServiceDetailsProductType' | translate }}
                </label>
                <p
                  class="bold"
                  [innerText]="
                    isArabic
                      ? certificateDetail?.MaterialType?.NameAR
                      : certificateDetail?.MaterialType?.NameEN
                  "
                ></p>
              </div>

              <div
                class="col-xl-1 col-lg-2 col-md-2 pt-55 direction_rtl alignment_right"
              >
                <label class="label-color font-12 bold">
                  {{ 'ServiceDetailsIssuedDate' | translate }}
                </label>
                <p class="bold">
                  {{ certificateDetail.IssueDate | date : 'dd/MM/yyyy' }}
                </p>
              </div>
              <div
                class="col-xl-1 col-lg-2 col-md-2 pt-55 direction_rtl alignment_right"
              >
                <label class="label-color font-12 bold">
                  {{ 'ServiceDetailsExpiredDate' | translate }}
                </label>

                <ng-container>
                  <p class="bold">
                    {{ certificateDetail.ExpiryDate | date : 'dd/MM/yyyy' }}
                  </p>
                </ng-container>

                <ng-container *ngIf="!certificateDetail.ExpiryDate">
                  <p class="bold">
                    {{
                      'ServiceDetailPackageRegisteredApprovedMaterialsPermanent'
                        | translate
                    }}
                  </p>
                </ng-container>
              </div>

              <div
                class="col-xl-4 col-lg-4 pt-3 my_custom_tooltip text-right practice_icon_con"
                *ngIf="enableActions(certificateDetail)"
              >
                <a
                  [queryParams]="{
                    requestNumber: certificateDetail.ProcedureInstanceID,
                    requestType: AnimalCareRequestTypeEnum.AddActivity
                  }"
                  [routerLink]="[
                    '/services/PractisingAnimalCareActivity/applicantinformation'
                  ]"
                  *ngIf="
                    certificateDetail.Status.toLocaleLowerCase() != 'expired'
                  "
                  class="padding_ico textShortToolTipShow cursor-pointer"
                >
                  <span
                    class="icon-add_activity"
                    ngbTooltip="{{ 'ServiceDetailsAddActivity' | translate }}"
                  ></span>
                </a>

                <a
                  [queryParams]="{
                    requestNumber: certificateDetail.ProcedureInstanceID,
                    requestType: AnimalCareRequestTypeEnum.CancelActivity
                  }"
                  [routerLink]="[
                    '/services/PractisingAnimalCareActivity/applicantinformation'
                  ]"
                  *ngIf="
                    certificateDetail.Status.toLocaleLowerCase() != 'expired'
                  "
                  class="padding_ico textShortToolTipShow cursor-pointer"
                >
                  <span
                    class="icon-cancel_activity"
                    ngbTooltip="{{
                      'ServiceDetailsCancelActivity' | translate
                    }}"
                  ></span>
                </a>

                <a
                  class="padding_ico textShortToolTipShow cursor-pointer"
                  *ngIf="
                    certificateDetail != null &&
                    certificateDetail.CertificateURL != null
                  "
                  [href]="REST_API_SERVER + certificateDetail.CertificateURL[0]"
                  target="_blank"
                  download
                >
                  <span
                    class="icon-get_app-24px"
                    ngbTooltip="{{
                      'ServiceDetailsDownLoadCertificate' | translate
                    }}"
                  ></span>
                </a>

                <a
                  (click)="viewRequest(certificateDetail.ProcedureInstanceID)"
                  class="padding_ico textShortToolTipShow cursor-pointer"
                >
                  <span
                    class="icon-visibility-24px-1"
                    ngbTooltip="{{ 'ServiceDetailsViewRequest' | translate }}"
                  ></span>
                </a>

                <a
                  [queryParams]="{
                    requestNumber: certificateDetail.ProcedureInstanceID,
                    requestType: AnimalCareRequestTypeEnum.Renew
                  }"
                  [routerLink]="[
                    '/services/PractisingAnimalCareActivity/applicantinformation'
                  ]"
                  class="padding_ico textShortToolTipShow cursor-pointer"
                >
                  <span
                    class="icon-renewal-24px"
                    ngbTooltip="{{ 'ServiceDetailsRenewal' | translate }}"
                  ></span>
                </a>
                <a
                  [queryParams]="{
                    requestNumber: certificateDetail.ProcedureInstanceID,
                    requestType: AnimalCareRequestTypeEnum.CancelLicense
                  }"
                  [routerLink]="[
                    '/services/PractisingAnimalCareActivity/applicantinformation'
                  ]"
                  class="padding_ico textShortToolTipShow cursor-pointer"
                >
                  <span
                    class="icon-cancel_license"
                    ngbTooltip=" {{
                      'ServiceDetailsCancelLicense' | translate
                    }}"
                    container="body"
                    tooltipClass="golden_tooltip"
                  ></span>
                </a>
              </div>
            </div>
          </div>
          <br />
        </div>

        <div
          class="d-flex align-items-center w-100"
          *ngIf="certificateDetailsObjectDataList.length == 0"
        >
          <div class="col-md-12 col-xs-12">
            <h3
              class="text-center arbic_width_100 direction_rtl no_records_fond"
            >
              {{ 'ServiceDetailsThereIsNoRecordsFound' | translate }}
            </h3>
          </div>
        </div>
        <div class="row fr">
          <div class="col-md-12 col-xs-12">
            <pagination-controls
              id="pagination2"
              previousLabel="{{ 'PaginationPreviousLabel' | translate }}"
              nextLabel="{{ 'PaginationNextLabel' | translate }}"
              (pageChange)="onCertificatePageChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
