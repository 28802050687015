import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {CertificateCatalogCodesEnum} from '../../models/common/ServiceMainTransactionData';
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class GetReportingBinaryFileDataService {

  private REST_API_SERVER = environment.reportingHostURL + 'Certificate/' + 'GetReport';

  constructor(private httpClient: HttpClient) {
  }

  public getBinaryFileData(certificateCatalogCode: CertificateCatalogCodesEnum, serviceRequestTransactionId: number): Observable<any> {

    let params: HttpParams = new HttpParams();
    params = params.append('serviceRequestTransactionId', serviceRequestTransactionId)
    params = params.append('certificateCatalogCode', certificateCatalogCode)
    return this.httpClient.get<any>(this.REST_API_SERVER, {
      responseType: 'blob' as 'json', params}  )
    .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetBinaryFileDataService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetBinaryFileDataService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
