<div id="important-link-cont" class="w-100 cahrt_containerr show-details dashboard_statistics" style="color: #fff;">
    <div class="container">
        <div class="my_heading_con flex_flow">
            <h2>{{'trImportantLinks' | translate}}</h2>
            <div class="custom_icon">
                <span (click)="closeImportantLinks()" 
                    style="font-size: 30px; cursor: pointer; color:#fff;" 
                    class="icon-cancel-24px-gold">
                </span>
            </div>
        </div>
        <div class="row chart_inner_container ml-2 mr-3">
            <div class="col-lg-6 mb-1" *ngIf="links?.CitesBalanceURL">
                <h4 class="color-40">{{"lnkCitesBalanceHeader" | translate}}</h4>
                <a class="important-link" [href]="baseEserviceUrl + links.CitesBalanceURL" target="_blank">
                    <span>{{"lnkCitesBalanceTitle" | translate}}</span>
                </a>
            </div>

            <div class="col-lg-6 mb-1" *ngIf="links?.ContractURL">
                <h4 class="color-40">{{"lnkContractsHeader" | translate}}</h4>
                <a class="important-link" [href]="baseEserviceUrl + links.ContractURL" target="_blank">
                    <span>{{"lnkShowContracts" | translate}}  - ({{links.ContractCount}})</span>
                </a>
            </div>

            <div class="col-lg-6 mb-1" *ngIf="links?.AllExchangeOrderURL">
                <h4 class="color-40">{{"lnkSupplierOrdersHeader" | translate}}</h4>
                <div>
                    <a class="important-link" [href]="baseEserviceUrl + links.DeliveredExchangeOrderURL" target="_blank">
                        <span>{{"lnkDeliveredOrders" | translate}}  - ({{links.DeliveredExchangeOrderCount}})</span>
                    </a>
                </div>
                <div>
                    <a class="important-link" [href]="baseEserviceUrl + links.UndeliveredExchangeOrderURL" target="_blank">
                        <span>{{"lnkUndeliveredOrders" | translate}}  - ({{links.UndeliveredExchangeOrderCount}})</span>
                    </a>
                </div>
                <div>
                    <a class="important-link" [href]="baseEserviceUrl + links.AllExchangeOrderURL" target="_blank">
                        <span>{{"lnkAllOrders" | translate}}  - ({{links.AllExchangeOrderCount}})</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>