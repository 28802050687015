import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { CitesReleaseSpeciesTransaction } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { CitesReviewFlowStepBaseComponent } from '../../shared/base/CitesReviewFlowStepBaseComponent';
import { CitesReleaseSpeciesWorkflowService } from '../cites-release-species-workflow.service';

@Component({
  selector: 'app-cites-release-species-request-review',
  templateUrl: './cites-release-species-request-review.component.html',
})
export class CitesReleaseSpeciesReviewComponent
  extends CitesReviewFlowStepBaseComponent<CitesReleaseSpeciesWorkflowService>
  implements OnInit, AfterViewInit
{
  transaction$: Observable<CitesReleaseSpeciesTransaction>;

  constructor(
    @Inject('WorkflowService')
    workflowService: CitesReleaseSpeciesWorkflowService,
    translate: TranslateService,
    router: Router
  ) {
    super(workflowService, router, translate);
  }

  async ngOnInit(): Promise<void> {}

  async ngAfterViewInit(): Promise<void> {}
}
