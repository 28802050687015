<div *ngIf="userDetails" [className]="contClasses">
  <legend
    [innerText]="'User Data' | translate"
    class="col-form-label">
  </legend>

  <div class="row">
    <div class="col-md-6 col-sm-12">
      <app-bs-display
        key="trName"
        [valueText]="isArabic? userDetails?.nameAr : userDetails?.nameEn">
      </app-bs-display>
    </div>
    <div class="col-md-6 col-sm-12">
      <app-bs-display
        key="trCity"
        [valueText]="userDetails?.city">
      </app-bs-display>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <app-bs-display
        key="trMobile"
        [valueText]="userDetails?.mobile">
      </app-bs-display>
    </div>
    <div class="col-md-6 col-sm-12">
      <app-bs-display
        key="trEmail"
        [valueText]="userDetails?.email">
      </app-bs-display>
    </div>
  </div>
</div>
