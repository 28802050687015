<ul id="progressbar">
  <li class="cursor-pointer next-arrow text-left">
    <a *ngIf="showBackArrow" class="cursor-pointer" (click)="moveBack()"
      ><img src="assets/Images/Icons/back-arrow.png"
    /></a>
  </li>
  <li
    *ngFor="let step of flow.steps; let i = index"
    ngbTooltip="{{ step.key | translate }}"
    tooltipClass="tooltip-bg-goldish-pr2"
    placement="bottom"
    [ngClass]="{
      active: step.isValidatedAndEnabled,
      'cursor-pointer': step.isValidatedAndEnabled,
      'ds-step-line': anyNextVisibleStep(i)
    }"
    class="ds-step active active-1"
    (click)="moveToStep(step.key)"
    [id]="step.key + (step.isValidatedAndEnabled ? '-active' : '')"
    [style.display]="step.display ? 'block' : 'none'"
  ></li>
  <li class="cursor-pointer back-arrow">
    <a
      *ngIf="flow.showForwardArrow"
      class="cursor-pointer"
      (click)="moveForward()"
      ><img src="assets/Images/Icons/back-arrow.png"
    /></a>
  </li>
</ul>
