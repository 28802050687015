import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesImportSpeciesTransaction } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { EsExportRequestTypesEnum } from 'src/app/core/models/shared/EsExportRequestTypesEnum';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-cites-import-species-request-review-details',
  templateUrl: './cites-import-species-request-review-details.component.html',
})

export class CitesImportSpeciesReviewDetailsComponent extends BaseComponent {
  @Input()
  transaction: CitesImportSpeciesTransaction;
  
  constructor(translate: TranslateService) {
    super(translate);
  }
}