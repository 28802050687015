import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BreadcrumbsDetails } from 'src/app/shared/base/BreadcrumbsDetails';
import { BaseComponent } from './BaseComponent';

export abstract class PageBaseComponent extends BaseComponent {
  breadcrumbsDetails: BreadcrumbsDetails = {};
  breadcrumbsDetails$ = new Subject<BreadcrumbsDetails>();

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  protected scrollToOnRouteActivate() {
    /// Note: This is to disable the default browser behaviour
    ///       of scrolling to the same position for visited pages
    /// https://stackoverflow.com/questions/18617367/disable-browers-auto-scroll-after-a-page-refresh
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }

    setTimeout(() => {
      let scrollTo = document.querySelector('.scroll-to');

      if (scrollTo) {
        scrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    }, 400);
  }

  public onActivate($event: any) {
    this.scrollToOnRouteActivate();
  }
}
