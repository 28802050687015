import {
  forwardRef,
  Injector,
  Output,
  EventEmitter,
  Directive,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgControl,
} from '@angular/forms';

@Directive()
export abstract class AbstractValueAccessor implements ControlValueAccessor {
  _value: any = '';
  ngControl: NgControl;

  get value(): any {
    return this._value;
  }
  set value(v: any) {
    if (v !== this._value) {
      const old = this._value;
      this._value = v;
      this.onChange(v);
      this.onTouched();
      this.change.emit({ old: old, new: v });
    }
  }

  @Output()
  change = new EventEmitter();

  constructor(protected inj: Injector) {}

  writeValue(value: any) {
    this._value = value;
    // warning: comment below if only want to emit on user intervention
    // this.onChange(value);
  }

  validate() {
    // mandatory to have the declaration, as it is used by reactive forms
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}

export function MakeProvider(type: any) {
  return [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => type),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => type),
      multi: true,
    },
  ];
}
