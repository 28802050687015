<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger':
        ngControl.touched && !ngControl.valid && !ngControl.disabled,
      'has-success': ngControl.touched && ngControl.valid
    }"
  >
    <label
      class="form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
      >{{ labelText }}</label
    >
    <input
      [id]="identifier"
      [name]="name"
      class="form-control"
      [ngClass]="
        hasRequiredError() ||
        hasEmailError ||
        hasCustomError ||
        hasEmiratesIdError ||
        hasPatternError ||
        hasMaxLengthError ||
        hasMinLengthError ||
        hasAlreadyExistError ||
        hasNotFoundError
          ? 'is-invalid'
          : touched
          ? 'is-valid'
          : ''
      "
      type="text"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      #control="ngModel"
      [required]="required == 'true' || required == '' ? '' : undefined"
      (blur)="onBlur()"
      [readonly]="readonly"
      [disabled]="disabled"
      [maxlength]="_maxLength"
      [mask]="mask"
      [prefix]="prefix"
      [dropSpecialCharacters]="dropSpecialCharacters"
    />
    <small
      [id]="identifier + 'Help'"
      *ngIf="helpText"
      class="form-text text-muted"
    >
      {{ helpText }}</small
    >
    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasEmailError">{{ emailMessage }}</div>

    <div class="invalid-feedback" *ngIf="hasCustomError">
      {{ customErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasEmiratesIdError">
      {{ emiratesIdErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMinLengthError">
      {{ minLengthErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMaxLengthError">
      {{ maxLengthErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasPatternError">
      {{ patternErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasAlreadyExistError">
      {{ alreadyExistMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasNotFoundError">
      {{ notFoundMessage }}
    </div>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <label
      class="col-sm-3 form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
      >{{ labelText }}</label
    >
    <div class="col-sm-9">
      <input
        [id]="identifier"
        [name]="name"
        class="form-control"
        [ngClass]="
          hasRequiredError() ||
          hasEmailError ||
          hasCustomError ||
          hasEmiratesIdError ||
          hasPatternError ||
          hasMaxLengthError ||
          hasMinLengthError ||
          hasAlreadyExistError ||
          hasNotFoundError
            ? 'is-invalid'
            : touched
            ? 'is-valid'
            : ''
        "
        type="text"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        #control="ngModel"
        [required]="required == 'true' || required == '' ? '' : undefined"
        (blur)="onBlur()"
        [readonly]="readonly"
        [disabled]="disabled"
        [maxlength]="_maxLength"
        [mask]="mask"
        [prefix]="prefix"
        [dropSpecialCharacters]="dropSpecialCharacters"
      />
      <small
        [id]="identifier + 'Help'"
        *ngIf="helpText"
        class="form-text text-muted"
      >
        {{ helpText }}</small
      >
      <div class="invalid-feedback" *ngIf="hasRequiredError()">
        {{ requiredMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasEmailError">
        {{ emailMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasCustomError">
        {{ customErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasEmiratesIdError">
        {{ emiratesIdErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMinLengthError">
        {{ minLengthErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMaxLengthError">
        {{ maxLengthErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasPatternError">
        {{ patternErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasAlreadyExistError">
        {{ alreadyExistMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasNotFoundError">
        {{ notFoundMessage }}
      </div>
    </div>
  </div>
</ng-template>
