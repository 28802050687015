<h3 class="d-flex justify-content-between">
  <div [innerText]="isArabic ? metadata.nameAr : metadata.nameEn"></div>
  <div class="favorite-star float-right pl-4">
    <a
      class="text-yellow cursor-pointer float-right"
      [class.text-secondary]="service?.isFavorite == false"
    >
      <i
        class="fa-star fa-lg"
        [ngClass]="service?.isFavorite ? 'fa-solid' : 'fa-regular'"
        aria-hidden="true"
        (click)="toggleFavorite(service)"
      ></i>
    </a>
  </div>
</h3>
