import {Component, Input, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import * as dashBoardDetails from '../../../../core/models/customer/DashBoardDetails';
import {Subscription} from 'rxjs';
import * as inspections from '../../../../core/models/Inspections/LicensingInspectionResultDataDTO';
import * as getCertificateDetails from '../../../../core/models/customer/CertificateDetails_Portal';
import {environment} from '../../../../../environments/environment';
import {HomeComponent} from '../../home.component';
import {UtilService} from '../../../../core/utilities/util.service';
import {GetInspectionsService} from '../../../../core/api-services/Inspections/get-inspections.service';
import {GetCertificateDetailsService} from '../../../../core/api-services/customer/get-certificate-details.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ServiceRequestTypeCodeEnum} from '../../../../core/enums/service-request-type-code.enum';
import {PackageTypes, PackageTypesCodes} from '../../../../core/enums/package-types';

@Component({
    selector: 'app-cities-balance-and-falcon-passport',
    templateUrl: './cites-balanceand-falcon-passport.component.html',
})
@AutoUnsubscribe({arrayName: 'observableSubscriptions'})
export class CitiesBalanceAndFalconPassportComponent implements OnInit, OnDestroy {

    @Input() packageObject: dashBoardDetails.Package;
    closeResult = '';
    observableSubscriptions: Subscription[] = [];
    inspection: inspections.InspectionsObject;
    inspectionsDataList: inspections.ResponseData[] = [];
    inspection_current_page = 1;
    inspection_totalRecords = 5;
    inspection_recordPerPage = 2;
    certificateDetailsObject: getCertificateDetails.GetCertificateDetailsObject;
    certificateDetailsObjectDataList: getCertificateDetails.ListItem[] = [];

    certificateDetailPageSize = 3;
    certificateDetailPageIndex = 1;
    certificateDetailTotalItems = 0;
    REST_API_SERVER = environment.domainURL;
    currentLang: string;

    selectedProcedureInstanceIdForInspection: any;
    selectedCertificateNumber: any;

    constructor(public homeComponent: HomeComponent,
                private utilService: UtilService,
                public getInspectionsService: GetInspectionsService,
                private certificateDetailService: GetCertificateDetailsService,
                private modalService: NgbModal,
                private translateService: TranslateService, private toastr: ToastrService) {
        this.currentLang = translateService.currentLang;
        this.observableSubscriptions.push(translateService.onLangChange.subscribe(() => {
                this.currentLang = translateService.currentLang;
            })
        );
        console.log('packageObject= ' + this.packageObject);

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.packageObject) {
            this.certificateDetailPageIndex = 1;
            this.getCertificateDetail();
        }
    }

    ngOnDestroy(): void {
    }

    closeServiceDetails() {
        this.homeComponent.closeServiceDetails();
    }

    ngOnInit(): void {

    }

    getCertificateDetail() {
        this.observableSubscriptions.push(
            this.certificateDetailService.GetCertificateDetails_Portal(this.utilService.getParticipantId(),
                this.packageObject.ID.toString(),
                this.certificateDetailPageSize,
                this.certificateDetailPageIndex,
                PackageTypesCodes.CitiesBalanceAndFalconPassportCode).subscribe((response: getCertificateDetails.GetCertificateDetailsObject) => {
                this.certificateDetailsObject = response;
                this.certificateDetailsObjectDataList = response.Data.ListItems;
                this.certificateDetailTotalItems = this.certificateDetailsObject.Data.TotalItems;
            }));
    }


    open(content: any, procedureInstanceId: number, certificateNumber: string) {
        this.selectedProcedureInstanceIdForInspection = procedureInstanceId;
        this.selectedCertificateNumber = certificateNumber;
        this.getInspectionsService.getInspectionsData_Portal(this.utilService.getParticipantId(), this.translateService.currentLang).toPromise().then((data: inspections.InspectionsObject) => {
            if (data && data.IsSuccess) {
                this.inspection = data;
                this.inspectionsDataList = data.ResponseData;
                this.inspection_totalRecords = this.inspectionsDataList.length; // should be data.TotalItems but its value always zero
                this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'}).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            } else {
                this.toastr.warning(this.translateService.instant('ThisRequestDoesNotHaveInspectionData'));
            }
        });

    }

    getInspections(procedureInstanceId: number) {
        this.observableSubscriptions.push(this.getInspectionsService.getInspectionsData_Portal(this.utilService.getParticipantId(), this.translateService.currentLang).subscribe(
            (data: inspections.InspectionsObject) => {
                if (data && data.IsSuccess) {
                    this.inspection = data;
                    this.inspectionsDataList = data.ResponseData;
                    this.inspection_totalRecords = this.inspectionsDataList.length; // should be data.TotalItems but its value always zero
                }

            }
        ));

    }

    onInspectionsPageChange(event: any) {
        this.inspection_current_page = event;
        this.getInspections(this.selectedProcedureInstanceIdForInspection);
    }

    onCertificatePageChange(event: any) {
        this.certificateDetailPageIndex = event;
        this.getCertificateDetail();
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    viewRequest(procedureInstanceID: number) {
        // Converts the route into a string that can be used
        // with the window.open() function
        this.homeComponent.viewRequest(procedureInstanceID.toString());
    }

    get AnimalCareRequestTypeEnum(): typeof ServiceRequestTypeCodeEnum {
        return ServiceRequestTypeCodeEnum;
    }

    public get PackageTypeEnum(): typeof PackageTypes {
        return PackageTypes;
    }

    /**Enables action incase of animal care certificate */
    enableActions(certificateObject: getCertificateDetails.ListItem): boolean {
        return certificateObject.CertificateType?.Id == 2;
    }
}
