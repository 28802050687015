import { Component, EventEmitter , Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesOutput } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-cites-outputs',
  templateUrl: './cites-outputs.component.html',
})
export class CitesOutputsComponent extends BaseComponent {
  @Input() outputs?: CitesOutput[] = null;
  @Output() verificationDoc = new EventEmitter<string>();
  
  get showVerification(): boolean{
    let isVerified = this.outputs.reduce((result,current)=> 
      result || current.isVerified,false
    );
    return isVerified;
  }

  searchText: string = '';

  currentPage: number = 1;
  itemsPerPage: number = 4;

  EXPIRED = 'EXPIRED';
  VALID = 'VALID';

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  updateSearchText($event: any) {
    this.searchText = $event.searchText;
  }

  downloadVerificationDoc(output: CitesOutput){
    this.verificationDoc.emit(output.certificateNumber);
  }
}
