import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LicenseDetails } from 'src/app/core/models/shared/LicenseDetails';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-license-details',
  templateUrl: './es-license-details.component.html',
})
export class EsLicenseDetailsComponent extends BaseComponent {
  @Input()
  licenseDetails?: LicenseDetails = null;

  @Input()
  contClasses?: string = "";

  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
