<div class="form-group green-border-focus" *ngIf="isHiddenSendMessage == false">
    <h4 style="text-align: start;" for="exampleFormControlTextarea5">{{'CommunicationMessageSendMessage' | translate}}</h4>
    <div class="row mb-2 px-3">
        <div class="col-md-11 col-9" style="padding-inline-start: 0;">
            <textarea [(ngModel)]="newComment" class="form-control m-0" id="exampleFormControlTextarea5" rows="6"></textarea>
        </div>
        <div class="col-md-1 col-3" style="display: block;padding: 0;box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 0%);border-radius: 5px;text-align: center;">
            <div style="height:100% ;display: flex;justify-content: center;align-items: center;">
                <label class="icon-paperclip" for="cust_file" style="font-size: 25px; font-weight: 100;margin: 0;color: #b68a35;"></label>
                <input (change)="handleFileInput($event.target)" type="file" id="cust_file" style="opacity: 0;z-index: 1;height: 100%;width: 100%;position: absolute;right: 0;cursor: pointer;">
                <div id="customer_message_attachment_button" class="img_wrapper" *ngIf="isSelectedFileReady">
                    <div (click)="removeSelectedImage()" class="remove_img">x</div>
                    <img [src]="selectedFileUrl | safeUrl" id="cust_image" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>

    <button (click)="addComment()" [disabled]="newComment == null || newComment ==''" class="search mx-0 mb-3">{{'CommunicationMessageSendMessageButton' | translate}}</button>
</div>

<div class="page-content page-container" id="page-content">
    <div class="px-0">
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 col-xs-12">
                <div class="box box-warning direct-chat direct-chat-warning">
                    <div class="box-header with-border">
                        <h3 class="box-title">{{'ChatMessages' | translate}}</h3>
                        <div class="box-tools pull-right"> <span data-toggle="tooltip" title="" class="badge bg-yellow" data-original-title="3 New Messages">{{commentMessageList.length}}</span> <button type="button" class="btn btn-box-tool" data-widget="collapse"><i class="fa fa-minus"></i> </button>                            <button type="button" class="btn btn-box-tool" data-toggle="tooltip" title="" data-widget="chat-pane-toggle" data-original-title="Contacts"> <i class="fa fa-comments"></i></button> <button type="button" class="btn btn-box-tool"
                                data-widget="remove"><i class="fa fa-times"></i> </button> </div>
                    </div>
                    <div class="box-body my_scroll_new">
                        <div class="direct-chat-messages">
                            <ng-container *ngFor="let comment of commentMessageList">
                                <div class="direct-chat-msg emp_msg" *ngIf="comment.employeeUserID != null;else Sent">
                                    <div class="direct-chat-info clearfix">
                                        <span class="direct-chat-name pull-left">{{comment.createdBy}}</span>
                                    </div>
                                    <div class="d-flex">
                                        <img class="direct-chat-img" src="assets/Images/Icons/back-office.png" alt="message user image">
                                        <div>
                                            <div class="direct-chat-text" style="margin: 0 10px !important;">
                                                <p class="m-0">
                                                    {{comment.message}}
                                                </p>
                                                <span class="direct-chat-timestamp " style="font-size: 10px;color: #333;float: none !important;">{{comment.createdDate | date:'medium'}}</span>
                                            </div>
                                            <div class="mx-3" style="text-align: start;" *ngIf="comment.fileAttachment != null">
                                                <a class="cursor-pointer" (click)="utilService.viewFile(comment.fileAttachment.filePath, comment.fileAttachment.fileMimeType)">
                                                    <span>{{comment.fileAttachment.fileName}}</span>
                                                </a>
                                            </div>
                                        </div>

                                    </div>


                                </div>

                                <ng-template #Sent>
                                    <div class="direct-chat-msg right clnt_msg">
                                        <div class="direct-chat-info clearfix">
                                            <span class="direct-chat-name pull-right">{{userProfileName}}</span>
                                        </div>
                                        <div class="d-flex " style="justify-content: flex-end;">
                                            <div>

                                                <div class="direct-chat-text" style="margin: 0 10px !important;">

                                                    <div>
                                                        <p class="m-0">
                                                            {{comment.message}}
                                                        </p>
                                                        <div>
                                                            <span class="direct-chat-timestamp" style="font-size: 10px;color: #fff;float: none !important;">{{comment.createdDate | date:'medium'}}</span>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="mx-3" style="text-align: start;" *ngIf="comment.fileAttachment != null">
                                                    <a class="cursor-pointer" (click)="utilService.viewFile(comment.fileAttachment.filePath, comment.fileAttachment.fileMimeType)">
                                                        <span>{{comment.fileAttachment.fileName}}</span>
                                                    </a>
                                                </div>
                                            </div>


                                            <img class="direct-chat-img" src="assets/Images/Icons/user.png" alt="message user image">

                                        </div>

                                    </div>

                                </ng-template>
                            </ng-container>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
