import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SignaturePad from 'signature_pad';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { EsSignatureEventEmitterDataHolder } from './EsSignatureEventEmitterDataHolder';
import { EsSignatureValueTypeEnum } from './EsSignatureValueTypeEnum';

@Component({
  selector: 'app-es-signature-pad',
  templateUrl: './es-signature-pad.component.html',
})
export class EsSignaturePadComponent extends BaseComponent {
  public identifier: string = 'signature';

  @Input()
  public labelText: string;

  @Input()
  public isRequired: boolean;

  @Input()
  key: string;

  @Output()
  public change = new EventEmitter<EsSignatureEventEmitterDataHolder>();

  @Output()
  public sign = new EventEmitter<EsSignatureEventEmitterDataHolder>();

  @ViewChild('signatureCanvas')
  public signatureCanvas: ElementRef;

  public signaturePad: SignaturePad;

  constructor(translate: TranslateService) {
    super(translate);
  }

  public ngAfterViewInit() {
    this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement, {
      backgroundColor: '#b68a351a',
    });

    this.signaturePad.addEventListener('endStroke', () => {
      this.emitChange();
    });
  }

  private emitChange() {
    this.change.emit({
      value: this.signaturePad.toDataURL(),
      type: EsSignatureValueTypeEnum.PngBase64,
    });
  }

  public clearSignature(): void {
    this.signaturePad.clear();

    this.emitChange();
  }

  public undo(): void {
    var data = this.signaturePad.toData();
    if (data) {
      data.pop(); // remove the last dot or line
      this.signaturePad.fromData(data);
    }

    this.emitChange();
  }

  public hasRequiredValidator(): boolean {
    return this.isRequired;
  }

  public onSign(): void {
    this.sign.emit();
  }
}
