export enum ImportProceduresEnum {
  ImportAnimalBirds = 169,
  ImportLiveFish = 177,
  ImportAgricultureConsignment = 174,
  ImportVeterinaryProduct = 396,
  ImportAnimalFeeds = 402,
  ImportVeterinaryRawMaterials = 400,
  ImportPheromonesConsignment = 405,
  ImportVeterinaryEquipment = 398,
  ImportFishingTools = 166,
  ImportAnimalSamples = 407,
  ImportAnimalProducts = 167,
  ImportAnimalSemenEmbryos = 175,
  ImportOzoneDepletingDrain = 331,
  ImportPesticideCOnsignment = 171,
  ImportAnimalPets = 172,
  ImportAgricultureRestrictedFertilizers = 173,
  ImportAgricultureUnrestrictedFertilizers = 176,
  ImportCitesSpecies = 364,
  ImportHorses = 179
}
