export class ServiceMetaData {
  nameEn: string;
  nameAr: string;
  termsAndConditionsEn: string;
  termsAndConditionsAr: string;
  formattedTermsAndConditionsEn: string;
  formattedTermsAndConditionsAr: string;
  youTubeVideoURLAr: string;
  youTubeVideoURLEn: string;
  portalLinkAr: string;
  portalLinkEn: string;
  faqAr: string;
  faqEn: string;
  digitalNumber: string;
}
