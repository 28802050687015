<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12">
      <legend
        class="col-form-label"
        [innerText]="'Initiator Actions' | translate"
      ></legend>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-sm-12">
      <bs-ng-dropdown
        formControlName="action"
        identifier="action"
        key="trAccreditInitiatorActionFld"
        name="action"
        [items]="lookups.initiatorActions"
        [bindLabel]="defaultLookupDisplayProperty"
      >
      </bs-ng-dropdown>
    </div>
  </div>
</form>
