<div class="custom-control custom-switch">
  <input
    [id]="identifier"
    [name]="name"
    type="checkbox"
    class="custom-control-input"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    #control="ngModel"
    [required]="required == 'true' || required == '' ? '' : undefined"
    (blur)="onTouched()"
    [readonly]="readonly"
    [disabled]="disabled"
  />
  <label class="custom-control-label" [for]="identifier">
    {{ labelText }}
  </label>
  <small
    [id]="identifier + 'Help'"
    *ngIf="helpText"
    class="form-text text-muted"
  >
    {{ helpText }}</small
  >
</div>
