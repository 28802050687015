import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from 'src/app/core/utilities/util.service';
import { CongratulationsObjectType } from '../../shared-workflow-object/workflow.service';
import { EsMainAttachmentsStateObjectType } from '../../../shared/base/EsMainAttachmentsStateObjectType';
import { RequestActions } from 'src/app/shared/base/RequestActions';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { WorkflowBaseService } from 'src/app/shared/base/WorkflowBaseService';
import { CitesServiceRequestStore } from './base/CitesServiceRequestStore';
import { CitesReviewObjectType } from './base/CitesReviewObjectType';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { BehaviorSubject } from 'rxjs';
import { CitesBaseMapper } from './CitesBaseMapper';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { EsMainTemplateService } from 'src/app/core/api-services/shared/es-main-template.service';
import { ServiceDetailByDigitalNumberService } from 'src/app/core/api-services/portal/service-detail-by-digital-number.service';
import { RequestPurposeTypeEnum } from 'src/app/core/enums/RequestPurposeTypeEnum';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { EsApplicantStateObjectType } from '../../shared-workflow-object/EsApplicantStateObjectType';
import { CitesMainFormChangeEmitterData } from './cites-main-form/CitesMainFormChangeEmitterData';
import { CitesFormFields } from './base/CitesFormFields';
import { FormMode } from 'src/app/shared/base/FormMode';
import { AbstractControl } from '@angular/forms';

export abstract class CitesWorkflowBaseService<
  TServiceRequestStore extends CitesServiceRequestStore<any>
> extends WorkflowBaseService {
  globalObject: {
    ApplicantConfiguration: EsApplicantStateObjectType;
    ServiceDetailsConfiguration: TServiceRequestStore;
    AttachmentsConfiguration: EsMainAttachmentsStateObjectType;
    ReviewConfiguration: CitesReviewObjectType;
    Congratulations: CongratulationsObjectType;
  };

  protected get store(): TServiceRequestStore {
    return this.globalObject.ServiceDetailsConfiguration;
  }

  abstract initializeServiceDetailForm(): TServiceRequestStore;
  protected abstract createRequestData(): any;

  constructor(
    protected translateService: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected utilService: UtilService,
    protected platform: Platform,
    protected document: Document,
    protected isCompanyService: boolean,
    protected api: EsMainTemplateService,
    commonApiService: EsCommonService,
    protected mapper: CitesBaseMapper<any>,
    protected metadataService: ServiceDetailByDigitalNumberService
  ) {
    super(
      translateService,
      route,
      router,
      toastr,
      metadataService,
      api,
      commonApiService,
      mapper,
      utilService,
      document,
      platform
    );

    if (this.validateUserProfile()) {
      this.initializeGlobalObject();
    }
  }

  //#region ui flow

  private initializeGlobalObject(): void {
    this.globalObject = {
      ApplicantConfiguration: Object.assign({}, this.ApplicantStateObject),
      ServiceDetailsConfiguration: this.initializeServiceDetailForm(),
      AttachmentsConfiguration: Object.assign({}, this.AttachmentsStateObject),
      Congratulations: Object.assign({}, this.CongratulationsStateObject),
      ReviewConfiguration: Object.assign({}, this.ReviewStateObject),
    };
  }

  //#endregion

  //#region consignment items

  startItemEditing($event: any, exceptControls?: string[]) {
    this.store.formItemStatus.mode = FormMode.EDITING;

    this.fillItemForm($event, exceptControls);
  }

  protected fillItemForm($event: any, exceptControls?: string[]) {
    this.store.itemInstance = $event.data;
    this.assignForm(this.store.formItem, $event.data, exceptControls);
    this.itemFormOnChange();
  }

  public deleteItem($event: any) {
    const index = this.store.serviceDetails.serviceTransaction.items.indexOf(
      $event.data
    );

    this.store.serviceDetails.serviceTransaction.items.splice(index, 1);
  }

  public async push(): Promise<void> {
    const store = this.store;
    const item = this.fromFormGroup(store.formItem);
    if (this.itemFormCustomValidation()) {
      if (store.formItemStatus.mode === FormMode.EDITING) {
        Object.keys(store.itemInstance).forEach(
          (key) => delete store.itemInstance[key]
        ); //to remove conditional properities
        Object.assign(store.itemInstance, item);
      } else if (store.formItemStatus.mode === FormMode.NEW) {
        store.serviceDetails.serviceTransaction.items.push(item);
      }

      this.resetItemForm();

      this.emitFieldsChange();
    }
  }

  resetItemForm(): void {
    this.store.formItemStatus.mode = FormMode.NEW;

    const formGroup = this.store.formItem;

    const exceptControls = this.resetConsignmentFormExcept();

    Object.keys(formGroup.controls).forEach((key) => {
      if (exceptControls.indexOf(key) >= 0) return;
      ((formGroup.controls as any)[key] as AbstractControl).reset();
    });
    this.store.itemInstance = null;
  }

  itemFormCustomValidation(): boolean {
    return true;
  }

  itemFormOnChange() {}

  protected resetConsignmentFormExcept(): string[] {
    return [];
  }

  //#endregion

  //#region load dropdowns

  public async onMainFormChange(
    $event: CitesMainFormChangeEmitterData
  ): Promise<void> {}

  protected subscribeToFieldsOptionsChanges() {
    const store = this.store;

    const fields = this.buildServiceConfigurationFieldsOptions();
    store.fields$ = new BehaviorSubject<CitesFormFields>(fields);
    store.fields$.subscribe((fields) => this.onFieldsOptionsChange(fields));
  }

  protected onServiceConfigurationLoad(
    serviceConfig: EsServiceRequestConfiguration
  ): void {
    super.onServiceConfigurationLoad(serviceConfig);

    this.emitFieldsChange();
  }

  protected onFieldsOptionsChange(fields: CitesFormFields): void {
    if (this.isNotInitialized()) return;

    const mainForm = this.store.form.controls;
    const formItem = this.store.formItem?.controls;

    this.syncControlsFromFelidsOptions(mainForm, fields);

    if (formItem) this.syncControlsFromFelidsOptions(formItem, fields);
  }

  protected buildServiceConfigurationFieldsOptions(): CitesFormFields {
    const fields: CitesFormFields = {
      authorityLicenseNumber: this.buildAuthorityLicenseNumber(),
    };

    fields.deliveryType = this.fob
      .init()
      .visible(true)
      .view({ displayWhen: this.isSendBack });

    fields.receiverRegion = this.fob
      .init()
      .visible(true)
      .view({ displayWhen: this.isSendBack });

    fields.receiverEmirate = this.fob
      .init()
      .visible(true)
      .view({ displayWhen: this.isSendBack });

    fields.mobileNumber = this.fob.init().visible(true);
    fields.addressDetails = this.fob.init().visible(true);

    return fields;
  }

  protected emitFieldsChange() {
    const newOptions = this.buildServiceConfigurationFieldsOptions();
    this.store.fields$.next(newOptions);
  }

  protected markMainFormTouched() {
    this.store.form.markAllAsTouched();
  }

  protected scrollToMainForm() {
    this.document.querySelector('.main-form').scrollIntoView();
  }

  resetPaymentAmount(code: RequestPurposeTypeEnum) {
    ///as request purpose type affects the authorityLicenseNumber input visibility
    this.emitFieldsChange();
  }

  private async emitFormAsTransaction(): Promise<void> {
    const mainForm = this.store.form;

    const formServiceTransaction = this.fromFormGroup(mainForm);

    const mergedServiceTransaction = {
      ...this.store.serviceDetails.serviceTransaction,
      ...formServiceTransaction,
    };

    const a = {
      ...this.store.serviceDetails,
      serviceTransaction: mergedServiceTransaction,
    };

    this.store.serviceDetails$.next(a);
  }

  public override async move($event: ActionEmitDataHolder): Promise<void> {
    switch ($event.action) {
      case RequestActions.SaveAsDraft:
        this.saveAsDraft();
        break;
      case RequestActions.SaveTemplate:
        this.saveRequestTemplate();
        break;
      case RequestActions.ShowRequestTemplateForm:
        this.showRequestTemplateForm();
        break;
      case RequestActions.Back:
        this.back();
        break;
      case RequestActions.Next:
        this.next();
        break;
      case RequestActions.Pay:
        this.callPayAndRedirectToGateway();
        break;
      case RequestActions.PayLater:
        this.payLater();
        break;
    }
  }

  private async next(): Promise<void> {
    if (this.currentStep == WorkflowSteps.ServiceDetailsConfiguration) {
      if (await this.validateMainForm()) {
        await this.loadAttachmentsConfigurations();
      } else {
        console.warn('main form is not valid', this.store.form);
        return;
      }
    }

    if (this.currentStep == WorkflowSteps.AttachmentsConfiguration) {
      if (await this.validateRequiredAttachments()) {
      } else {
        this.toastr.warning(
          this.translateService.instant(
            'trRequiredAttachmentNotUploadedMessage'
          )
        );
        return;
      }
    }

    if (this.currentStep == WorkflowSteps.ReviewConfiguration) {
      if (this.isSendBack) {
        await this.resubmit();
      } else {
        if (await this.validateTermsAndConditions()) {
          await this.saveOrUpdate();
        } else {
          ///return called to stay on the same page
          return;
        }

        if (this.store.isPaymentRequired) {
          await this.updateReviewButtons();

          ///return called to stay on the same page
          return;
        } else this.globalObject.ReviewConfiguration.isValidationSkipped = true;
      }
    }

    const nextStep = this.steps[this.steps.indexOf(this.currentStep) + 1];

    if (nextStep == WorkflowSteps.ReviewConfiguration)
      await this.emitFormAsTransaction();

    this.moveForward(this.router);
  }

  protected async validateMainForm(): Promise<boolean> {
    const mainFormDir = this.store.form;

    mainFormDir.markAllAsTouched();

    if (mainFormDir.invalid) {
      //this.scrollToMainForm();
      this.scrollToFirstError();

      return false;
    }

    return await this.validateItems();
  }

  protected async validateItems(): Promise<boolean> {
    const formItem = this.store.formItem;

    if (
      this.store.serviceDetails.serviceTransaction.items &&
      this.store.serviceDetails.serviceTransaction.items.length > 0
    ) {
      return true;
    }

    if (formItem) {
      formItem.markAllAsTouched();

      if (formItem.invalid) {
        this.scrollToFirstError();

        return false;
      }
    }

    return true;
  }

  protected scrollToConsignmentItems(): void {
    setTimeout(() => {
      this.document
        .querySelector('.consignment-item-form')
        .closest('.form-section')
        .scrollIntoView();
    });
  }

  public isIdentifiable(classCode: string): boolean {
    return classCode?.indexOf('Identifiable') > -1;
  }

  public isOriginCertificateRequired(appendix: string) {
    return appendix != '1';
  }

  public isAnimalProduct(classCode: string) {
    return classCode?.indexOf('CITESAnimalProducts') > -1;
  }

  public isFalcon(classCode: string) {
    return (
      [
        'CITESAnimals5_Person_CITESFalcons_Identifiable',
        'CITESAnimals22_CITESFalcons_Identifiable',
      ].indexOf(classCode) > -1
    );
  }

  download(content: any, fileName: string, contentType: string) {
    var a = document.createElement('a');
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  protected override createDraftRequestData(): any {
    const mainForm = this.globalObject.ServiceDetailsConfiguration.form;

    let transaction = this.fromFormGroup(mainForm);

    return transaction;
  }

  protected override createDraftRequestDetailsData(): any {
    const items =
      this.globalObject.ServiceDetailsConfiguration.serviceDetails
        .serviceTransaction.items;

    return items || [];
  }

  protected showItemsValidationError() {
    this.toastr.error(
      this.translateService.instant('trExportConsignmentItemsValidationError')
    );
  }
}
