import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericServiceAttachmentDTO } from '../../core/models/animal-care/GenericServiceAttachmentDTO';
import * as serviceDetailByDigitalNumberResponse from './../../core/models/portal/ServiceDetailByDigitalNumberResponse';
import { TranslateService } from '@ngx-translate/core';
import { ServiceRequestTypeCodeEnum } from '../../core/enums/service-request-type-code.enum';
import { ResponseModel } from '../../core/models/shared/CommonResponseDTO';
import {
  CustomerRequestActionsEnum,
  ServiceMainTransactionData,
  WorkflowActionsCodesEnum,
  WorkflowStepStatusCodesEnum,
} from '../../core/models/common/ServiceMainTransactionData';
import { ServiceRequestType } from 'src/app/core/models/common/ServiceRequestType';
import { RequestAttachment } from '../../core/models/common/RequestAttachment';
import {
  Employee,
  RegisteredUserTypesEnum,
} from '../../core/models/profile/RegisteredUser';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../core/utilities/util.service';
import { ServiceDetailByDigitalNumberService } from '../../core/api-services/portal/service-detail-by-digital-number.service';
import { TemplateService } from '../../core/api-services/common/template.service';
import * as personalProfileByEmirateId from '../../core/models/common/PersonalProfileByEmirateId';
import {
  ApplicantIdentityTypeEnum,
  CustomerApplicant,
} from '../../core/models/customer/CustomerApplicant';
import { ServiceRequestChannelTypesEnum } from '../../core/enums/ServiceRequestChannelTypesEnum';
import { Platform } from '@angular/cdk/platform';
import { ServiceExternalIntegrationData } from '../../core/models/common/ExternalIntegration/ServiceExternalIntegrationData';
import { GetServiceExternalIntegrationService } from 'src/app/core/api-services/common/ExternalIntegration/get-service-external-integration.service';
import { RequestPurposeTypeEnum } from '../../core/enums/RequestPurposeTypeEnum';
import { HasPaymentDto } from '../../core/models/common/ServiceValidationResponseDto';
import { AttachmentFormObject } from 'src/app/core/models/common/AttachmentFormObject';
import { BackendSystemEnum } from '../shared-services-steps-module/congratulations/congratulations.component';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';

declare let TimeMe: any;

export abstract class WorkflowService<T> {
  renderAutoFocusElement: boolean = true;
  retrievedServiceObjectDto: T;
  currentServiceObjectDto: T;
  retrievedServiceRequestTransaction: ServiceMainTransactionData;
  currentServiceRequestTransaction: ServiceMainTransactionData;
  submittedActionCode: WorkflowActionsCodesEnum;
  lastSubmittedActionCode: WorkflowActionsCodesEnum =
    WorkflowActionsCodesEnum.ReturnToCustomer;
  requestTypeCode: ServiceRequestTypeCodeEnum;
  isStaticPayment: boolean;
  requestTypeObject: ServiceRequestType;
  serviceRequestTransactionId: number;
  serviceId: number;
  requestTypeId: number;
  feesFromPreviousYears: number;
  currentLang: string;
  buyerRegisteredUserId: number;
  eServiceResponseDataEnglish: serviceDetailByDigitalNumberResponse.ResponseData;
  eServiceResponseDataArabic: serviceDetailByDigitalNumberResponse.ResponseData;
  hasPaymentDto: HasPaymentDto;
  globalObject: {
    ApplicantConfiguration: ApplicantStateObjectType;
    ServiceDetailsConfiguration: any;
    AttachmentsConfiguration: AttachmentsStateObjectType;
    ReviewConfiguration: ReviewObjectType;
    Congratulations: CongratulationsObjectType;
    Response: ServiceMainTransactionData;
  };
  isSendBack: boolean;
  abstract currentStep: string;
  abstract steps: string[];
  public stepsCount: number = 4;
  abstract initializeServiceDetailForm(): any;
  abstract getServiceUrlKeyWord(): string;
  abstract showOrHideTermsAndConditions(): boolean;
  abstract isPendingOnPayment(): boolean;
  abstract isWaitingForCustomerAction(): boolean;
  abstract prepareServiceDetailToPost(): T;
  abstract getAttachments(): Observable<
    ResponseModel<GenericServiceAttachmentDTO[]>
  >;
  abstract setRetrievedServiceRequestTransactionObject(): void;
  public pageNumber: number = 1;
  constructor(
    protected translateService: TranslateService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected toastr: ToastrService,
    protected utilService: UtilService,
    protected serviceDetailByDigitalNumberService: ServiceDetailByDigitalNumberService,
    protected templateService: TemplateService<T>,
    protected platform: Platform,
    protected IsCompanyService: boolean,
    protected getServiceExternalIntegrationService: GetServiceExternalIntegrationService
  ) {
    if (this.validateUserProfile()) {
      this.validateRequest();
      this.initializeGlobalObject();
      this.startTimer();
    }
  }

  public get backendSystem(): BackendSystemEnum {
    return BackendSystemEnum.DigitalServices;
  }

  private startTimer(): void {
    TimeMe.initialize({
      currentPageName: this.getServiceUrlKeyWord(), // current page
      idleTimeoutInSeconds: 600,
    });

    TimeMe.startTimer();
  }

  private getTimeTakenByCustomer(): number {
    const key = this.getServiceUrlKeyWord();

    const result = Math.floor(TimeMe.getTimeOnPageInSeconds(key) || 0);

    return result;
  }

  isServiceRequestTransactionInProgress(): boolean {
    return (
      this.retrievedServiceRequestTransaction?.statusCode ==
        WorkflowStepStatusCodesEnum.NewRequest ||
      this.retrievedServiceRequestTransaction?.isDraft == true ||
      this.retrievedServiceRequestTransaction?.statusCode ==
        WorkflowStepStatusCodesEnum.WaitingCustomerAction
    );
  }

  postData(
    action: CustomerRequestActionsEnum
  ): Observable<ResponseModel<ServiceMainTransactionData>> {
    this.currentServiceObjectDto = this.prepareServiceObjectForPostData(action);
    if (
      this.retrievedServiceRequestTransaction != null &&
      (this.retrievedServiceRequestTransaction.statusCode ==
        WorkflowStepStatusCodesEnum.NewRequest ||
        this.retrievedServiceRequestTransaction.isDraft == true ||
        this.retrievedServiceRequestTransaction.statusCode ==
          WorkflowStepStatusCodesEnum.WaitingCustomerAction)
    ) {
      this.currentServiceRequestTransaction.id =
        this.retrievedServiceRequestTransaction.id;
      return this.templateService.update(this.currentServiceObjectDto);
    } else {
      return this.templateService.create(this.currentServiceObjectDto);
    }
  }

  prepareServiceObjectForPostData(action: CustomerRequestActionsEnum): T {
    let serviceMainTransactionData = new ServiceMainTransactionData();
    // ########################## Prepare applicant ##########################
    if (
      this.utilService.getUserProfile().registeredUserType ===
      RegisteredUserTypesEnum.Authority
    ) {
      let applicant: CustomerApplicant = {} as CustomerApplicant;
      const applicantStateObject = this.globalObject.ApplicantConfiguration;

      if (applicantStateObject.isNewApplicant) {
        applicant.email = applicantStateObject.form.get('EmailID').value;
        applicant.identityNumber =
          applicantStateObject.form.get('EmiratesId').value;
        applicant.mobile = applicantStateObject.form.get('MobileNumber').value;
        applicant.nameEn =
          applicantStateObject.findByEmirateIdResponse.Data.ParticipantToRegister.NameEn;
        applicant.nameAr =
          applicantStateObject.findByEmirateIdResponse.Data.ParticipantToRegister.NameAr;
        applicant.area =
          applicantStateObject.findByEmirateIdResponse.Data.ParticipantToRegister.Area;
        applicant.address =
          applicantStateObject.findByEmirateIdResponse.Data.ParticipantToRegister.ParticipantAddress;
        applicant.emirate =
          applicantStateObject.findByEmirateIdResponse.Data.ParticipantToRegister.Emirate;
        applicant.country =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.Country;
        applicant.countryId =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.CountryId;
        if (
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister
            .BirthDate
        ) {
          let from =
            applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.BirthDate?.split(
              '-'
            );
          let date = new Date(+from[2], +from[1] - 1, +from[0]);
          applicant.birthDate = date;
        }
        applicant.birthPlace =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.BirthPlace;
        applicant.sex =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.Sex;
        applicant.specializationID =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.SpecializationID;
        applicant.occupationID =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.OccupationID;
        applicant.maritalStatusID =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.MaritalStatusID;
        applicant.religionID =
          applicantStateObject.findByEmirateIdResponse.Data.PersonToRegister.ReligionID;
      } else if (applicantStateObject.isEditMode) {
        const applicantInForm = applicantStateObject.form.get('ApplicantName')
          .value as CustomerApplicant;
        applicant = applicantInForm;
        applicant.mobile = applicantStateObject.form.get('MobileNumber').value;
        applicant.email = applicantStateObject.form.get('EmailID').value;
      }
      //add
      else {
        const applicantInForm = applicantStateObject.form.get('ApplicantName')
          .value as CustomerApplicant;
        applicant = applicantInForm;
      }

      applicant.registerUserId = +this.utilService.getUserProfile().id;
      applicant.identityType = ApplicantIdentityTypeEnum.EmiratesId;
      serviceMainTransactionData.requestPurposeTypeId =
        applicantStateObject.form.get('selectedRequestPurposeType').value;
      serviceMainTransactionData.serviceApplicantData = applicant;
      serviceMainTransactionData.serviceApplicantId = applicant.id;
    }

    //Check One of the External Integration Exists.
    if (
      this.globalObject.AttachmentsConfiguration.ServiceExternalIntegration !=
      null
    ) {
      serviceMainTransactionData.serviceExternalIntegrationData =
        this.globalObject.AttachmentsConfiguration.ServiceExternalIntegration;
      if (serviceMainTransactionData.serviceExternalIntegrationData) {
        serviceMainTransactionData.serviceExternalIntegrationData.envPermitLicenseDetailData =
          null;
      }
    }

    //Set is Disable individual User
    if (
      this.utilService.getUserProfile().people != null &&
      this.utilService.getUserProfile().people.isDisableUser
    ) {
      serviceMainTransactionData.isFromPeopleOfDetermination = true;
    }

    serviceMainTransactionData.language = this.currentLang;

    serviceMainTransactionData.serviceId = this.serviceId;
    serviceMainTransactionData.registeredUserId =
      +this.utilService.getUserProfile().id;

    serviceMainTransactionData.serviceRequestTypeId = this.requestTypeId;
    let requestChannel = ServiceRequestChannelTypesEnum.Web;
    if (this.platform.IOS) {
      requestChannel = ServiceRequestChannelTypesEnum.IOS;
    } else if (this.platform.ANDROID) {
      requestChannel = ServiceRequestChannelTypesEnum.Andriod;
    } else if (this.platform.EDGE) {
      requestChannel = ServiceRequestChannelTypesEnum.EDGE;
    } else if (this.platform.FIREFOX) {
      requestChannel = ServiceRequestChannelTypesEnum.FIREFOX;
    } else if (this.platform.SAFARI) {
      requestChannel = ServiceRequestChannelTypesEnum.SAFARI;
    } else if (this.platform.TRIDENT) {
      requestChannel = ServiceRequestChannelTypesEnum.TRIDENT;
    } else if (this.platform.WEBKIT) {
      requestChannel = ServiceRequestChannelTypesEnum.WEBKIT;
    }
    serviceMainTransactionData.serviceRequestChannelType = requestChannel;
    serviceMainTransactionData.registrationType = 'Authority';
    serviceMainTransactionData.language = this.translateService.currentLang;

    // ########################## Set attachments ##########################
    const attachments: RequestAttachment[] = [];
    const attachmentStateObject = this.globalObject.AttachmentsConfiguration;
    const attachmentsInForm =
      attachmentStateObject.form.get('Attachments').value;

    for (let attachmentObj of attachmentsInForm) {
      let attachment: AttachmentFormObject = attachmentObj;
      if (
        attachment &&
        attachment.attachmentId &&
        attachment.attachmentFiles &&
        attachment.attachmentFiles.length > 0
      ) {
        for (let attachmentFile of attachment.attachmentFiles) {
          let att = new RequestAttachment();
          att.attachmentID = attachment.attachmentId;
          att.fileAttachmentId = attachmentFile.storedFileAttachmentDBId;
          att.isNewMessage = false;
          att.serviceCatalogId = this.serviceId;
          att.employeeFeedbackMessage = attachmentFile.employeeFeedbackMessage;
          attachments.push(att);
        }
      }
    }

    if (this.buyerRegisteredUserId) {
      serviceMainTransactionData.buyerRegisteredUserId =
        this.buyerRegisteredUserId;
    }

    //Set Employee Id if exists [Employee login on behalf of customer].
    let employeeObj: Employee = this.utilService.getEmployeeProfile();
    if (employeeObj) {
      serviceMainTransactionData.employeeId = employeeObj.employeeId;
    }

    serviceMainTransactionData.requestAttachments = attachments;
    serviceMainTransactionData.customerRequestAction = action;
    this.currentServiceRequestTransaction = serviceMainTransactionData;

    this.currentServiceRequestTransaction.elapsedTime = {
      time: this.getTimeTakenByCustomer(),
    };

    return this.prepareServiceDetailToPost();
  }

  saveAsDraft() {
    this.postData(CustomerRequestActionsEnum.OnDraft).subscribe((response) => {
      this.router.navigate(['./home']).then(() => {
        this.toastr.info(
          this.translateService.instant('CongratulationsApplyDraftRequest', {
            RequestNumber: response.responseData.serviceRequestNumber,
          })
        );
      });
    });
  }

  resetAttachment() {
    this.globalObject.AttachmentsConfiguration.form.reset();
    (<FormArray>(
      this.globalObject.AttachmentsConfiguration.form.get('Attachments')
    )).clear();
    this.globalObject.AttachmentsConfiguration.IsAttachmentRetrieved = false;

    // Reset Retrieved External Integration Data.
    this.ResetRetrievedExternalIntegrationData();
  }

  private initializeGlobalObject(): void {
    this.globalObject = {
      ApplicantConfiguration: Object.assign({}, this.ApplicantStateObject),
      ServiceDetailsConfiguration: this.initializeServiceDetailForm(),
      AttachmentsConfiguration: Object.assign({}, this.AttachmentsStateObject),
      ReviewConfiguration: Object.assign({}, this.ReviewStateObject),
      Congratulations: Object.assign({}, this.CongratulationsStateObject),
      Response: {} as ServiceMainTransactionData,
    };
  }
  abstract submitNewRequest(router: Router): void;
  abstract showSubmitNewRequestButton: boolean;
  // should be implemented by each service
  abstract isServiceDetailAutoRenewal(): boolean;

  isValidAutoRenewal(): boolean {
    if (!this.requestTypeObject.isAutoRenewal) return false;

    return this.isServiceDetailAutoRenewal();
  }

  validateUserProfile(): boolean {
    const userProfile = this.utilService.getUserProfile();
    if (
      userProfile == null ||
      userProfile.registeredUserAddress == null ||
      userProfile.registeredUserAddress == ''
    ) {
      this.router.navigate(['/home'], { queryParams: { dashboard: 'true' } });
      console.log(
        "************** The Participant doesn't have address *********************"
      );
      this.toastr.warning(
        this.translateService.instant(
          'YouDoNotHaveAddressPleaseEditYourProfile'
        )
      );
      return false;
    }
    //##########End Validate address of the Participant ####################################

    //########## Validate Registered User is a company and not individual ######################
    if (
      userProfile != null &&
      this.IsCompanyService &&
      userProfile.registeredUserType != RegisteredUserTypesEnum.Authority
    ) {
      console.log(
        '************** The Participant is individual*********************'
      );
      this.toastr
        .warning(
          this.translateService.instant(
            'ItIsNotAllowedToProvideThisServiceToIndividuals'
          )
        )
        .onShown.subscribe((a) => {
          this.router.navigate(['/home'], {
            queryParams: { dashboard: 'true' },
          });
        });
      return false;
    }
    return true;
    //########## End Validate Registered User is a company and not individual ##############
  }

  validateRequest() {
    let queryParamsSnapshot = this.route.snapshot.queryParams;
    this.setRequestType(queryParamsSnapshot['requestTypeCode']);
    this.setRequestId(queryParamsSnapshot['requestId']);
    this.setActionCode(queryParamsSnapshot['actionCode']);

    if (
      !Object.values(ServiceRequestTypeCodeEnum).includes(this.requestTypeCode)
    ) {
      console.log('###############(4)');
      this.toastr
        .warning(
          this.translateService.instant('YouAccessedTheServiceWithImproperWay')
        )
        .onShown.subscribe((a) => {
          this.router.navigate(['/']);
        });
      return;
    }

    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(() => {
      this.currentLang = this.translateService.currentLang;
    });

    this.route.queryParams.subscribe((params) => {
      if (params['requestTypeCode']) {
        //incase the customer changed requestType queryParams modified after initial redirection
        if (
          this.requestTypeCode &&
          params['requestTypeCode'].toString() != this.requestTypeCode
        ) {
          console.log('###############(1)');
          this.toastr
            .warning(
              this.translateService.instant(
                'YouAccessedTheServiceWithImproperWay'
              )
            )
            .onShown.subscribe((a) => {
              this.router.navigate(['/']);
            });
          return;
        }

        this.setRequestType(params['requestTypeCode']);
      }
      if (params['requestId']) {
        this.setRequestId(params['requestId']);
      }

      if (!params['requestId']) {
        if (this.serviceRequestTransactionId) {
          this.router.navigate([], {
            queryParams: { requestNumber: this.serviceRequestTransactionId },
            queryParamsHandling: 'merge',
          });
        } else if (this.requestTypeCode != ServiceRequestTypeCodeEnum.Issue) {
          this.router.navigate(['/']);
          console.log('###############(2)');
          this.toastr
            .warning(
              this.translateService.instant(
                'YouAccessedTheServiceWithImproperWay'
              )
            )
            .onShown.subscribe((a) => {
              this.router.navigate(['/']);
            });
          return;
        }
      }
    });

    this.templateService
      .validate(this.requestTypeCode, this.serviceRequestTransactionId)
      .subscribe(
        (response) => {
          this.retrievedServiceObjectDto =
            response.responseData.serviceObjectDto;
          this.requestTypeId = response.responseData.serviceRequestTypeDto.id;
          this.requestTypeObject = response.responseData.serviceRequestTypeDto;
          this.serviceId =
            response.responseData.serviceRequestTypeDto.serviceData.id;

          if (
            !this.utilService.isEmployeeHasAccessOnTheService(this.serviceId)
          ) {
            this.toastr
              .warning(
                this.translateService.instant(
                  'MinistryEmployeeDoNotHavePrivilegeOnService'
                )
              )
              .onShown.subscribe((a) => {
                this.router.navigate(['/']);
              });
            return;
          }

          let staticPayment = false;
          if (
            response.responseData.hasPaymentDto &&
            response.responseData.hasPaymentDto.hasPayment === true &&
            response.responseData.hasPaymentDto.isVariablePayment === false
          ) {
            staticPayment = true;
            this.setIsStaticPayment(staticPayment);

            if (response.responseData.hasPaymentDto.staticServiceFees) {
              this.setTotalPayment(
                response.responseData.hasPaymentDto.staticServiceFees -
                  response.responseData.hasPaymentDto
                    .staticRenewalFeesForPreviousYears
              );
            }

            this.hasPaymentDto = response.responseData.hasPaymentDto;
          }
          this.setRetrievedServiceRequestTransactionObject();
          if (this.retrievedServiceRequestTransaction) {
            this.setRetrievedApplicantInfoAndAttachment();
            if (
              this.requestTypeCode == ServiceRequestTypeCodeEnum.Renew ||
              this.requestTypeCode == ServiceRequestTypeCodeEnum.CancelLicense
            ) {
              if (staticPayment) {
                this.feesFromPreviousYears =
                  response.responseData.hasPaymentDto.staticRenewalFeesForPreviousYears;
              } else {
                this.templateService
                  .getRenewalFeesForPreviousYears(this.serviceId)
                  .subscribe((response) => {
                    this.feesFromPreviousYears = response.responseData;
                  });
              }
            }
          }
          this.getServiceDetailByDigitalNumber();
          this.getServiceExternalIntegrationDataByRequestTransactionId();
          this.handleRequestPurposeTypeValidation();
          this.skipApplicant();
        },
        (error) => {
          this.router.navigate(['/home']);
        }
      );
  }
  skipApplicant(): void {
    if (
      this.utilService.getUserProfile().registeredUserType ===
      RegisteredUserTypesEnum.Person
    ) {
      this.reInitializeApplicantForm();
      this.moveForward(this.router, true);
    }
  }

  reInitializeApplicantForm(): void {
    this.globalObject.ApplicantConfiguration.form = new FormGroup({
      ApplicantName: new FormControl(null),
      EmiratesId: new FormControl(null),
      Name: new FormControl(null),
      MobileNumber: new FormControl(null),
      EmailID: new FormControl(null),
      selectedRequestPurposeType: new FormControl(null),
    });
  }

  protected ApplicantStateObject: ApplicantStateObjectType = {
    form: new FormGroup({
      ApplicantName: new FormControl(null, Validators.required),
      EmiratesId: new FormControl(null),
      Name: new FormControl(null),
      MobileNumber: new FormControl(null),
      EmailID: new FormControl(null),
      selectedRequestPurposeType: new FormControl(null),
    }),
    isNewApplicant: false,
    findByEmirateIdResponse: null,
    isValidEmiratesId: false,
    enableStep: false,
    showAddNewApplicantButton: false,
    showEditApplicantButton: false,
    showDetailsApplicantButton: true,
    showDetails: false,
    isEditMode: false,
    showRequestPurposeType: false,
    selectedRequestPurposeTypeCode: null,
    url: '/services/' + this.getServiceUrlKeyWord() + '/applicantinformation',
  };
  protected AttachmentsStateObject: AttachmentsStateObjectType = {
    Attachments: [] as GenericServiceAttachmentDTO[], //Coming from get attachments which implemented in each service detail [GenericService Attachment DTO]
    RetrievedAttachments: [] as RequestAttachment[], // Retrieved Attachments  [Service Request Attachments]
    IsAttachmentRetrieved: false, // if you need to reset attachment from service details page do we have to call reset attachment and sit this param with false.
    form: new FormGroup({
      Attachments: new FormArray([]), //This the form construct from custom object inside attachment module [AttachmentFormObject]
    }),
    ServiceExternalIntegration: null,
    enableStep: false,

    url: '/services/' + this.getServiceUrlKeyWord() + '/attachments',
  };
  protected ReviewStateObject: ReviewObjectType = {
    form: new FormGroup({
      AcceptTerms: new FormControl(null),
      AutoRenewal: new FormControl(null),
      TotalPayment: new FormControl({ value: null, disabled: true }),
    }),
    enableStep: false,
    url: '/services/' + this.getServiceUrlKeyWord() + '/review',
  };
  protected CongratulationsStateObject: CongratulationsObjectType = {
    form: {
      valid: false,
    },
    url: '/services/' + this.getServiceUrlKeyWord() + '/congrats',
  };

  getTotalPayment(): number {
    return this.globalObject.ReviewConfiguration.form.get('TotalPayment').value;
  }
  setTotalPayment(totalPayment: number): void {
    this.globalObject.ReviewConfiguration.form
      .get('TotalPayment')
      .setValue(totalPayment);
  }

  setRetrievedApplicantInfoAndAttachment(): void {
    if (this.retrievedServiceRequestTransaction.serviceApplicantData) {
      (this.globalObject.ApplicantConfiguration.form as FormGroup)
        .get('ApplicantName')
        .setValue(this.retrievedServiceRequestTransaction.serviceApplicantData);
    }
    (this.globalObject.ApplicantConfiguration.form as FormGroup)
      .get('selectedRequestPurposeType')
      .setValue(this.retrievedServiceRequestTransaction.requestPurposeTypeId);
    this.globalObject.AttachmentsConfiguration.RetrievedAttachments =
      this.retrievedServiceRequestTransaction.requestAttachments;
  }

  getServiceDetailByDigitalNumber(): void {
    this.serviceDetailByDigitalNumberService
      .getServiceDetailByDigitalNumber(this.serviceId.toString())
      .subscribe((response) => {
        if (
          response &&
          response.responseData &&
          response.responseData.length > 1
        ) {
          this.setEServiceResponseDataEnglish(
            response.responseData.find(
              (element) => element.language == 'English'
            )
          );
          this.setEServiceResponseDataArabic(
            response.responseData.find(
              (element) => element.language == 'Arabic'
            )
          );
        }
      });
  }

  isRoutingDirectionForward(previousStep: string): boolean {
    const previousStepIndex = this.steps.indexOf(previousStep);
    const currentIndex = this.steps.indexOf(this.currentStep);

    return previousStepIndex <= currentIndex;
  }

  setRequestType(requestType: ServiceRequestTypeCodeEnum): void {
    this.requestTypeCode = requestType;
  }

  setIsStaticPayment(isStaticPayment: boolean): void {
    this.isStaticPayment = isStaticPayment;
  }

  setRequestId(requestId: number) {
    this.serviceRequestTransactionId = requestId;
  }

  setActionCode(actionCode: WorkflowActionsCodesEnum) {
    this.submittedActionCode = actionCode;
  }

  goToStep(step: string, router: Router = null) {
    if (this.globalObject.Congratulations.form.valid == false) {
      const url = (<any>this.globalObject)[step].url;
      if (url) {
        this.router.navigate([url], { queryParamsHandling: 'preserve' });
      }
    }
  }

  redirectTo(uri: string, router: Router) {
    router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => router.navigate([uri]));
  }

  moveBack(router: Router = null, isSkipped: boolean = false) {
    const curStep = this.currentStep;
    const curIndex = this.steps.indexOf(curStep);
    if (curIndex > 0) {
      const url = (<any>this.globalObject)[this.steps[curIndex - 1]].url;
      if (url) {
        if (!isSkipped) this.pageNumber--;
        this.router.navigate([url], { queryParamsHandling: 'preserve' });
      }
    }
  }

  moveForward(router: Router, isSkipped: boolean = false) {
    const curStep = this.currentStep;
    const curIndex = this.steps.indexOf(curStep);
    (<any>this.globalObject)[this.steps[curIndex]].enableStep = true;
    if (curIndex < this.steps.length) {
      const url = (<any>this.globalObject)[this.steps[curIndex + 1]].url;
      if (url) {
        if (!isSkipped) this.pageNumber++;
        router.navigate([url], { queryParamsHandling: 'preserve' });
      }
    }
  }

  getNextStepStateObject() {
    const curStep = this.currentStep;
    const curIndex = this.steps.indexOf(curStep);
    if (curIndex < this.steps.length) {
      return (<any>this.globalObject)[this.steps[curIndex + 1]];
    }
    return null;
  }

  isValidAndEnabledStep(step: string): boolean {
    const curIndex = this.steps.indexOf(step);
    if (curIndex >= 0) {
      return (
        (<any>this.globalObject)[this.steps[curIndex]].enableStep == true &&
        (<any>this.globalObject)[this.steps[curIndex]].form.valid == true
      );
    }
    return false;
  }
  isValidStep(step: string): boolean {
    const curIndex = this.steps.indexOf(step);
    if (curIndex >= 0) {
      return (<any>this.globalObject)[this.steps[curIndex]].form.valid == true;
    }
    return false;
  }
  checkAllPerviousStepsValidation(): boolean {
    const curStep = this.currentStep;
    let curIndex = this.steps.indexOf(curStep);
    if (curIndex <= 0) {
      return true;
    }
    let result = true;
    while (--curIndex >= 0) {
      if (!this.isValidStep(this.steps[curIndex])) {
        result = false;
        break;
      }
    }
    return result;
  }

  showBackArrow(): boolean {
    const curStep = this.currentStep;
    const curIndex = this.steps.indexOf(curStep);
    if (curIndex <= 0 || curIndex >= this.steps.length - 1) {
      return false;
    }
    return true;
  }

  getServiceName() {
    const requestType = this.requestTypeObject;
    if (requestType) {
      const requestTypeName =
        this.translateService.currentLang == 'ar'
          ? requestType.serviceData?.nameAr
          : requestType.serviceData?.nameEn;
      return requestTypeName;
    }

    return '';
  }
  setEServiceResponseDataEnglish(
    eServiceResponseDataEnglish: serviceDetailByDigitalNumberResponse.ResponseData
  ): void {
    this.eServiceResponseDataEnglish = eServiceResponseDataEnglish;
  }

  setEServiceResponseDataArabic(
    setEServiceResponseDataArabic: serviceDetailByDigitalNumberResponse.ResponseData
  ): void {
    this.eServiceResponseDataArabic = setEServiceResponseDataArabic;
  }

  getPossibleStepAfterLastValidStep(curStep: string): string {
    const curIndex = this.steps.indexOf(curStep);
    if (
      // check if this is the first step
      curIndex <= 0 ||
      // check if previous step is valid and entered
      ((<any>this.globalObject)[this.steps[curIndex - 1]].form.valid == true &&
        (<any>this.globalObject)[this.steps[curIndex - 1]].enableStep ==
          true) ||
      // check if the current step is valid and entered
      ((<any>this.globalObject)[this.steps[curIndex]].form.valid &&
        (<any>this.globalObject)[this.steps[curIndex]].enableStep == true)
    ) {
      return curStep;
    } else {
      return this.getPossibleStepAfterLastValidStep(this.steps[curIndex - 1]);
    }
  }

  getPostDataResponseObject(): ServiceMainTransactionData {
    const Response = this.globalObject.Response;

    return Response;
  }

  showPayNow(): boolean {
    return (
      this.requestTypeObject.hasFrontPayment &&
      (this.getTotalPayment() > 0 || this.feesFromPreviousYears > 0)
    );
  }

  showSubmit(): boolean {
    return !this.showPayNow();
  }

  ResetRetrievedExternalIntegrationData() {
    //Reset Retrieved Environmental Permit Integration Data.
    this.ResetRetrievedEnvironmentalPermitIntegrationData();
  }

  ResetRetrievedEnvironmentalPermitIntegrationData() {
    if (
      this.globalObject.AttachmentsConfiguration?.ServiceExternalIntegration ==
        null ||
      this.globalObject.AttachmentsConfiguration.ServiceExternalIntegration
        .envPermitLicenseDetailData == null
    ) {
      return;
    }

    this.globalObject.AttachmentsConfiguration.ServiceExternalIntegration.envPermitLicenseDetailData =
      null;
    this.globalObject.AttachmentsConfiguration.ServiceExternalIntegration.envPermitLicenseDetailId =
      null;
  }

  getServiceExternalIntegrationDataByRequestTransactionId(): void {
    let serviceRequestTransactionId: number = null;
    serviceRequestTransactionId = this.isServiceRequestTransactionInProgress()
      ? this.retrievedServiceRequestTransaction?.id
      : null;

    if (serviceRequestTransactionId == null) {
      return;
    }
    if (
      this.retrievedServiceRequestTransaction?.serviceExternalIntegrationData ==
      null
    ) {
      return;
    }

    this.getServiceExternalIntegrationService
      .GetServiceExternalIntegration(serviceRequestTransactionId)
      .subscribe((data) => {
        let envPermitLicenseDetailData =
          data.responseData.envPermitLicenseDetailData;
        this.globalObject.AttachmentsConfiguration.ServiceExternalIntegration =
          data.responseData;
      });
  }

  handleRequestPurposeTypeValidation(): void {
    if (
      this.utilService.isProfileHasResearchCenterType() &&
      this.requestTypeObject.serviceData?.isAllowingException
    ) {
      this.globalObject.ApplicantConfiguration.form
        .get('selectedRequestPurposeType')
        .setValidators([Validators.required]);
      this.globalObject.ApplicantConfiguration.form
        .get('selectedRequestPurposeType')
        .updateValueAndValidity();
      this.globalObject.ApplicantConfiguration.showRequestPurposeType = true;
    }
    if (this.isWaitingForCustomerAction() === true) {
      this.globalObject.ApplicantConfiguration.form
        .get('selectedRequestPurposeType')
        .disable();
    }
  }

  resetPaymentAmount(
    selectedRequestPurposeTypeCode: RequestPurposeTypeEnum
  ): void {
    if (selectedRequestPurposeTypeCode == null) {
      return;
    }

    if (
      this.submittedActionCode == WorkflowActionsCodesEnum.ReSubmitBYCustomer
    ) {
      return;
    }

    // this.globalObject.ServiceDetailsConfiguration.form.reset();
    if (!this.isStaticPayment) {
      this.reInitializeServiceDetail();
    }
    if (selectedRequestPurposeTypeCode == RequestPurposeTypeEnum.Commercial) {
      if (this.isStaticPayment) {
        this.feesFromPreviousYears =
          this.hasPaymentDto.staticRenewalFeesForPreviousYears;
        this.setTotalPayment(
          this.hasPaymentDto.staticServiceFees -
            this.hasPaymentDto.staticRenewalFeesForPreviousYears
        );
      }
    } else {
      if (this.isStaticPayment) {
        this.feesFromPreviousYears = 0;
        this.setTotalPayment(0);
      }
    }

    if (
      this.requestTypeCode == ServiceRequestTypeCodeEnum.Renew ||
      this.requestTypeCode == ServiceRequestTypeCodeEnum.CancelLicense
    ) {
      if (!this.isStaticPayment) {
        this.templateService
          .getRenewalFeesForPreviousYears(
            this.serviceId,
            selectedRequestPurposeTypeCode
          )
          .subscribe((response) => {
            this.feesFromPreviousYears = response.responseData;
          });
      }
    }
  }

  abstract reInitializeServiceDetail(): void;

  public async setFeedbackDone(): Promise<void> {}
}

export interface ApplicantStateObjectType {
  form: FormGroup;
  isNewApplicant: boolean;
  isValidEmiratesId: boolean;
  enableStep: boolean;
  findByEmirateIdResponse: personalProfileByEmirateId.PersonalProfileByEmirateIdObject;
  showAddNewApplicantButton: boolean;
  showEditApplicantButton: boolean;
  showDetailsApplicantButton: boolean;
  showDetails: boolean;
  showRequestPurposeType: boolean;
  selectedRequestPurposeTypeCode: RequestPurposeTypeEnum;
  isEditMode: boolean;
  url: string;
}

export interface AttachmentsStateObjectType {
  form: FormGroup;
  Attachments: GenericServiceAttachmentDTO[];
  RetrievedAttachments: RequestAttachment[];
  IsAttachmentRetrieved: boolean;
  enableStep: boolean;
  ServiceExternalIntegration: ServiceExternalIntegrationData;
  url: string;
}

export interface ReviewObjectType {
  form: FormGroup;
  enableStep: boolean;
  url: string;
}

export interface CongratulationsObjectType {
  form: { valid: boolean };
  url: string;

  transactionId?: string;
  registerUserId?: string;
  customerPulseLinkingId?: string;
  customerPulseLinkingIdForMobile?: string;
  serviceRequestNumber?: string;
  statusNameAr?: string;
  statusNameEn?: string;

  isFeedbackDone?: boolean;

  messages?: CongratsMessages;
}

export interface CongratsMessages {
  saveAndUpdate?: { key: string; keyArgs: any };
}
