<div *ngIf="showLegend">
  <legend
    [innerText]="'trAttachments' | translate"
    class="col-form-label"
  ></legend>
</div>

<div class="row">
  <div class="col-12">
    <ul
      *ngIf="
        attachmentsGroups && attachmentsGroups.length;
        else noItemsAddedBlock
      "
      class="list-group ds-list-group-default"
    >
      <li
        class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2"
      >
        <app-detail-display
          key="trAttachmentName"
          [value]=""
        ></app-detail-display>
      </li>
      <li
        class="list-group-item d-flex justify-content-start align-items-center ds-print-pba-always"
        *ngFor="let attachmentsGroup of attachmentsGroups; let index = index"
      >
        <div
          class="ds-list-group-item-head d-flex w-100 p-2"
          [class.ds-list-group-item-head-open]="isCollapsed[index] == false"
          (click)="isCollapsed[index] = !isCollapsed[index]"
        >
          <app-detail-display
            [value]="attachmentsGroup.attachmentType | lookup : lang"
          ></app-detail-display>
          <div class="list-group-item-options">
            <a
              class="ds-btn-icon ds-btn-bg-bright"
              [attr.aria-expanded]="!isCollapsed[index]"
              ><i class="fi-arrow-thin-down"></i
            ></a>
          </div>
        </div>

        <div
          class="list-group-item-details collapse show w-100 col"
          [ngbCollapse]="isCollapsed[index] ?? (isCollapsed[index] = true)"
        >
          <div class="row">
            <div class="col-12">
              <div
                class="ds-detail-table ds-detail-table-border-none ds-detail-table-default"
              >
                <div
                  class="row border-bottom d-none d-md-flex ds-detail-table-head"
                  *ngIf="attachmentsGroup.attachments?.length"
                >
                  <div class="col-12 col-md-10 col-print-10">
                    <app-detail-display
                      key="trAttachmentFileName"
                    ></app-detail-display>
                  </div>
                  <div class="col-md-2 col-print-none"></div>
                </div>

                <div
                  *ngFor="let attachment of attachmentsGroup.attachments"
                  class="row border-bottom ds-detail-table-row ds-detail-display-responsive py-2"
                >
                  <div class="col-12 col-md-10 col-print-10">
                    <app-detail-display
                      [value]="attachment.attachmentFileName"
                    ></app-detail-display>
                  </div>
                  <div class="col-md-2 col-print-none">
                    <a
                      class="ds-btn-icon ds-btn-bg-bright"
                      (click)="download(attachment)"
                    >
                      <i class="fi-download fi-large mx-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template #noItemsAddedBlock>
  <app-list-no-items></app-list-no-items>
</ng-template>
