import { Pipe, PipeTransform } from '@angular/core';
import { ILookup } from 'src/app/core/models/shared/Lookups';

@Pipe({
  name: 'lookup',
})
export class LookupPipe implements PipeTransform {
  constructor() {}

  transform(value: ILookup, lang: string): string {
    if (value) return lang === 'ar' ? value.nameAr : value.nameEn;

    return '';
  }
}

@Pipe({
  name: 'lookups',
})
export class LookupsPipe implements PipeTransform {
  constructor() {}

  transform(value: ILookup[], lang: string, separator: string = ' ,'): string {
    if (value) {
      return value
        .map((a: any) => {
          return lang === 'ar' ? a.nameAr : a.nameEn;
        })
        .join(separator);
    }

    return '';
  }
}
