<div class="row justify-content-center">
  <div dsCol [colOptions]="breadcrumbsColumnOptions">
    <app-breadcrumbs
      [breadcrumbsDetails]="breadcrumbsDetails$ | async"
      [serviceMetaData]="serviceMetaData$ | async"
      [serviceKey]="serviceKey"
    ></app-breadcrumbs>
  </div>
</div>

<div
  class="row justify-content-center title-container"
  *ngIf="serviceMetaData$ | async as metadata"
  dsCol
  [colOptions]="mainColOptions"
>
  <es-service-title [metadata]="metadata"></es-service-title>
</div>

<div class="row justify-content-center" dsCol [colOptions]="mainColOptions">
  <div class="row">
    <div class="col-2 pr-0 d-none d-sm-block sections-indexer-container">
      <app-section-indexer></app-section-indexer>
    </div>

    <div class="col-12 col-sm-10">
      <div
        class="forms-container"
        *ngIf="transaction$ | async as transaction; else loading"
      >
        <div *ngIf="serviceConfig$ | async as config; else loading">
          <app-es-license-details
            [licenseDetails]="config.licenseDetails"
            contClasses="form-section"
          >
          </app-es-license-details>
        </div>

        <div class="form-section">
          <app-es-service-request-details
            [requestDetails]="transaction.serviceRequestTransactionData"
          >
          </app-es-service-request-details>
        </div>

        <div
          class="form-section"
          *ngIf="
            transaction.citesOutputs && transaction.citesOutputs.length > 0
          "
        >
          <app-cites-outputs
            [outputs]="transaction.citesOutputs"
            (verificationDoc)="downloadVerificationDocument($event)"
          >
          </app-cites-outputs>
        </div>

        <div class="form-section">
          <app-cites-importer-review
            [importerDetails]="transaction.serviceTransaction?.importerDetails"
          >
          </app-cites-importer-review>
        </div>

        <div class="form-section">
          <app-cites-exporter-review
            [exporterDetails]="transaction.serviceTransaction?.exporterDetails"
          >
          </app-cites-exporter-review>
        </div>

        <div class="form-section">
          <app-cites-release-species-request-review-details
            [transaction]="transaction"
          >
          </app-cites-release-species-request-review-details>
        </div>

        <div
          class="form-section"
          *ngIf="
            transaction.serviceTransaction?.releaseItems &&
            transaction.serviceTransaction.releaseItems.length > 0
          "
        >
          <app-cites-release-species-item-list
            [items]="transaction.serviceTransaction.releaseItems"
            [enableEditing]="false"
            titleKey="ReleaseConsignmentItems"
          >
          </app-cites-release-species-item-list>
        </div>

        <div
          class="form-section"
          *ngIf="
            transaction.serviceTransaction?.items &&
            transaction.serviceTransaction.items.length > 0
          "
        >
          <app-cites-release-species-item-list
            [items]="transaction.serviceTransaction.items"
            [enableEditing]="false"
            titleKey="ImportConsignmentItems"
          >
          </app-cites-release-species-item-list>
        </div>

        <div class="form-section" *ngIf="isShowFeesSection(transaction)">
          <app-es-fee-list [fees]="fees$ | async"></app-es-fee-list>
        </div>

        <div class="form-section">
          <app-es-attachments-details
            [attachments]="transaction.attachments"
          ></app-es-attachments-details>
        </div>

        <div class="form-section" *ngIf="isShowReceiptSection(transaction)">
          <app-es-receipt-details
            [receipts]="transaction.receipts"
          ></app-es-receipt-details>
        </div>

        <div class="form-section">
          <app-es-comments
            [comments]="transaction.comments"
            [requestId]="transaction.serviceTransaction.procedureInstanceId"
          ></app-es-comments>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <p [innerText]="'trLoading' | translate"></p>
</ng-template>
