import { Lookup } from '../common/Lookup';
import { PaymentTypeLkp } from './Lookups';
import { EsRequestStatusCodesEnum } from './MainWorkflowStepStatusCodesEnum';

export class ServiceRequestTransactionEsData {
  id?: number;
  createdBy?: string;
  createdDate?: Date;
  serviceId?: number;
  registeredUserId?: number;
  serviceRequestNumber?: string;
  statusCode?: EsRequestStatusCodesEnum;
  statusAr?: string;
  statusEn?: string;
  registrationType?: string;
  remarks?: string;
  serviceApplicantId?: number;
  completeDate?: Date;
  isDraft?: boolean;
  customerServiceCenterId?: number;
  reasonOfRejection?: string;
  reasonOfReturn?: string;
  isFromPeopleOfDetermination?: boolean;
  serviceRequestTypeId?: number;
  cancellationReasonId?: number;
  frontOfficeProcessPageUrl?: string;
  frontOfficeViewPageUrl?: string;
  language?: string;
  customerNote?: string;
  requestPurposeTypeId?: number;
  buyerRegisteredUserId?: number;
  employeeId?: number;
  requestPurposeId?: number;
  isFeedbackDone?: boolean;
}

export interface EsReceipt {
  receiptId?: number;
  receiptNumber?: string;
  receiptDate?: Date;
  paymentType?: PaymentTypeLkp;
  amount?: number;
  eDirhamFees?: number;
  remarks?: string;
  creationDate?: Date;
  fees?: EsFee[];
}

export interface EsFee {
  id?: number;
  nameEn?: string;
  nameAr?: string;
  nameUr?: string;
  code?: string;
  percentage?: number;
  minValue?: number;
  maxValue?: number;
  operator?: null;
  value1?: null;
  value2?: null;
  variableId1?: null;
  variableId2?: null;
  feeTotal?: number;
  total?: number;
  lastModifiedBy?: null;
  lastModified?: null;
  quantity?: number;
  isRepeated?: boolean;
  onceAtShipment?: boolean;
  certificateTypeId?: null;
  feeAmount?: number;
  isVariableFee?: boolean;
}

export class EsComment {
  creationDate?: string;
  commentSource?: string;
  commentSourceAr?: string;
  commentBody?: string;
  procedureInstanceId?: number;
  attachments?: EsAttachmentsNames[];
}

export class EsAttachmentsNames {
  name: string;
  guid: string;
}

export interface EsAttachment {
  createdBy?: string;
  creationDate?: Date;
  lastModificationDate?: Date;
  lastModifiedBy?: string;
  path?: string;
  procedureAttachmentId?: number;
  procedureInstanceId?: number;
  procInstAttchId?: number;
  attachmentLocalizedName?: string;
  attachmentNameAr?: string;
  attachmentNameUr?: string;
  attachmentNameEn?: string;
  attachmentCode?: string;
  fileName?: string;
  isRequired?: boolean;
  attachmentFileName?: string;
  loadFromHistory?: string;
}

export interface EsAttachmentGroup {
  procedureAttachmentId: number;
  attachments: EsAttachment[];
  attachmentType: Lookup;
}
