export enum EsRequestStatusCodesEnum {
  NewRequest = 'NewRequest',
  WaitingCustomerAction = 'WaitingCustomerAction',
  Canceled = 'Canceled',
  CustomerServiceApproval = 'CustomerServiceApproval',
  CustomerServiceFirstApproval = 'CustomerServiceFirstApproval',
  CustomerServiceTarasolApproval = 'CustomerServiceTarasolApproval',
  Draft = 'Draft',
  Execution = 'Execution',
  Exported = 'Exported',
  Expropriated = 'Expropriated',
  Finished = 'Finished',
  FinishedPartialRelease = 'FinishedPartialRelease',
  ImportPermitIssued = 'ImportPermitIssued',
  ImportPremitIssued = 'ImportPremitIssued',
  InitiatorSendBack = 'InitiatorSendBack',
  New = 'New',
  NULLdfsdf = 'NULLdfsdf',
  PendingOnAgrAdvisorInspection = 'PendingOnAgrAdvisorInspection',
  PendingOnAgriWealthDeptCommitteeApproval = 'PendingOnAgriWealthDeptCommitteeApproval',
  PendingOnAnalysisRequirement = 'PendingOnAnalysisRequirement',
  PendingOnAnimalDepartmentApproval = 'PendingOnAnimalDepartmentApproval',
  PendingOnBiodiversityDepartment = 'PendingOnBiodiversityDepartment',
  PendingOnChemicalEngineerInspection = 'PendingOnChemicalEngineerInspection',
  PendingOnChemicalsWastesDept = 'PendingOnChemicalsWastesDept',
  PendingOnCITESEngInspection = 'PendingOnCITESEngInspection',
  PendingOnCITESTOWhomCusomterSrvTeam = 'PendingOnCITESTOWhomCusomterSrvTeam',
  PendingOnCITESVetInspection = 'PendingOnCITESVetInspection',
  PendingOnCompanyDelivery = 'PendingOnCompanyDelivery',
  PendingOnConcernedCountryApproval = 'PendingOnConcernedCountryApproval',
  PendingOnCustomerServiceEngineerAuditing = 'PendingOnCustomerServiceEngineerAuditing',
  PendingOnDelivery = 'PendingOnDelivery',
  PendingOnEngInspection = 'PendingOnEngInspection',
  PendingOnEntryPortVetInspection = 'PendingOnEntryPortVetInspection',
  PendingOnEnvironmentalAuditing = 'PendingOnEnvironmentalAuditing',
  PendingOnExitPortVetInspection = 'PendingOnExitPortVetInspection',
  PendingOnExternalInspection = 'PendingOnExternalInspection',
  PendingOnFishAdminCom = 'PendingOnFishAdminCom',
  PendingOnFishersWealthDeptCommitteeApproval = 'PendingOnFishersWealthDeptCommitteeApproval',
  PendingOnFishFarmExperts = 'PendingOnFishFarmExperts',
  PendingOnFoodSafetyAdminstrator = 'PendingOnFoodSafetyAdminstrator',
  PendingOnFoodSafetyAdminstratorVisitInfo = 'PendingOnFoodSafetyAdminstratorVisitInfo',
  PendingOnFoodSafetyEmployee = 'PendingOnFoodSafetyEmployee',
  PendingOnInitiatorAction = 'PendingOnInitiatorAction',
  PendingOnInitiatorUploading = 'PendingOnInitiatorUploading',
  PendingOnInitiatorUploadingMortgage = 'PendingOnInitiatorUploadingMortgage',
  PendingOnInspection = 'PendingOnInspection',
  PendingOnLabResult = 'PendingOnLabResult',
  PendingOnLabTechInspection = 'PendingOnLabTechInspection',
  PendingOnLocalAuthority = 'PendingOnLocalAuthority',
  PendingOnMinistryOfInteriorApproval = 'PendingOnMinistryOfInteriorApproval',
  PendingOnNationalCommitteeForFoodSafetyAdmins = 'PendingOnNationalCommitteeForFoodSafetyAdmins',
  PendingOnOwnerApproval = 'PendingOnOwnerApproval',
  PendingOnPayment = 'PendingOnPayment',
  PendingOnPenaltyPayment = 'PendingOnPenaltyPayment',
  PendingOnPersonalDelivery = 'PendingOnPersonalDelivery',
  PendingOnProvidingResultReport = 'PendingOnProvidingResultReport',
  PendingOnQuarantineDecision = 'PendingOnQuarantineDecision',
  PendingOnReadinessMessage = 'PendingOnReadinessMessage',
  PendingOnReceivingSample = 'PendingOnReceivingSample',
  PendingOnSecondPayment = 'PendingOnSecondPayment',
  PendingOnSignature = 'PendingOnSignature',
  PendingOnSupplierApproval = 'PendingOnSupplierApproval',
  PendingOnTechCommitteeAgri = 'PendingOnTechCommitteeAgri',
  PendingOnTechnicalCommitteeApproval = 'PendingOnTechnicalCommitteeApproval',
  PendingOnTechnicalCommitteeFishFarm = 'PendingOnTechnicalCommitteeFishFarm',
  PendingOnTechnicalCommitteeInspection = 'PendingOnTechnicalCommitteeInspection',
  PendingOnTechnicalTeam = 'PendingOnTechnicalTeam',
  PendingOnVetAdvisorInspection = 'PendingOnVetAdvisorInspection',
  PendingOnVeterinarianProductsCommitteeApproval = 'PendingOnVeterinarianProductsCommitteeApproval',
  PendingOnVeterinarianVisit = 'PendingOnVeterinarianVisit',
  PendingOnVetInspection = 'PendingOnVetInspection',
  Quarantined = 'Quarantined',
  Rejected = 'Rejected',
  ObjectionApplied = 'ObjectionApplied',
  PendingOnCustomerSubmission = 'PendingOnCustomerSubmission',
}
