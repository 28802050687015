export enum ServiceRequestChannelTypesEnum
{
  Internet= 'Internet',
  Intranet= 'Intranet',
  Web= 'Web',
  Mobile= 'Mobile',
  Andriod= 'Andriod',
  IOS = 'IOS',
  BLINK= 'BLINK',
  EDGE= 'EDGE',
  FIREFOX= 'FIREFOX',
  SAFARI= 'SAFARI',
  WEBKIT= 'WEBKIT',
  TRIDENT= 'TRIDENT'
}
