import { UtilService } from '../../core/utilities/util.service';
import { GetProfileService } from '../../core/api-services/customer/get-profile.service';
import { environment } from 'src/environments/environment';

export function appProfileInitializer(
  utilService: UtilService,
  getProfileService: GetProfileService
) {
  return () =>
    new Promise((resolve) => {
      if (utilService.isParticipantIdExists() == false) {
        const url = new URL(window.location.href);
        const participantId = url.searchParams.get('registeredUserId');

        if (participantId != null) {
          utilService.saveParticipantId(participantId);
          utilService.setGatewayTokenCoockie(environment.gatewayPermanentToken);
        }
      }

      if (utilService.isParticipantIdExists()) {
        getProfileService
          .GetProfileData()
          .subscribe(
            (value) => {
              if (value.responseData != null) {
                utilService.saveProfileData(value.responseData);
              } else {
                utilService.deleteUserProfile();
              }
            },
            (error) => {
              utilService.deleteUserProfile();
            }
          )
          .add(resolve);
      } else {
        utilService.deleteUserProfile();
        resolve(true);
      }
    });
}
