<div
  class="form-section-valid-indicator"
  [ngClass]="{
    'form-section-invalid': isFailed(),
    'form-section-valid': isSuccess()
  }"
  #mainEl
>
  <i *ngIf="hasErrors(); else validTemplate" class="fi-info"></i>
</div>

<ng-template #validTemplate>
  <i class="fi-tick"></i>
</ng-template>
