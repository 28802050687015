export class Customer {
  emirates_id: string;
  name: string;
  email: string;
  mobile: string;
  gender: string;
  nationality: string;
  user_id: string;
}

export class Employee {
  employee_id: string;
}

export class MetaData {
  customer: Customer;
  employee: Employee;
  transaction_id: string;
}

export class GeneratingCustomerPulseRequestObject {
  linking_id: string;
  meta_data: MetaData;
}



interface ResponseData {
  token: string;
}

export interface GeneratingCustomerPulseResponseObject {
  message: string;
  data: ResponseData;
}
