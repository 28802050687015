import { GetServiceConfigurationResponse } from 'src/app/core/api-services/shared/GetServiceDetailsResponse';
import { DatePipe } from '@angular/common';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { ServiceBaseMapper } from '../../../shared/base/ServiceBaseMapper';
import { CitesBaseServiceRequest } from 'src/app/core/models/cites/shared/CitesBaseServiceRequest';
import { GetDraftRequestDto } from 'src/app/core/api-services/shared/save-as-draft/SaveAsDraftRequest';
import { CitesTransactionBaseData } from 'src/app/core/models/cites/shared/CitesTransactionBaseData';
import { ParticipantTemplateDto } from 'src/app/core/api-services/export/shared/template-services/TemplateServicesResponse';
import { LookupDto } from 'src/app/core/models/shared/LookupDto';
import { SourceLkp, TypeLkp } from 'src/app/core/models/shared/Lookups';
import { Lookup } from 'src/app/core/models/common/Lookup';

export abstract class CitesBaseMapper<TTransaction> extends ServiceBaseMapper {
  constructor(datePipe: DatePipe) {
    super(datePipe);
  }

  public abstract mapGetServiceConfigurationResponse(
    response: GetServiceConfigurationResponse
  ): EsServiceRequestConfiguration;

  public override mapUltimateDraftResponse(
    dto: GetDraftRequestDto
  ): CitesBaseServiceRequest<any> {
    const result = super.mapUltimateDraftResponse(
      dto
    ) as CitesBaseServiceRequest<CitesTransactionBaseData>;

    result.serviceTransaction.items = JSON.parse(dto.DraftRequestDetails);

    return result;
  }

  public override mapTemplateRequestResponse(
    dto: ParticipantTemplateDto
  ): CitesBaseServiceRequest<any> {
    const result = super.mapTemplateRequestResponse(
      dto
    ) as CitesBaseServiceRequest<CitesTransactionBaseData>;

    result.serviceTransaction.items = JSON.parse(dto.TemplateDetails);

    return result;
  }

  mapSourceLookup(lookupArray: LookupDto[]): SourceLkp[]{
    return lookupArray.map((i) => {
      return {
        nameAr: i.NameAr,
        nameEn: i.NameEn,
        nameUr: i.NameUr,
        id: i.Id,
        code: i.Code,
        parentId: i.ParentID,
        productType: i.ProductType
      };
    });
  }

  mapTypeLookup(lookupArray: LookupDto[]): TypeLkp[]{
    return lookupArray.map((i) => {
      return {
        nameAr: i.NameAr,
        nameEn: i.NameEn,
        nameUr: i.NameUr,
        id: i.Id,
        code: i.Code,
        parentId: i.ParentID,
        productType: i.ProductType
      };
    });
  }
  
  mapPurposeLookup(lookupArray: LookupDto[]): Lookup[]{
    return lookupArray.map((i) => {
      return {
        nameAr: i.NameAr + " - " + i.Code,
        nameEn: i.NameEn + " - " + i.Code,
        nameUr: i.NameUr + " - " + i.Code,
        id: i.Id,
        code: i.Code,
        parentId: i.ParentID,
      };
    });
  }
}
