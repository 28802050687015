<div *ngIf="licenseDetails?.showLicenseDetails && 
            licenseDetails?.certificateNumber != null &&
            licenseDetails?.certificateNumber != ''" [className]="contClasses">
  <legend
    [innerText]="'License Details' | translate"
    class="col-form-label"
  ></legend>
  <div class="row">
    <div class="col-md-6 col-sm-12 col-print-6">
      <app-bs-display
        key="trLicenseNumber"
        [valueText]="licenseDetails?.certificateNumber"
      ></app-bs-display>
    </div>
    <div class="col-md-6 col-sm-12 col-print-6">
      <app-bs-display
        key="trCertificateType"
        [valueText]="
          isArabic
            ? licenseDetails?.certificateTypeNameAr
            : licenseDetails?.certificateTypeName
        "
      ></app-bs-display>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12 col-print-6">
      <app-bs-display
        key="trLastIssuance"
        [valueText]="licenseDetails?.startDate"
      ></app-bs-display>
    </div>
    <div class="col-md-6 col-sm-12 col-print-6">
      <app-bs-display
        key="trExpiryDate"
        [valueText]="licenseDetails?.expiryDate"
      ></app-bs-display>
    </div>
  </div>
</div>
