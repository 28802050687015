import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { FavoriteServiceHybrid } from '../../core/api-services/shared/services/ServicesListDataService';
import {
  ServiceCardActions,
  ServiceCardEventEmitterDataHolder,
} from './ServiceCardEventEmitterDataHolder';
import { fadeAnimation, rotateShake, scaleY } from '../animations/fade.animation';

@Component({
  selector: 'service-card',
  templateUrl: './service-card.component.html',
  animations: [fadeAnimation, scaleY, rotateShake],
})
export class ServiceCardComponent extends BaseComponent {
  @Input()
  service: FavoriteServiceHybrid;

  @Output()
  action = new EventEmitter<ServiceCardEventEmitterDataHolder>();

  public emitAction(service: ServiceCardEventEmitterDataHolder) {
    this.action.emit(service);
  }

  public toggleFavorite(service: FavoriteServiceHybrid) {
    this.emitAction({
      action: ServiceCardActions.ToggleFavorite,
      data: service,
    });
  }

  public fetchServiceMetadata(service: FavoriteServiceHybrid) {
    this.emitAction({
      action: ServiceCardActions.FetchMetadata,
      data: service,
    });
  }

  public collapse(service: FavoriteServiceHybrid) {
    this.emitAction({
      action: ServiceCardActions.Collapse,
      data: service,
    });
  }

  public getRelativeURL(url: string): string{
    if(url)
      return new URL(url).pathname;
    else
      return "";
  }
}
