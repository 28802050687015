import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import {
  FavoriteServiceHybrid,
  ServicesListDataService,
} from 'src/app/core/api-services/shared/services/ServicesListDataService';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'es-service-title',
  templateUrl: './es-service-title.component.html',
})
export class EsServiceTitleComponent extends BaseComponent implements OnInit {
  @Input()
  metadata: ServiceMetaData;

  public service: FavoriteServiceHybrid;

  constructor(
    translateService: TranslateService,
    protected servicesListDataService: ServicesListDataService
  ) {
    super(translateService);

    this.servicesListDataService.$allServices.subscribe((services) => {
      const service = services.find(
        (ff) => ff.serviceDetails.digitalNumber == this.metadata.digitalNumber
      );

      this.service = service || null;
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.servicesListDataService.loadFavoriteServicesHybrid(this.lang);
  }

  public async toggleFavorite(service: FavoriteServiceHybrid): Promise<void> {
    let isFavorite = service?.isFavorite;

    await this.servicesListDataService.updateFavorite(
      this.metadata.digitalNumber,
      !isFavorite
    );
  }
}
