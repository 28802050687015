import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../shared/base/BaseComponent';


@Component({ template: '' })
export class EsRequestCardExtraBaseComponent extends BaseComponent {
  @Input()
  requestId: number;

  @Input()
  serviceId: number;
}
