<div class="w-100 cahrt_containerr show-details dashboard_statistics" style="color: #fff;" id="containerDivId">

  <div class="container">
    <div class="my_heading_con flex_flow">
      <h2>{{'MyStatistics' | translate}}</h2>
      <!-- <span (click)="closeDashboardStatistics()"  class="close_btn"><img src="assets/Images/close_white.svg" width="10"></span> -->
      <div class="custom_icon"><span (click)="closeDashboardStatistics()" style="font-size: 30px; cursor: pointer; color:#fff;" class="icon-cancel-24px-gold"></span></div>
    </div>


<div class="chart_inner_contaier w-100">
  <ul class="chart_menu ar_right">
    <li><a (click)="chartType='request'" [ngClass]="{'active_pay': chartType=='request' }" class="cursor-pointer">{{'DashboardStatisticsMyRequests' | translate}}</a></li>
    <li><a (click)="chartType='payment'" [ngClass]="{'active_pay': chartType=='payment' }" class="cursor-pointer">{{'DashboardStatisticsPaidPayments' | translate}}</a></li>
  </ul>

   <input readonly type="text" #dashboardStatisticsInputAutoFocusWeb id="dashboardStatisticsInputAutoFocusWeb" style="opacity:0;  height:0;" name="dashboardStatisticsInputAutoFocusWeb">

    <div *ngIf="chartType=='request'" class="chart-wrapper">
      <canvas baseChart
              [datasets]="currentLang == 'en'? requestBarChartDataEn : requestBarChartDataAr"
              [labels]="currentLang == 'en'? chartLabelsEn : chartLabelsAr"
              [options]="requestBarChartOptions"
              [plugins]="requestBarChartPlugins"
              [legend]="requestBarChartLegend"
              [colors]="requestBarChartColors"
              [chartType]="requestBarChartType"

              >
      </canvas>
    </div>

    <div *ngIf="chartType=='payment'" class="chart-wrapper">
      <canvas baseChart
              height="40vh" width="80vw"
              [datasets]="currentLang == 'en'? paymentBarChartDataEn : paymentBarChartDataAr"
              [labels]="currentLang == 'en'? chartLabelsEn : chartLabelsAr"
              [options]="paymentBarChartOptions"
              [plugins]="paymentBarChartPlugins"
              [legend]="paymentBarChartLegend"
              [colors]="paymentBarChartColors"
              [chartType]="paymentBarChartType">
      </canvas>
    </div>
  </div>
  </div>


</div>
