<div class="container" id="containerDivId">
  <div class="most-used-services">
    <div class="col-sm-12 text-left" style="display: none">
      <h5 class="bold border-left">
        {{
          lang == 'en'
            ? workflowService.requestTypeObject?.serviceData.nameEn + ' -'
            : workflowService.requestTypeObject?.serviceData.nameAr + ' -'
        }}
        {{
          lang == 'en'
            ? workflowService.requestTypeObject?.nameEn
            : workflowService.requestTypeObject?.nameAr
        }}
      </h5>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="card-10 congrats_container">
      <input
        *ngIf="renderAutoFocusElement"
        readonly
        type="text"
        readonly
        #inputAutofocus
        id="autofocus"
        style="opacity: 0; height: 0; position: absolute"
        name="autofocus"
        autofocus
      />
      <center>
        <img src="assets/Images//Icons/verified-24px.png" class="pt-1" />
        <h3>
          <b>{{ 'CONGRATS' | translate }}</b>
        </h3>
        <ng-container
          *ngIf="
            !workflowService.isWaitingForCustomerAction() &&
            !workflowService.isSendBack
          "
        >
          <ng-container *ngIf="isArabic == false">
            <p>
              {{ 'CongratulationsYour' | translate }}
              <span
                >{{ 'CongratulationsRequest' | translate }}
                {{ stateObject.serviceRequestNumber }}</span
              >
              {{ 'CongratulationsIsSuccessfullySubmitted' | translate }}
              <span
                *ngIf="stateObject.statusNameEn"
                [innerText]="'CongratulationsRequestStatus' | translate"
              ></span>
              <span class="bold" *ngIf="stateObject.statusNameEn">
                {{ stateObject.statusNameEn }} </span
              ><br />{{ 'CongratulationsAt' | translate }}
              {{ currentDate | date : 'short' }}
            </p>
          </ng-container>

          <ng-container *ngIf="isArabic">
            <p>
              <span> {{ stateObject.serviceRequestNumber }} </span>
              {{ 'CongratulationsYour' | translate }}
              <span>{{ 'CongratulationsRequest' | translate }} </span>
              {{ 'CongratulationsIsSuccessfullySubmitted' | translate }}
              <span
                *ngIf="stateObject.statusNameAr"
                [innerText]="'CongratulationsRequestStatus' | translate"
              ></span>
              <span class="bold" *ngIf="stateObject.statusNameAr">
                {{ stateObject.statusNameAr }}
              </span>
              <br />{{ 'CongratulationsAt' | translate }}
              {{ currentDate | date : 'short' }}
            </p>
          </ng-container>

          <div class="col col-12" *ngIf="messages?.saveAndUpdate">
            <span
              [innerHtml]="
                messages.saveAndUpdate.key
                  | translate : messages.saveAndUpdate.keyArgs
              "
            ></span>
          </div>
        </ng-container>

        <ng-container
          *ngIf="
            workflowService.isWaitingForCustomerAction() &&
            !workflowService.isSendBack
          "
        >
          <ng-container *ngIf="isArabic == false">
            <p>
              {{ 'CongratulationsYour' | translate }}
              <span
                >{{ 'CongratulationsRequest' | translate }}
                {{ stateObject.serviceRequestNumber }}</span
              >
              {{ 'CongratulationsIsSuccessfullyResubmitted' | translate }}
              <span
                *ngIf="stateObject.statusNameAr"
                [innerText]="'CongratulationsRequestStatus' | translate"
              ></span>
              <span class="bold" *ngIf="stateObject.statusNameAr">
                {{ stateObject.statusNameAr }}
              </span>
              <br />{{ 'CongratulationsAt' | translate }}
              {{ currentDate | date : 'short' }}
            </p>
          </ng-container>

          <ng-container *ngIf="isArabic">
            <p>
              <span> {{ stateObject.serviceRequestNumber }} </span>
              {{ 'CongratulationsYourReqResubmitted' | translate }}
              <span>{{ 'CongratulationsRequest' | translate }} </span>
              {{ 'CongratulationsIsSuccessfullySubmitted' | translate }}
              <span
                *ngIf="stateObject.statusNameAr"
                [innerText]="'CongratulationsRequestStatus' | translate"
              ></span>
              <span class="bold" *ngIf="stateObject.statusNameAr">
                {{ stateObject.statusNameAr }}
              </span>
              <br />{{ 'CongratulationsAt' | translate }}
              {{ currentDate | date : 'short' }}
            </p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="workflowService.isSendBack">
          <ng-container *ngIf="isArabic == false">
            <p>
              {{ 'CongratulationsYour' | translate }}
              <span
                >{{ 'CongratulationsRequest' | translate }}
                {{ stateObject.serviceRequestNumber }}</span
              >
              {{ 'CongratulationsIsSuccessfullyReturned' | translate }}
              <span
                *ngIf="stateObject.statusNameEn"
                [innerText]="'CongratulationsRequestStatus' | translate"
              ></span>
              <span class="bold" *ngIf="stateObject.statusNameEn">
                {{ stateObject.statusNameEn }} </span
              ><br />{{ 'CongratulationsAt' | translate }}
              {{ currentDate | date : 'short' }}
            </p>
          </ng-container>
          <ng-container *ngIf="isArabic">
            <p>
              <span> {{ stateObject.serviceRequestNumber }} </span>
              {{ 'CongratulationsYourReqReturned' | translate }}
              <!-- <span>{{ "CongratulationsRequest" | translate }} </span> -->
              {{ 'CongratulationsIsSuccessfullySubmitted' | translate }}
              <span
                *ngIf="stateObject.statusNameAr"
                [innerText]="'CongratulationsRequestStatus' | translate"
              ></span>
              <span class="bold" *ngIf="stateObject.statusNameAr">
                {{ stateObject.statusNameAr }}
              </span>
              <br />{{ 'CongratulationsAt' | translate }}
              {{ currentDate | date : 'short' }}
            </p>
          </ng-container>
        </ng-container>

        <button
          *ngIf="workflowService.showSubmitNewRequestButton"
          class="btn-902 cursor-pointer"
          style="display: none"
          (click)="submitAnotherRequest()"
        >
          {{ 'SUBMITANOTHERREQUEST' | translate }}</button
        ><br /><br />
        <button
          class="btn-902 cursor-pointer"
          (click)="gotoPage('home?dashboard=true')"
        >
          {{ 'GOBACKTODASHBOARD' | translate }}
        </button>
      </center>
    </div>
  </div>
</div>
