import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as getNotificationResponse from "../../models/customer/RegisteredUserNotification";
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {RegisteredUserNotification} from '../../models/customer/RegisteredUserNotification';
import {WorkFlowTransactionCustomerEmpChatMessages} from '../../models/common/ServiceMainTransactionData';

@Injectable({
  providedIn: 'root'
})
export class GetNotificationMessagesService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'CustomerEmpChatMessages/' + 'GetCustomerNotificationMessages';
  private REST_API_SERVER_COUNT = environment.DigitalServicesHostURL + 'CustomerEmpChatMessages/' + 'GetCustomerNotificationMessagesCount';
  private REST_API_SERVER_DISMISS = environment.DigitalServicesHostURL + 'CustomerEmpChatMessages/' + 'DismissNotificationMessage';

  constructor(private httpClient: HttpClient) {
  }

  public getMessages(): Observable<ResponseModel<WorkFlowTransactionCustomerEmpChatMessages[]>> {

    return this.httpClient.get<ResponseModel<WorkFlowTransactionCustomerEmpChatMessages[]>>(this.REST_API_SERVER)
                          .pipe(catchError(this.handleError));
  }
  public getMessagesCount(): Observable<ResponseModel<number>> {
    return this.httpClient.get<ResponseModel<number>>(this.REST_API_SERVER_COUNT)
                          .pipe(catchError(this.handleError));
  }
  public dismissNotificationMessage(messageId: number): Observable<ResponseModel<WorkFlowTransactionCustomerEmpChatMessages[]>> {
    let params: HttpParams = new HttpParams();
    params = params.append('messageId', messageId);
    return this.httpClient.put<ResponseModel<WorkFlowTransactionCustomerEmpChatMessages[]>>(this.REST_API_SERVER_DISMISS, null, {params})
                          .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetNotificationService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetNotificationService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }


}
