import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-captions',
  templateUrl: './steps-captions.component.html',
})
export class StepsCaptionsComponent implements OnInit {
  constructor() {}

  @Input()
  nextKey: string = 'trAttachmentsPage';

  @Input()
  backKey: string = 'trApplicantInformation';

  @Input()
  showNextCaption = true;

  ngOnInit(): void {}
}
