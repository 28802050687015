<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger':
        ngControl.touched && !ngControl.valid && !ngControl.disabled,
      'has-success': ngControl.touched && ngControl.valid
    }"
  >
    <label
      *ngIf="!noLabel"
      class="form-control-label"
      [for]="identifier"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      >{{ labelText }}</label
    >
    <textarea
      [id]="identifier"
      [name]="name"
      class="form-control"
      [ngClass]="
        hasRequiredError() ||
        hasPatternError ||
        hasMinLengthError ||
        hasMaxLengthError
          ? 'is-invalid'
          : touched
          ? 'is-valid'
          : ''
      "
      type="text"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      #control="ngModel"
      [required]="required == 'true' || required == '' ? '' : undefined"
      [rows]="rows"
      [cols]="cols"
      [readonly]="readonly"
      (blur)="onTouched()"
      [disabled]="disabled"
      [maxlength]="_maxLength"
    ></textarea>
    <small
      [id]="identifier + 'Help'"
      *ngIf="helpText"
      class="form-text text-muted"
    >
      {{ helpText }}</small
    >
    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMinLengthError">
      {{ minLengthErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasMaxLengthError">
      {{ maxLengthErrorMessage }}
    </div>

    <div class="invalid-feedback" *ngIf="hasPatternError">
      {{ patternErrorMessage }}
    </div>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <label
      *ngIf="!noLabel"
      class="col-sm-3 form-control-label"
      [for]="identifier"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      >{{ labelText }}</label
    >

    <div class="col-sm-9">
      <textarea
        [id]="identifier"
        [name]="name"
        class="form-control"
        [ngClass]="
          hasRequiredError() ||
          hasPatternError ||
          hasMinLengthError ||
          hasMaxLengthError
            ? 'is-invalid'
            : touched
            ? 'is-valid'
            : ''
        "
        type="text"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        #control="ngModel"
        [required]="required == 'true' || required == '' ? '' : undefined"
        [rows]="rows"
        [cols]="cols"
        [readonly]="readonly"
        (blur)="onTouched()"
        [disabled]="disabled"
        [maxlength]="_maxLength"
      ></textarea>
      <small
        [id]="identifier + 'Help'"
        *ngIf="helpText"
        class="form-text text-muted"
      >
        {{ helpText }}</small
      >
      <div class="invalid-feedback" *ngIf="hasRequiredError()">
        {{ requiredMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMinLengthError">
        {{ minLengthErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasMaxLengthError">
        {{ maxLengthErrorMessage }}
      </div>

      <div class="invalid-feedback" *ngIf="hasPatternError">
        {{ patternErrorMessage }}
      </div>
    </div>
  </div>
</ng-template>
