<form [formGroup]="form">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="custom-control custom-checkbox">
        <input
          id="acceptTerms"
          name="acceptTerms"
          type="checkbox"
          class="custom-control-input"
          formControlName="acceptTerms"
          [ngClass]="hasErrors ? 'is-invalid' : touched ? 'is-valid' : ''"
        />
        <label class="custom-control-label" for="acceptTerms">
          <span [innerText]="'trAcceptTermsFld.labelText' | translate"></span>
          <a
            class="gold"
            data-toggle="modal"
            data-target="#notificationMessageModalDialog"
            [innerText]="'trTermsAndConditions' | translate"
          ></a>
        </label>
        <span class="custom-checkbox-required-start" [innerText]="'*'"></span>
        <div
          class="invalid-feedback"
          *ngIf="hasErrors"
          [innerText]="('trAcceptTermsFld' | translate).requiredMessage"
        ></div>
      </div>
    </div>
  </div>
</form>

<div
  class="modal fade"
  id="notificationMessageModalDialog"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header direction_rtl alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ "ServiceTermsAndConditions" | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <img src="assets/Images/Icons/cancel-24px%20(gold).png" alt="" />
        </button>
      </div>
      <div class="modal-body p-4">
        <app-rendering-html
          [dynamicHtml]="
            isArabic
              ? metaData.formattedTermsAndConditionsAr
              : metaData.formattedTermsAndConditionsEn
          "
        ></app-rendering-html>
      </div>
    </div>
  </div>
</div>
