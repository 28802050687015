import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CancelRequestService } from 'src/app/core/api-services/common/cancel-request.service';
import { GetRequestStatusesService } from 'src/app/core/api-services/customer/get-request-statuses.service';
import { SearchMyRequestsService } from 'src/app/core/api-services/customer/search-my-requests.service';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { Lookup } from 'src/app/core/models/common/Lookup';
import { WorkflowStepStatusCodesEnum } from 'src/app/core/models/common/ServiceMainTransactionData';
import {
  CertificateData,
  RegisterUserRequestsData,
} from 'src/app/core/models/customer/RegisterUserRequestsData';
import { RequestStatuses } from 'src/app/core/models/customer/RequestStatuses';
import {
  SearchDashboardRequestsParams,
  SearchLookups,
} from 'src/app/core/models/customer/SearchDashboardRequestsParams';
import { Pagination } from 'src/app/core/models/shared/CommonResponseDTO';
import {
  AttachmentsNamesDto,
  EsCommentDto,
} from 'src/app/core/models/shared/EsCommentDto';
import { LookupDto } from 'src/app/core/models/shared/LookupDto';
import { EsRequestStatusCodesEnum } from 'src/app/core/models/shared/MainWorkflowStepStatusCodesEnum';
import {
  EsAttachmentsNames,
  EsComment,
} from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { UtilService } from 'src/app/core/utilities/util.service';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import Swal, { SweetAlertResult } from 'sweetalert2';

@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
@Component({
  selector: 'app-dashboard-requests',
  templateUrl: './dashboard-requests.component.html',
})
export class DashboardRequestsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  totalPage: number;
  page = 1;
  pageSize = 5;
  filterObject: SearchDashboardRequestsParams = {
    pagination: {
      pageNo: this.page,
      pageSize: this.pageSize,
    },
  };
  observableSubscriptions: Subscription[] = [];
  requestData: RegisterUserRequestsData[] = [];
  count: number;
  currentSelectedServiceRequestTransactionId: number;
  currentSelectedStatusCode: WorkflowStepStatusCodesEnum;
  requestStatusList: RequestStatuses[] = [];
  currentSelectedCertificateList: CertificateData[] = [];

  esComments: EsComment[];
  esCommentsProcedureInstanceId: number;

  showAdvancedSearch: boolean = false;
  lookups: SearchLookups;

  constructor(
    translateService: TranslateService,
    private searchRequestService: SearchMyRequestsService,
    private router: Router,
    public utilService: UtilService,
    private cancelRequestService: CancelRequestService,
    private getRequestStatusesService: GetRequestStatusesService,
    private esCommonService: EsCommonService,
    private toastr: ToastrService
  ) {
    super(translateService);

    this.translateService.onLangChange.subscribe((langChangeEvent) => {
      if (this.lookups) {
        this.lookups.services = [...this.lookups.services];
        this.lookups.statuses = [...this.lookups.statuses];
        this.lookups.ports = [...this.lookups.ports];
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.getSearchLookups();
    this.retrieveRequest();
  }

  public onTableDataChange(event: any) {
    this.page = event;
    this.retrieveRequest();
  }

  public onClickCommunicationMessage(
    workflowStatusCode: WorkflowStepStatusCodesEnum,
    serviceRequestTransactionId: number,
    isFromMessages = false
  ) {
    if (this.utilService.isEmployeeAction()) {
      this.toastr
        .warning(
          this.translateService.instant(
            'EmployeeCannotEditNotificationMessagesForCustomer'
          )
        )
        .onShown.subscribe((a) => {});
      return;
    }

    // if (isFromMessages) this.hideMessage();
    this.currentSelectedServiceRequestTransactionId = null;
    setTimeout(() => {
      this.currentSelectedServiceRequestTransactionId =
        serviceRequestTransactionId;
      this.currentSelectedStatusCode = workflowStatusCode;
      console.log(
        'this.currentSelectedStatusCode= ' + this.currentSelectedStatusCode
      );
    }, 100);
  }

  public goToPage(name: string) {
    this.router.navigateByUrl(name);
  }

  public cancelRequest(
    workflowTransactionId: number,
    serviceRequestTransactionId: number,
    action: any,
    statusCode: string
  ) {
    if (this.utilService.isEmployeeAction()) {
      this.toastr
        .warning(
          this.translateService.instant(
            'EmployeeCaNotCancelRequestFromExternalSystem'
          )
        )
        .onShown.subscribe((a) => {});
      return;
    }

    const sweet = this.callCancelRequest();

    sweet.then((result: any) => {
      if (action.actionPageURL == 'CancelRequestEs') {
        this.cancelEServicesRequest(
          result,
          serviceRequestTransactionId,
          statusCode == EsRequestStatusCodesEnum.Draft
        );
      } else {
        this.cancelDigitalServicesRequest(result, workflowTransactionId);
      }
    });
  }

  private cancelDigitalServicesRequest(
    result: any,
    workflowTransactionId: number
  ): void {
    if (result.value) {
      this.cancelRequestService.CancelRequest(workflowTransactionId).subscribe(
        (res: any) => {
          //TODO: refresh notifications
          this.retrieveRequest();
          Swal.fire(
            this.translateService.instant('RequestCanceledSuccessfully'),
            '',
            this.translateService.instant('ConfirmBoxSuccess')
          );
        },
        (error: any) => {
          Swal.fire(
            this.translateService.instant('RequestCancellationFailed'),
            '',
            this.translateService.instant('ConfirmBoxError')
          );
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      return;
    }
  }

  public callCancelRequest(): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: this.translateService.instant(
        'ConfirmBoxAreYouSureYouWantToCancelTheRequest'
      ),
      // text: 'You will not be able to recover this file!',
      icon: this.translateService.instant('ConfirmBoxWarning'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('ConfirmBoxYesCancelIt'),
      cancelButtonText: this.translateService.instant('ConfirmBoxNoKeepIt'),
    });
  }

  private cancelEServicesRequest(
    result: any,
    serviceRequestTransactionId: number,
    isDraft: boolean
  ): void {
    if (result.value) {
      if (isDraft) {
        this.esCommonService
          .cancelDraftRequest(serviceRequestTransactionId.toString())
          .subscribe(
            (res: any) => {
              //TODO: refresh notifications
              this.retrieveRequest();
              Swal.fire(
                this.translateService.instant('RequestCanceledSuccessfully'),
                '',
                this.translateService.instant('ConfirmBoxSuccess')
              );
            },
            (error: any) => {
              Swal.fire(
                this.translateService.instant('RequestCancellationFailed'),
                '',
                this.translateService.instant('ConfirmBoxError')
              );
            }
          );
      } else {
        this.esCommonService
          .cancelRequest(serviceRequestTransactionId)
          .subscribe(
            (res: any) => {
              //TODO: refresh notifications
              this.retrieveRequest();
              Swal.fire(
                this.translateService.instant('RequestCanceledSuccessfully'),
                '',
                this.translateService.instant('ConfirmBoxSuccess')
              );
            },
            (error: any) => {
              Swal.fire(
                this.translateService.instant('RequestCancellationFailed'),
                '',
                this.translateService.instant('ConfirmBoxError')
              );
            }
          );
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      return;
    }
  }

  public async onEsClickCommunicationMessage(
    request: RegisterUserRequestsData
  ): Promise<void> {
    this.esCommentsProcedureInstanceId = request.serviceRequestTransactionId;

    const comments = await this.esCommonService
      .getRequestComments(request.serviceRequestTransactionId)
      .toPromise();

    this.esComments = this.mapCommentsDto(comments.Data);
  }

  private mapCommentsDto(comments: EsCommentDto[]): EsComment[] {
    return comments.map((c: EsCommentDto) => {
      const result = new EsComment();

      result.attachments = this.mapAttachmentNames(c.Attachments);
      result.commentBody = c.CommentBody;
      result.commentSource = c.CommentSource;
      result.commentSourceAr = c.CommentSourceAr;
      result.creationDate = c.CreationDate;
      result.procedureInstanceId = c.ProcedureInstanceId;

      return result;
    });
  }

  private mapAttachmentNames(
    attachmentsDto: AttachmentsNamesDto[]
  ): EsAttachmentsNames[] {
    return attachmentsDto?.map((a: AttachmentsNamesDto) => {
      const result = new EsAttachmentsNames();

      result.guid = a.Value;
      result.name = a.Name;

      return result;
    });
  }

  public selectCertificate(workflowItem: RegisterUserRequestsData) {
    this.currentSelectedCertificateList = workflowItem.certificateData;
    this.currentSelectedServiceRequestTransactionId =
      workflowItem.serviceRequestTransactionId;
  }

  public cancelDraftRequest(serviceRequestTransactionId: number) {
    if (this.utilService.isEmployeeAction()) {
      this.toastr
        .warning(
          this.translateService.instant(
            'EmployeeCaNotCancelRequestFromExternalSystem'
          )
        )
        .onShown.subscribe((a) => {});

      return;
    }

    Swal.fire({
      title: this.translateService.instant(
        'ConfirmBoxAreYouSureYouWantToCancelTheRequest'
      ),
      // text: 'You will not be able to recover this file!',
      icon: this.translateService.instant('ConfirmBoxWarning'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('ConfirmBoxYesCancelIt'),
      cancelButtonText: this.translateService.instant('ConfirmBoxNoKeepIt'),
    }).then((result: any) => {
      if (result.value) {
        this.cancelRequestService
          .CancelDraftRequest(serviceRequestTransactionId)
          .subscribe(
            (res: any) => {
              //TODO: refresh notifications
              this.retrieveRequest();
              Swal.fire(
                this.translateService.instant('RequestCanceledSuccessfully'),
                '',
                this.translateService.instant('ConfirmBoxSuccess')
              );
            },
            (error: any) => {
              Swal.fire(
                this.translateService.instant('RequestCancellationFailed'),
                '',
                this.translateService.instant('ConfirmBoxError')
              );
            }
          );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }

  private getSearchLookups() {
    this.searchRequestService.getSearchLookups().subscribe(
      (response) => {
        if (response) {
          this.lookups = {
            services: this.mapLookup(response.Procedures),
            statuses: this.mapLookup(response.ProcedureStatues),
            ports: this.mapLookup(response.Ports),
          };
        }
      },
      (error: any) => {}
    );
  }

  public onAdvancedSearchClick($event: any) {
    $event.preventDefault();
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  public onSearchClicked() {
    this.page = 1;
    this.retrieveRequest();
  }

  private retrieveRequest() {
    this.filterObject.pagination = {
      pageNo: this.page,
      pageSize: this.pageSize,
    };
    this.searchRequestService.searchMyRequestEs(this.filterObject).subscribe(
      (data) => {
        if (data) {
          this.requestData = data.responseData;
          this.count = data.pagination.totalItems;
          this.totalPage = Math.ceil(
            data.pagination.totalItems / this.pageSize
          );
        }
      },
      (err: any) => {}
    );
  }

  private mapLookup(lookupArray: LookupDto[]): Lookup[] {
    if (lookupArray) {
      return lookupArray.map((i) => {
        return { nameAr: i.NameAr, nameEn: i.NameEn, id: i.Id, code: i.Code };
      });
    } else {
      return [];
    }
  }

  public resetFilters($event:any){
    $event.preventDefault();

    this.filterObject = {
      pagination: {
        pageNo: this.page,
        pageSize: this.pageSize,
      },
    };

    this.retrieveRequest();
  }
}
