import { trigger, transition, style, animate } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('300ms', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('300ms', style({ opacity: 0 })),
  ]),
]);

export const scaleY = trigger('scaleY', [
  transition(':enter', [
    style({ transform: 'scaleY(0.9)' }),
    animate('300ms', style({ transform: 'scaleY(1)' })),
  ]),
]);

export const rotateShake = trigger('rotateShake', [
  transition(':enter', [
    style({ transform: 'rotate(0deg)' }),
    animate('50ms', style({ transform: 'rotate(0deg)' })),
    animate('50ms', style({ transform: 'rotate(1deg)' })),
    animate('50ms', style({ transform: 'rotate(-1deg)' })),
    animate('50ms', style({ transform: 'rotate(0deg)' })),
  ]),
]);
