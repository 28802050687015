<div class="mesgs reason_messages">
  <div class="msg_history">
    <div class="incoming_msg" *ngFor="let message of sendBackMessages">
      <ng-container *ngIf="message.employeeUserID != null && (message.transitionActionData?.actionCode == WorkflowActionsCodesEnum.ReturnToCustomer || message.transitionActionData?.actionCode == WorkflowActionsCodesEnum.RejectRequest)">
        <div class="incoming_msg_img"> <img src="assets/Images/Icons/back-office.png" alt="sunil"> </div>
        <div class="received_msg">
          <div class="received_withd_msg">
            <p>{{message.message}}</p>
            <span class="time_date">{{message.createdDate | date:'medium'}}</span></div>
        </div>
        <div class="mx-3" style="text-align: start;" *ngIf="message.fileAttachment != null">
          <a class="cursor-pointer" (click)="utilService.viewFile(message.fileAttachment.filePath, message.fileAttachment.fileMimeType)">
            <span>{{message.fileAttachment.fileName}}</span>
          </a>
        </div>
      </ng-container>
    </div>
<!--    <div class="outgoing_msg">-->
<!--      <div class="sent_msg">-->
<!--        <p>Test which is a new approach to have all-->
<!--          solutions</p>-->
<!--        <span class="time_date"> 11:01 AM    |    June 9</span> </div>-->
<!--    </div>-->
  </div>
</div>
