import { Component, Injector, Input, Inject } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import {
  IDateSettings,
  InputSettingsService,
} from '../../../input-settings.service';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';
import {
  lessThanOrEqualDate,
  moreThanOrEqualDate,
} from '../../../validators/more-than-date.validator';

@Component({
  selector: 'bs-ng-date',
  templateUrl: './bs-ng-date.component.html',
  providers: [MakeProvider(BsNgDateComponent)],
})
export class BsNgDateComponent extends BsNgInputBaseComponent {
  @Input() type?: 'date' | 'time' | 'datetime-local' = 'date';

  @Input()
  public min: string;

  public settings: IDateSettings;

  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);

    this.setDefaultSettings(defaultSettings);
  }

  private setDefaultSettings(defaultSettings: InputSettingsService) {
    this.min = defaultSettings.date.min;
    this.settings = defaultSettings.date;
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  ngAfterViewInit() {
    this.assignMinDateValidation();
  }

  private assignMinDateValidation() {
    if (this.settings && this.settings.minValidator) {
      this.ngControl.control.addValidators(
        moreThanOrEqualDate(this.settings.minValidator, {
          key: this.settings.minValidatorLabelKey,
          keySource: 'Pure',
        })
      );
      this.ngControl.control.updateValueAndValidity();
    }
    if (this.settings && this.settings.maxValidator) {
      this.ngControl.control.addValidators(
        lessThanOrEqualDate(this.settings.maxValidator, {
          key: this.settings.maxValidatorLabelKey,
          keySource: 'Pure',
        })
      );
      this.ngControl.control.updateValueAndValidity();
    }
  }
}
