<div class="main-content-speaker-id">
  <div
    class="main-content using-bootstrap next-design"
    data-toggle="font-size-changeable"
  >
    <div class="container more-relative">
      <div id="snav"></div>
    </div>
    <!-- <div class="most-used-services-container">
      <div class="container">
        <div class="most-used-services">
          <div class="row logo_con">
            <div class="col-lg-1 col-md-2 col-sm-2 col-xs-12 text-right">
              <span class="my_logo_area">
                <img src="assets/Images/company-logo.jpg"
              /></span>
            </div>
            <div class="col-md-10 col-sm-10 col-xs-12 text-left">
              <label *ngIf="employeeObject" class="label-color"
                >{{ 'HomeEmployeeName' | translate }}
                {{
                  isEnglish ? employeeObject?.nameEn : employeeObject?.nameAr
                }}</label
              >

              <span class="heading-dashboard d-block">
                {{ 'Welcome' | translate }}
                <strong
                  [innerText]="
                    isEnglish
                      ? getUserProfile()?.nameEn
                      : getUserProfile()?.nameAr
                  "
                ></strong>
              </span>
              <label *ngIf="noOfDaysWithoutRequest" class="text-muted">
                {{
                  'YouHaveMadeNoRequestsInThePastDays'
                    | translate
                      : { noOfDaysWithoutRequest: noOfDaysWithoutRequest }
                }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container-1">
      <div class="container p-3">
        <dashboard-header
          (onOpenDashboardStatistics)="openDashboardStatistics()"
          (onOpenImportantLinks)="openImportantLinks()"
        ></dashboard-header>
      </div>

      <div
        class="container pb-3"
        *ngIf="registeredUserType == RegisteredUserTypesEnum.Person"
      >
        <main-individual-package
          [currentSelectedPackage]="currentSelectedPackage"
          [currentSelectedPackageId]="currentSelectedPackageId"
          [dashBoardPackages]="dashBoardPackages"
          [showDetailClicked]="showDetailClicked"
        ></main-individual-package>
      </div>

      <div
        class="container pb-3"
        *ngIf="registeredUserType == RegisteredUserTypesEnum.Authority"
      >
        <main-company-package
          [currentSelectedPackage]="currentSelectedPackage"
          [currentSelectedPackageId]="currentSelectedPackageId"
          [dashBoardPackages]="dashBoardPackages"
          [showDetailClicked]="showDetailClicked"
        ></main-company-package>
      </div>
    </div>

    <dashboard-statistics
      *ngIf="showDashboardStatistics"
      [closeDashboardCallback]="closeDashboardStatistics"
    ></dashboard-statistics>

    <dashboard-important-links 
      *ngIf="showImportantLinks"
      [closeImportantLinksCallback]="closeImportantLinks">
    </dashboard-important-links>

    <div class="container-card">
      <div class="container">
        <div class="col">
          <div class="row" *ngIf="noneConfirmedRequests">
            <div class="col-12">
              <app-bs-alert
                [alert]="confirmationRequestAlert"
                [importance]="Secondary"
              >
                <div body class="ds-btn-container">
                  <a
                    class="ds-btn ds-btn-primary mt-2"
                    [innerText]="'trConfirm' | translate"
                    [href]="noneConfirmedRequests.url"
                  ></a>
                  <a
                    class="ds-btn ds-btn-secondary mt-2"
                    [innerText]="'trIgnoreConfirmation' | translate"
                    (click)="confirmOnCallRequestPopUp()"
                  ></a>
                </div>
              </app-bs-alert>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-card">
      <div class="container">
        <app-dashboard-requests></app-dashboard-requests>
      </div>
    </div>
  </div>
</div>
