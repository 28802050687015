import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AddRequestCommentsService} from '../../core/api-services/common/comments/add-request-comments.service';
import * as dashBoardDetails from '../../core/models/customer/DashBoardDetails';
import {UtilService} from '../../core/utilities/util.service';
import {ToastrService} from 'ngx-toastr';
import { GetRequestCommentsService } from 'src/app/core/api-services/common/comments/get-request-comments.service';
import * as getRequestCommentsResponse from "../../core/models/common/comments/GetRequestCommentsResponse";
import { TranslateService } from '@ngx-translate/core';
//import {FileUploader} from 'ng2-file-upload';

import {
  WorkflowActionsCodesEnum,
  WorkflowStepStatusCodesEnum,
  WorkFlowTransactionCustomerEmpChatMessages
} from '../../core/models/common/ServiceMainTransactionData';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {FormArray} from '@angular/forms';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-communication-message',
  templateUrl: './communication-message.component.html',
  styleUrls: ['./communication-message.component.scss']
})
export class CommunicationMessageComponent implements OnInit, OnChanges {
  @Input() serviceRequestTransactionId: number;
  @Input() statusCode: WorkflowStepStatusCodesEnum;
  commentMessageList:  WorkFlowTransactionCustomerEmpChatMessages[] = [];
  isSelectedFileReady = false;
  selectedFileUrl="";
  newComment: string;
  isHiddenSendMessage : boolean = false;
  fileToUpload: File;
  userProfileName : string;
  fileExtensionsList: string[] = ['tif','tiff','pdf','doc','docx','ppt','pptx','xls','xlsx'];
  constructor(private addRequestCommentsService: AddRequestCommentsService,
              public utilService: UtilService,
              private toastr: ToastrService,
              private getRequestMessagesService: GetRequestCommentsService,
              private translateService: TranslateService) {
    this.userProfileName = this.utilService.getUserProfile().nameEn;

  }

  ngOnInit(): void {
    this.hideSendMessage();

  }

  addComment(): void {
    if (this.utilService.isEmployeeAction()) {
      this.toastr.warning(this.translateService.instant("EmployeeCannotEditNotificationMessagesForCustomer")).onShown.subscribe(a => {
        });
      return;
    }

    if(!this.newComment && this.newComment.trim() != '') {
      return;
    }

    this.addRequestCommentsService.addRequestComments(this.serviceRequestTransactionId, this.newComment, this.fileToUpload, null).subscribe((res)=>{
      this.newComment = "";
      this.getRequestMessages();
      this.removeSelectedImage();
      this.toastr.info(this.translateService.instant('CommunicationMessageYourMessageHasBeenSentSuccessfully'))

    });

  }
  removeSelectedImage(){
    this.isSelectedFileReady =false;
    this.fileToUpload = null;
  }
  handleFileInput(event: any) {
    this.fileToUpload = event.files.item(0);
    let extension = this.fileToUpload.name.replace(/^.*\./, '');
    if (this.fileExtensionsList.indexOf(extension) < 0) {
      this.selectedFileUrl = URL.createObjectURL(this.fileToUpload);
    } else {
      this.selectedFileUrl = `${environment.fileServerURL}/icons/${extension}.png`;
    }
    this.isSelectedFileReady =true;
  }
  showError(errorMessage: string): void {
    this.toastr.error('', errorMessage);
  }
  // onSelectFile(event: NgxFileDropEntry[], attachmentId: number, attachmentIndex: number): void {
  //   for (const droppedFile of event) {
  //     if (droppedFile.fileEntry.isFile) {
  //       const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //       fileEntry.file((file: File) => {
  //         const validFileExtensions = ['jpg', 'jpeg', 'gif' , 'png', 'pdf', 'bmp', 'doc', 'docx', 'xls', 'xlsx'];
  //         const fileName = file.name;
  //         const lastDotInfileName = fileName.lastIndexOf('.');
  //         const FileExtension: string = fileName.substring(lastDotInfileName + 1);
  //
  //         if (!validFileExtensions.includes(FileExtension.toLowerCase())) {
  //           this.showError('Only Images ( JPG | PNG | gif | png ) and PDF,doc,docx,xls,xlsx are allowed');
  //           return;
  //         }
  //         const sizeInBytes: number = file.size;
  //         console.log('sizeInBytes= ' + sizeInBytes + ' fileName =' + fileName);
  //         // Maximum 3 MB = 3145728 Bytes
  //         if (sizeInBytes > 3145728) {
  //           this.showError(this.translateService.instant('MaximumAllowedSizeIsThreeMB'));
  //           return;
  //         }
  //         const reader = new FileReader();
  //         reader.onload = (event2) => { // called once readAsDataURL is completed
  //           const blob = new Blob( [file] , { type: file.type });
  //           const url = window.URL.createObjectURL(blob);
  //           this.postAttachmentService.submitAttachmentFile(file).
  //           then(value => {
  //             // store the attachment blob besides the attachmentId, url ,storedAttachmentDBId and filename in the form to avoid losing it while moving between workflow steps
  //             const attachmentObject: AttachmentFormObject = {
  //               thumbPath: value.responseData.thumbPath,
  //               attachmentId, url,
  //               fileType: value.responseData.fileMimeType,
  //               filePath: value.responseData.filePath,
  //               filename: file.name,
  //               employeeFeedbackMessage: null,
  //               blob,
  //               fileId:  value.responseData.fileId,
  //               storedFileAttachmentDBId: value.responseData.id
  //             };
  //             (this.form.get('Attachments') as FormArray).controls[attachmentIndex].setValue(attachmentObject);
  //           });
  //
  //         };
  //         reader.readAsDataURL(file);
  //
  //       });
  //
  //     }
  //   }
  // }

  getRequestMessages() {
    this.commentMessageList = [];
    this.getRequestMessagesService.getRequestComments(this.serviceRequestTransactionId).subscribe(
      (res)=>{
        if(res && res.responseData.length > 0) {
          this.commentMessageList = res.responseData;//.filter(comment=>comment.IsSendBackComment != true);
        }
      }
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.newComment = "";
      if (changes['serviceRequestTransactionId'].currentValue != null) {
        this.getRequestMessages();
        this.hideSendMessage();
      }
  }

  hideSendMessage() {

    if ( this.statusCode == WorkflowStepStatusCodesEnum.Completed ||
         this.statusCode == WorkflowStepStatusCodesEnum.Cancelled ||
         this.statusCode == WorkflowStepStatusCodesEnum.Rejected ||
         this.statusCode == WorkflowStepStatusCodesEnum.InitialPayment) {
        this.isHiddenSendMessage = true;
    } else {
      this.isHiddenSendMessage = false;
    }
  }


}
