import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-form-validation-indicator',
  templateUrl: './form-validation-indicator.component.html',
})
export class FormValidationIndicatorComponent implements OnInit {
  @Input()
  formGroup: FormGroup;

  @ViewChild('mainEl')
  mainEl: ElementRef;

  detector: Observable<any>;

  constructor() {}

  ngOnInit(): void {
    this.detector = this.formGroup.valueChanges.pipe();
    this.detector.subscribe(() => this.formValueChanged());
  }

  formValueChanged(): void {
    if (this.mainEl) {
      this.mainEl.nativeElement.classList.add('highlight');
      setTimeout(() => {
        this.mainEl.nativeElement.classList.remove('highlight');
      }, 500);
    }
  }

  hasErrors() {
    return this.formGroup.valid == false;
  }

  isSuccess() {
    return this.formGroup.touched && this.formGroup.valid;
  }

  isFailed() {
    return this.formGroup.touched && this.formGroup.valid == false;
  }
}
