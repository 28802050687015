import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-no-items',
  templateUrl: './list-no-items.component.html',
})
export class ListNoItemsComponent implements OnInit {
  @Input()
  key: string;

  constructor() {}

  ngOnInit(): void {}
}
