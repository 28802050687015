import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-section-indexer',
  templateUrl: './section-indexer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionIndexerComponent implements OnInit {
  windowScroll: number;
  constructor(
    @Inject(DOCUMENT)
    private _document: Document,
    private languageService: LanguageService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    this.subscribeToLanguageChange();
  }

  allLabels: any = [];
  sections: Section[] = [];

  windowScrollY: number;
  sticky: boolean = false;

  sectionsStartPosition() {
    const firstStartPosition = this.allLabels[0]?.getBoundingClientRect().y;
    return firstStartPosition;
  }

  sectionsEndPosition() {
    return this.allLabels[this.allLabels.length - 1].getBoundingClientRect().y;
  }

  bottomPositionMargin = 30;

  top: 0;
  stickyHeaderHeight = 50;
  activeRangeAllowance = 150;
  scrollIndent = 25;

  private subscribeToLanguageChange() {
    this.translateService.onLangChange.subscribe(() => {
      setTimeout(() => {
        this.loadSections();
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadSections();
    });

    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked() {
    this.setSectionPositions();
  }

  setSectionPositions() {
    this.allLabels = this._document.querySelectorAll(
      '.form-section:not([hidden])'
    );
  }

  loadSections() {
    var labels = this._document.querySelectorAll('.col-form-label');

    ///Clear the array without changing the array reference, because angular is observing it ;)
    while (this.sections.length) {
      this.sections.pop();
    }

    labels.forEach((label, labelIndex) => {
      this.sections.push({
        labelElement: label,
        title: label.innerHTML,
        active: labelIndex == 0,
      });
    });
  }

  private isIActive(section: any) {
    return (
      section.labelElement.getBoundingClientRect().top <=
        this.stickyHeaderHeight + this.activeRangeAllowance ||
      section.labelElement.getBoundingClientRect().top <=
        this.stickyHeaderHeight - this.activeRangeAllowance
    );
  }

  scrollTo(section: any) {
    const windowOffSet = window.pageYOffset;
    this.windowScrollY = window.pageXOffset;
    window.scrollTo({
      top:
        section.labelElement.getBoundingClientRect().top +
        windowOffSet -
        this.stickyHeaderHeight -
        this.scrollIndent,
    });
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    this.windowScroll = window.pageYOffset;
    if (
      this.sectionsStartPosition() <= 0 &&
      this.sectionsEndPosition() - this.bottomPositionMargin >= 0
    ) {
      this.sticky = true;
      this.sections.forEach((item) => {
        let active = this.isIActive(item);
        if (active) {
          this.sections.forEach((section) => (section.active = false));
          item.active = active;
        }
      });
    } else {
      this.sticky = false;
    }
  }

  ngDoCheck(){//to handle conditionally added or removed sections
    var labels = this._document.querySelectorAll('.col-form-label');
    if(labels.length != this.sections.length)
      setTimeout(() => {
        this.ngAfterViewInit();
      });
  }
}

class Section {
  active: boolean = false;
  labelElement: Element;
  title: string;
}
