import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  RegisteredUser,
  RegisteredUserTypesEnum,
} from 'src/app/core/models/profile/RegisteredUser';
import { EsApplicant } from 'src/app/core/models/shared/EsApplicant';
import { ServiceRequestTransactionEsData } from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { UtilService } from 'src/app/core/utilities/util.service';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-service-request-details',
  templateUrl: './es-service-request-details.component.html',
})
export class EsRequestDetailsComponent extends BaseComponent implements OnInit {
  @Input()
  requestDetails?: ServiceRequestTransactionEsData = null;

  @Input()
  applicant?: EsApplicant = null;

  profile?: RegisteredUser;

  public get isIndividual(): boolean {
    return this.profile?.registeredUserType == RegisteredUserTypesEnum.Person;
  }

  public get isAuthority(): boolean {
    return (
      this.profile?.registeredUserType == RegisteredUserTypesEnum.Authority
    );
  }

  public get participantKey(): string {
    return this.isIndividual
      ? 'trIndividualName'
      : this.isAuthority
      ? 'trCompanyName'
      : 'trAuthorityName';
  }

  constructor(
    translateService: TranslateService,
    private utilService: UtilService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.profile = this.utilService.getUserProfile();
  }
}
