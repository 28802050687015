import { Component, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base/BaseComponent';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
})
export class StickyMenuComponent extends BaseComponent {
  constructor(translateService: TranslateService) {
    super(translateService);
  }

  @Output()
  public action = new EventEmitter<StickyMenuActionDataHandler>();

  public showCustomerPulseModal() {
    this.emitAction(StickyMenuActionEnum.ShowCustomerPulse);
  }

  public emitAction(action: StickyMenuActionEnum) {
    this.action.emit({
      type: action,
    });
  }
}

export enum StickyMenuActionEnum {
  ShowCustomerPulse,
}

export interface StickyMenuActionDataHandler {
  type: StickyMenuActionEnum;
}
