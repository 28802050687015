<div class="outstading_payment">
	<div class="container">
		
		<!-- ====================================================== -->
		<!-- ================= Search Fields ====================== -->
		<!-- ====================================================== -->

		<div class="row">
			<div class="col-lg-12">
				<div class="payment_search_fields">
					<div class="row">
						<div class="col-lg-5 col-md-5">
							<div class="form-group">
								<input type="text" class="form-control input-lg" placeholder="Request Reference Number">
							</div>
						</div>
						<div class="col-lg-3 col-md-4">
							<div class="form-group">
								<select name="" id="" class="form-control input-lg">
									<option value="">Service Name</option>
									<option value="">One</option>
									<option value="">Two</option>
									<option value="">Three</option>
									<option value="">Four</option>
									<option value="">Five</option>
								</select>
							</div>
						</div>
						<div class="col-lg-3 col-md-3">
							<button class="search search-btn">
								<img src="assets/Images/Icons/round-search-24px (white).png">Search
							</button>
						</div>
					</div>
					
				</div>
			</div>
		</div>

		<!-- ====================================================== -->
		<!-- ================ Search Results Cards ================ -->
		<!-- ====================================================== -->

		<div class="row">
			<div class="col-lg-12">
				
				<!-- ============== Signle Card ============== -->

				<div class="card-3 pt-6  mb-3">
					<div class="row">
						<div class="col-lg-1">
							<div class="outstanding_payment_chkbox">
								<div class=" page__custom-settings ">
		                            <div class="page__toggle">
		                              	<label class="toggle">
		                                	<input class="toggle__input" type="checkbox">
		                                	<span class="toggle__label">
		                                  		<span class="toggle__text"></span>
		                                	</span>
		                              	</label>
		                            </div>
		                         </div>
							</div>
						</div>
						<div class="col-lg-11">
							<div class="row">
								<div class="col-sm-12 direction_rtl">
									<p class="bold alignment_right cursor-pointer">
										<a>AWD-19042021-474531 1</a>
									</p>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 direction_rtl">
									<p class="color-1 alignment_right">19/04/2021 </p>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-6 col-md-5  direction_rtl">
									<small class="alignment_right c-primary">Practicing Animal Activities License</small>
								</div>
							
								<div class="col-lg-3 col-md-4 cente_alignment">
									<p class="status-code bold position">Pending for Payment</p>
								</div>
								<div class="col-lg-3 col-md-3 cente_alignment">
									<p class="status-code bold position">1000</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- ============== Signle Card ============== -->

				<div class="card-3 pt-6  mb-3">
					<div class="row">
						<div class="col-lg-1">
							<div class="outstanding_payment_chkbox">
								<div class=" page__custom-settings ">
		                            <div class="page__toggle">
		                              	<label class="toggle">
		                                	<input class="toggle__input" type="checkbox">
		                                	<span class="toggle__label">
		                                  		<span class="toggle__text"></span>
		                                	</span>
		                              	</label>
		                            </div>
		                         </div>
							</div>
						</div>
						<div class="col-lg-11">
							<div class="row">
								<div class="col-sm-12 direction_rtl">
									<p class="bold alignment_right cursor-pointer">
										<a>AWD-19042021-474531 1</a>
									</p>
								</div>
							</div>
							<div class
							="row">
								<div class="col-sm-6 direction_rtl">
									<p class="color-1 alignment_right">19/04/2021 </p>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-6 col-md-5  direction_rtl">
									<small class="alignment_right c-primary">Practicing Animal Activities License</small>
								</div>
							
								<div class="col-lg-3 col-md-4 cente_alignment">
									<p class="status-code bold position">Pending for Payment</p>
								</div>
								<div class="col-lg-3 col-md-3 cente_alignment">
									<p class="status-code bold position">1000</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- ============== Signle Card ============== -->

				<div class="card-3 pt-6  mb-3">
					<div class="row">
						<div class="col-lg-1">
							<div class="outstanding_payment_chkbox">
								<div class=" page__custom-settings ">
		                            <div class="page__toggle">
		                              	<label class="toggle">
		                                	<input class="toggle__input" type="checkbox">
		                                	<span class="toggle__label">
		                                  		<span class="toggle__text"></span>
		                                	</span>
		                              	</label>
		                            </div>
		                         </div>
							</div>
						</div>
						<div class="col-lg-11">
							<div class="row">
								<div class="col-sm-12 direction_rtl">
									<p class="bold alignment_right cursor-pointer">
										<a>AWD-19042021-474531 1</a>
									</p>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 direction_rtl">
									<p class="color-1 alignment_right">19/04/2021 </p>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-6 col-md-5  direction_rtl">
									<small class="alignment_right c-primary">Practicing Animal Activities License</small>
								</div>
							
								<div class="col-lg-3 col-md-4 cente_alignment">
									<p class="status-code bold position">Pending for Payment</p>
								</div>
								<div class="col-lg-3 col-md-3 cente_alignment">
									<p class="status-code bold position">1000</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- ============== Signle Card ============== -->

				<div class="card-3 pt-6  mb-3">
					<div class="row">
						<div class="col-lg-1">
							<div class="outstanding_payment_chkbox">
								<div class=" page__custom-settings ">
		                            <div class="page__toggle">
		                              	<label class="toggle">
		                                	<input class="toggle__input" type="checkbox">
		                                	<span class="toggle__label">
		                                  		<span class="toggle__text"></span>
		                                	</span>
		                              	</label>
		                            </div>
		                         </div>
							</div>
						</div>
						<div class="col-lg-11">
							<div class="row">
								<div class="col-sm-12 direction_rtl">
									<p class="bold alignment_right cursor-pointer">
										<a>AWD-19042021-474531 1</a>
									</p>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-6 direction_rtl">
									<p class="color-1 alignment_right">19/04/2021 </p>
								</div>
							</div>
							<div class="row">
								<div class="col-lg-6 col-md-5  direction_rtl">
									<small class="alignment_right c-primary">Practicing Animal Activities License</small>
								</div>
							
								<div class="col-lg-3 col-md-4 cente_alignment">
									<p class="status-code bold position">Pending for Payment</p>
								</div>
								<div class="col-lg-3 col-md-3 cente_alignment">
									<p class="status-code bold position">1000</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- ====================================================== -->
		<!-- ================ Total Payment Box =================== -->
		<!-- ====================================================== -->

		<div class="row">
			<div class="col-lg-12">
				<div class="total_payment">
					<div class="text">Total Payment</div>
					<div class="number">4000</div>
				</div>
			</div>
		</div>

		<!-- ====================================================== -->
		<!-- ================== Payment Buttons  ================== -->
		<!-- ====================================================== -->

		<div class="row">
			<div class="col-lg-3 col-md-6">
				<div class="payment_button">
					<button>Pay Now</button>
				</div>
			</div>
			<div class="col-lg-3 col-md-6">
				<div class="payment_button">
					<a href="">Go to dashboard</a>
				</div>
			</div>
		</div>
	</div>
</div>