<a
  *ngIf="serviceDetails == null"
  type="button"
  (click)="loadRequestDetails()"
  class="ds-btn-link text-decoration-none"
>
  <i class="fi-arrow-thin-down fi-large mr-2"></i>
  <span [innerText]="'trRequestCardMoreDetails' | translate"></span>
</a>

<tabset *ngIf="serviceDetails">
  <tab [heading]="'trRequestCardOutputs' | translate" class="mt-4">
    <app-es-outputs
      [outputs]="serviceDetails.outputs"
      [showLegend]="false"
    ></app-es-outputs>
  </tab>
  <tab [heading]="'trRequestCardReceipts' | translate" class="mt-4">
    <app-es-receipt-details
      [receipts]="serviceDetails.receipts"
      [showLegend]="false"
    ></app-es-receipt-details>
  </tab>
  <tab [heading]="'trRequestCardAttachments' | translate" class="mt-4">
    <app-es-attachments-details
      [attachments]="serviceDetails.attachments"
      [showLegend]="false"
    ></app-es-attachments-details>
  </tab>
</tabset>
