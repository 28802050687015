    export interface CustomerApplicant {
      id: number;
      registerUserId: number;
      identityType: string;
      identityNumber: ApplicantIdentityTypeEnum;
      unifiedNumber?: any;
      emirate?: any;
      city?: any;
      nameAr: string;
      nameEn: string;
      countryId?: any;
      country?: any;
      sex?: any;
      birthDate: Date;
      birthPlace?: any;
      maritalStatusID?: any;
      specializationID?: any;
      occupationID?: any;
      address?: any;
      area?: any;
      mobile?: any;
      poBox?: any;
      email: string;
      passport?: any;
      emirateId?: any;
      religionID: number;

      eserviceApplicantId?: number;
    }

    export enum ApplicantIdentityTypeEnum
    {
      Passport= 'Passport',
      EmiratesId= 'EmiratesId',
      ID= 'ID'
    }


