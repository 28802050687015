import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsReceipt } from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-receipt-details',
  templateUrl: './es-receipt-details.component.html',
})
export class EsReceiptDetailsComponent extends BaseComponent {
  public isCollapsed: boolean[] = [];

  @Input()
  showLegend = true;

  @Input()
  receipts?: EsReceipt[] = null;

  @Input()
  initiallyCollapsed = true;

  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
