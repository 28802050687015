<app-loader></app-loader>

<div class="container mb-4 scroll-to arabic-styling next-design">
  <div class="row justify-content-center">
    <div dsCol [colOptions]="breadcrumbsColumnOptions">
      <app-breadcrumbs
        [breadcrumbsDetails]="breadcrumbsDetails$ | async"
        [serviceMetaData]="serviceMetaData$ | async"
        [serviceKey]="serviceKey"
      ></app-breadcrumbs>
    </div>
  </div>

  <div
    class="row justify-content-center title-container"
    dsCol
    [colOptions]="mainColOptions"
    *ngIf="serviceMetaData$ | async as metadata"
  >
    <es-service-title [metadata]="metadata"></es-service-title>
  </div>

  <div class="row justify-content-center" dsCol [colOptions]="mainColOptions">
    <div class="row">
      <div
        class="col-2 d-none d-sm-block"
        [class.pl-0]="isArabic"
        [class.pr-0]="isEnglish"
      >
        <app-section-indexer></app-section-indexer>
      </div>
      <div class="col-12 col-sm-10">
        <div
          class="forms-container"
          *ngIf="transaction$ | async as transaction; else loading"
        >
          <div *ngIf="serviceConfig$ | async as config; else loading">
            <app-es-license-details
              [licenseDetails]="config.licenseDetails"
              contClasses="form-section"
            ></app-es-license-details>
          </div>

          <div class="form-section">
            <app-es-service-request-details
              [requestDetails]="transaction.serviceRequestTransactionData"
              [applicant]="transaction.applicant"
            >
            </app-es-service-request-details>
          </div>

          <div class="form-section" *ngIf="isShowOutputsSection(transaction)">
            <app-es-outputs [outputs]="transaction.outputs"></app-es-outputs>
          </div>

          <router-outlet (activate)="onActivate($event)"></router-outlet>

          <div class="form-section" *ngIf="isShowFeesSection(transaction)">
            <app-es-fee-list [fees]="fees$ | async"></app-es-fee-list>
          </div>

          <div class="form-section">
            <app-es-attachments-details
              [attachments]="transaction.attachments"
            ></app-es-attachments-details>
          </div>

          <div class="form-section" *ngIf="isShowReceiptSection(transaction)">
            <app-es-receipt-details
              [receipts]="transaction.receipts"
            ></app-es-receipt-details>
          </div>

          <div class="form-section">
            <app-es-comments
              [comments]="transaction.comments"
              [requestId]="requestId"
            ></app-es-comments>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <p [innerText]="'trLoading' | translate"></p>
</ng-template>
