import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { TranslateService } from '@ngx-translate/core';
import { GetLatestSubmittedRequestsService } from '../../core/api-services/customer/get-latest-submitted-requests.service';
import { UtilService } from 'src/app/core/utilities/util.service';
import * as getLatestSubmittedRequestsResponse from '../../core/models/customer/GetLatestSubmittedRequestsResponse';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { AvailableThemes } from 'src/app/core/enums/available-themes';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { GetStatisticsForCompletedRequestsService } from 'src/app/core/api-services/customer/get-statistics-for-completed-requests.service';
import { ThemeService } from 'src/app/core/utilities/theme.service';

@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
@Component({
  selector: 'dashboard-statistics',
  templateUrl: './dashboard-statistics.component.html',
  styleUrls: ['./dashboard-statistics.component.scss'],
})
export class DashboardStatisticsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  currentLang: string;
  currentTheme: string;
  window: any;
  @Input() closeDashboardCallback: () => void;

  observableSubscriptions: Subscription[] = [];
  constructor(
    private translateService: TranslateService,
    private getLatestSubmittedRequestsService: GetLatestSubmittedRequestsService,
    private getStatisticsForCompletedRequestsService: GetStatisticsForCompletedRequestsService,
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private utilService: UtilService,
    private themeService: ThemeService
  ) {
    this.currentLang = translateService.currentLang;
    this.window = this.document.defaultView;
    translateService.onLangChange.subscribe(() => {
      this.currentLang = translateService.currentLang;
      this.currentTheme = themeService.getCurrentTheme();
    });
  }

  ngOnDestroy(): void {}

  getTheProperColorDependOnThemes(): string {
    switch (this.themeService.getCurrentTheme()) {
      case AvailableThemes.Red: {
        return '#c22c35';
        break;
      }

      case AvailableThemes.Green: {
        return '#2c7500';
        break;
      }

      case AvailableThemes.Gold: {
        return '#E2A712';
        break;
      }

      case AvailableThemes.Grey: {
        return '#4d5053';
        break;
      }

      case AvailableThemes.Dark: {
        return '#333333';
        break;
      }

      default: {
        return '#E2A712';
        break;
      }
    }
  }

  chartType = 'request';
  requestBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  requestBarChartType: ChartType = 'bar';
  requestBarChartLegend = true;
  requestBarChartPlugins: any = [];
  requestBarChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: this.getTheProperColorDependOnThemes(),
    },
  ];

  paymentBarChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            precision: 0,
            beginAtZero: true,
          },
        },
      ],
    },
  };

  paymentBarChartType: ChartType = 'bar';
  paymentBarChartLegend = true;
  paymentBarChartPlugins: any = [];
  paymentBarChartColors: Color[] = [
    {
      backgroundColor: this.getTheProperColorDependOnThemes(), //'#9A680D',
      borderColor: 'rgba(225,10,24,0.2)',
    },
  ];

  chartLabelsArValues: string[] = [];
  chartLabelsEnValues: string[] = [];

  chartLabelsAr: Label[] = [];
  chartLabelsEn: Label[] = [];

  requestBarChartDataValues: number[] = [];
  paymentBarChartDataValues: number[] = [];

  requestBarChartDataAr: ChartDataSets[] = [];
  requestBarChartDataEn: ChartDataSets[] = [];

  paymentBarChartDataAr: ChartDataSets[] = [];
  paymentBarChartDataEn: ChartDataSets[] = [];

  ngOnInit(): void {
    if (this.chartLabelsAr.length == 0) {
      this.observableSubscriptions.push(
        this.getLatestSubmittedRequestsService
          .getLatestSubmittedRequests(this.utilService.getParticipantId())
          .subscribe((response) => {
            //E-service Statistics Data.
            let eServiceStatisticsResponseData: getLatestSubmittedRequestsResponse.Data[];
            eServiceStatisticsResponseData = response.Data;

            //Get Digital Services Statistics Data.
            this.getStatisticsForCompletedRequestsService
              .getStatisticsForCompletedRequests()
              .subscribe((dsStatisticsResponse) => {
                if (dsStatisticsResponse.isSuccess) {
                  var dsStatisticsResponseData =
                    dsStatisticsResponse.responseData;

                  //### Merge Two Arrays [Digital Services && E-service] Statistics Data. ###
                  for (let dsResponseData of dsStatisticsResponseData) {
                    let month = dsResponseData.month;
                    let year = dsResponseData.year;

                    if (
                      eServiceStatisticsResponseData != null &&
                      eServiceStatisticsResponseData.length > 0 &&
                      eServiceStatisticsResponseData.find(
                        (i) => i.Month == month && i.Year == year
                      ) != null
                    ) {
                      let existObj = eServiceStatisticsResponseData.find(
                        (i) => i.Month == month && i.Year == year
                      );
                      existObj.Count = existObj.Count + dsResponseData.count;
                      existObj.Amount = existObj.Amount + dsResponseData.amount;
                    } else {
                      let newObj: getLatestSubmittedRequestsResponse.Data =
                        new getLatestSubmittedRequestsResponse.Data();
                      newObj.Amount = dsResponseData.amount;
                      newObj.Count = dsResponseData.count;
                      newObj.Month = dsResponseData.month;
                      newObj.Year = dsResponseData.year;
                      newObj.MonthEn = dsResponseData.monthEn;
                      newObj.MonthAr = dsResponseData.monthAr;
                      if (
                        eServiceStatisticsResponseData != null &&
                        eServiceStatisticsResponseData.length > 0
                      ) {
                        eServiceStatisticsResponseData.push(newObj);
                      } else {
                        eServiceStatisticsResponseData = [];
                        eServiceStatisticsResponseData.push(newObj);
                      }
                    }
                  }
                  //### End Merge Two Arrays [Digital Services && E-service] Statistics Data. ###
                }

                let monthEn: string;
                let monthAr: string;
                let year: number;
                let count: number;
                let amount: number;

                for (let object of eServiceStatisticsResponseData) {
                  amount = object.Amount;
                  count = object.Count;
                  year = object.Year;
                  monthEn = object.MonthEn;
                  monthAr = object.MonthAr;

                  this.chartLabelsArValues.push(monthAr + '-' + year);
                  this.chartLabelsEnValues.push(monthEn + '-' + year);

                  this.requestBarChartDataValues.push(count);
                  this.paymentBarChartDataValues.push(
                    amount != null ? Number(amount.toFixed(2)) : amount
                  );
                }

                this.chartLabelsAr = this.chartLabelsArValues;
                this.chartLabelsEn = this.chartLabelsEnValues;

                this.requestBarChartDataAr = [
                  {
                    data: this.requestBarChartDataValues,
                    label: 'عدد الطلبات',
                  },
                ];
                this.requestBarChartDataEn = [
                  {
                    data: this.requestBarChartDataValues,
                    label: 'Requests Count',
                  },
                ];

                this.paymentBarChartDataAr = [
                  {
                    data: this.paymentBarChartDataValues,
                    label: 'اجمالي الرسوم المدفوعة',
                  },
                ];
                this.paymentBarChartDataEn = [
                  {
                    data: this.paymentBarChartDataValues,
                    label: 'Total Paid Fees',
                  },
                ];
              });
          })
      );
    }
  }

  @ViewChild('dashboardStatisticsInputAutoFocusWeb')
  InputAutoFocusWeb: ElementRef;
  ngAfterViewInit() {
    this.InputAutoFocusWeb.nativeElement.focus();

    if (this.platform.ANDROID) {
      console.log('ANDROID');
      this.window.setTimeout(() => {
        var element = document.getElementById(
          'dashboardStatisticsInputAutoFocusWeb'
        );
        element.scrollIntoView(true);
      }, 1000);
    }

    if (this.platform.IOS) {
      //IOS focusing
      var element = document.getElementById('containerDivId');
      this.window.setTimeout(() => {
        element.scrollIntoView(true);
      });
    }
  }

  closeDashboardStatistics() {
    this.closeDashboardCallback();
  }
}
