export enum RequestStatusCodesColors {
    PendingOnPayment="#C65911",
    PendingOnTechnicalCommitteeApproval="#663399",
    PendingOnInspection="#4472C4",
    InitiatorSendBack="#008080",
    Finished="#375623",
    Canceled="#A50000",
    Rejected="#FF0000"

}
