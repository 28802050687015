import { Component, Input } from "@angular/core";
import { CitesExporterDetails } from "src/app/core/models/cites/shared/CitesExporterDetails";
import { CitesBaseFormComponent } from "../base/CitesBaseFormComponent";
import { CitesMainFormChangeType } from "../cites-main-form/CitesMainFormChangeEmitterData";

@Component({
    selector: 'app-cites-exporter-form',
    templateUrl: './cites-exporter-form.component.html',
})
  
export class CitesExporterFormComponent extends CitesBaseFormComponent {
  @Input()
  exporterDetails: CitesExporterDetails;
  @Input()
  disableCountry: boolean;

  onCountryChange($event: any){
    this.change.emit({
      type: CitesMainFormChangeType.CountryChange,
      value: $event.new
    })
  }

  onSelfExportedChange($event: any){
    this.change.emit({
      type: CitesMainFormChangeType.IsSelfExported,
      value: $event.target?.checked
    })
  }
}