import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EsBaseTemplateService } from './es-base-template.service';
import {
  EsAttachmentResponse,
  EsDownloadResponse,
} from './EsAttachmentResponse';
import { catchError } from 'rxjs/operators';

@Injectable()
export class EsAttachmentService extends EsBaseTemplateService {
  readonly basePath: string = 'CommonServices';

  private REST_API_SERVER_UPLOAD =
    environment.eServiceAPIHost + 'Common/UploadAttachment';

  private REST_API_SERVER_GET_BINARY_FILE =
    environment.eServiceAPIHost +
    'Common/GetBinaryFileData?fileID={fileID}';

  private REST_API_SERVER_ADD_REQUEST_ATTACHMENT =
    environment.eServiceAPIHost + 'CommonServices/AddRequestAttachment';

  private REST_API_SERVER_DELETE_REQUEST_ATTACHMENT =
    environment.eServiceAPIHost + 'CommonServices/DeleteRequestAttachment';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public uploadAttachment(request: any): Observable<EsAttachmentResponse> {
    const formData = new FormData();
    formData.append('file', request);

    return this.httpClient
      .post<EsAttachmentResponse>(this.REST_API_SERVER_UPLOAD, formData)
      .pipe(catchError(this.handleError));
  }

  public addRequestAttachment(
    requestAttachment: EsPostRequestAttachment
  ): Observable<EsAttachmentResponse> {
    return this.httpClient
      .post<EsAttachmentResponse>(
        this.REST_API_SERVER_ADD_REQUEST_ATTACHMENT,
        requestAttachment
      )
      .pipe(catchError(this.handleError));
  }

  public deleteRequestAttachment(fileGuid: {
    FileGuid: string;
  }): Observable<EsAttachmentResponse> {
    return this.httpClient
      .post<EsAttachmentResponse>(
        this.REST_API_SERVER_DELETE_REQUEST_ATTACHMENT,
        fileGuid
      )
      .pipe(catchError(this.handleError));
  }

  public download(path: string): Observable<EsDownloadResponse> {
    return this.httpClient
      .get<EsDownloadResponse>(
        this.REST_API_SERVER_GET_BINARY_FILE.replace('{fileID}', path)
      )
      .pipe(catchError(this.handleError));
  }
}

export class EsPostRequestAttachment {
  ProcInstanceId: number;
  ProcAttachmentId: number;
  FileGuid: string;
  AttachmentFileName: string;
}
