import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { LicenseDetails } from './LicenseDetails';
import { MainLookups } from './MainLookups';
import { EsServiceAttachments } from './EsServiceAttachments';
import { UserDetails } from './UserDetails';
import { EsUserAccessibilityToService } from './EsUserAccessibilityToService';
import { EsApplicant } from './EsApplicant';

export class EsServiceRequestConfiguration {
  procedureId?: number;
  mainLookups?: MainLookups = {};

  licenseDetails?: LicenseDetails;
  userDetails?: UserDetails = {};
  isDraftAllowed?: boolean = false;
  attachments?: EsServiceAttachments[];

  alerts?: AlertOptions[] = [];
  customAlerts?: AlertOptions[] = [];

  // showRequestPurposeType?: boolean;
  // isAllowedToUseService?: boolean;

  accessibility: EsUserAccessibilityToService;

  applicants: EsApplicant[];
}
