import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppInjector } from 'src/app/app.module';
import { LanguageService } from 'src/app/services/language.service';
import { AlertImportanceEnum } from '../bs-alert/AlertImportanceEnum';

@Component({
  template: '',
})
export abstract class BaseComponent {
  public isArabic: boolean;
  public isEnglish: boolean;
  public lang: 'ar' | 'en';
  public defaultLookupDisplayProperty: string;
  public defaultCodeLookupValueProperty = 'code';

  public readonly arabic: 'ar' = 'ar';
  public readonly english: 'en' = 'en';

  public readonly defaultSeparator = ', ';

  Info = AlertImportanceEnum.Info;
  Secondary = AlertImportanceEnum.Secondary;
  Warning = AlertImportanceEnum.Warning;
  Danger = AlertImportanceEnum.Danger;

  public emiratesIdMask = '0000-0000000-0';
  public emiratesIdMaskPrefix = '784-';

  readonly phoneNumberMask: string = '00000000009999999999';
  readonly faxMask: string = '00000000009999999999';

  readonly EMIRATES_ID_LENGTH = 18; ///With dashes
  readonly OTP_LENGTH = 5;

  constructor(protected translateService: TranslateService) {
    this.translateService.onLangChange.subscribe((langChangeEvent) => {
      this.setLanguageIndicators(langChangeEvent.lang);
    });

    this.setLanguageIndicators(this.translateService.currentLang);
  }

  private setLanguageIndicators(currentLang: string) {
    this.isArabic = currentLang == this.arabic;
    this.isEnglish = currentLang == this.english;

    this.defaultLookupDisplayProperty = this.isArabic ? 'nameAr' : 'nameEn';
    this.lang = this.isArabic ? this.arabic : this.english;
  }

  protected changeLanguage(lang: string) {
    const languageService = AppInjector.get(LanguageService);

    if (lang) languageService.changeLanguage(lang);
  }
}
