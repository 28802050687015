import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import * as getRequestCommentsResponse from "../../../models/common/comments/GetRequestCommentsResponse";
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {ResponseModel} from '../../../models/shared/CommonResponseDTO';
import {WorkFlowTransactionCustomerEmpChatMessages} from '../../../models/common/ServiceMainTransactionData';

@Injectable({
  providedIn: 'root'
})
export class GetRequestCommentsService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'CustomerEmpChatMessages/' + 'GetCustomerEmpChatMessage';

  constructor(private httpClient: HttpClient) {
  }

  public getRequestComments(serviceRequestTransactionId: number): Observable<ResponseModel<WorkFlowTransactionCustomerEmpChatMessages[]>> {

    let params = new HttpParams();
    params = params.append('serviceRequestTransactionId', serviceRequestTransactionId);
    return this.httpClient.get<ResponseModel<WorkFlowTransactionCustomerEmpChatMessages[]>>(this.REST_API_SERVER,
                                                                                           {params})
               .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse : HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetRequestCommentsService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetRequestCommentsService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }
}
