import {
  Component,
  DoCheck,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Component({
  selector: '[dsCol]',
  template: `
    <ng-container
      *ngIf="_colOptions.renderAs === 'wrapper'; then wrapper; else self"
    >
    </ng-container>

    <ng-template #wrapper>
      <div [class]="resolveColSize">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </ng-template>

    <ng-template #self>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-template>

    <!-- ng-content wrapped in a template in order to reuse the SAME content in multiple places -->
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class DsColComponent implements DoCheck {
  @Input()
  set colOptions(value: ColOptions) {
    if (value) {
      this._colOptions = value;
      this.handleInputChanges();
    }
  }

  _colOptions: ColOptions = {};
  _previousColOptions: ColOptions = {};

  get resolveColSize(): string {
    let result = 'animate-resizing ';

    if (this._colOptions) {
      if (this._colOptions.minimumSize) {
        result += `col-${this._colOptions.minimumSize.screenMediaSize}-${this._colOptions.minimumSize.colSpace} `;
      }
      if (this._colOptions.maximumSize) {
        result += `col-${this._colOptions.maximumSize.screenMediaSize}-${this._colOptions.maximumSize.colSpace} `;
      }
    }

    return result;
  }

  constructor(private mainElement: ElementRef, private renderer: Renderer2) {}

  private addResolvedClassesToSelfElement() {
    this.clearSizingClasses();

    for (let c of this.resolveColSize.split(' ')) {
      if (c) this.renderer.addClass(this.mainElement.nativeElement, c);
    }
  }

  private clearSizingClasses() {
    const classList = this.mainElement?.nativeElement?.classList as string[];
    let noneSizingClasses: string[] = [];

    if (classList) {
      for (const c of classList) {
        const isSizingClass = c.indexOf('col') >= 0;

        if (isSizingClass) {
          continue;
        } else {
          noneSizingClasses.push(c);
        }
      }
    }

    this.renderer.setAttribute(
      this.mainElement.nativeElement,
      'class',
      noneSizingClasses.join(' ')
    );
  }

  private handleInputChanges() {
    if (this.isSelfRendering) {
      if (this.isMaximumSizeChanged || this.isMinimumSizeChanged) {
        this.addResolvedClassesToSelfElement();
      }
    }
  }

  private get isMinimumSizeChanged(): boolean {
    return (
      this._previousColOptions?.minimumSize?.colSpace !==
      this._colOptions.minimumSize?.colSpace
    );
  }

  private get isMaximumSizeChanged(): boolean {
    return (
      this._previousColOptions?.maximumSize?.colSpace !==
      this._colOptions.maximumSize?.colSpace
    );
  }

  private get isSelfRendering() {
    return (
      this._colOptions?.renderAs == 'self' && this.mainElement?.nativeElement
    );
  }

  ngDoCheck() {
    this.handleInputChanges();

    this._previousColOptions = {
      maximumSize: { ...this._colOptions.maximumSize },
      minimumSize: { ...this._colOptions.minimumSize },
    };
  }
}

export class ColOptions {
  minimumSize?: ColSize;
  maximumSize?: ColSize;

  renderAs?: 'wrapper' | 'self' = 'wrapper';
}

export enum ColSpace {
  Full = 12,
  TwoThirds = 8,
  Third = 4,
  Half = 6,
  Two = 2,
}

export class ColSize {
  colSpace: ColSpace;
  screenMediaSize: ScreenMediaSize;
}

export enum ScreenMediaSize {
  Small = 'sm',
  Medium = 'md',
}
