import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import * as getAllServicesDetailResponse from '../../../core/models/portal/GetAllServicesDetailResponse';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GetAllServicesDetailService {

  private REST_API_SERVER = environment.hostPortalURL + 'func=GetAllServicesDetail';

  constructor(private httpClient: HttpClient) {
  }

  public getAllServicesDetail(lang: string): Observable<getAllServicesDetailResponse.GetAllServicesDetailResponse> {
    const httpOptionsDev = {
      headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json; charset=utf-8',
          'token': '7mNWdxBbeEgAAAAAAAAAAAAAAAAAAAAATEc0OTViNWFiMi1jMzdlLTQ5YTctYmU5OS0yZGJjNjllYzBjN2E=',
        }
      )
    };

    const httpOptionsProd = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json; charset=utf-8',
          'token': '7mNWdxBbeEgAAAAAAAAAAAAAAAAAAAAATEc0OTViNWFiMi1jMzdlLTQ5YTctYmU5OS0yZGJjNjllYzBjN2E='
        }
      )
    };

    const params = '&lang=' + lang;
    if (environment.production) {
      return this.httpClient.get<getAllServicesDetailResponse.GetAllServicesDetailResponse>(this.REST_API_SERVER + params, httpOptionsProd)
        .pipe(catchError(this.handleError));
    } else {
      return this.httpClient.get<getAllServicesDetailResponse.GetAllServicesDetailResponse>(this.REST_API_SERVER + params, httpOptionsDev)
        .pipe(catchError(this.handleError));
    }
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('[GetAllServicesDetail] Client Side Error', errorResponse.error.message);
    } else {
      console.error('[GetAllServicesDetail] Server Side Error', errorResponse.error.message);
    }

    return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }
}
