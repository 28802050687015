export interface EsUnconfirmedPoppedUpRequestDto {
  Id: number;
  Url: string;
  ParticipantID: number;
  ProcedureInstanceID: number;
  ConfirmationType: string;
}

export class EsUnconfirmedPoppedUpRequest {
  id: number;
  url: string;
  participantID: number;
  procedureInstanceID: number;
  confirmationType: string;
}

export enum ConfirmationRequestCodeEnum {
  _48Hours = '_48Hours',
  _4Hours = '_4Hours',
}
