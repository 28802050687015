import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CitesImportSpeciesInitiateRequestDto } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesInitiateRequestDto';
import { INRTypeLookupDto } from 'src/app/core/models/cites/cites-register-species/CitesRegisterSpeciesServiceRequestDto';
import { LookupDto } from 'src/app/core/models/shared/LookupDto';
import { EsTemplateService } from '../../shared/es-template.service';
import { EsResponseModel } from '../../shared/EsResponseModel';

@Injectable()
export class CitesImportSpeciesService
    extends EsTemplateService<CitesImportSpeciesInitiateRequestDto> {
        readonly basePath: string = 'CITESImport';
        private readonly _GetCITESINRTypesByAppendix = "GetCITESINRTypesByAppendix";
        private readonly _GetTypeAllowedQuantityForParticipant = "GetTypeAllowedQuantityForParticipant";
        private readonly _IsUniqueRingNumber = "IsUniqueRingNumber";
        private readonly _IsUniqueChipNumber = "IsUniqueChipNumber";
        private readonly _GetUnitsByTypeDescID = "GetUnitsByTypeDescID";
        private readonly _DownloadVerificationDocument = "DownloadVerifiedFile";

        constructor(httpClient: HttpClient) {
            super(httpClient);
        }

        GetINRTypes(classId: number,appendixId: number): Observable<EsResponseModel<INRTypeLookupDto[]>> {
            const url = this.buildUrl(
                `${this._GetCITESINRTypesByAppendix}?classId=${classId}&appendixId=${appendixId}`
            );
        
            return this.httpClient
                    .get<EsResponseModel<INRTypeLookupDto[]>>(url)
                    .pipe(catchError(this.handleError));
        }

        GetTypeDescUnits(typeDescId: number): Observable<EsResponseModel<LookupDto[]>> {
            const url = this.buildUrl(
                `${this._GetUnitsByTypeDescID}?typeDescId=${typeDescId}`
            );
        
            return this.httpClient
                    .get<EsResponseModel<LookupDto[]>>(url)
                    .pipe(catchError(this.handleError));
        }

        ValidateRingNumber(ringNumber: string,requestId: number = 0): Observable<EsResponseModel<boolean>> {
            const url = this.buildUrl(this._IsUniqueRingNumber);
        
            return this.httpClient
                    .post<EsResponseModel<boolean>>(url,
                        {
                            RequestId: requestId,
                            RingNumber: ringNumber
                        })
                    .pipe(catchError(this.handleError));
        }

        ValidateChipNumber(chipNumber: string,requestId: number = 0): Observable<EsResponseModel<boolean>> {
            const url = this.buildUrl(this._IsUniqueChipNumber);
        
            return this.httpClient
                    .post<EsResponseModel<boolean>>(url,
                        {
                            RequestId: requestId,
                            ChipNumber: chipNumber
                        })
                    .pipe(catchError(this.handleError));
        }

        GetAllowedQuantity(typeId: number,requestId: number): Observable<EsResponseModel<number>>{
            const url = this.buildUrl(
                `${this._GetTypeAllowedQuantityForParticipant}?typeId=${typeId}&requestId=${requestId}`
            );
        
            return this.httpClient
                    .get<EsResponseModel<number>>(url)
                    .pipe(catchError(this.handleError));
        }

        DownloadVerifiedDocument(certificateNo: string,requestId: number): Observable<string>{
            const url = this.buildUrl(
                `${this._DownloadVerificationDocument}?requestId=${requestId}&certificateNumber=${certificateNo}`
            );
        
            return this.httpClient
                    .get<string>(url)
                    .pipe(catchError(this.handleError));
        }

        
}
