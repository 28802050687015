<div class="text-center m-auto p-5">
  <img src="assets/Images/error.jpg" class="img-fluid" alt="" />
  <h1 class="bold">Error</h1>
  <h3>Couldn't load profile data from server</h3>
  <p>
    <ng-container
      *ngIf="isParticipantIdExists == false; then participantNotExist"
    ></ng-container>
    <ng-container
      *ngIf="isTokenExists == false; then tokenIsNotExist"
    ></ng-container>
    <ng-container
      *ngIf="isUserProfileExists == false; then userProfileNotExists"
    ></ng-container>
  </p>
</div>

<ng-template #participantNotExist>
  <p>Participant was not loaded properly!</p>
</ng-template>

<ng-template #tokenIsNotExist>
  <p>Token was not set!</p>
</ng-template>

<ng-template #userProfileNotExists>
  <p>Participant profile was not loaded or not exist!</p>
</ng-template>

<ng-template #block5> </ng-template>
