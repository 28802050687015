<div class="custom-control custom-radio">
  <input
    [id]="identifier"
    [name]="name"
    type="radio"
    class="custom-control-input"
    [class.is-invalid]="hasRequiredError()"
    [value]="set"
    [checked]="_value == set"
    [placeholder]="placeholder"
    #control="ngModel"
    [required]="required == 'true' || required == '' ? '' : undefined"
    (blur)="onTouched()"
    [readonly]="readonly"
    [disabled]="disabled"
    [ngModel]="_value"
    (ngModelChange)="onModelChange($event)"
  />
  <label class="custom-control-label" [for]="identifier">
    {{ labelText }}
  </label>
  <small
    [id]="identifier + 'Help'"
    *ngIf="helpText"
    class="form-text text-muted"
  >
    {{ helpText }}</small
  >
</div>
