import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GetNotificationMessagesService } from 'src/app/core/api-services/customer/get-notification-messages.service';
import { GetNotificationService } from 'src/app/core/api-services/customer/get-notification.service';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import {
  WorkflowStepStatusCodesEnum,
  WorkFlowTransactionCustomerEmpChatMessages,
} from 'src/app/core/models/common/ServiceMainTransactionData';
import { RegisteredUserNotification } from 'src/app/core/models/customer/RegisteredUserNotification';
import { UtilService } from 'src/app/core/utilities/util.service';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { CancelRequestService } from 'src/app/core/api-services/common/cancel-request.service';
import { environment } from 'src/environments/environment';

@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
@Component({
  selector: 'dashboard-header',
  templateUrl: './dashboard-header.component.html',
})
export class DashboardHeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  messagePage = 0;
  messagesList: WorkFlowTransactionCustomerEmpChatMessages[] = [];
  messagesCount = 0;
  messagesPageSize = 3;
  showMessage = false;

  observableSubscriptions: Subscription[] = [];

  showNotification = false;
  notificationPageSize = 3;
  notificationList: RegisteredUserNotification[] = [];
  notificationCount = 0;
  notificationPage = 0;

  currentSelectedServiceRequestTransactionId: number;
  currentSelectedStatusCode: WorkflowStepStatusCodesEnum;

  @Output()
  onOpenDashboardStatistics = new EventEmitter();

  @Output()
  onOpenImportantLinks = new EventEmitter();

  isShowFavoriteList = false;
  isShowTemplates = false;

  constructor(
    translateService: TranslateService,
    private getNotificationService: GetNotificationService,
    private getNotificationMessagesService: GetNotificationMessagesService,
    public utilService: UtilService,
    private toastr: ToastrService,
    private router: Router,
    private esCommonService: EsCommonService,
    private cancelRequestService: CancelRequestService
  ) {
    super(translateService);
  }

  ngOnDestroy(): void {}

  public ngOnInit(): void {
    this.getNotificationCount();
    this.getMessagesCount();
  }

  public onNotificationMessagesPageChange(event: any) {
    this.messagePage = event;
  }

  public getMessages() {
    this.observableSubscriptions.push(
      this.getNotificationMessagesService.getMessages().subscribe((res) => {
        this.messagesList = res.responseData;
        this.messagesCount = this.messagesList.length;
        this.messagesCount = this.messagesList.length;
        this.showMessage = this.messagesCount > 0;
        this.hideNotification();
      })
    );
  }

  public hideNotification() {
    this.showNotification = false;
  }

  public hideMessage() {
    this.showMessage = false;
  }

  public dismissMessage(id: number) {
    if (this.utilService.isEmployeeAction()) {
      this.toastr
        .warning(
          this.translateService.instant(
            'EmployeeCannotEditNotificationMessagesForCustomer'
          )
        )
        .onShown.subscribe((a) => {});
      return;
    }

    this.observableSubscriptions.push(
      this.getNotificationMessagesService
        .dismissNotificationMessage(id)
        .subscribe((res) => {
          this.messagesList = res.responseData;
          this.hideNotification();
          this.messagesCount = this.messagesList.length;
          this.showMessage = this.messagesCount > 0;
        })
    );
  }

  public getNotification() {
    this.observableSubscriptions.push(
      this.getNotificationService.getNotification().subscribe((res) => {
        this.notificationList = res.responseData;
        this.showNotification = this.notificationList.length > 0;
        this.hideMessage();
        this.notificationCount = this.notificationList.length;
      })
    );
  }

  public getNotificationCount() {
    this.observableSubscriptions.push(
      this.getNotificationService.getNotificationCount().subscribe((res) => {
        this.notificationCount = res.responseData;
      })
    );
  }

  public getMessagesCount() {
    this.observableSubscriptions.push(
      this.getNotificationMessagesService
        .getMessagesCount()
        .subscribe((res) => {
          this.messagesCount = res.responseData;
        })
    );
  }

  public onClickCommunicationMessage(
    workflowStatusCode: WorkflowStepStatusCodesEnum,
    serviceRequestTransactionId: number,
    isFromMessages = false
  ) {
    if (this.utilService.isEmployeeAction()) {
      this.toastr
        .warning(
          this.translateService.instant(
            'EmployeeCannotEditNotificationMessagesForCustomer'
          )
        )
        .onShown.subscribe((a) => {});
      return;
    }

    if (isFromMessages) this.hideMessage();
    this.currentSelectedServiceRequestTransactionId = null;
    setTimeout(() => {
      this.currentSelectedServiceRequestTransactionId =
        serviceRequestTransactionId;
      this.currentSelectedStatusCode = workflowStatusCode;
      console.log(
        'this.currentSelectedStatusCode= ' + this.currentSelectedStatusCode
      );
    }, 100);
  }

  public goToPage(name: string) {
    this.router.navigateByUrl(name);
  }

  public callCancelRequest(): Promise<SweetAlertResult<any>> {
    return Swal.fire({
      title: this.translateService.instant(
        'ConfirmBoxAreYouSureYouWantToCancelTheRequest'
      ),
      icon: this.translateService.instant('ConfirmBoxWarning'),
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('ConfirmBoxYesCancelIt'),
      cancelButtonText: this.translateService.instant('ConfirmBoxNoKeepIt'),
    });
  }

  public onNotificationPageChange(event: any) {
    this.notificationPage = event;
  }

  public openDashboardStatistics() {
    this.onOpenDashboardStatistics.emit();
  }

  public openImportantLinks() {
    this.onOpenImportantLinks.emit();
  }

  public cancelRequest(
    workflowTransactionId: number,
    serviceRequestTransactionId: number,
    action: any
  ) {
    if (this.utilService.isEmployeeAction()) {
      this.toastr
        .warning(
          this.translateService.instant(
            'EmployeeCaNotCancelRequestFromExternalSystem'
          )
        )
        .onShown.subscribe((a) => {});
      return;
    }

    const sweet = this.callCancelRequest();

    sweet.then((result: any) => {
      if (action.actionPageURL == 'CancelRequestEs') {
        this.cancelEServicesRequest(result, serviceRequestTransactionId);
      } else {
        this.cancelDigitalServicesRequest(result, workflowTransactionId);
      }
    });
  }

  private cancelEServicesRequest(
    result: any,
    serviceRequestTransactionId: number
  ): void {
    if (result.value) {
      this.esCommonService.cancelRequest(serviceRequestTransactionId).subscribe(
        (res: any) => {
          this.getNotification();
          //TODO: notify parent to refresh the requests
          Swal.fire(
            this.translateService.instant('RequestCanceledSuccessfully'),
            '',
            this.translateService.instant('ConfirmBoxSuccess')
          );
        },
        (error: any) => {
          Swal.fire(
            this.translateService.instant('RequestCancellationFailed'),
            '',
            this.translateService.instant('ConfirmBoxError')
          );
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      return;
    }
  }

  private cancelDigitalServicesRequest(
    result: any,
    workflowTransactionId: number
  ): void {
    if (result.value) {
      this.cancelRequestService.CancelRequest(workflowTransactionId).subscribe(
        (res: any) => {
          this.getNotification();
          //TODO: notify parent to refresh requests
          Swal.fire(
            this.translateService.instant('RequestCanceledSuccessfully'),
            '',
            this.translateService.instant('ConfirmBoxSuccess')
          );
        },
        (error: any) => {
          Swal.fire(
            this.translateService.instant('RequestCancellationFailed'),
            '',
            this.translateService.instant('ConfirmBoxError')
          );
        }
      );
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      return;
    }
  }

  public toggleFavoriteServices() {
    this.isShowFavoriteList = !this.isShowFavoriteList;
  }

  public navigateToEService(){
    window.open( environment.domainURL, "_blank");
  }
}
