import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../../base/BaseComponent';
import { InputTemplateOptions } from '../../InputTemplateOptions';

@Component({
  selector: 'app-bs-display',
  templateUrl: './bs-display.component.html',
})
export class BsDisplayComponent extends BaseComponent implements OnInit {
  @Input() key = '';
  @Input() labelText = '';
  @Input() valueText: string | boolean | number;
  @Input() valueTextAr = '';
  @Input() valueTextEn = '';
  @Input() templateOptions: InputTemplateOptions = { direction: 'default' };
  @Input() infoDialogId?: string = null;

  constructor(private translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {}

  public get showValueText(): boolean {
    return this.valueText != undefined && this.valueText != null;
  }
}
