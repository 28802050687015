import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform<TResult>(list: TResult[], search: string): TResult[] {
    if (search == null || search == '' || search == undefined) return list;

    if (list) {
      const regexp = new RegExp(search, 'i');

      return [
        ...list.filter((item) => {
          const text = JSON.stringify(item);
          return regexp.test(text);
        }),
      ];
    }

    return [];
  }
}
