import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormMode } from 'src/app/shared/base/FormMode';
import { CitesPageBaseComponent } from '../shared/CitesPageBaseComponent';
import { CitesImportSpeciesWorkflowService } from './cites-import-species-workflow.service';

@Component({
  selector: 'app-cites-import-species',
  templateUrl: './cites-import-species.component.html',
  styleUrls: ['./../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: 'WorkflowService',
      useClass: CitesImportSpeciesWorkflowService,
    },
  ],
})
export class CitesImportSpeciesComponent extends CitesPageBaseComponent {
  get formMode(): FormMode {
    return FormMode.EDITING;
  }

  constructor(
    @Inject('WorkflowService')
    workflowService: CitesImportSpeciesWorkflowService,
    route: ActivatedRoute,
    translate: TranslateService
  ) {
    super(workflowService, route, translate);
  }

  ngOnInit(): void {
    this.subscribeToSizeSettings();
  }
}
