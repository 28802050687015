import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesReleaseSpeciesItem } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { CitesSpeciesDetails } from 'src/app/core/models/cites/shared/CitesSpeciesDetails';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { MainLookups } from 'src/app/core/models/shared/MainLookups';
import { BaseItemFormComponent } from 'src/app/shared/base/BaseItemFormComponent';
import { CitesItemFormChangeEmitterData } from '../../shared/CitesItemFormChangeEmitterData';

@Component({
  selector: 'app-cites-release-species-item-form',
  templateUrl: './cites-release-species-item-form.component.html',
})

export class CitesReleaseSpeciesItemFormComponent extends BaseItemFormComponent {
  
  @Input() lookups: MainLookups = {};
  @Input() item: CitesReleaseSpeciesItem;
  @Input() disclaimers: AlertOptions[];

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  @Output()
  itemFormChange = new EventEmitter<CitesItemFormChangeEmitterData>();
}
