import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ValidationBaseOptions } from './ValidationBaseOptions';

function convertToDateOnly(originalDate: Date | string): Date {
  if (originalDate instanceof Date) {
    const dateOnly = new Date(originalDate);
    dateOnly.setHours(0, 0, 0, 0);
    return dateOnly;
  }

  return convertToDateOnly(new Date(originalDate));
}

export function moreThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid = convertToDateOnly(control.value) >= convertToDateOnly(date);

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function lessThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid = convertToDateOnly(control.value) <= convertToDateOnly(date);

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { lessThanDate: true };
  };
}

export function moreThanDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid = convertToDateOnly(control.value) > convertToDateOnly(date);

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}

export function lessThanDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value == null || control.value == '') {
      return null;
    }

    const valid = convertToDateOnly(control.value) < convertToDateOnly(date);

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { lessThanDate: true };
  };
}

export function optionalMoreThanOrEqualDate(
  date: Date,
  options?: ValidationBaseOptions
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid =
      control.value == null ||
      convertToDateOnly(control.value) >= convertToDateOnly(date);

    const hasOptions = options && true;

    return valid
      ? null
      : hasOptions
      ? { [options.key]: options }
      : { moreThanDate: true };
  };
}
