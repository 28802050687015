<div class="carousel">
  <div class="slide-container">
    <!--  Slider  -->
    <div class="slide-boarding" *ngIf="hideApplicantPage != true">
      <div class="visual-content-container">
        <ng-container *ngIf="isArabic">
          <img [src]="fileServerURL + applicantInfoNameAr" alt="" />
        </ng-container>

        <ng-container *ngIf="isEnglish">
          <img [src]="fileServerURL + applicantInfoNameEn" alt="" />
        </ng-container>
      </div>
      <div class="helper-content-container">
        <div class="helper-content">
          <h2 class="slide-title">
            {{ 'OnBoardingApplicantInfoTitle' | translate }}
          </h2>
          <br />
          {{ 'OnBoardingApplicantInfoBody' | translate }}
        </div>
      </div>
    </div>

    <!--  Slider  -->
    <div class="slide-boarding" *ngIf="hideServicePage != true">
      <div class="visual-content-container">
        <img
          [src]="
            isArabic
              ? fileServerURL + serviceDetailsNameAr
              : fileServerURL + serviceDetailsNameEn
          "
          alt=""
        />
      </div>
      <div class="helper-content-container">
        <div class="helper-content">
          <h2 class="slide-title">
            {{ 'OnBoardingStepsServiceDetailTitle' | translate }}
          </h2>
          <br />
          {{ 'OnBoardingStepsServiceDetailContent' | translate }}
        </div>
      </div>
    </div>

    <!--  Slider  -->
    <div class="slide-boarding">
      <div class="visual-content-container">
        <img
          [src]="
            isArabic
              ? fileServerURL + attachmentNameAr
              : fileServerURL + attachmentNameEn
          "
          alt=""
        />
      </div>
      <div class="helper-content-container">
        <div class="helper-content">
          <h2 class="slide-title">
            {{ 'OnBoardingStepsAttachmentsTitle' | translate }}
          </h2>
          <br />
          {{ 'OnBoardingStepsAttachmentsContent' | translate }}
        </div>
      </div>
    </div>

    <!--  Slider  -->
    <div class="slide-boarding">
      <div class="visual-content-container">
        <img
          [src]="
            isArabic
              ? fileServerURL + serviceReviewAr
              : fileServerURL + serviceReviewEn
          "
          alt=""
        />
      </div>
      <div class="helper-content-container">
        <div class="helper-content">
          <h2 class="slide-title">
            {{ 'OnBoardingStepsReviewTitle' | translate }}
          </h2>
          <br />
          {{ 'OnBoardingStepsReviewContent' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="controls on_borarding_controls">
    <button class="button button-nav previous" title="Previous Slide">
      {{ 'OnBoardingStepsBack' | translate }}
    </button>
    <div class="dynamic-toggles"></div>
    <button class="button button-nav button-primary next" title="Next Slide">
      {{ 'OnBoardingStepsNext' | translate }}
    </button>
    <button
      class="close"
      data-dismiss="modal"
      class="button primary button-primary complete"
      title="Got It!"
    >
      {{ 'OnBoardingStepsDone' | translate }}
    </button>
  </div>
</div>
