import {URLAction} from './RegisterUserRequestsData';
import {CertificateCatalogCodesEnum} from '../common/ServiceMainTransactionData';

export class RegisteredUserCertificate {
  serviceRequestTransactionId: number;
  certificateActions: URLAction[];
  certificateExpiryDate: Date;
  certificateIssueDate: Date;
  certificatePractisingDate: Date;
  certificateCatalogNameAr: string;
  certificateCatalogNameEn: string;
  certificateNumber: string;
  certificateUrl: string;
  certificateId: number;
  isExpired: boolean;
  isAboutToExpire: boolean;
  certificateCatalogId: number;
  certificateCatalogCode: CertificateCatalogCodesEnum;
  isPortal = false;
}

export class RegisteredUserCertificatesCount {
  activeCertificatesCount: number;
  expiredCertificatesCount: number;
  isAboutToExpiredCertificatesCount: number;
}
export enum FrontOfficeCardPackageEnum
{
  // Company
  MyLicensesForPracticingActivities = 'MyLicensesForPracticingActivities',

  // Individual
  MyLicensesForPracticingProfessional = 'MyLicensesForPracticingProfessional'
}
