import { Injectable } from '@angular/core';

import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as getVisitorCountResponse from "../../models/common/GetVisitorCountResponse";


@Injectable({
  providedIn: 'root'
})
export class GetVisitorCountService {

  private REST_API_SERVER = environment.hostURL + environment.hostCommon + "GetVisitorCount";

  constructor(private httpClient: HttpClient) {
  }


  public getVisitorCount() : Observable<getVisitorCountResponse.GetVisitorCountResponseObject> {

    return this.httpClient.get<getVisitorCountResponse.GetVisitorCountResponseObject>(this.REST_API_SERVER)
               .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse : HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetVisitorCountService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetVisitorCountService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
