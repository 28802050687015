<div class="breadcrumb pt-1 my_bred_cumb mb-0 px-0">
  <div class="breadcrumb-content">
    <div class="bred_crumb">
      <a
        *ngFor="let breadcrumb of breadcrumbs"
        [routerLink]="[breadcrumb.url + (breadcrumb.params || '')]"
        class="breadcrumbs-link"
        [innerHtml]="breadcrumb.labelKey | translate"
      >
      </a>
      <div *ngIf="showHelpSection" class="help-bar">
        <div class="help-item manual_icon">
          <a
            target="_blank"
            [href]="
              isEnglish
                ? 'https://www.moccae.gov.ae/assets/download/19ebe3dd/services-user-manual-english.aspx'
                : 'https://www.moccae.gov.ae/assets/download/2caedebd/services-user-manual-arabic.aspx'
            "
            ngbTooltip="{{ 'EndUserManual' | translate }}"
            tooltipClass="tooltip-bg-goldish-pr2"
            container="body"
            ><img src="assets/Images/end-user-Manual.png" alt="" />
          </a>
        </div>

        <div
          class="help-item onboarding_icon cursor-pointer"
          *ngIf="serviceKey"
        >
          <a
            data-toggle="modal"
            data-target="#onBoarding"
            ngbTooltip="{{ 'OnBoarding' | translate }}"
            tooltipClass="tooltip-bg-goldish-pr2"
            container="body"
            ><img src="assets/Images/on-boarding_new.png" alt="" />
          </a>
        </div>

        <div class="help-item youtube_plyer_icon">
          <a
            [href]="
              isEnglish
                ? serviceMetaData?.youTubeVideoURLEn
                : serviceMetaData?.youTubeVideoURLAr
            "
            target="_blank"
            ngbTooltip="{{ 'EducationalVideo' | translate }}"
            tooltipClass="tooltip-bg-goldish-pr2"
            container="body"
            ><img src="assets/Images/YouTube_Player.png" alt="" />
          </a>
        </div>

        <div class="help-item faq_icon cursor-pointer" *ngIf="showFaqLink">
          <a
            target="_blank"
            data-toggle="modal"
            data-target="#FAQModal"
            ngbTooltip="{{ 'FAQ' | translate }}"
            tooltipClass="tooltip-bg-goldish-pr2"
            container="body"
            ><img src="assets/Images/FAQ.png" alt="" />
          </a>
        </div>

        <div class="view_info services_icon">
          <a
            target="_blank"
            [href]="
              isEnglish
                ? serviceMetaData?.portalLinkEn
                : serviceMetaData?.portalLinkAr
            "
            ngbTooltip="{{ 'ViewServiceInformation' | translate }}"
            tooltipClass="tooltip-bg-goldish-pr2"
            container="body"
          >
            <img src="assets/Images/view_services.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade my_tms_model"
  id="FAQModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="FAQModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header direction_rtl alignment_right">
        <h5 class="modal-title" id="FAQModalLabel">
          {{ 'ModalTitleFAQ' | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <div class="custom_icon">
            <span class="icon-cancel-24px-gold"></span>
          </div>
        </button>
      </div>
      <div class="modal-body faq_modal_body p-4">
        <app-rendering-html
          [dynamicHtml]="
            isArabic ? serviceMetaData.faqAr : serviceMetaData.faqEn
          "
        ></app-rendering-html>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="onBoarding"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header direction_rtl alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ 'TourGuide' | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <div class="custom_icon">
            <span class="icon-cancel-24px-gold"></span>
          </div>
        </button>
      </div>
      <div class="modal-body">
        <app-onboarding-steps [folderName]="serviceKey"></app-onboarding-steps>
      </div>
    </div>
  </div>
</div>
