import { EsApplicantIdentityType } from './EsApplicantIdentityType';
import { EsApplicantPreferredLanguage } from './EsApplicantPreferredLanguage';

export class EsApplicant {
  id: number;
  participantId: number;
  name: string;
  nameAr: string;
  identityType: EsApplicantIdentityType;
  identityNumber: string;
  passport: null;
  email: null | string;
  phone: string;
  preferredLanguage: EsApplicantPreferredLanguage;
  IsDefaultAgent: boolean;

  countryId: number;
  affiliation: string;
  address: string;
  birthDate: Date;
  emirateId: number;
  idStatus: string;
}
