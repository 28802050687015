import { Component, Input, IterableDiffers } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-list-validation',
  templateUrl: './list-validation.component.html'
})

export class ListValidationComponent {
  @Input("name") ctrlName: string;

  @Input("formGroup") form: FormGroup;

  @Input("items") items: any[];

  @Input("min") min?: number;

  @Input("max") max?: number;

  @Input("isEditable") isEditable?: boolean;

  @Input("minValidationMsgKey") minValidationMsgKey?: string = "ListMinLengthValidation";
  @Input("maxValidationMsgKey") maxValidationMsgKey?: string = "ListMaxLengthValidation";

  differ: any;
  
  constructor(private differs: IterableDiffers) {
    this.differ = this.differs.find([]).create(null);
  }
  
  ngOnInit(){
    if(this.form && this.ctrlName){
      let ctrl = new FormControl(null);
      this.setControlValidators(ctrl);
      this.form.addControl(this.ctrlName,ctrl);
    }
  }

  setControlValidators(ctrl: AbstractControl){
    if(ctrl){
      ctrl.clearValidators();
      if(this.min && this.min > 0)
          ctrl.addValidators([Validators.required,Validators.min(this.min)]);
      if(this.max && this.max > 0)
          ctrl.addValidators(Validators.max(this.max));
    }
  }

  ngOnDestroy() {
    this.form?.removeControl(this.ctrlName);
  }

  ngDoCheck(){
    if(this.form && this.items && this.ctrlName){
      let changes = this.differ.diff(this.items);
      if (changes != null){
        setTimeout(()=> {
          this.setControlValidators(this.form.controls[this.ctrlName]);
          this.form.controls[this.ctrlName].setValue(this.items.length);
        });
      }
    }
  }

  hasRequiredError():boolean{
    if(this.isEditable && this.form ){
      return (
        this.form.controls[this.ctrlName]?.errors &&
        this.form.controls[this.ctrlName]?.errors?.required
      );
    }
    else
      return false;
  }
  
  hasMinError():boolean{
    if(this.isEditable && this.form ){
      return (
        this.form.controls[this.ctrlName]?.errors &&
        !this.form.controls[this.ctrlName]?.errors?.required &&
        this.form.controls[this.ctrlName]?.errors?.min
      );
    }
    else
      return false;
  }

  hasMaxError():boolean{
    if(this.isEditable && this.form ){
      return (
        this.form.controls[this.ctrlName]?.errors &&
        this.form.controls[this.ctrlName]?.errors?.max
      );
    }
    else
      return false;
  }
}
