import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  BsNgTextComponent,
  BsNgTextareaComponent,
  BsNgSelectComponent,
  BsNgNumberComponent,
  BsNgPhoneComponent,
  BsNgDateComponent,
  BsNgCheckboxComponent,
  BsNgSwitchComponent,
} from './components/bs-ng-components';
import { FormsModule } from '@angular/forms';
import { BsNgLookupComponent } from './components/bs-ng-components/bs-ng-lookup/bs-ng-lookup.component';
import { BsNgRadioComponent } from './components/bs-ng-components/bs-ng-radiobox/bs-ng-radiobox.component';
import { BsNgDropdownComponent } from './components/bs-ng-components/bs-ng-dropdown/bs-ng-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsNgMapComponent } from './components/bs-ng-components/bs-ng-map/bs-ng-map.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from 'src/environments/environment';
import { TranslateModule } from '@ngx-translate/core';
import { BsMapDisplayComponent } from './components/bs-display-components/bs-map-display/bs-map-display.component';
import { NgxMaskModule } from 'ngx-mask';
import { SortArrayPipe } from './pipes/sortArray.pipe';
import { BsNgInvalidFeedbackComponent } from './components/bs-ng-invalid-feedback/bs-ng-invalid-feedback.component';
import { InputSettingsService } from './input-settings.service';

export const INPUT_CONFIG_OPTIONS = new InjectionToken<InputSettingsService>(
  'INPUT_CONFIG_OPTIONS'
);
import { BsDisplayComponent } from './components/bs-display/bs-display.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapConfigs.APIKey,
      libraries: ['places'],
    }),
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    BsNgTextComponent,
    BsNgTextareaComponent,
    BsNgSelectComponent,
    BsNgNumberComponent,
    BsNgPhoneComponent,
    BsNgDateComponent,
    BsNgCheckboxComponent,
    BsNgLookupComponent,
    BsNgRadioComponent,
    BsNgSwitchComponent,
    BsNgDropdownComponent,
    BsNgMapComponent,
    BsMapDisplayComponent,
    SortArrayPipe,
    BsNgInvalidFeedbackComponent,
    BsDisplayComponent,
  ],
  exports: [
    BsNgTextComponent,
    BsNgTextareaComponent,
    BsNgSelectComponent,
    BsNgNumberComponent,
    BsNgPhoneComponent,
    BsNgDateComponent,
    BsNgCheckboxComponent,
    BsNgLookupComponent,
    BsNgRadioComponent,
    BsNgSwitchComponent,
    BsNgDropdownComponent,
    BsNgMapComponent,
    BsMapDisplayComponent,
    SortArrayPipe,
    BsNgInvalidFeedbackComponent,
    BsDisplayComponent,
  ],
})
export class InputComponentsModule {
  static forRoot(
    options: InputSettingsService
  ): ModuleWithProviders<InputComponentsModule> {
    return {
      ngModule: InputComponentsModule,
      providers: [{ provide: INPUT_CONFIG_OPTIONS, useValue: options }],
    };
  }
}
