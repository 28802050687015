import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BaseComponent } from "../../../../base/BaseComponent";
import { MapLocation } from "../../../../../core/models/shared/MapLocation";
import { environment } from "src/environments/environment";
import { MapsAPILoader } from "@agm/core";


@Component({
    selector: 'app-bs-map-display',
    templateUrl: './bs-map-display.component.html',
})

export class BsMapDisplayComponent extends BaseComponent{
    @Input() key = '';
    @Input() labelText = '';
    @Input() location:MapLocation = null;

    _location:MapLocation;
    zoom: number;
    radius: number;
    circleColor: string;
    
    constructor(private translate: TranslateService,private mapsAPILoader: MapsAPILoader) {
        super(translate);
        this._location = new MapLocation();
        this.zoom = environment.googleMapConfigs.defaultZoom;
        this.radius = environment.googleMapConfigs.defaultRadius;
        this.circleColor = environment.googleMapConfigs.defaultCircleColor;
    }

    ngOnChanges() {
        if(this.location){
            this._location.latitude = this.location.latitude;
            this._location.longitude = this.location.longitude;
            this._location.address = this.location.address;
        }
    }

}