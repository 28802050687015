<div class="row">
  <div class="col">
    <h2 [innerText]="'trRequestTemplates' | translate"></h2>
  </div>
</div>

<div class="row my-3">
  <div class="col-12">
    <input
      class="form-control"
      [(ngModel)]="searchText"
      [placeholder]="'trFilter' | translate"
    />
  </div>
</div>

<div class="row">
  <div class="col-12">
    <ul
      *ngIf="templates && templates.length"
      class="list-group ds-list-group-default"
    >
      <li
        class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2"
      >
        <app-detail-display
          class="w-mm-30"
          [value]=""
          key="trTemplateName"
        ></app-detail-display>
        <app-detail-display
          class="w-mm-40"
          [value]=""
          key="trTemplateServiceName"
        ></app-detail-display>
      </li>
      <li
        *ngFor="
          let template of templates | orderByIdDesc | searchFilter : searchText;
          let index = index
        "
        class="list-group-item d-flex justify-content-start align-items-center"
      >
        <div class="ds-list-group-item-head d-flex w-100 p-2">
          <app-detail-display
            class="w-mm-30"
            [value]="template.name"
          ></app-detail-display>
          <app-detail-display
            class="w-mm-40"
            [value]="isArabic ? template.serviceNameAr : template.serviceNameEn"
          ></app-detail-display>

          <div class="list-group-item-options">
            <ng-container *ngIf="enableEditing">
              <a
                class="ds-btn-icon"
                [title]="'trEdit' | translate"
                [routerLink]="'/' + template.url.replace('?requestId=', '')"
                [queryParams]="{
                  procedureId: template.procedureId,
                  templateId: template.id
                }"
                target="_blank"
              >
                <i class="fi-register fi-large"></i>
              </a>
              <a
                class="ds-btn-icon"
                [title]="'trCloneRequest' | translate"
                [routerLink]="'/' + template.url.replace('?requestId=', '')"
                [queryParams]="{
                  procedureId: template.procedureId,
                  cloneTemplateId: template.id
                }"
                target="_blank"
              >
                <i class="fa fa-clone" aria-hidden="true"></i>
              </a>
              <a
                class="ds-btn-icon"
                [title]="'trDelete' | translate"
                (click)="confirmDelete(template)"
                ><i class="fi-close-thick"></i>
              </a>
            </ng-container>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
