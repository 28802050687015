import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilService } from 'src/app/core/utilities/util.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public utilService: UtilService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userToken = this.utilService.getToken();
    const ParticipantId = this.utilService.getParticipantId();
    const uuid = this.utilService.getUUID();
    const eServiceLang  = this.utilService.getEServiceLang();
    const employeeId  = this.utilService.getEmployeeId();


    // skip interceptor in case the APi header has apikey because it will be a staging ll or public API.
    if (request.headers.get('apikey')) {
      request = request.clone({
        //headers: request.headers.delete('apikey'),
      });
      return next.handle(request);
    }

    // skip interceptor in case the APi header has "Authorization" because it will be a staging call or public API.
    if (request.headers.get('Authorization')) {
      request = request.clone({
      });
      return next.handle(request);
    }

    if (request.headers.get('isPublic')) {
      request = request.clone({
        headers: request.headers.delete('isPublic'),
      });
      return next.handle(request);
    }

    if (request.headers.get('fileServer') == null){
      request = request.clone({
        headers: request.headers.set('x-moccae-token', `${userToken}`),
      });

      request = request.clone({
        headers: request.headers.set('RegisteredUserId', ParticipantId)
      });

    }else {
      request = request.clone({
        headers: request.headers.delete('fileServer'),
      });
    }

    return next.handle(request);
  }
}
