<div class="row">
  <div class="col-6">
    <legend [innerText]="'trOutputs' | translate" class="col-form-label"></legend>
  </div>
  <div class="col-4 offset-md-2">
    <app-list-filter (search)="updateSearchText($event)"></app-list-filter>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <ul *ngIf="outputs && outputs.length; else noItemsAddedBlock"
        class="list-group ds-list-group-default">

      <li class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2">
        <app-detail-display
          class="w-mm-50"
          key="trOutputCertificateName">
        </app-detail-display>
        <div *ngIf="showVerification" class="w-mm-25">
          <div class="detail-group">
            <label>{{'trCitesOutputVerifyDoc' | translate}}
              <i class="fi-info"
                  #t="ngbTooltip"
                  tooltipClass="tooltip-UAEVerify"
                  container="body"
                  [ngbTooltip]="UAEverifyToolTip"
                  placement="bottom"
                  triggers="manual"
                  [autoClose]="'outside'"
                  (mouseenter)="t.open()">
                </i>
            </label>
          </div>
        </div>
      </li>

      <li *ngFor="let output of outputs | searchFilter: searchText | paginate: { id: 'cites_output_pagination', itemsPerPage: itemsPerPage, currentPage: currentPage };"
          class="list-group-item d-flex justify-content-start align-items-center cursor-default shadow-none">

        <div class="ds-list-group-item-head d-flex w-100 p-2">
          <div [class.w-mm-50]="showVerification">
            <app-detail-display
              [value]="isArabic ? output.titleAr : output.titleEn">
            </app-detail-display>
          </div>
          <div class="w-mm-25" *ngIf="showVerification">
            <div *ngIf="output.isVerified" class="detail-group">
              <div class="detail-text">
                <a class="ds-btn-sm cursor-pointer"
                    [innerText]="'trCitesOutputVerifyDoc' | translate"
                    (click)="downloadVerificationDoc(output)">
                </a>
              </div>
            </div>
            <div *ngIf="!output.isVerified" class="detail-group">
              <div class="detail-text">{{'NotAvailable' | translate}}</div>
            </div>
          </div>
          <div class="list-group-item-options">
            <span class="align-self-center">
              <i class="fi-info"
                  [class.success]="output.colorKey == VALID"
                  [class.danger]="output.colorKey == EXPIRED"
                  ngbTooltip="{{ 'trOutput.' + output.colorKey | translate }}"></i>
            </span>
            <div class="w-mm-5"></div>
            <a class="ds-btn ds-btn-success ds-btn-sm"
                [href]="output.url"
                [innerText]="'trDownloadOutput' | translate">
            </a>
          </div>
        </div>
      </li>

      <li class="list-group-item d-flex justify-content-start align-items-center px-2">
        <div *ngIf="outputs.length > itemsPerPage"
              class="w-mm-50 pt-1">
          <p>
            {{ ("ItemsCount" | translate) +": "+ outputs.length }} 
          </p>
        </div>
        <div class="w-mm-50 pagination_in">
          <div class="pagination">
            <pagination-controls 
              id="cites_output_pagination"
              previousLabel="{{'PaginationPreviousLabel' | translate}}"
              nextLabel="{{'PaginationNextLabel' | translate }}"
              (pageChange)="currentPage = $event"
              [autoHide]="true"
              [responsive]="true"
              [maxSize]="3">
            </pagination-controls>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="row" *ngIf="showVerification">
  <div class="col-12">
    <h5>
      {{"trCitesOutputUAEVerify" | translate}} 
      <a href="https://stageuaeverify.dv.u.ae/"
          target="_blank">
        <img style="width: 80px" src="assets/Images/Icons/VerifierPortalSmallWhiteLogo.png">
      </a>
    </h5>
  </div>
</div>

<ng-template #noItemsAddedBlock>
  <app-list-no-items></app-list-no-items>
</ng-template>


<ng-template #UAEverifyToolTip>
  <div class="UAEVerifyTitle">
    <h3>{{"trUAEverifyTitle" | translate}}</h3>
  </div>
  <div>
    <p>{{"trUAEverifyDesc" | translate}}</p>
    <hr />
    <h4>{{"trUAEverifyPropTitle" | translate}}</h4>
    <ul>
      <li>{{"trUAEverifyFirstProp" | translate}}</li>
      <li>{{"trUAEverifySecondProp" | translate}}</li>
      <li>{{"trUAEverifyThirdProp" | translate}}</li>
    </ul>
    <h4>
      <strong>{{"trUAEverifyMoreDetails" | translate}}</strong>
      <a title="{{'ClickHere' | translate}}" 
          href="https://uaeverify.gov.ae/faqs" 
          target="_blank" 
          rel="noopener">
        {{"ClickHere" | translate}}
      </a>
    </h4>
  </div>
</ng-template>