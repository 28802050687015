import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../base/BaseComponent';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
})
export class FabComponent extends BaseComponent {
  show = false;

  @Input()
  notificationCount = 0;

  @Output()
  action = new EventEmitter<FabActionDatHolder>();

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  goToNotification(): void {
    this.emitAction(FabActionEnum.Notifications);
  }

  goToPage(url: string) {
    this.action.emit({ type: FabActionEnum.GoToPage, goToPageUrl: url });
  }

  public emitAction(action: FabActionEnum) {
    this.action.emit({ type: action });
  }
}

export enum FabActionEnum {
  Notifications,
  GoToPage,
}

export interface FabActionDatHolder {
  type: FabActionEnum;
  goToPageUrl?: string;
}
