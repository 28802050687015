<ul class="list-group text-center">
    <app-list-no-items *ngIf="(!hasRequiredError() && !hasMinError()) && items?.length == 0">
    </app-list-no-items>
    <app-bs-alert *ngIf="hasRequiredError() || hasMinError()" 
        class="has-error is-invalid" 
        [alert]="{text: minValidationMsgKey | translate, display: true }">
    </app-bs-alert>
    <app-bs-alert *ngIf="hasMaxError()" 
        class="has-error is-invalid" 
        [alert]="{text: maxValidationMsgKey | translate, display: true }">
    </app-bs-alert>
</ul>