
  export class Data {
      Month: number;
      MonthEn: string;
      MonthAr: string;
      Year: number;
      Count: number;
      Amount: number;
  }

  export interface GetLatestSubmittedRequestsResponseObject {
      Data: Data[];
      Validations?: any;
      Code: number;
      Message: string;
      IsSuccess: boolean;
  }



