import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { FormMode } from 'src/app/shared/base/FormMode';
import { ItemFormActions } from 'src/app/core/enums/ItemFormActions.enum';
import { ListOptions } from 'src/app/shared/base/ListOptions';

@Component({
  selector: 'app-es-main-item-form-buttons',
  templateUrl: './es-main-item-form-buttons.component.html',
})
export class EsMainItemFormButtonsComponent
  extends BaseComponent
  implements OnInit
{
  @Input()
  mode: FormMode;

  @Input()
  options: ListOptions;

  @Input()
  isDisableClear: boolean;

  public get isEditing() {
    return this.mode === FormMode.EDITING;
  }

  public get isNew() {
    return this.mode === FormMode.NEW;
  }

  public get isView() {
    return this.mode === FormMode.VIEW;
  }

  public get allowAdding(): boolean {
    return this.options ? this.options.allowAdding : true;
  }

  public get allowEditing(): boolean {
    return this.options ? this.options.allowEditing : true;
  }

  public get allowReset(): boolean {
    return this.options ? this.options.allowReset : true;
  }

  @Output()
  public action = new EventEmitter();

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {}

  public cancelEditingItem(): void {
    this.action.emit({
      action: ItemFormActions.cancel,
    });
  }

  public reset(): void {
    this.action.emit({
      action: ItemFormActions.reset,
    });
  }
}
