import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { FlowButtonOptions } from 'src/app/shared/base/ButtonOptions';
import { RequestActions } from 'src/app/shared/base/RequestActions';
import { FlowButtons } from '../../../shared/base/FlowButtons';

@Component({
  selector: 'app-es-main-buttons',
  templateUrl: './es-main-buttons.component.html',
})
export class EsMainButtonsComponent extends BaseComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  isWaitingForCustomerAction: boolean;

  @Input()
  isPendingOnPayment: boolean;

  @Input()
  buttons: FlowButtons;

  @Output()
  action = new EventEmitter();

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {}

  public saveAsDraft() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.SaveAsDraft,
    };
    this.action.emit(emitData);
  }

  public saveTemplate() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.SaveTemplate,
    };
    this.action.emit(emitData);
  }

  public showRequestTemplateForm() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.ShowRequestTemplateForm,
    };
    this.action.emit(emitData);
  }

  public pay() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.Pay,
    };
    this.action.emit(emitData);
  }

  public payLater() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.PayLater,
    };
    this.action.emit(emitData);
  }

  public submitInitiatorAction() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.SubmitInitiatorAction,
    };
    this.action.emit(emitData);
  }

  public submitOwnerAction() {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.SubmitOwnerAction,
    };
    this.action.emit(emitData);
  }

  public moveBack(flowButtonOptions: FlowButtonOptions) {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.Back,
      behaviors: flowButtonOptions.behaviors,
    };
    this.action.emit(emitData);
  }

  public moveNext(flowButtonOptions: FlowButtonOptions) {
    let emitData: ActionEmitDataHolder = {
      action: RequestActions.Next,
      behaviors: flowButtonOptions.behaviors,
    };
    this.action.emit(emitData);
  }
}
