<div
  class="form-group"
  [ngClass]="{
    'has-error has-danger': hasRequiredError(),
    'has-success': isValid()
  }"
>
  <label class="form-control-label" [for]="identifier">{{ labelText }}</label>
  <div class="form-group">
    <div class="input-group" *ngIf="showFilter || !value">
      <input
        (input)="onTextFilterChanged($event)"
        class="form-control input-search"
        [placeholder]="placeholder"
        type="text"
        [readonly]="readonly"
        [disabled]="disabled"
        #textFilterInputRef
      />
      <div class="input-group-append">
        <span class="input-group-text"><i class="fas fa-search"></i></span>
      </div>
      <small
        [id]="identifier + 'Help'"
        *ngIf="helpText"
        class="form-text text-muted"
      >
        {{ helpText }}</small
      >
    </div>
    <ul *ngIf="value && selected" class="list-group selected-list-group">
      <li class="list-group-item" (click)="selectedItemClicked()">
        {{ textResolver ? textResolver(selected) : selected[optionText] }}
      </li>
    </ul>
    <div class="list-group-container" [hidden]="!opened">
      <ul class="list-group">
        <li
          *ngIf="showClearSelection"
          class="
            list-group-item list-group-item-clear
            d-flex
            w-100
            justify-content-between
          "
          (click)="clearSelection()"
        >
          {{ clearSelectionText || "Clear selection" }}
          <i class="fas fa-times"></i>
        </li>
        <li
          *ngFor="let option of options"
          class="list-group-item"
          [class.selected]="option == value"
          (click)="select(option)"
        >
          {{ textResolver ? textResolver(option) : option[optionText] }}
        </li>
      </ul>
      <select
        style="display: none"
        [id]="identifier"
        [name]="name"
        [(ngModel)]="value"
        [required]="required == 'true' || required == '' ? '' : undefined"
        #control="ngModel"
        (change)="selectedChange($event)"
        (blur)="onTouched()"
        [disabled]="disabled"
      >
        <option *ngFor="let option of options" [ngValue]="option[optionValue]">
          {{ textResolver ? textResolver(option) : option[optionText] }}
        </option>
      </select>
    </div>
    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>
  </div>
</div>
