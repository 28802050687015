import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base/BaseComponent';

@Component({
  selector: 'app-detail-display',
  templateUrl: './detail-display.component.html',
})
export class DetailDisplayComponent extends BaseComponent {
  @Input() key = '';
  @Input() value: any = '';
  @Input() labelText: string;

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {}

  get renderLabelText(): string {
    if (this.labelText) {
      return this.labelText;
    } else {
      return this.translateService.instant(this.key + '.labelText');
    }
  }

  get showLabelText(): boolean {
    const hasKey: boolean = this.key && true;
    const hasLabelText: boolean = this.labelText && true;

    return hasKey || hasLabelText;
  }
}
