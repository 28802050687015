import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { FavoriteServicesService } from 'src/app/core/api-services/favorite-services/favorite-services.service';
import { InputComponentsModule } from 'src/app/shared/input-components/input-components.module';
import { SharedModule } from 'src/app/shared/shared-module/shared-module.module';
import { LicensesForPracticingActivitiesComponent } from './company-package-details/licenses-for-practicing-activities/licenses-for-practicing-activities.component';
import { LicensesForPracticingProfessionalComponent } from './company-package-details/licenses-for-practicing-professional/licenses-for-practicing-professional.component';
import { MainCompanyPackageComponent } from './company-package-details/main-company-package.component';
import { MaterialBalancesCitiesComponent } from './company-package-details/material-balances-cities/material-balances-cities.component';
import { RegisteredApprovedMaterialsComponent } from './company-package-details/registered-approved-materials/registered-approved-materials.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { FavoriteServicesComponent } from './favorite-services/favorite-services.component';
import { OrderByIsFavoritePipe } from './favorite-services/OrderByIsFavoritePipe';
import { FavoriteServicesFilterPipe } from './favorite-services/FavoriteServicesFilterPipe';
import { HomeComponent } from './home.component';
import { CitiesBalanceAndFalconPassportComponent } from './individual-package-details/cities-balance-and-falcon-passport/cities-balance-and-falcon-passport.component';
import { MainIndividualPackageComponent } from './individual-package-details/main-individual-package.component';
import { MyAgriculturalAndAnimalPossesComponent } from './individual-package-details/my-agricultural-and-animal-posses/my-agricultural-and-animal-posses.component';
import { MyLicensesForFishingBoatsComponent } from './individual-package-details/my-licenses-for-fishing-boats/my-licenses-for-fishing-boats.component';
import { MyLicensesForPracticingProfessComponent } from './individual-package-details/my-licenses-for-practicing-profess/my-licenses-for-practicing-profess.component';
import { RequestCardComponent } from './request-card/request-card.component';
import { ServicesListDataService } from '../../core/api-services/shared/services/ServicesListDataService';
import { DashboardRequestsComponent } from './dashboard-requests/dashboard-requests.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TemplateServicesComponent } from './template-services/template-services.component';
import { OrderByIdDescPipe } from './template-services/OrderByIsFavoritePipe';
import { RequestCardComponentResolverService } from './request-card/request-card-component-resolver.service';

@NgModule({
  declarations: [
    HomeComponent,
    DashboardHeaderComponent,
    DashboardRequestsComponent,
    MainIndividualPackageComponent,
    MainCompanyPackageComponent,
    RequestCardComponent,
    FavoriteServicesComponent,
    MyLicensesForPracticingProfessComponent,
    MyLicensesForFishingBoatsComponent,
    MyAgriculturalAndAnimalPossesComponent,
    CitiesBalanceAndFalconPassportComponent,
    RegisteredApprovedMaterialsComponent,
    MaterialBalancesCitiesComponent,
    LicensesForPracticingActivitiesComponent,
    LicensesForPracticingProfessionalComponent,
    FavoriteServicesFilterPipe,
    OrderByIsFavoritePipe,
    OrderByIdDescPipe,
    TemplateServicesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    InputComponentsModule,
    NgxPaginationModule,
    NgSelectModule,
  ],
  providers: [FavoriteServicesService, ServicesListDataService, RequestCardComponentResolverService],
})
export class HomeModule {}
