<ng-container
  *ngIf="{
    transaction: transaction$ | async,
    config: serviceConfig$ | async
  } as both"
>
  <div class="form-section">
    <div class="row" *ngIf="both.config.alerts.length">
      <div class="col-sm-12">
        <app-es-service-disclaimers
          [alerts]="both.config.alerts"
        ></app-es-service-disclaimers>
      </div>
    </div>
  </div>

  <div class="main-form">
    <div class="form-section">
      <app-form-validation-indicator [formGroup]="form">
      </app-form-validation-indicator>

      <app-cites-importer-form
        [lookups]="both.config.mainLookups"
        [formGroup]="form"
        #mainFormDir="ngForm"
        [importerDetails]="both.transaction.serviceTransaction?.importerDetails"
        (change)="mainFormChange($event)"
        [formOptions]="formOptions"
      >
      </app-cites-importer-form>
    </div>

    <div class="form-section">
      <app-cites-exporter-form
        [lookups]="both.config.mainLookups"
        [formGroup]="form"
        #mainFormDir="ngForm"
        [exporterDetails]="both.transaction.serviceTransaction?.exporterDetails"
        [disableCountry]="both.transaction.serviceTransaction.items?.length > 0"
        (change)="mainFormChange($event)"
        [formOptions]="formOptions"
      >
      </app-cites-exporter-form>
    </div>

    <div class="form-section">
      <app-cites-import-species-request-form
        [lookups]="both.config.mainLookups"
        [purposes]="both.config.filteredPurposes"
        [formGroup]="form"
        #mainFormDir="ngForm"
        [alerts]="both.config.alerts"
        [transaction]="both.transaction"
        [fields]="fields$ | async"
        (change)="mainFormChange($event)"
        [formOptions]="formOptions"
        [disablePurpose]="both.transaction.serviceTransaction.items?.length > 0"
      >
      </app-cites-import-species-request-form>
    </div>
  </div>

  <div
    *ngIf="both.transaction.showItemsForm"
    class="form-section consignment-item-form"
  >
    <app-form-validation-indicator [formGroup]="formItem">
    </app-form-validation-indicator>

    <app-cites-import-species-item-form
      [lookups]="both.config.mainLookups"
      [sources]="both.config.filteredSources"
      [types]="both.config.filteredTypes"
      [formGroup]="formItem"
      [disclaimers]="both.transaction.disclaimers"
      #consignmentFormDir="ngForm"
      (push)="pushItem($event)"
      [mode]="formItemStatus.mode"
      (itemFormChange)="itemsFormChange($event)"
      [formOptions]="formOptions"
    >
    </app-cites-import-species-item-form>

    <app-cites-import-species-item-list
      [formGroup]="form"
      [items]="both.transaction.serviceTransaction.items"
      [maxItemCount]="both.transaction.maxItemCount"
      (copy)="copy($event)"
      (edit)="edit($event)"
      (delete)="deleteItem($event)"
    >
    </app-cites-import-species-item-list>
  </div>

  <div class="form-section">
    <app-form-validation-indicator
      [formGroup]="form"
    ></app-form-validation-indicator>

    <app-cites-import-species-footer-form
      [lookups]="both.config.mainLookups"
      [formGroup]="form"
      [transaction]="both.transaction"
      [formOptions]="formOptions"
    ></app-cites-import-species-footer-form>
  </div>
</ng-container>
