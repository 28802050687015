<ng-container
  *ngIf="
    templateOptions.direction === 'horizontal';
    then horizontal;
    else default
  "
>
</ng-container>

<ng-template #default>
  <div
    class="form-group"
    [ngClass]="{
      'has-error has-danger':
        ngControl.touched && !ngControl.valid && !ngControl.disabled,
      'has-success': ngControl.touched && ngControl.valid
    }"
    #map
  >
    <label
      class="form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
    >
      {{ labelText }}
    </label>
    <input
      type="text"
      [id]="identifier"
      [name]="name"
      class="form-control"
      [ngClass]="hasRequiredError() ? 'is-invalid' : touched ? 'is-valid' : ''"
      [(ngModel)]="location.address"
      #control="ngModel"
      [placeholder]="'trLocationSearch' | translate"
      (keydown.enter)="$event.preventDefault()"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      (change)="addressChange()"
      #search
    />

    <div class="invalid-feedback" *ngIf="hasRequiredError()">
      {{ requiredMessage }}
    </div>

    <agm-map
      [zoom]="zoom"
      [latitude]="location.latitude"
      [longitude]="location.longitude"
    >
      <agm-marker
        [latitude]="location.latitude"
        [longitude]="location.longitude"
        [markerDraggable]="markerDraggable"
        (dragEnd)="markerDragEnd($event)"
      >
      </agm-marker>

      <agm-circle
        [latitude]="location.latitude"
        [longitude]="location.longitude"
        [radius]="radius"
        [editable]="false"
        [fillColor]="circleColor"
      >
      </agm-circle>
    </agm-map>
  </div>
</ng-template>

<ng-template #horizontal>
  <div class="form-group row">
    <label
      class="col-sm-3 form-control-label"
      [ngClass]="{ 'form-control-label-required': hasRequiredValidator() }"
      [for]="identifier"
    >
      {{ labelText }}
    </label>
    <div class="col-sm-9">
      <input
        type="text"
        [id]="identifier"
        [name]="name"
        class="form-control"
        [ngClass]="
          hasRequiredError() ? 'is-invalid' : touched ? 'is-valid' : ''
        "
        [(ngModel)]="location.address"
        #control="ngModel"
        [placeholder]="'trLocationSearch' | translate"
        (keydown.enter)="$event.preventDefault()"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        type="text"
        (change)="addressChange()"
        #search
      />

      <div class="invalid-feedback" *ngIf="hasRequiredError()">
        {{ requiredMessage }}
      </div>

      <agm-map
        [zoom]="zoom"
        [latitude]="location.latitude"
        [longitude]="location.longitude"
      >
        <agm-marker
          [latitude]="location.latitude"
          [longitude]="location.longitude"
          [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)"
        >
        </agm-marker>

        <agm-circle
          [latitude]="location.latitude"
          [longitude]="location.longitude"
          [radius]="radius"
          [editable]="false"
          [fillColor]="circleColor"
        >
        </agm-circle>
      </agm-map>
    </div>
  </div>
</ng-template>
