import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetVisitorCountService } from 'src/app/core/api-services/common/get-visitor-count.service';
import { AuthService } from '../../authentication/auth.service';
import { BaseComponent } from '../../base/BaseComponent';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends BaseComponent implements OnInit {
  currentLang: string;
  visitorCount: number;
  constructor(
    translateService: TranslateService,
    getVisitorCountService: GetVisitorCountService,
    public auth: AuthService
  ) {
    super(translateService);

    this.currentLang = translateService.currentLang;
    translateService.onLangChange.subscribe(() => {
      this.currentLang = translateService.currentLang;
    });

    //TODO: remove the authentication check if the DS doesn't redirect anymore to ES on login
    if (auth.isAuthenticated())
      getVisitorCountService.getVisitorCount().subscribe((response) => {
        this.visitorCount = response.Data;
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }
}
