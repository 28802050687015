import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActionEmitDataHolder } from '../../../../shared/base/ActionEmitDataHolder';
import { CitesWorkflowBaseService } from '../cites-workflow-base.service';
import { RequestBaseComponent } from 'src/app/shared/base/RequestBaseComponent';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { FlowButtons } from 'src/app/shared/base/FlowButtons';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { Observable } from 'rxjs/internal/Observable';
import { FormSectionOptions } from 'src/app/services/es-shared/es-form-section-directive/FormSectionOptions';
import { CitesServiceRequestStore } from './CitesServiceRequestStore';
import { CitesBaseServiceRequest } from 'src/app/core/models/cites/shared/CitesBaseServiceRequest';
import { CitesFormFields } from './CitesFormFields';
import { CitesMainFormChangeEmitterData } from '../cites-main-form/CitesMainFormChangeEmitterData';
import { CitesTransactionBaseData } from 'src/app/core/models/cites/shared/CitesTransactionBaseData';
import { FormGroup } from '@angular/forms';
import { FormStatus } from 'src/app/shared/base/FormStatus';
import { ItemFormActions } from 'src/app/core/enums/ItemFormActions.enum';

export abstract class CitesRequestBaseComponent<
  TWorkflow extends CitesWorkflowBaseService<
    CitesServiceRequestStore<CitesBaseServiceRequest<any>>
  >
> extends RequestBaseComponent {
  formItem: FormGroup;
  formItemStatus: FormStatus;
  buttons: FlowButtons;

  serviceConfig$: Observable<EsServiceRequestConfiguration>;
  transaction$: Observable<CitesBaseServiceRequest<CitesTransactionBaseData>>;

  fields$: Observable<CitesFormFields>;

  mainFormSection: FormSectionOptions = { isCollapsible: true, render: true };
  itemFormSection: FormSectionOptions = { isCollapsible: true, render: true };

  constructor(
    public workflowService: TWorkflow,
    protected router: Router,
    translateService: TranslateService
  ) {
    super(translateService);

    this.workflowService.setCurrentStep(
      WorkflowSteps.ServiceDetailsConfiguration
    );

    this.fields$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.fields$;

    this.form =
      this.workflowService.globalObject.ServiceDetailsConfiguration.form;

    this.formItem =
      this.workflowService.globalObject.ServiceDetailsConfiguration.formItem;

    this.formItemStatus =
      this.workflowService.globalObject.ServiceDetailsConfiguration.formItemStatus;

    this.buttons =
      this.workflowService.globalObject.ServiceDetailsConfiguration.flowButtons;

    this.serviceConfig$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.transaction$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceDetails$;
  }

  public async actionHandler($event: ActionEmitDataHolder) {
    this.workflowService.move($event);
  }

  scrollToItemForm() {
    const scrollTo = document.querySelector('.consignment-item-form');
    if (scrollTo) scrollTo.scrollIntoView();
  }

  scrollToMainForm() {
    const scrollTo = document.querySelector('.main-form');
    if (scrollTo) scrollTo.scrollIntoView();
  }

  async mainFormChange($event: CitesMainFormChangeEmitterData) {
    await this.workflowService.onMainFormChange($event);
  }

  edit($event: any) {
    this.workflowService.startItemEditing($event);
    this.scrollToItemForm();
  }

  deleteItem($event: any) {
    this.workflowService.deleteItem($event);
  }

  public async pushItem($event: any) {
    if ($event.action == ItemFormActions.push) {
      await this.workflowService.push();
      return;
    }
    if ($event.action === ItemFormActions.cancel) {
      this.workflowService.resetItemForm();
      return;
    }

    console.warn(`no action binding found with ${$event.action}`);
  }
}
