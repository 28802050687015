<form [formGroup]="formGroup">
  <legend
    class="col-form-label"
    [innerText]="'trRequestTemplate' | translate"
  ></legend>

  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <bs-ng-text
        formControlName="name"
        identifier="name"
        key="trRequestTemplateNameFld"
        name="name"
      ></bs-ng-text>
    </div>
  </div>
  <div class="row">
    <div dsCol [colOptions]="fo.halfCol">
      <button
        [innerText]="'trUpdateTemplate' | translate"
        class="btn btn-primary btn-small"
        (click)="saveRequestTemplate()"
      ></button>
    </div>
  </div>
</form>
