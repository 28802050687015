import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as serviceRequestData from '../../models/common/ServiceRequestData';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {ServiceMainTransactionData} from '../../models/common/ServiceMainTransactionData';
@Injectable({
  providedIn: 'root'
})
export class GetRequestDataService {

  private REST_API_SERVER = environment.hostURL + environment.hostAnimalCare + 'GetRequest';

  constructor(private httpClient: HttpClient) {
  }

  public GetRequestData(procedureInstanceID: string): Observable<ResponseModel<ServiceMainTransactionData>>{

    let params = new HttpParams();
    params = params.append('procedureInstanceID', procedureInstanceID);

    return this.httpClient.get<ResponseModel<ServiceMainTransactionData>>(this.REST_API_SERVER,
                                                                {params})
    .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<never> {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetRequestDataService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetRequestDataService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
