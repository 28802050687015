import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bs-ng-invalid-feedback',
  templateUrl: './bs-ng-invalid-feedback.component.html',
})
export class BsNgInvalidFeedbackComponent implements OnInit {
  @Input() form: AbstractControl;
  @Input() error: string;
  @Input() key: string;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {}

  get hasFeedbackError(): boolean {
    return (
      this.form.errors &&
      (this.form.errors as any)[this.error] &&
      (this.form.errors as any)[this.error] != null
    );
  }

  get feedbackErrorMessage(): string {
    const exist =
      this.translateService.instant(`${this.key}.${this.error}`) !=
      `${this.key}.${this.error}`;

    if (exist) {
      return this.translateService.instant(`${this.key}.${this.error}`);
    } else {
      return this.translateService.instant(`${this.key}.${this.error}Message`);
    }
  }

  get touched(): boolean {
    return this.form.touched;
  }
}
