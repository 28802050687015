import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FavoriteService } from 'src/app/core/api-services/export/shared/responses/FavoriteServicesResponse';
import { FavoriteServicesService } from 'src/app/core/api-services/favorite-services/favorite-services.service';
import { GetAllServicesDetailService } from 'src/app/core/api-services/portal/get-all-services-detail.service';
import { ServiceDetailByDigitalNumberService } from 'src/app/core/api-services/portal/service-detail-by-digital-number.service';
import { GetAllServicesDetailDto } from 'src/app/core/models/portal/GetAllServicesDetailResponse';
import { ResponseData } from 'src/app/core/models/portal/ServiceDetailByDigitalNumberResponse';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';

@Injectable()
export class ServicesListDataService {
  constructor(
    private favoriteServicesService: FavoriteServicesService,
    private getAllServicesDetailService: GetAllServicesDetailService,
    protected metadataService: ServiceDetailByDigitalNumberService
  ) {}

  private _allServices: FavoriteServiceHybrid[] = [];
  public $allServices = new BehaviorSubject<FavoriteServiceHybrid[]>([]);

  private _favorites: FavoriteService[] = null;
  public $favorites = new Subject<FavoriteService[]>();

  public get isFavoriteLoaded() {
    return this._favorites != null;
  }

  private _allServicesDetails: GetAllServicesDetailDto[] = [];

  public async loadFavoriteServicesHybrid(lang: string): Promise<void> {
    await this.loadFavoriteServicesAsync();

    await this.loadAllServicesDetailsAsync(lang);

    this.combineFavoriteServicesWithMetadata();
  }

  private combineFavoriteServicesWithMetadata(): void {
    const all: FavoriteServiceHybrid[] = this._allServicesDetails.map(
      (serviceDetails) => {
        const favoriteService = this.findFavoriteService(
          serviceDetails.digitalNumber
        );

        const service: FavoriteServiceHybrid = {
          expanded: false,
          metadata: null,
          favorite: favoriteService,
          serviceDetails: serviceDetails,
          isFavorite: favoriteService != null,
          isFavoriteDeleted: false,
        };

        return service;
      }
    );

    this.emitFavoriteServicesWithMetadata(all);
  }

  private findFavoriteService(digitalNumber: string): FavoriteService {
    return this._favorites.find((f) => f.procedureId == digitalNumber);
  }

  private emitFavoriteServicesWithMetadata(all: FavoriteServiceHybrid[] = []) {
    this._allServices = all;

    this.$allServices.next(this._allServices);
  }

  private async loadFavoriteServicesAsync() {
    const response = await this.favoriteServicesService.get().toPromise();

    if (response.IsSuccess) {
      const favorites = response.Data.map((a) => {
        return {
          code: a.Code,
          id: a.IdString,
          procedureId: a.ProcedureId,
          url: a.Url,
          isFavorite: true,
        };
      });

      this.emitFavoriteServices(favorites);
    }
  }

  private emitFavoriteServices(favorites: FavoriteService[]) {
    this._favorites = favorites;

    this.$favorites.next(this._favorites);
  }

  private async loadAllServicesDetailsAsync(lang: string) {
    const allServicesResponse = await this.getAllServicesDetailService
      .getAllServicesDetail(lang)
      .toPromise();

    if (allServicesResponse.isSuccess) {
      this._allServicesDetails = allServicesResponse.responseData;
    }
  }

  public async updateFavorite(
    procedureId: string,
    isFavorite: boolean
  ): Promise<void> {
    const response = await this.favoriteServicesService
      .toggle(procedureId, isFavorite)
      .toPromise();

    if (response.IsSuccess) {
      this.updateFavoriteLocal(procedureId, isFavorite);
    }
  }

  private updateFavoriteLocal(procedureId: string, isFavorite: boolean) {
    const service = this._allServices.find(
      (service) =>
        service.serviceDetails?.digitalNumber == procedureId ||
        service.favorite?.procedureId == procedureId
    );

    if (service) {
      service.isFavorite = isFavorite;
      service.isFavoriteDeleted = isFavorite == false;

      this.emitFavoriteServicesWithMetadata(this._allServices);
    }
  }

  public async fetchServiceDetails(
    procedureId: string
  ): Promise<ServiceMetaData> {
    const response = await this.metadataService
      .getServiceDetailByDigitalNumber(procedureId)
      .toPromise();

    return this.mapMetadata(response.responseData);
  }

  public mapMetadata(responseData: ResponseData[]): ServiceMetaData {
    const arabic = 'Arabic';
    const english = 'English';

    const metadataEn = this.getMetadataByLanguage(responseData, english);
    const metadataAr =
      this.getMetadataByLanguage(responseData, arabic) ?? metadataEn;

    const metadata: ServiceMetaData = {
      nameAr: metadataAr?.title,
      nameEn: metadataEn?.title,
      formattedTermsAndConditionsAr: metadataAr?.termsAndConditions,
      formattedTermsAndConditionsEn: metadataEn?.termsAndConditions,
      portalLinkAr: metadataAr?.link,
      portalLinkEn: metadataEn?.link,
      termsAndConditionsAr: metadataAr?.termsAndConditions,
      termsAndConditionsEn: metadataEn?.termsAndConditions,
      youTubeVideoURLAr: metadataAr?.webYoutubeLink,
      youTubeVideoURLEn: metadataEn?.webYoutubeLink,
      faqAr: metadataAr?.serviceFAQ,
      faqEn: metadataEn?.serviceFAQ,
      digitalNumber: metadataEn.digitalNumber,
    };

    return metadata;
  }

  private getMetadataByLanguage(
    responseData: ResponseData[],
    language: string
  ): ResponseData {
    return responseData.filter(
      (responseItem) => responseItem.language == language
    )[0];
  }
}

export class FavoriteServiceHybrid {
  favorite: FavoriteService;
  serviceDetails: GetAllServicesDetailDto;
  metadata: ServiceMetaData;
  expanded: boolean;

  isFavorite: boolean;
  isFavoriteDeleted: boolean;
}
