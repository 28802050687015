import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsTemplateService } from 'src/app/core/api-services/shared/es-template.service';
import { EsResponseModel } from 'src/app/core/api-services/shared/EsResponseModel';
import { EsRequestCardExtraBaseComponent } from 'src/app/services/es-shared/base/EsRequestCardExtraBaseComponent';
import { CitesReleaseSpeciesTransaction } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { CitesReleaseSpeciesServiceRequestDto } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesServiceRequestDto';
import { CitesReleaseSpeciesMapper } from '../CitesReleaseSpeciesMapper';
import { CitesReleaseSpeciesWorkflowService } from '../cites-release-species-workflow.service';

@Component({
  selector: 'cites-release-species-request-card-extra',
  templateUrl: './cites-release-species-request-card-extra.component.html',
})
export class CitesReleaseSpeciesRequestCardExtraComponent
  extends EsRequestCardExtraBaseComponent
  implements OnInit
{
  isCollapsed: boolean = false;

  serviceDetails?: CitesReleaseSpeciesTransaction;

  constructor(
    translateService: TranslateService,
    @Inject('apiService')
    public apiService: EsTemplateService<any>,
    @Inject('mapperService')
    public mapperService: CitesReleaseSpeciesMapper,
    @Inject('WorkflowService')
    public workflowService: CitesReleaseSpeciesWorkflowService
  ) {
    super(translateService);
  }

  ngOnInit(): void {}

  public async loadRequestDetails(): Promise<void> {
    const response = await this.apiService
      .getRequest<EsResponseModel<CitesReleaseSpeciesServiceRequestDto>>(
        this.requestId.toString()
      )
      .toPromise();

    this.serviceDetails = this.mapperService.mapUltimate(response.Data);
  }

  public downloadVerificationDocument($event: any) {
    this.workflowService.globalObject.ServiceDetailsConfiguration.id = this.requestId;
    this.workflowService.DownloadVerificationDocument($event);
  }
}
