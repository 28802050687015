import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as serviceDetailByDigitalNumberResponse from '../../../core/models/portal/ServiceDetailByDigitalNumberResponse';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServiceDetailByDigitalNumberService {

  private REST_API_SERVER = environment.hostPortalURL + 'func=ServiceDetailByDigitalNumber';

  constructor(private httpClient: HttpClient) {
  }

  public  getServiceDetailByDigitalNumber(serviceNumber: string): Observable<serviceDetailByDigitalNumberResponse.ServiceDetailByDigitalNumberResponseObject> {
    const httpOptionsDev = {
      headers: new HttpHeaders(
          {
          'Access-Control-Allow-Origin' : '*',
          'Content-Type' : 'application/json; charset=utf-8',
          'token' : '7mNWdxBbeEgAAAAAAAAAAAAAAAAAAAAATEc0OTViNWFiMi1jMzdlLTQ5YTctYmU5OS0yZGJjNjllYzBjN2E=',
          }
        )
    };

    const httpOptionsProd = {
      headers: new HttpHeaders(
          {
          'Content-Type' : 'application/json; charset=utf-8',
          'token' : '7mNWdxBbeEgAAAAAAAAAAAAAAAAAAAAATEc0OTViNWFiMi1jMzdlLTQ5YTctYmU5OS0yZGJjNjllYzBjN2E='
          }
        )
    };

    const params = '&number=' + serviceNumber;
    if (environment.production) {
      return this.httpClient.get<serviceDetailByDigitalNumberResponse.ServiceDetailByDigitalNumberResponseObject>(this.REST_API_SERVER + params, httpOptionsProd)
                 .pipe(catchError(this.handleError));
    } else {
      return this.httpClient.get<serviceDetailByDigitalNumberResponse.ServiceDetailByDigitalNumberResponseObject>(this.REST_API_SERVER + params, httpOptionsDev)
                 .pipe(catchError(this.handleError));
    }

  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[ServiceDetailByDigitalNumberService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[ServiceDetailByDigitalNumberService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }


}
