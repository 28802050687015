import { Component, Input, Injector, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { MakeProvider } from '../AbstractValueAccessor';
import { InputSettingsService } from '../../../input-settings.service';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';

@Component({
  selector: 'bs-ng-textarea',
  templateUrl: './bs-ng-textarea.component.html',
  providers: [MakeProvider(BsNgTextareaComponent)],
})
export class BsNgTextareaComponent extends BsNgInputBaseComponent {
  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);
  }

  @Input() cols: number;
  @Input() rows: number;
  @Input() maxLength: string;

  @Input()
  trimWhitespace: boolean = false;

  override onBlur() {
    if (this.trimWhitespace && this.value?.trim) {
      this.value = this.value.trim();
    }

    super.onBlur();
  }

  get _maxLength(): number {
    return Number(this.maxLength);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }
}
