import { ClassLkp, CountryLkp, GenderLkp, INRTypeLkp, PackageUnitLkp, SourceLkp, TypeLkp } from "../../shared/Lookups";

export class CitesSpeciesDetails{
    class: ClassLkp;
    type: INRTypeLkp;
    originCountry: CountryLkp;
    exportingCertificateNo: string;
    importingCertificateNo: string;
    specimenDescription: string;
    source: SourceLkp;
    sourceReason: string;
    quantity: number;//nonIdentifiable
    unit: PackageUnitLkp;//nonIdentifiable
    typeDescription: TypeLkp;//nonIdentifiable
    ringNumber: string;//Identifiable
    chipNumber: string;//Identifiable
    gender: GenderLkp;//Identifiable
}