import { Injectable } from '@angular/core';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { UtilService } from 'src/app/core/utilities/util.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private utilService: UtilService) {
  }



  // ...
  public isAuthenticated(): boolean {
    const token = this.utilService.getToken();
    const helper = new JwtHelperService();

    if (this.utilService.isParticipantIdExists() == false) {
        return false;
    }

    if (this.utilService.isTokenExists() == false) {
      return false;
    }

    //const expirationDate = helper.getTokenExpirationDate(token);
    // Check whether the token is expired and return
    // true or false
    return !helper.isTokenExpired(token);
  }

  goToExternalPage(url: string) {
    window.location.href = url;
  }
}
