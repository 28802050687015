<div class="actions-container">
  <div class="text-left alignmet_left">
    <div
      class="d-flex"
      [ngClass]="isEnglish ? 'justify-content-start' : 'justify-content-end'"
    >
      <button
        *ngIf="buttons.back"
        (click)="moveBack(buttons.back)"
        class="ds-btn ds-btn-secondary ds-mr-1"
        [innerText]="buttons.back?.titleKey | translate"
      ></button>
    </div>
  </div>

  <div
    class="text-right ds-btns"
    *ngIf="
      buttons.draft?.isVisible ||
      buttons.saveTemplate?.isVisible ||
      buttons.addTemplate?.isVisible ||
      buttons.next
    "
  >
    <button
      *ngIf="buttons.draft && buttons.draft.isVisible"
      (click)="saveAsDraft()"
      class="ds-btn ds-btn-secondary"
      [innerText]="buttons.draft?.titleKey | translate"
    ></button>
    <button
      *ngIf="buttons.saveTemplate && buttons.saveTemplate.isVisible"
      (click)="saveTemplate()"
      class="ds-btn ds-btn-secondary"
      [innerText]="buttons.saveTemplate?.titleKey | translate"
    ></button>
    <button
      *ngIf="buttons.addTemplate && buttons.addTemplate.isVisible"
      (click)="showRequestTemplateForm()"
      class="ds-btn ds-btn-secondary"
      [innerText]="buttons.addTemplate?.titleKey | translate"
    ></button>
    <button
      *ngIf="buttons.next"
      (click)="moveNext(buttons.next)"
      class="ds-btn ds-btn-primary"
      [innerText]="buttons.next?.titleKey | translate"
    ></button>
  </div>

  <div class="text-right" *ngIf="buttons.isShowPay">
    <button
      type="button"
      (click)="pay()"
      *ngIf="buttons.isShowPay"
      class="ds-btn ds-btn-success mx-1"
      [innerText]="'trPay' | translate"
    ></button>
    <button
      type="button"
      (click)="payLater()"
      *ngIf="buttons.isShowPayLater"
      class="ds-btn ds-btn-primary mx-1"
      [innerText]="'PayLater' | translate"
    ></button>
  </div>

  <button
    type="button"
    (click)="submitInitiatorAction()"
    *ngIf="buttons.isShowInitiatorAction"
    class="ds-btn ds-btn-primary mx-1"
    [innerText]="'Submit' | translate"
  ></button>

  <button
    type="button"
    (click)="submitOwnerAction()"
    *ngIf="buttons.isShowOwnerAction"
    class="ds-btn ds-btn-primary mx-1"
    [innerText]="'Submit' | translate"
  ></button>
</div>
