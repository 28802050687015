import { Component, Input } from '@angular/core';
import { MainLookups } from 'src/app/core/models/shared/MainLookups';
import { BaseFormComponent } from 'src/app/shared/base/BaseFormComponent';

@Component({
  selector: 'app-cites-owner-action-form',
  templateUrl: './cites-owner-action-form.component.html',
})
export class CitesOwnerActionFormComponent extends BaseFormComponent {
  @Input() lookups: MainLookups = {};
}
