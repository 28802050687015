<div *ngIf="showLegend">
  <legend
    [innerText]="'trReceipts' | translate"
    class="col-form-label"
  ></legend>
</div>
<ul
  *ngIf="receipts && receipts.length; else noItemsAddedBlock"
  class="list-group ds-list-group-default"
>
  <li
    class="list-group-item ds-list-group-head-default d-flex justify-content-start align-items-center px-2"
  >
    <app-detail-display
      class="w-mm-30 d-none d-md-block"
      [value]=""
      key="trReceiptNumber"
    ></app-detail-display>
    <app-detail-display
      class="w-mm-30"
      [value]=""
      key="trReceiptAmount"
    ></app-detail-display>
    <app-detail-display [value]="" key="trReceiptDate"></app-detail-display>
  </li>
  <li
    class="list-group-item d-flex justify-content-start align-items-center ds-print-pba-always"
    *ngFor="let receipt of receipts; let index = index"
    class="list-group-item d-flex justify-content-start align-items-center"
  >
    <div
      class="ds-list-group-item-head d-flex w-100 p-2"
      [class.ds-list-group-item-head-open]="isCollapsed[index] == false"
      (click)="isCollapsed[index] = !isCollapsed[index]"
    >
      <app-detail-display
        class="w-mm-30 d-none d-print-block d-md-block"
        [value]="receipt.receiptNumber"
      ></app-detail-display>
      <app-detail-display
        class="w-mm-30"
        [value]="receipt.amount"
      ></app-detail-display>
      <app-detail-display
        [value]="receipt.receiptDate | date"
      ></app-detail-display>

      <div class="list-group-item-options">
        <a
          class="ds-btn-icon ds-btn-bg-bright"
          [attr.aria-expanded]="!isCollapsed[index]"
          ><i class="fi-arrow-thin-down"></i
        ></a>
      </div>
    </div>

    <div
      class="list-group-item-details collapse show w-100 col"
      [ngbCollapse]="
        isCollapsed[index] ?? (isCollapsed[index] = initiallyCollapsed)
      "
    >
      <div class="row">
        <div class="col-md-6 col-sm-12 col-print-6">
          <app-detail-display
            key="trReceiptNumber"
            class="w-mm-30"
            [value]="receipt.receiptNumber"
          ></app-detail-display>
        </div>

        <div class="col-md-6 col-sm-12 col-print-6">
          <app-detail-display
            key="trReceiptPaymentType"
            [value]="receipt.paymentType | lookup : lang"
          >
          </app-detail-display>
        </div>

        <div class="col-md-6 col-sm-12 col-print-6">
          <app-detail-display
            key="trReceiptGatewayFees"
            class="w-mm-30"
            [value]="receipt.eDirhamFees"
          >
          </app-detail-display>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div
            class="ds-detail-table ds-detail-table-border-none ds-detail-table-default"
          >
            <div
              class="row border-bottom d-none d-md-flex ds-detail-table-head"
              *ngIf="receipt.fees?.length"
            >
              <div class="col-md-6 col-print-6">
                <app-detail-display key="trReceiptFeeName"></app-detail-display>
              </div>
              <div class="col-md-2 col-print-2">
                <app-detail-display
                  key="trReceiptFeeAmount"
                ></app-detail-display>
              </div>
              <div class="col-md-2 col-print-2">
                <app-detail-display
                  key="trReceiptFeeQuantity"
                ></app-detail-display>
              </div>
              <div class="col-md-2 col-print-2">
                <app-detail-display key="trReceiptFeeCode"></app-detail-display>
              </div>
            </div>

            <div
              *ngFor="let fee of receipt.fees"
              class="row border-bottom ds-detail-table-row ds-detail-display-responsive"
            >
              <div class="col-md-6 col-print-6">
                <app-detail-display
                  [value]="isArabic ? fee.nameAr : fee.nameEn"
                  key="trReceiptFeeName"
                ></app-detail-display>
              </div>
              <div class="col-md-2 col-print-2">
                <app-detail-display
                  [value]="fee.feeTotal"
                  key="trReceiptFeeAmount"
                ></app-detail-display>
              </div>
              <div class="col-md-2 col-print-2">
                <app-detail-display
                  [value]="fee.quantity"
                  key="trReceiptFeeQuantity"
                ></app-detail-display>
              </div>
              <div class="col-md-2 col-print-2">
                <app-detail-display
                  [value]="fee.code"
                  key="trReceiptFeeCode"
                ></app-detail-display>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>

<ng-template #noItemsAddedBlock>
  <app-list-no-items></app-list-no-items>
</ng-template>
