<div class="btn-group profile-cont" *ngIf="user">
    <a type="button" class="dropdown-toggle"  
       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
       {{ 'Welcome' | translate }}, 
       <strong [innerText]="isEnglish ? user?.nameEn : user?.nameAr"></strong>
    </a>
    <div class="dropdown-menu">
        <ng-container *ngFor="let account of accounts">
            <ng-container *ngIf="account.Id != user.id">
                <a class="dropdown-item" href="#" (click)="switchUserAccount($event,account.Id)">
                    {{isEnglish ? account.NameEn :  account.NameAr}}
                </a>
                <div class="dropdown-divider"></div>
            </ng-container>
        </ng-container>
        <a class="dropdown-item" href="#" (click)="editProfile($event)">{{'HeaderEditProfile' | translate}}</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item logout" href="#" (click)="logout($event)">
            {{'MemberLogout' | translate}}
        </a>
    </div>
</div>