<div body class="ds-btn-container">
  <button
    *ngIf="isNew && allowAdding"
    [innerText]="'Add' | translate"
    type="submit"
    class="ds-btn ds-btn-primary w-100"
  ></button>
  <button
    *ngIf="isNew && allowReset"
    [innerText]="'trClearFields' | translate"
    (click)="reset()"
    class="ds-btn ds-btn-secondary btn-small"
    [disabled]="isDisableClear"
  ></button>
  <button
    *ngIf="isEditing && allowEditing"
    [innerText]="'Save' | translate"
    type="submit"
    class="ds-btn ds-btn-primary w-100"
  ></button>
  <button
    *ngIf="isEditing"
    [innerText]="'Cancel' | translate"
    class="ds-btn ds-btn-secondary"
    (click)="cancelEditingItem()"
  ></button>
</div>
