import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { EsAttachmentService } from 'src/app/core/api-services/shared/es-attachment.service';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';
import { EsCommentPostDto } from 'src/app/core/api-services/shared/EsCommentPostDto';
import { EsAttachmentResponse } from 'src/app/core/api-services/shared/EsAttachmentResponse';
import {
  EsAttachmentsNames,
  EsComment,
} from 'src/app/core/models/shared/ServiceRequestTransactionEsData';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { AttachmentsNamesDto } from 'src/app/core/models/shared/EsCommentDto';

@Component({
  selector: 'app-es-comments',
  templateUrl: './es-comments.component.html',
  styleUrls: ['./es-comments.component.scss'],
})
export class EsCommentsComponent extends BaseComponent {
  @Input()
  comments?: EsComment[] = null;

  @Input()
  allowedToComment = true;

  @Input()
  requestId: number;

  comment: string;

  uploadedAttachments: EsAttachmentsNames[] = [];

  readonly PARTICIPANT = 'Internet';
  readonly MAX_FILE_SIZE_BYTE: number = 3 * 1024 * 1024; ///3MB

  constructor(
    translateService: TranslateService,
    private attachmentService: EsAttachmentService,
    private commonEsService: EsCommonService,
    private toaster: ToastrService
  ) {
    super(translateService);
  }

  async download(attachment: EsAttachmentsNames): Promise<void> {
    const result = await this.attachmentService
      .download(attachment.guid)
      .toPromise();

    const array = this.dataURItoArray(result.Data.FileData);

    const blob = new Blob([array], { type: result.Data.FileMimeType });

    const url = URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = url;
    link.download = result.Data.FileName;
    link.click();
  }

  private dataURItoArray(dataURI: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return int8Array;
  }

  public handleUploadFilesButtonClick(func: any): void {
    func();
  }

  public onSelectFile(event: NgxFileDropEntry[]): void {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.validateFileSize(file)) {
            this.attachmentService
              .uploadAttachment(file)
              .toPromise()
              .then((res: EsAttachmentResponse) => {
                ///link the file guid from response with the comment object

                const uploaded: EsAttachmentsNames[] = res.Data.map((u) => {
                  return { name: u.AttchfileNames, guid: u.FileId };
                });

                this.uploadedAttachments.push(...uploaded);
              });
          } else {
            this.toaster.error(
              this.translateService.instant('trMaximumAllowedFileSize', {
                maximumAllowedFileSize:
                  this.convertByteToMegaByte(this.MAX_FILE_SIZE_BYTE) + ' MB',
              })
            );
          }
        });
      }
    }
  }

  private convertByteToMegaByte(sizeInByte: number) {
    if (sizeInByte <= 0) {
      return 0;
    }

    return sizeInByte / (1024 * 1024);
  }

  private validateFileSize(file: File) {
    return file.size <= this.MAX_FILE_SIZE_BYTE;
  }

  public async send(): Promise<void> {
    if (this.comment) {
      const comment: EsCommentPostDto = {
        ProcedureInstanceId: this.requestId,
        CommentBody: this.comment,
        Attachments: this.mapUploadedAttachments(),
      };

      const result = await this.commonEsService
        .createComment(comment)
        .toPromise();

      if (result.IsSuccess) {
        this.comments.push({
          creationDate: new Date().toString(),
          attachments: this.uploadedAttachments,
          commentBody: this.comment,
          commentSource: this.PARTICIPANT,
        });

        this.clearInputs();
      }

      console.log(result);
    } else {
      this.toaster.info(
        this.translateService.instant('trCommentMessage.requiredMessage')
      );
    }
  }

  private clearInputs() {
    this.comment = '';
    this.uploadedAttachments = [];
  }

  private mapUploadedAttachments(): AttachmentsNamesDto[] {
    return this.uploadedAttachments.map((uploaded) => {
      let result: AttachmentsNamesDto = {
        Name: uploaded.name,
        Value: uploaded.guid,
      };

      return result;
    });
  }

  public removeUploaded(removeUploaded: EsAttachmentsNames) {
    this.uploadedAttachments = this.uploadedAttachments.filter(
      (u) => u.guid != removeUploaded.guid
    );
  }
}
