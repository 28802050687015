import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemFormActions } from 'src/app/core/enums/ItemFormActions.enum';
import { MainLookups } from 'src/app/core/models/shared/MainLookups';
import { CitesFormFields } from 'src/app/services/cites/shared/base/CitesFormFields';
import { CitesItemFormChangeEmitterData } from 'src/app/services/cites/shared/CitesItemFormChangeEmitterData';
import { BaseFormComponent } from 'src/app/shared/base/BaseFormComponent';
import { ItemEmitHolder } from './ActionEmitDataHolder';

@Component({ template: '' })
export abstract class BaseItemFormComponent extends BaseFormComponent {
  @Input()
  lookups: MainLookups = {};

  @Output()
  push: EventEmitter<ItemEmitHolder> = new EventEmitter();

  @Output()
  change = new EventEmitter<CitesItemFormChangeEmitterData>();

  @Input()
  fields: CitesFormFields;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  submit() {
    if (this.formGroup.valid) {
      this.push.emit({
        data: {},
        action: ItemFormActions.push,
      });
    } else {
      console.warn(this.formGroup);
    }
  }

  public cancelEditingItem(): void {
    this.push.emit({
      action: ItemFormActions.cancel,
    });
  }
  
  public handleItemFormAction($event: { action: ItemFormActions }) {
    switch ($event.action) {
      case ItemFormActions.cancel:
        this.cancelEditingItem();
        break;
      case ItemFormActions.reset:
        this.reset();
        break;
    }
  }
}
