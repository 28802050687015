import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesSpeciesDetails } from 'src/app/core/models/cites/shared/CitesSpeciesDetails';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { MainLookups } from 'src/app/core/models/shared/MainLookups';
import { BaseItemFormComponent } from 'src/app/shared/base/BaseItemFormComponent';
import { CitesMainFormChangeType } from '../../shared/cites-main-form/CitesMainFormChangeEmitterData';
import { CitesItemFormChangeEmitterData } from '../../shared/CitesItemFormChangeEmitterData';
import { SourceCodesData } from '../../shared/cites-codes/source-codes.data';
import { Lookup } from 'src/app/core/models/common/Lookup';
import { SourceLkp, TypeLkp } from 'src/app/core/models/shared/Lookups';

@Component({
  selector: 'app-cites-import-species-item-form',
  templateUrl: './cites-import-species-item-form.component.html',
})

export class CitesImportSpeciesItemFormComponent extends BaseItemFormComponent {
  
  @Input() lookups: MainLookups = {};
  @Input() sources: SourceLkp[] = [];
  @Input() types: TypeLkp[] = [];
  @Input() item: CitesSpeciesDetails;
  @Input() disclaimers: AlertOptions[];

  sourceCodes:Lookup[];

  constructor(translateService: TranslateService) {
    super(translateService);
    this.sourceCodes = SourceCodesData.data;
  }

  @Output()
  itemFormChange = new EventEmitter<CitesItemFormChangeEmitterData>();

  onClassChange($event: any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.ClassChange,
      value: $event.new
    });
  }
  
  onAppendixChange($event: any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.AppendixChange,
      value: $event.new
    });
  }

  onTypeChange($event: any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.TypeChange,
      value: $event.new?.id
    });
  }

  onSourceChange($event: any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.SourceChange,
      value: $event.new?.id
    });
  }
  
  onRingNumberChange($event:any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.RingNumberChange,
      value: $event.new
    });
  }

  onChipNumberChange($event:any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.ChipNumberChange,
      value: $event.new
    });
  }

  onTypeDescriptionChange($event:any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.TypeDescriptionChange,
      value: $event.new?.id
    });
  }

  onIsRelatedChange($event:any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.IsRelatedChange,
      value: $event.target.checked
    });
  }

  onOriginCountryChange($event:any){
    this.itemFormChange.emit({
      type: CitesMainFormChangeType.CountryChange,
      value: $event.new?.id
    });
  }
}
