import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesImporttSpeciesRequestDataDto } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesServiceRequestDto';
import { CitesReleaseServiceConfigurationResponse } from 'src/app/core/models/cites/cites-release-species/CitesReleaseServiceConfigurationResponse';
import { CitesReleaseSpeciesServiceRequestConfiguration } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesServiceRequestConfiguration';
import { CitesReleaseSpeciesItemDto, CitesReleaseSpeciesRequestDataDto, CitesReleaseSpeciesServiceRequestDto } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesServiceRequestDto';
import { CitesReleaseSpeciesItem, CitesReleaseSpeciesTransaction, CitesReleaseSpeciesTransactionData } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { UserDetails } from 'src/app/core/models/shared/UserDetails';
import { CitesImportSpeciesMapper } from '../cites-import-species/CitesImportSpeciesMapper';
import { CitesBaseMapper } from '../shared/CitesBaseMapper';
import { CitesSharedMapper } from '../shared/CitesSharedMapper';

@Injectable()
export class CitesReleaseSpeciesMapper extends CitesBaseMapper<CitesReleaseSpeciesTransaction> {
  importMapper: CitesImportSpeciesMapper;

  get isArabic(): boolean {
    return this.translateService.currentLang == 'ar';
  }

  constructor(
    datePipe: DatePipe,
    private translateService: TranslateService,
    public sharedMapper: CitesSharedMapper
  ) {
    super(datePipe);
    this.importMapper =  new CitesImportSpeciesMapper(this.datePipe,translateService,sharedMapper);
  }

  public mapGetServiceConfigurationResponse(
    response: CitesReleaseServiceConfigurationResponse
  ): EsServiceRequestConfiguration {
    const config = super.mapBasicServiceConfigurationResponse(response) as CitesReleaseSpeciesServiceRequestConfiguration;
    config.userDetails = this.mapUserDetails(response.Data.ImportRequest.UserData);

    const data = response.Data;
    const importRequestData = data.ImportRequest.RequestData as CitesImporttSpeciesRequestDataDto;

    config.isValidForRelease = data.ImportRequest.IsValidForRelease;
    config.isReleaseInitiated = data.ImportRequest.IsReleaseInitiated;
    config.enablePartialRelease = data.EnablePartialRelease;
    config.maxAllowedReleaseQuanity = data.MaxAllowedQuantityForRelease;
    config.importRequest = this.importMapper.mapServiceRequest(
      importRequestData,
      config.userDetails
    );
    config.importRequest.items = this.mapItems(importRequestData.RequestItems);

    config.mainLookups.shippingMethods = this.mapLookup(data.ShippingTypes);
    return config;
  }

  mapUltimate(dto: CitesReleaseSpeciesServiceRequestDto): 
    CitesReleaseSpeciesTransaction {
      let transaction = new CitesReleaseSpeciesTransaction();
      transaction = super.mapBasic(dto);
      transaction.citesOutputs = this.sharedMapper.mapCitesOutputs(dto.CITES_OutPuts);
      transaction.userDetails = this.mapUserDetails(dto.UserData);

      transaction.serviceRequestTransactionData = 
        this.mapGetServiceRequestDetailsResponse(dto.ProcedureInstanceObject);

      transaction.serviceTransaction = this.mapServiceRequest(
        dto.RequestData,transaction.userDetails
      );

      transaction.serviceTransaction.releaseItems = this.mapItems(dto.RequestData.RequestItems);
      transaction.serviceTransaction.items = this.mapItems(dto.ImportRequestItems);

      return transaction;
  }

  mapServiceRequest(dto: CitesReleaseSpeciesRequestDataDto,userDetails: UserDetails): 
    CitesReleaseSpeciesTransactionData{
    const result = this.importMapper.mapServiceRequest(dto,userDetails);
    return{
      ...result,
      ...{
        shippingBillNumber: dto.ShippingBillNumber,
        shippingMethod: {
          id: dto.ShippingMethodID,
          nameAr: dto.ShippingMethodNameAr,
          nameEn: dto.ShippingMethodNameEn,
        },
        shippingPolicy: dto.ShippingPolicy,
        tanker: dto.Tanker,
        exportingPortForRelease: dto.ExportingPortForRelease,
        checkinDate: this.asDateInstance(dto.CheckinDate)
      }
    }
  }

  mapItems(itemsDto: CitesReleaseSpeciesItemDto[]):
    CitesReleaseSpeciesItem[]{
    return itemsDto.map(itemDto =>
      {
        return{
          ...this.importMapper.mapItem(itemDto),
          ...{
            status: itemDto.ItemStatus,
          }
        }
      }
    );
  }

  toDto(trans: CitesReleaseSpeciesTransactionData): 
    CitesReleaseSpeciesRequestDataDto {
      const result = this.importMapper.toRequestDto(trans);
      let requestDto: CitesReleaseSpeciesRequestDataDto = {
        ...result,
        ...{
          ShippingBillNumber: trans.shippingBillNumber,
          ShippingMethodID: trans.shippingMethod?.id,
          ShippingPolicy: trans.shippingPolicy,
          Tanker: trans.tanker,
          ExportingPortForRelease: trans.exportingPortForRelease,
          CheckinDate: trans.checkinDate
        }
      }
      requestDto.RequestItems = this.toItemsDto(trans.items);
      return requestDto;
  }

  toItemsDto(items: CitesReleaseSpeciesItem[]): CitesReleaseSpeciesItemDto[]{
    return items.map(item =>
      {
        return{
          ...this.importMapper.toItemDto(item),
          ...{
            ItemStatus: item.status
          }
        }
      }
    );
  }

}