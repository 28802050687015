<ng-container
  *ngIf="{
    transaction: transaction$ | async,
    config: serviceConfig$ | async
  } as both"
>
  <div class="form-section">
    <div class="row" *ngIf="both.config.alerts.length">
      <div class="col-sm-12">
        <app-es-service-disclaimers
          [alerts]="both.config.alerts"
        ></app-es-service-disclaimers>
      </div>
    </div>
  </div>

  <div class="main-form">
    <div class="form-section">
      <app-cites-importer-review
        [importerDetails]="both.transaction.serviceTransaction?.importerDetails"
        [formOptions]="formOptions"
      >
      </app-cites-importer-review>
    </div>
    <div class="form-section">
      <app-cites-exporter-review
        [exporterDetails]="both.transaction.serviceTransaction?.exporterDetails"
        [formOptions]="formOptions"
      >
      </app-cites-exporter-review>
    </div>
    <div class="form-section">
      <app-form-validation-indicator [formGroup]="form">
      </app-form-validation-indicator>
      <app-cites-release-species-request-form
        [lookups]="both.config.mainLookups"
        [formGroup]="form"
        #mainFormDir="ngForm"
        [alerts]="both.config.alerts"
        [transaction]="both.transaction"
        [fields]="fields$ | async"
        (change)="mainFormChange($event)"
        [formOptions]="formOptions"
      >
      </app-cites-release-species-request-form>
    </div>
  </div>

  <div class="form-section consignment-item-form">
    <app-cites-release-species-item-form
      [lookups]="both.config.mainLookups"
      [item]="
        workflowService.globalObject.ServiceDetailsConfiguration.itemInstance
      "
      [formGroup]="formItem"
      #consignmentFormDir="ngForm"
      (push)="pushItem($event)"
      [mode]="formItemStatus.mode"
      (itemFormChange)="itemsFormChange($event)"
      [formOptions]="formOptions"
    >
    </app-cites-release-species-item-form>

    <app-cites-release-species-item-list
      [formGroup]="form"
      [items]="both.transaction.serviceTransaction.releaseItems"
      [enableSelection]="both.config.enablePartialRelease"
      [maxItems]="both.config.maxAllowedReleaseQuanity"
      (edit)="edit($event)"
      (delete)="deleteItem($event)"
    >
    </app-cites-release-species-item-list>
  </div>
</ng-container>
