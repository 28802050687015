import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseServiceRequestStore } from 'src/app/shared/base/BaseServiceRequestStore';
import { FormStatus } from 'src/app/shared/base/FormStatus';
import { CitesFormFields } from './CitesFormFields';

export class CitesServiceRequestStore<
  TTransaction
> extends BaseServiceRequestStore {
  form: FormGroup;
  formItem: FormGroup;

  url: string;

  formItemStatus: FormStatus;
  itemInstance?: any;

  serviceDetails: TTransaction;
  serviceDetails$?: BehaviorSubject<TTransaction>;

  fields$: BehaviorSubject<CitesFormFields>;

  isValid: boolean;

  initiatorActionForm: FormGroup;
}
