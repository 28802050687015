import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { FieldOptions } from '../base/FieldOptions';
import { SectionOptions } from '../base/SectionOptions';

@Injectable()
export class FieldOptionsService {
  constructor(private datePipe: DatePipe) {}

  /// Form control name must match the name of property of the field option to be synced
  public syncControlsFromFelidsOptions(
    controls: { [key: string]: AbstractControl },
    fields: any
  ) {
    Object.keys(fields).forEach((fieldName) => {
      if ((fields as any)[fieldName] instanceof FieldOptions) {
        if (controls[fieldName]) {
          const options = (fields as any)[fieldName] as FieldOptions;
          const control = controls[fieldName];

          if (options.isRequired != undefined) {
            options.isRequired
              ? control.addValidators(Validators.required)
              : control.removeValidators(Validators.required);
            control.updateValueAndValidity();
          }

          if (options.isDisabled != undefined) {
            if (
              options.isDisabledToggleOnly &&
              options.isDisabled == control.disabled
            ) {
              ///skip enable/disable as disabled state is same
            } else {
              options.isDisabled ? control.disable() : control.enable();
            }
          }

          if (options.value != undefined || options.value === null) {
            if (options.value instanceof Date) {
              control.setValue(this.convertToDisplayDate(options.value));
            } else {
              if (fieldName == 'veterinarianIdentity') {
                console.log(options.value);
              }
              control.setValue(options.value);
            }
          }

          if (options.isReset) control.reset();

          if (options.markAsTouched) control.markAsTouched();
        }
      } else if ((fields as any)[fieldName] instanceof SectionOptions) {
        const options = (fields as any)[fieldName] as SectionOptions;

        this.syncControlsFromFelidsOptions(controls, options.fields);
      }
    });
  }

  public convertToDisplayDate(date: Date): string {
    if (date) {
      if (date.toString() === 'Invalid Date') {
        console.warn('Date is invalid');
        return null;
      }

      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    return null;
  }
}
