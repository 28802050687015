<!-- // -->

<div id="collapse2" class="panel-collapse mobile_popup direction_rtl mt-3">
  <div class="panel-body panelstatpads show-details stat-1">
    <div
      class="container"
      [ngClass]="{
        arrow_second: isEnglish,
        arrow_third_ar: isArabic
      }"
    >
      <div class="row direction_rtl">
        <div class="col-sm-10 alignment_right">
          <h4
            class="bold my-1"
            [innerText]="isArabic ? packageObject.NameAR : packageObject.NameEN"
          ></h4>
        </div>

        <div class="col-xs-2 alignmet_left">
          <div class="text-right my-1 cursor-pointer alignmet_left">
            <a (click)="closeServiceDetails()"
              ><img src="assets/Images/Icons/cancel-24px (white).png"
            /></a>
          </div>
        </div>
      </div>

      <div class="row">
        <div
          class="col-sm-12"
          *ngFor="
            let certificateDetail of certificateDetailsObjectDataList
              | paginate
                : {
                    id: 'pagination2',
                    itemsPerPage: certificateDetailPageSize,
                    currentPage: certificateDetailPageIndex,
                    totalItems: certificateDetailTotalItems
                  }
          "
        >
          <div class="card-2">
            <div class="row">
              <div class="col-xl-2 col-md-3 my-auto">
                <h5 class="color-9 bold alignment_right">
                  {{ certificateDetail.CertificateNumber }}
                </h5>
                <label
                  class="page-color-1 alignment_right arbic_width_100"
                  [innerText]="
                    isArabic
                      ? certificateDetail?.CertificateType?.NameAr
                      : certificateDetail?.CertificateType?.NameEn
                  "
                >
                </label>
              </div>
              <div
                *ngIf="
                  certificateDetail != null && certificateDetail.Status != null
                "
                class="col-xl-1 col-lg-2 col-md-2 col-12 my-auto"
              >
                <p
                  *ngIf="
                    certificateDetail.Status.toLocaleLowerCase() == 'expired'
                  "
                  class="pt-11 expired bold position"
                >
                  {{ 'ExpiredLicenses' | translate }}
                </p>

                <p
                  *ngIf="
                    certificateDetail.Status.toLocaleLowerCase() == 'active'
                  "
                  class="pt-11 active bold position"
                >
                  {{ 'ActiveLicenses' | translate }}
                </p>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6 col">
                <div class="row mb-2">
                  <div
                    class="col-xl-4 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold"
                      >{{ 'ServiceDetailsPracticingDate' | translate }}
                    </label>
                    <p class="bold">
                      {{
                        certificateDetail.PracticingDate | date : 'dd/MM/yyyy'
                      }}
                    </p>
                  </div>
                  <div
                    class="col-xl-4 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold">
                      {{ 'ServiceDetailsIssuedDate' | translate }}
                    </label>
                    <p class="bold">
                      {{ certificateDetail.IssueDate | date : 'dd/MM/yyyy' }}
                    </p>
                  </div>
                  <div
                    class="col-xl-4 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold"
                      >{{ 'ServiceDetailsExpiredDate' | translate }}
                    </label>
                    <p class="bold">
                      {{ certificateDetail.ExpiryDate | date : 'dd/MM/yyyy' }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold">
                      {{ 'CertificateDetailsNumberOfUnpaidFines' | translate }}
                    </label>
                    <p class="bold">0</p>
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-6 my-auto pt-55 direction_rtl alignment_right"
                  >
                    <label class="label-color font-12 bold">
                      {{ 'CertificateDetailsAmountsOfUnpaidFines' | translate }}
                    </label>
                    <p class="bold">0 {{ 'Currency' | translate }}</p>
                  </div>
                </div>
              </div>

              <div
                class="col-xl-5 col-lg-12 pt-3 my_custom_tooltip my-auto"
                style="display: none"
              >
                <div
                  class="row border-bottom mb-2"
                  style="font-size: 7px; font-weight: 700; padding-bottom: 10px"
                >
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-add_activity"
                      style="font-size: 22px"
                    ></span>
                    Add Activity
                  </a>
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-cancel_activity"
                      style="font-size: 22px"
                    ></span>
                    Cancel Activity
                  </a>
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-get_app-24px"
                      style="font-size: 22px"
                    ></span>
                    View Certificate
                  </a>
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-policy-24px"
                      style="font-size: 22px"
                    ></span
                    >Inspections
                  </a>
                </div>
                <div class="row" style="font-size: 7px; font-weight: 700">
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-visibility-24px-1"
                      style="font-size: 22px"
                    ></span
                    >View Request
                  </a>
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-renewal-24px"
                      style="font-size: 22px"
                    ></span
                    >Renewal
                  </a>
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-cancel_license"
                      style="font-size: 22px"
                    ></span>
                    Cancel License
                  </a>
                  <a
                    target="_blank"
                    class="col-3 text-center padding_ico textShortToolTipShow cursor-pointer"
                    href=""
                  >
                    <span
                      container="body"
                      tooltipClass="golden_tooltip"
                      ngbTooltip="tooltip text"
                      class="d-block icon-service_cog"
                      style="font-size: 22px"
                    ></span
                    >Related Services
                  </a>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>

        <div class="row fr">
          <div class="col-md-12 col-xs-12">
            <pagination-controls
              id="pagination2"
              previousLabel="{{ 'PaginationPreviousLabel' | translate }}"
              nextLabel="{{ 'PaginationNextLabel' | translate }}"
              (pageChange)="onCertificatePageChange($event)"
            >
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
