import { Directive, ElementRef, HostListener } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
  selector: '[appInputComponentsInvalidScroller]',
})
export class InputComponentsInvalidScrollerDirective {
  constructor(
    private el: ElementRef,
    private formGroupDir: FormGroupDirective
  ) {}

  @HostListener('ngSubmit') onSubmit() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('.is-invalid') ||
      this.el.nativeElement.querySelector('.ng-invalid');

    this.formGroupDir.control.markAllAsTouched();

    if (firstInvalidControl) {
      let controlTopOffset = this.getTopOffset(firstInvalidControl);

      window.scroll({
        top: controlTopOffset,
        left: 0,
        behavior: 'smooth',
      });

      if (this.needScrolling(controlTopOffset))
        this.highlightControl(firstInvalidControl);

      fromEvent(window, 'scroll')
        .pipe(debounceTime(100), take(1))
        .subscribe(() => {
          firstInvalidControl.focus();
          this.highlightControl(firstInvalidControl);
        });
    }
  }

  private needScrolling(controlTopOffset: number) {
    return Math.round(window.screenY) != controlTopOffset;
  }

  private highlightControl(firstInvalidControl: HTMLElement) {
    firstInvalidControl.classList.add('highlight');
    setTimeout(() => firstInvalidControl.classList.remove('highlight'), 500);
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 100;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
