import { Pipe, PipeTransform } from '@angular/core';
import { FavoriteServiceHybrid } from '../../../core/api-services/shared/services/ServicesListDataService';


@Pipe({
  name: 'favoriteServicesFilter',
})
export class FavoriteServicesFilterPipe implements PipeTransform {
  public transform(value: FavoriteServiceHybrid[], isShowAllServices: boolean) {
    return (value || []).filter(
      (service: FavoriteServiceHybrid) => service.isFavorite == true ||
        service.isFavoriteDeleted == true ||
        isShowAllServices
    );
  }
}
