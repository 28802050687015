import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as serviceRequestData from '../../core/models/common/ServiceRequestData';
import {WorkflowActionsCodesEnum, WorkFlowTransactionCustomerEmpChatMessages} from '../../core/models/common/ServiceMainTransactionData';
import {UtilService} from '../../core/utilities/util.service';
@Component({
  selector: 'app-send-back-communication-messages',
  templateUrl: './send-back-communication-messages.component.html',
  styleUrls: ['./send-back-communication-messages.component.scss']
})
export class SendBackCommunicationMessagesComponent implements OnInit, OnChanges {
  @Input('sendBackMessages') sendBackMessages: WorkFlowTransactionCustomerEmpChatMessages[] = [];
  constructor(public utilService: UtilService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
  get WorkflowActionsCodesEnum(): typeof WorkflowActionsCodesEnum{
    return WorkflowActionsCodesEnum;
  }

}
