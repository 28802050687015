import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CitesImportSpeciesServiceRequestConfiguration } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesServiceRequestConfiguration';
import { CitesImportSpeciesItem, CitesImportSpeciesTransaction } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { CitesRequestBaseComponent } from '../../shared/base/CitesRequestBaseComponent';
import { CitesMainFormChangeEmitterData, CitesMainFormChangeType } from '../../shared/cites-main-form/CitesMainFormChangeEmitterData';
import { CitesItemFormChangeEmitterData } from '../../shared/CitesItemFormChangeEmitterData';
import { CitesImportSpeciesWorkflowService } from '../cites-import-species-workflow.service';

@Component({
  selector: 'app-cites-import-species-request',
  templateUrl: './cites-import-species-request.component.html',
})

export class CitesImportSpeciesRequestComponent
  extends CitesRequestBaseComponent<any>
  implements OnInit
{  
  transaction$: Observable<CitesImportSpeciesTransaction>;
  serviceConfig$: Observable<CitesImportSpeciesServiceRequestConfiguration>;
  constructor(
    @Inject('WorkflowService')
    public workflowService: CitesImportSpeciesWorkflowService,
    router: Router,
    translateService: TranslateService
  ) {
    super(workflowService, router, translateService);
  }

  ngOnInit(): void {
    this.subscribeToFormsTemplatesSettings();
    this.workflowService.setCurrentStep(
      WorkflowSteps.ServiceDetailsConfiguration
    );
  }

  async mainFormChange($event: CitesMainFormChangeEmitterData) {
    if($event.type == CitesMainFormChangeType.CountryChange)
      this.workflowService.onCountryChange($event.value);
    else if($event.type == CitesMainFormChangeType.PurposeChange)
      this.workflowService.onPurposeChange($event.value);
    else if($event.type == CitesMainFormChangeType.IsSelfExported)
      this.workflowService.onSelfExported($event.value);
  }

  itemsFormChange($event: CitesItemFormChangeEmitterData) {
    if($event.type == CitesMainFormChangeType.ClassChange){
      this.workflowService.onClassChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.AppendixChange){
      this.workflowService.onAppendixChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.TypeChange){
      this.workflowService.onTypeChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.IsRelatedChange){
      this.workflowService.onIsRelatedChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.SourceChange){
      this.workflowService.onSourceChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.RingNumberChange){
      this.workflowService.onRingNumberChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.ChipNumberChange){
      this.workflowService.onChipNumberChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.TypeDescriptionChange){
      this.workflowService.onTypeDescriptionChange($event.value);
    }
    else if($event.type == CitesMainFormChangeType.CountryChange){
      this.workflowService.onOriginCountryChange($event.value);
    }
  }

  copy($event: CitesImportSpeciesItem){
    this.workflowService.copyItem($event);
  }
}
