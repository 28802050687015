import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  GetDraftRequestResponse,
  SaveAsDraftRequestDto,
  SaveAsDraftResponse,
} from './SaveAsDraftRequest';
import { HttpClient } from '@angular/common/http';
import { EsBaseTemplateService } from '../es-base-template.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EsSaveAsDraftService extends EsBaseTemplateService {
  basePath: string = 'DraftProcedureInstance';

  private POST_DRAFT_PROCEDURE_INSTANCE = 'Draft';
  private GET_DRAFT_PROCEDURE_INSTANCE =
    'Get?draftProcedureInstanceId={draftProcedureInstanceId}';
  private CANCEL_DRAFT_PROCEDURE_INSTANCE =
    'Cancel?draftProcedureInstanceId={draftProcedureInstanceId}';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public saveAsDraft(
    request: SaveAsDraftRequestDto
  ): Observable<SaveAsDraftResponse> {
    const url = this.buildUrl(this.POST_DRAFT_PROCEDURE_INSTANCE);

    return this.httpClient
      .post<SaveAsDraftResponse>(url, request)
      .pipe(catchError(this.handleError));
  }

  public getDraftRequest(
    draftProcedureInstanceId: string
  ): Observable<GetDraftRequestResponse> {
    const url = this.buildUrl(this.GET_DRAFT_PROCEDURE_INSTANCE).replace(
      '{draftProcedureInstanceId}',
      draftProcedureInstanceId
    );

    return this.httpClient
      .get<GetDraftRequestResponse>(url)
      .pipe(catchError(this.handleError));
  }

  public cancelDraftRequest(
    draftProcedureInstanceId: string
  ): Observable<GetDraftRequestResponse> {
    const url = this.buildUrl(this.CANCEL_DRAFT_PROCEDURE_INSTANCE).replace(
      '{draftProcedureInstanceId}',
      draftProcedureInstanceId
    );

    return this.httpClient
      .put<GetDraftRequestResponse>(url, null)
      .pipe(catchError(this.handleError));
  }
}
