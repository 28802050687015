import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesReleaseSpeciesTransaction } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesTransaction';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-cites-release-species-request-review-details',
  templateUrl: './cites-release-species-request-review-details.component.html',
})

export class CitesReleaseSpeciesReviewDetailsComponent extends BaseComponent {
  @Input()
  transaction: CitesReleaseSpeciesTransaction;
  
  constructor(translate: TranslateService) {
    super(translate);
  }
}