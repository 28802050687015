import { Lookup } from "src/app/core/models/common/Lookup";

export class SourceCodesData{
    static data: Lookup[] =[
        {
            id:1,
            code:"W",
            nameEn:"Specimens taken from the wild ",
            nameAr:"عينات مأخوذة من البرية"
        },
        {
            id:2,
            code:"R",
            nameEn:"Ranched specimens: specimens of animals reared in a controlled environment, taken as eggs or juveniles from the wild, where they would otherwise have had a very low probability of surviving to adulthood ",
            nameAr:"عينات مستزرعة: عينات من الحيوانات التي تم تربيتها في بيئة متحكم بها، تم أخذها كبيض أو صغار من البرية، حيث كانت ستواجه احتمالية منخفضة جدًا للبقاء حتى مرحلة البلوغ."
        },
        {
            id:3,
            code:"D",
            nameEn:`Appendix-I animals bred in captivity for commercial purposes in operations included in the Secretariat's Register, in accordance with CITES <a target="_blank" href="https://cites.org/sites/default/files/document/E-Res-12-10-R15.pdf">Resolution Conf. 12.10 (Rev. CoP15)</a>, and Appendix-I plants artificially propagated for commercial purposes, as well as parts and derivatives thereof, exported under the provisions of Article VII, paragraph 4, of the Convention `,
            nameAr:`الحيوانات المدرجة في الملحق الأول والمرباة في الأسر لأغراض تجارية في العمليات المدرجة في سجل امانة اتفاقية سايتس ، وفقًا لقرار اتفاقية سايتس رقم <a target="_blank" href="https://cites.org/sites/default/files/document/E-Res-12-10-R15.pdf">Conf. 12.10 (Rev. CoP15)</a>، والنباتات المدرجة في الملحق الأول التي تم اكثارها بشكل اصطناعي لأغراض تجارية، وكذلك أجزائها ومشتقاتها، والتي يتم تصديرها بموجب أحكام المادة السابعة، الفقرة 4، من الاتفاقية.`
        },
        {
            id:4,
            code:"A",
            nameEn:`Plants that are artificially propagated in accordance with CITES <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-11-11-R18.pdf">Resolution Conf. 11.11 (Rev. CoP18)</a>, as well as parts and derivatives thereof, exported under the provisions of Article VII, paragraph 5 (specimens of species included in Appendix I that have been propagated artificially for non-commercial purposes and specimens of species included in Appendices II and III) `,
            nameAr:`النباتات التي تم اكثارها بشكل اصطناعي وفقًا لقرار اتفاقية سايتس رقم <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-11-11-R18.pdf">Conf. 11.11 (Rev. CoP18)</a>، وكذلك أجزائها ومشتقاتها، والتي يتم تصديرها بموجب أحكام المادة السابعة، الفقرة 5 (عينات الأنواع المدرجة في الملحق الأول التي تم اكثارها بشكل اصطناعي لأغراض غير تجارية وعينات الأنواع المدرجة في الملاحق الثاني والثالث). `
        },
        {
            id:5,
            code:"C",
            nameEn:`Animals bred in captivity in accordance with CITES <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-10-16-R19.pdf">Resolution Conf. 10.16 (Rev.)</a>, as well as parts and derivatives thereof, exported under the provisions of Article VII, paragraph 5 `,
            nameAr:`الحيوانات التي تم تربيتها في الأسر وفقًا لقرار اتفاقية سايتس رقم <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-10-16-R19.pdf">Conf. 10.16 (Rev.)</a>، وكذلك أجزائها ومشتقاتها، والتي يتم تصديرها بموجب أحكام المادة السابعة، الفقرة 5 من الاتفاقية.`
        },
        {
            id:6,
            code:"Y",
            nameEn:`Specimens of plants that fulfil the definition for ‘assisted production’ in CITES <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-11-11-R18.pdf">Resolution Conf. 11.11 (Rev. CoP18)</a> as well as parts and derivatives thereof `,
            nameAr:`عينات من النباتات التي تفي بتعريف "الإنتاج المساعد" في قرار اتفاقية سايتس رقم <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-11-11-R18.pdf">Conf. 11.11 (Rev. CoP18)</a>، وكذلك أجزائها ومشتقاتها.`
        },
        {
            id:7,
            code:"F",
            nameEn:`Animals born in captivity (F1 or subsequent generations) that do not fulfil the definition of ‘bred in captivity’ in CITES <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-10-16-R19.pdf">Resolution Conf. 10.16 (Rev.)</a>, as well as parts and derivatives thereof `,
            nameAr:`الحيوانات التي ولدت في الأسر (الجيل الأول أو الأجيال اللاحقة) والتي لا تفي بتعريف "المرباة في الأسر" في قرار اتفاقية سايتس رقم <a target="_blank" href="https://cites.org/sites/default/files/documents/COP/19/resolution/E-Res-10-16-R19.pdf">Conf. 10.16 (Rev.)</a>، وكذلك أجزائها ومشتقاتها.`
        },
        {
            id:8,
            code:"U",
            nameEn:"Source unknown (must be justified)",
            nameAr:"المصدر غير معروف "
        },
        {
            id:9,
            code:"I",
            nameEn:"Confiscated or seized specimens ",
            nameAr:"عينات مصادرة أو محجوزة"
        },
        {
            id:10,
            code:"O",
            nameEn:"Pre-Convention specimens ",
            nameAr:"عينات ما قبل الاتفاقية"
        },
    ];
}