import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError, ObservableInput } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/authentication/auth.service';
import { RedirectService } from 'src/app/shared/authentication/redirect.service';
import {
  ResponseModel,
  ToastMessageType,
} from '../models/shared/CommonResponseDTO';
import { catchError } from 'rxjs/operators';
import { AuthGuardCheckerService } from 'src/app/shared/authentication/auth-guard-checker.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    public auth: AuthService,
    public redirectService: RedirectService,
    private translateService: TranslateService,
    private authGuardCheckerService: AuthGuardCheckerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => this.redirectToLogin(error)),
      catchError((error) => this.handleDigitalServicesError(error)),
      catchError((error) => this.handleEServicesError(error))
    );
  }

  private redirectToLogin(error: any): ObservableInput<any> {
    if (
      this.authGuardCheckerService.hasAuthGuard(
        this.route.snapshot,
        this.router.routerState.snapshot
      )
    ) {
      if (environment.production && this.auth.isAuthenticated() == false) {
        this.redirectService.redirectToLoginPage();
      }
    }

    return throwError(error);
  }

  private handleDigitalServicesError(error: any): ObservableInput<any> {
    this.showDigitalServicesGenericError(error);

    return throwError(error);
  }

  private handleEServicesError(error: any): ObservableInput<any> {
    this.showEServicesGenericError(error);

    return throwError(error);
  }

  private showEServicesGenericError(error: any) {
    if (error.url == environment.ipInfoUrl) {
      //don't show error for ip info call even if failed.
      return;
    }

    if (error.error && error.error.Message) {
      let messageKey = `trEServiceErrors.${error.error.Message}`;

      if (
        error.error.Data?.IsDataValid == false &&
        error.error.Data.Validations?.length
      ) {
        if (
          this.hasTranslation(
            `trEServiceErrors.ValidationErrors.${error.error.Data.Validations[0].Key}`
          )
        )
          messageKey = `trEServiceErrors.ValidationErrors.${error.error.Data.Validations[0].Key}`;
      }

      let message = this.errorOrGeneric(messageKey);

      this.toastr.error(message, '', {
        timeOut: 12000,
      });

      return;
    }

    this.translateService.get('GenericError').subscribe((res) => {
      this.toastr.error(res, '', {
        timeOut: 12000,
      });
    });
  }

  private showDigitalServicesGenericError(error: any): void {
    if (error.error && error.error.errorResponse) {
      const responseError = error.error as ResponseModel<any>;

      let errorMessage = responseError.errorResponse.errorMessageEn;

      if (this.translateService.currentLang === 'ar') {
        errorMessage = responseError.errorResponse.errorMessageAr;
      }
      switch (responseError.errorResponse.messageType) {
        case ToastMessageType.Error:
          this.toastr.error(errorMessage);
          break;
        case ToastMessageType.Info:
          this.toastr.info(errorMessage);
          break;
        case ToastMessageType.Success:
          this.toastr.success(errorMessage);
          break;
        case ToastMessageType.Warning:
          this.toastr.warning(errorMessage);
          break;
      }
    }
  }

  private errorOrGeneric(messageKey: string): string {
    if (this.hasTranslation(messageKey)) {
      return this.translateService.instant(messageKey);
    }

    return this.translateService.instant('trEServiceErrors.GeneralError');
  }

  private hasTranslation(messageKey: string): boolean {
    let message = this.translateService.instant(messageKey);

    return message != messageKey;
  }
}
