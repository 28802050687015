import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-list-filter',
  templateUrl: './list-filter.component.html',
})
export class ListFilterComponent implements OnInit {
  searchForm: FormGroup;

  private searchSubscription?: Subscription;
  private readonly searchSubject = new Subject<string | undefined>();

  @Output()
  search = new EventEmitter<any>();

  @Input()
  debounceTime: number = 300;

  constructor(private formBuilder: FormBuilder) {
    this.searchForm = this.formBuilder.group({
      search: '',
    });
  }

  ngOnInit(): void {
    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe((searchQuery) =>
        this.search.emit({ searchText: searchQuery })
      );
  }

  clearSearch() {
    this.searchForm.reset();
    this.search.emit({ searchText: '' });
  }

  public onSearchQueryInput(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchSubject.next(searchQuery?.trim());
  }

  public ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }
}
