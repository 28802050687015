<div [formGroup]="searchForm" class="form-group">
  <label class="form-control-label" [innerText]="'trListFilter.labelText' | translate"></label>
  <div class="input-group">
    <input
      class="form-control"
      type="text"
      formControlName="search"
      (input)="onSearchQueryInput($event)"
    />
    <div class="input-group-append cursor-pointer" (click)="clearSearch()">
      <span class="input-group-text">
        <i class="fi-close-n"></i>
      </span>
    </div>
  </div>
</div>
