import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { EsBaseServiceRequest } from 'src/app/core/models/shared/EsBaseServiceRequest';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { FlowButtons } from 'src/app/shared/base/FlowButtons';
import { PageBaseComponent } from 'src/app/shared/base/PageBaseComponent';
import { WorkflowBaseService } from 'src/app/shared/base/WorkflowBaseService';
import { MainFormEventEmitterData } from '../../fishers/shared/FishersMainFormChangeEmitterData';
import {
  FormTemplateEnum,
  AppSettingsService,
} from 'src/app/shared/settings/app-settings.service';
import { FormOptions } from '../ds-col/FormOptions';
import { AppInjector } from 'src/app/app.module';
import {
  ColOptions,
  ColSpace,
  ScreenMediaSize,
} from '../ds-col/ds-col.component';

@Component({
  selector: 'app-es-request-page',
  templateUrl: './es-request-page.component.html',
  styleUrls: ['./../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EsRequestPageComponent extends PageBaseComponent {
  form: FormGroup;
  @ViewChild('mainFormDir') mainFormDir: FormGroupDirective;

  settingsService: AppSettingsService;

  serviceConfig$: Observable<EsServiceRequestConfiguration>;
  transaction$: Observable<EsBaseServiceRequest>;

  protected subscribeToFormsTemplatesSettings() {
    this.settingsService.setSupportFormsTemplates(true);
    this.settingsService.formsTemplate$.subscribe((t) => {
      this.changeHalfSize(t.template);
    });
  }

  private halfCol: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Half,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  private displayHalfCol: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Half,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  private fullCol: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  public get formOptions(): FormOptions {
    return {
      halfCol: this.halfCol,
      fullCol: this.fullCol,

      displayHalfCol: this.displayHalfCol,
    };
  }

  constructor(
    @Inject('WorkflowService')
    public workflowService: WorkflowBaseService,
    translateService: TranslateService
  ) {
    super(translateService);

    this.settingsService = AppInjector.get(AppSettingsService);

    this.serviceConfig$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.transaction$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceDetails$;

    this.subscribeToFormsTemplatesSettings();
  }

  public async mainFormChange($event: MainFormEventEmitterData) {
    await this.workflowService.onMainFormChange($event);
  }

  public async actionHandler($event: ActionEmitDataHolder) {
    this.workflowService.move($event);
  }

  public get buttons(): FlowButtons {
    return this.workflowService.globalObject.ServiceDetailsConfiguration
      .flowButtons;
  }

  public get isShowRequestTemplateForm(): boolean {
    return this.workflowService.globalObject.ServiceDetailsConfiguration
      .isShowRequestTemplateForm;
  }

  public get requestTemplateForm(): FormGroup {
    return this.workflowService.globalObject.ServiceDetailsConfiguration
      .formRequestTemplate;
  }

  public saveRequestTemplate(): void {
    this.workflowService.saveRequestTemplate();
  }

  private changeHalfSize(formTemplate: FormTemplateEnum): void {
    if (formTemplate == FormTemplateEnum.Default) {
      this.halfCol.maximumSize.colSpace = ColSpace.Half;
      return;
    }

    if (formTemplate == FormTemplateEnum.Horizontal) {
      this.halfCol.maximumSize.colSpace = ColSpace.Full;
      return;
    }
  }
}
