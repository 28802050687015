import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as getBinaryFileDataResponse from "../../models/common/GetBinaryFileDataResponse";
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class GetBinaryFileDataService {


  constructor(private httpClient: HttpClient) {
  }

  public getBinaryFileData(filePath: string) : Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.append("fileServer", 'fileServer');


    return this.httpClient.get<any>(filePath, {
      responseType: 'blob' as 'json',headers:headers}  )
    .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse : HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetBinaryFileDataService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetBinaryFileDataService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
