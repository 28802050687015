import { Component, Input } from "@angular/core";
import { CitesImporterDetails } from "src/app/core/models/cites/shared/CitesImporterDetails";
import { CitesBaseFormComponent } from "../base/CitesBaseFormComponent";

@Component({
    selector: 'app-cites-importer-review',
    templateUrl: './cites-importer-review.component.html',
  })
  
export class CitesImporterReviewComponent extends CitesBaseFormComponent {
  @Input()
  importerDetails: CitesImporterDetails;
}