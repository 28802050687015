import {
  Component,
  Injector,
  Inject,
  Input,
  SimpleChanges,
} from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { InputSettingsService } from '../../../input-settings.service';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';

@Component({
  selector: 'bs-ng-checkbox',
  templateUrl: './bs-ng-checkbox.component.html',
  providers: [MakeProvider(BsNgCheckboxComponent)],
})
export class BsNgCheckboxComponent extends BsNgInputBaseComponent {
  @Input()
  set: any = true;

  htmlLabel: string = '';

  evaluatedHtmlLabel: string = '';

  @Input() url = '';

  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }

  hasEqualTrueError() {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      !this.ngControl.control.errors.required &&
      this.ngControl.control.errors.equalTrue
    );
  }

  override assignTranslations(trs: any) {
    super.assignTranslations(trs);
    if (this.url) {
      this.htmlLabel = trs.htmlLabel;
      this.EvaluateHtmlLabel();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url?.currentValue != changes.url?.previousValue)
      this.EvaluateHtmlLabel();
  }

  private EvaluateHtmlLabel() {
    this.evaluatedHtmlLabel = this.htmlLabel.replace('{url}', this.url);
  }
}
