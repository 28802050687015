import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageBaseComponent } from 'src/app/shared/base/PageBaseComponent';

@Component({
  selector: 'app-es-view-page',
  templateUrl: './es-view-page.component.html',
})
export class EsViewComponent extends PageBaseComponent {
  constructor(translate: TranslateService) {
    super(translate);
  }
}
