<div class="steps-captions-container row">
  <div class="col-6 pt-2 pb-1 align-left">
    <h5 class="" [innerText]="'Back' | translate"></h5>
    <h4 class="bold f_14" [innerText]="backKey + '.labelText' | translate"></h4>
  </div>
  <div class="col-6 pt-2 pb-1 align-right" *ngIf="showNextCaption">
    <h5 class="" [innerText]="'Next' | translate"></h5>
    <h4 class="bold f_14" [innerText]="nextKey + '.labelText' | translate"></h4>
  </div>
</div>
