<form [formGroup]="formGroup">
    <legend
        class="col-form-label"
        [innerText]="'Importer Details' | translate">
    </legend>

    <div class="row">
        <div dsCol [colOptions]="fo.fullCol">
            <div *ngIf="formGroup.controls.importerName?.enabled">
                <bs-ng-text
                    formControlName="importerName"
                    identifier="importerName"
                    key="trCitesImporterNameFld"
                    name="importerName"
                    maxLength="200">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.importerName?.enabled">
                <app-bs-display
                    key="trCitesImporterNameFld"
                    [valueText]="importerDetails.importerName">
                </app-bs-display>
            </div>
        </div>
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.importerEmail?.enabled">
                <bs-ng-text
                    formControlName="importerEmail"
                    identifier="importerEmail"
                    key="trCitesEmailFld"
                    name="importerEmail"
                    maxLength="200">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.importerEmail?.enabled">
                <app-bs-display
                    key="trCitesEmailFld"
                    [valueText]="importerDetails.importerEmail">
                </app-bs-display>
            </div>
        </div> -->
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.importerPOBox?.enabled">
                <bs-ng-text
                    formControlName="importerPOBox"
                    identifier="importerPOBox"
                    key="trCitesPOBoxFld"
                    name="importerPOBox"
                    maxLength="200">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.importerPOBox?.enabled">
                <app-bs-display
                    key="trCitesPOBoxFld"
                    [valueText]="importerDetails.importerPOBox">
                </app-bs-display>
            </div>
        </div> -->
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.importerPhone?.enabled">
                <bs-ng-text
                    formControlName="importerPhone"
                    identifier="importerPhone"
                    key="trCitesPhoneFld"
                    name="importerPhone"
                    maxLength="20"
                    [minlength]="10"
                    [mask]="phoneNumberMask">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.importerPhone?.enabled">
                <app-bs-display
                    key="trCitesPhoneFld"
                    [valueText]="importerDetails.importerPhone">
                </app-bs-display>
            </div>
        </div> -->
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.importerFax?.enabled">
                <bs-ng-text
                    formControlName="importerFax"
                    identifier="importerFax"
                    key="trCitesFaxFld"
                    name="importerFax"
                    maxLength="20"
                    [mask]="faxMask">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.importerFax?.enabled">
                <app-bs-display
                    key="trCitesFaxFld"
                    [valueText]="importerDetails.importerPOBox">
                </app-bs-display>
            </div>
        </div> -->
        <div dsCol [colOptions]="fo.fullCol">
            <div *ngIf="formGroup.controls.importerCountry?.enabled">
                <bs-ng-dropdown
                    formControlName="importerCountry"
                    identifier="importerCountry"
                    key="trCitesImporterCountryFld"
                    name="importerCountry"
                    [items]="lookups.originCountries"
                    [bindLabel]="defaultLookupDisplayProperty">
                </bs-ng-dropdown>
            </div>
            <!-- <div *ngIf="!formGroup.controls.importerCountry?.enabled">
                <div *ngIf="importerDetails.importerCountry">
                    <app-bs-display
                        key="trCitesImporterCountryFld"
                        [valueText]="importerDetails.importerCountry | lookup:lang">
                    </app-bs-display>
                </div>
                <div *ngIf="importerDetails.importerCountry == null">
                    <app-bs-display
                        key="trCitesImporterCountryFld"
                        [valueText]="'trUAECountryName' | translate">
                    </app-bs-display>
                </div>
            </div> -->
        </div>
        <!-- <div dsCol [colOptions]="fo.halfCol">
            <div *ngIf="formGroup.controls.importerCityName?.enabled">
                <bs-ng-text
                    formControlName="importerCityName"
                    identifier="importerCityName"
                    key="trCitesCityFld"
                    name="importerCityName"
                    maxLength="50">
                </bs-ng-text>
            </div>
            <div *ngIf="!formGroup.controls.importerCityName?.enabled">
                <app-bs-display
                    key="trCitesCityFld"
                    [valueText]="importerDetails.importerCityName">
                </app-bs-display>
            </div>
        </div> -->
        <div dsCol [colOptions]="fo.fullCol">
            <div *ngIf="formGroup.controls.importerAddress?.enabled">
                <bs-ng-textarea
                    formControlName="importerAddress"
                    identifier="importerAddress"
                    key="trCitesImporterAddressFld"
                    name="importerAddress"
                    maxLength="250">
                </bs-ng-textarea>
            </div>
            <div *ngIf="!formGroup.controls.importerAddress?.enabled">
                <app-bs-display
                    key="trCitesImporterAddressFld"
                    [valueText]="importerDetails.importerAddress">
                </app-bs-display>
            </div>
        </div>
    </div>
</form>