<ng-container *ngIf="transaction$ | async as transaction">
  <div class="form-section">
    <app-cites-importer-review
      [importerDetails]="transaction.serviceTransaction?.importerDetails"
    >
    </app-cites-importer-review>
  </div>

  <div class="form-section">
    <app-cites-exporter-review
      [exporterDetails]="transaction.serviceTransaction?.exporterDetails"
    >
    </app-cites-exporter-review>
  </div>

  <div class="form-section">
    <app-cites-import-species-request-review-details
      [transaction]="transaction"
    >
    </app-cites-import-species-request-review-details>
  </div>

  <div
    class="form-section"
    *ngIf="
      transaction.serviceTransaction?.items &&
      transaction.serviceTransaction.items.length > 0
    "
  >
    <app-cites-import-species-item-list
      [items]="transaction.serviceTransaction.items"
      [enableEditing]="false"
    >
    </app-cites-import-species-item-list>
  </div>
</ng-container>