import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { CitesWorkflowMain } from './CitesWorkflowMain';
import { ReviewFlowStepBaseComponent } from 'src/app/shared/base/ReviewFlowStepBaseComponent';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { WorkflowSteps } from 'src/app/shared/base/WorkflowSteps';
import { EsRequestStatusCodesEnum } from 'src/app/core/models/shared/MainWorkflowStepStatusCodesEnum';
import { FormSectionOptions } from 'src/app/services/es-shared/es-form-section-directive/FormSectionOptions';
import { delay } from 'rxjs/operators';
import { CitesBaseServiceRequest } from 'src/app/core/models/cites/shared/CitesBaseServiceRequest';
import { EsFees } from 'src/app/core/models/shared/EsFees';

export abstract class CitesReviewFlowStepBaseComponent<
  TWorkflow extends CitesWorkflowMain
> extends ReviewFlowStepBaseComponent {
  fees$: Observable<EsFees>;
  config$: Observable<EsServiceRequestConfiguration>;
  form: FormGroup;
  serviceMetaData$: Subject<ServiceMetaData>;
  transaction$: Observable<CitesBaseServiceRequest<any>>;
  statusCode: EsRequestStatusCodesEnum;

  initiatorActionForm: FormGroup;

  requestDetailsFormSection: FormSectionOptions = {
    isCollapsible: true,
    render: true,
  };

  constructor(
    public workflowService: TWorkflow,
    protected router: Router,
    translate: TranslateService
  ) {
    super(translate);

    this.workflowService.setCurrentStep(WorkflowSteps.ReviewConfiguration);

    this.config$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.buttons =
      this.workflowService.globalObject.ReviewConfiguration.flowButtons;

    this.serviceMetaData$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceMetaData$;

    this.form = this.workflowService.globalObject.ReviewConfiguration.form;

    this.initiatorActionForm =
      this.workflowService.globalObject.ServiceDetailsConfiguration.initiatorActionForm;

    this.transaction$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceDetails$;

    this.transaction$.subscribe(async (t) => {
      if (this.workflowService.globalObject.ServiceDetailsConfiguration.id)
        this.statusCode = t.serviceRequestTransactionData?.statusCode;
    });

    this.fees$ = this.workflowService.getFees();

    this.subscribeToFees();
  }

  protected subscribeToFees() {
    this.fees$.pipe(delay(200)).subscribe((fees) => {
      if (fees && fees.total) {
        this.scrollToFees();
      }
    });
  }

  private scrollToFees() {
    document.querySelector('.fee-list-legend').scrollIntoView();
  }

  public async actionHandler($event: ActionEmitDataHolder) {
    this.workflowService.move($event);
  }

  get noPaymentButton(): boolean {
    return this.buttons.isShowPay == false || this.buttons.isShowPay == null;
  }

  get noSavedTransaction(): boolean {
    const isSaved =
      this.workflowService.globalObject.ServiceDetailsConfiguration.id > 0;

    return isSaved == false;
  }

  get serviceRequestFormSection(): FormSectionOptions {
    let result = new FormSectionOptions();

    result.isCollapsible = true;
    result.render =
      this.workflowService.globalObject.ServiceDetailsConfiguration.id > 0;

    return result;
  }

  get showCommentsSection(): boolean {
    return (
      this.statusCode &&
      this.statusCode == EsRequestStatusCodesEnum.InitiatorSendBack
    );
  }

  get showTermsAndConditionsSection(): boolean {
    return this.statusCode
      ? this.statusCode == EsRequestStatusCodesEnum.NewRequest
      : true; //new request status code is undefined
  }
}
