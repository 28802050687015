import { ValidatorFn, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

export function emiratesId(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== undefined) {
      return environment.formats.EMIRATES_ID.test(control.value)
        ? null
        : { emiratesIdFormat: true };
    }
    return null;
  };
}
