import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CitesReleaseSpeciesInitiateRequestDto } from 'src/app/core/models/cites/cites-release-species/CitesReleaseSpeciesInitiateRequestDto';
import { EsTemplateService } from '../../shared/es-template.service';

@Injectable()
export class CitesReleaseSpeciesService
    extends EsTemplateService<CitesReleaseSpeciesInitiateRequestDto> {
        readonly basePath: string = 'CITESRelease';

        private readonly _DownloadVerificationDocument = "DownloadVerifiedFile";

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    DownloadVerifiedDocument(certificateNo: string,requestId: number): Observable<string>{
        const url = this.buildUrl(
            `${this._DownloadVerificationDocument}?requestId=${requestId}&certificateNumber=${certificateNo}`
        );
    
        return this.httpClient
                .get<string>(url)
                .pipe(catchError(this.handleError));
    }
}
