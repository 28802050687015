import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainLookups } from 'src/app/core/models/shared/MainLookups';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { BaseFormComponent } from 'src/app/shared/base/BaseFormComponent';
import { CitesMainFormChangeEmitterData } from '../cites-main-form/CitesMainFormChangeEmitterData';
import { CitesFormFields } from './CitesFormFields';

@Component({ template: '' })
export abstract class CitesBaseFormComponent extends BaseFormComponent {
  @Input()
  lookups: MainLookups = {};

  @Output()
  change = new EventEmitter<CitesMainFormChangeEmitterData>();

  @Input()
  fields: CitesFormFields;

  @Input()
  alerts: AlertOptions[] = [];

  public readonly phoneNumberMask: string = '00000000009999999999';

  constructor(translateService: TranslateService) {
    super(translateService);
  }
}
