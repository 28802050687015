import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemFormActions } from 'src/app/core/enums/ItemFormActions.enum';
import { BaseItemListComponent } from 'src/app/shared/base/BaseItemListComponent';
import { CitesImportSpeciesWorkflowService } from '../cites-import-species-workflow.service';

@Component({
  selector: 'app-cites-import-species-item-list',
  templateUrl: './cites-import-species-item-list.component.html',
})

export class CitesImportSpeciesItemListComponent extends BaseItemListComponent {
  @Input() maxItemCount: number;
  @Output() copy: EventEmitter<any> = new EventEmitter();

  currentPage: number = 1;
  itemsPerPage: number = 4;

  public get isFalcon(): boolean {
    return this.items?.filter(i=>this.workflowService.isFalcon(i.class.code)).length == 
            this.items?.length;
  }

  constructor(
    @Inject('WorkflowService')
    public workflowService: CitesImportSpeciesWorkflowService,
    translateService: TranslateService) {
    super(translateService);
  }

  emitCopy($event: any,item: any) {
    this.copy.emit({data: item, action: ItemFormActions.copy});
    $event.stopPropagation();
  }

  override emitEdit(item: any,$event?: any,) {
    super.emitEdit(item);
    $event.stopPropagation();
  }

  override emitDelete(item: any,$event?: any) {
    if((this.items.length-1) % this.itemsPerPage == 0){
      this.currentPage--;
    }
    super.emitDelete(item);  
    $event.stopPropagation();  
  }
}