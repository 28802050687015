import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetServiceConfigurationResponse } from 'src/app/core/api-services/shared/GetServiceDetailsResponse';
import { CitesImportSpeciesServiceRequestConfiguration } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesServiceRequestConfiguration';
import { CitesImportSpeciesItemDto, CitesImportSpeciesServiceRequestDto, CitesImporttSpeciesRequestDataDto, CountryLookupDto } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesServiceRequestDto';
import { CitesImportSpeciesItem, CitesImportSpeciesTransaction, CitesImportSpeciesTransactionData } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { CitesExporterDetails } from 'src/app/core/models/cites/shared/CitesExporterDetails';
import { CitesImporterDetails } from 'src/app/core/models/cites/shared/CitesImporterDetails';
import { CitesImportExportRequestDataDto } from 'src/app/core/models/cites/shared/CitesImportExportRequestDataDto';
import { AlertOptions } from 'src/app/shared/bs-alert/AlertOptions';
import { LookupDto } from 'src/app/core/models/shared/LookupDto';
import { CountryLkp, SourceLkp } from 'src/app/core/models/shared/Lookups';
import { UserDetails } from 'src/app/core/models/shared/UserDetails';
import { CitesBaseMapper } from '../shared/CitesBaseMapper';
import { CitesSharedMapper } from '../shared/CitesSharedMapper';

@Injectable()
export class CitesImportSpeciesMapper extends CitesBaseMapper<CitesImportSpeciesTransaction> {

  get isArabic(): boolean {
    return this.translateService.currentLang == 'ar';
  }

  constructor(
    datePipe: DatePipe,
    private translateService: TranslateService,
    public sharedMapper: CitesSharedMapper
  ) {
    super(datePipe);
  }

  public mapGetServiceConfigurationResponse(
    response: GetServiceConfigurationResponse
  ): CitesImportSpeciesServiceRequestConfiguration {
    const config = super.mapBasicServiceConfigurationResponse(response) as CitesImportSpeciesServiceRequestConfiguration;
    
    const data = response.Data;
    config.procedureId = 364;//to be removed if returned from server
    config.mainLookups.originCountries = this.mapCountryLookup(data.Countries);
    config.mainLookups.genders = this.mapLookup(data.Sex);
    config.mainLookups.sources = this.mapSourceLookup(data.Source);
    config.mainLookups.appendixes = this.mapLookup(data.Appendicies);
    config.mainLookups.operationPurposes = config.filteredPurposes = this.mapPurposeLookup(data.OperationPurposes);
    config.mainLookups.classes = this.mapLookup(data.Classes);
    config.mainLookups.types = this.mapTypeLookup(data.TypeDescriptions);
    config.declarationUrl = data.DeclartionURL;
    config.validateQuantity = data.ValidateQuantity;

    config.mainLookups.sources.unshift(this.sharedMapper.unknownLkp);
    return config;
  }

  mapUltimate(dto: CitesImportSpeciesServiceRequestDto): 
    CitesImportSpeciesTransaction {
      let transaction = new CitesImportSpeciesTransaction();
      transaction = super.mapBasic(dto);
      transaction.userDetails = this.mapUserDetails(dto.UserData);
      transaction.citesOutputs = this.sharedMapper.mapCitesOutputs(dto.CITES_OutPuts);

      transaction.serviceRequestTransactionData = 
        this.mapGetServiceRequestDetailsResponse(dto.ProcedureInstanceObject);

      transaction.serviceTransaction = this.mapServiceRequest(
        dto.RequestData,
        transaction.userDetails
      );
      transaction.serviceTransaction.items = 
        this.mapItems(dto.RequestData.RequestItems);


      return transaction;
  }

  mapServiceRequest(
    request: CitesImporttSpeciesRequestDataDto,
    userDetails: UserDetails):CitesImportSpeciesTransactionData{
    return {
      id: request.ID,
      procedureInstanceId: request.ProcedureInstanceID,

      purpose: {
        id: request.OperationPurposeID,
        nameAr: request.OperationPurposeNameAr,
        nameEn: request.OperationPurposeNameEn,
      },
      arrivalDate: request.ExpectedArrivalDate,
      entryPort: {
        id: request.EntryPortID,
        nameAr: request.EntryPortNameAr,
        nameEn: request.EntryPortNameEn
      },
      specialCases: request.SpecialCases,

      exporterDetails: this.mapExporterDetails(request),
      importerDetails: this.mapImporterDetails(userDetails,request),
    };
  }

  mapItems(itemsDto: CitesImportSpeciesItemDto[]):
    CitesImportSpeciesItem[]{
      return itemsDto.map(itemDto =>
        {
          return this.mapItem(itemDto);
        }
      );
  }

  mapItem(itemDto: CitesImportSpeciesItemDto): CitesImportSpeciesItem{
    return{
      ...this.sharedMapper.mapItemDetails(itemDto),
      ...{
        id: itemDto.ID,
        requestId: itemDto.ParentRequestID,

        appendix: {
          id: itemDto.AppendixID,
          nameAr: itemDto.AppendixNameAr,
          nameEn: itemDto.AppendixNameEn,
          code: itemDto.AppendixCode
        },
        accompanyingCertificateNo: itemDto.AccompanyingCertNumber,
        accompanyingCertificateDate: itemDto.AccompanyingCertDate?
          this.asDateInstance(itemDto.AccompanyingCertDate) : null,
        isRelated: itemDto.RelatedTypeId? true: false,
        relatedType: itemDto.RelatedTypeId? {
          id: itemDto.RelatedTypeId,
          nameAr: itemDto.RelatedTypeNameAr,
          nameEn: itemDto.RelatedTypeNameEn,
        }: null
      }
    }
  }

  toDto(trans: CitesImportSpeciesTransactionData): 
    CitesImporttSpeciesRequestDataDto {
      let requestDto: CitesImporttSpeciesRequestDataDto = this.toRequestDto(trans);
      requestDto.RequestItems = this.toItemsDto(trans.items);
      return requestDto;
  }

  toRequestDto(trans: CitesImportSpeciesTransactionData):CitesImporttSpeciesRequestDataDto{
    return {
      ID: trans.id,
      ProcedureInstanceID: trans.procedureInstanceId,

      OperationPurposeID: trans.purpose?.id,
      ExpectedArrivalDate: trans.arrivalDate,
      EntryPortID: trans.entryPort?.id,
      SpecialCases: trans.specialCases,

      ParticipantAddress: trans.importerDetails?.importerAddress,

      SecondPartyName: trans.exporterDetails?.exporterName,
      SecondPartyCountryID: trans.exporterDetails?.exporterCountry.id,
      // SecondPartyCityName: trans.exporterDetails?.exporterCityName,
      SecondPartyAddress: trans.exporterDetails?.exporterAddress,
      // SecondPartyPhone: trans.exporterDetails?.exporterPhone,
      // SecondPartyEmail: trans.exporterDetails?.exporterEmail,
      // SecondPartyPOBox: trans.exporterDetails?.exporterPOBox,
      // SecondPartyFax: trans.exporterDetails?.exporterPhone
    };
  }

  toItemsDto(items: CitesImportSpeciesItem[]): CitesImportSpeciesItemDto[]{
    return items.map(item =>
      {
        return this.toItemDto(item);
      }
    );
  }

  toItemDto(item: CitesImportSpeciesItem): CitesImportSpeciesItemDto{
    return{
      ...this.sharedMapper.mapItemDetailsDto(item),
      ...{
        id: item.id,
        ParentRequestID: item.requestId,

        AccompanyingCertDate: item.accompanyingCertificateDate,
        AccompanyingCertNumber: item.accompanyingCertificateNo,
        RelatedTypeId: item.relatedType?.id,
        Quantity: item.isRelated ? 0 : item.quantity,
      }
    }
  }

  public mapExporterDetails(exporterDto?: CitesImportExportRequestDataDto): CitesExporterDetails {
    return {
      exporterName: exporterDto.SecondPartyName,
      // exporterEmail: exporterDto.SecondPartyEmail,
      // exporterPOBox: exporterDto.SecondPartyPOBox,
      // exporterPhone: exporterDto.SecondPartyPhone,
      // exporterFax: exporterDto.SecondPartyFax,
      // exporterCityName: exporterDto.SecondPartyCityName,
      exporterAddress: exporterDto.SecondPartyAddress,
      exporterCountry: {
        id: exporterDto.SecondPartyCountryID,
        nameAr: exporterDto.SecondPartyCountryNameAr,
        nameEn: exporterDto.SecondPartyCountryNameEn
      }
    }
  }

  public mapImporterDetails(
    userDetails: UserDetails,
    importerDto?: CitesImportExportRequestDataDto):CitesImporterDetails
  {
    return {
      importerName: userDetails.nameEn,
      // importerCityName: userDetails.city,
      // importerPhone: userDetails.mobile,
      // importerEmail: userDetails.email,
      // importerPOBox: userDetails.poBox,
      // importerFax: userDetails.fax,
      importerAddress: importerDto?.ParticipantAddress?? userDetails.address,
    }
  }

  private mapCountryLookup(lookupArray: CountryLookupDto[]):CountryLkp[]{
    return lookupArray.map((i) => {
      return {
        nameAr: i.NameAr,
        nameEn: i.NameEn,
        id: i.Id,
        code: i.Code,
        parentId: i.ParentID,
        showDeclaration: i.ShowDeclaration
      }
    });
  }

  mapAlertFromLookup(items: LookupDto[]): AlertOptions[] {
    return items.map((item: LookupDto) => {
      return {
        textAr: item.NameAr,
        textEn: item.NameEn,
        code: item.Code,
        display: true,
      };
    });
  }
}