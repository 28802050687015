<div class="card-2 mb-1 pt-1">
  <div class="row">
    <div class="col">
      <h3 [innerText]="'MyRequests' | translate"></h3>
    </div>
  </div>
      
  <div class="row">
    <div class="col-lg-4 col-md-6 mt-2">
      <div class="dashbord-form-group">
        <label>{{ 'RequestNumber' | translate }}</label>
        <input
          [(ngModel)]="filterObject.requestNumber"
          placeholder="{{'Insert' | translate}} {{'RequestNumber' | translate }}"
        />
      </div>
    </div>

    <div class="col-lg-4 col-md-6 mt-2">
      <div class="dashbord-form-group">
        <label>{{ 'ServiceName' | translate }}</label>
        <ng-select
          id="service"
          name="service"
          [items]="lookups?.services"
          [(ngModel)]="filterObject.procedureId"
          [searchable]="true"
          dropdownPosition="bottom"
          [bindLabel]="defaultLookupDisplayProperty"
          bindValue="id"
          placeholder="{{'Select' | translate}} {{ 'ServiceName' | translate }}">
        </ng-select>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 mt-2">
      <div class="dashbord-form-group">
        <label>{{ 'ServiceStatus' | translate }}</label>
        <ng-select
          id="status"
          class="select_custom_style"
          name="status"
          [items]="lookups?.statuses"
          [(ngModel)]="filterObject.statusCode"
          [searchable]="true"
          dropdownPosition="bottom"
          [bindLabel]="defaultLookupDisplayProperty"
          bindValue="code"
          placeholder="{{'Select' | translate}} {{ 'ServiceStatus' | translate }}">
        </ng-select>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 mt-2">
      <div class="dashbord-form-group">
        <label>{{ 'RequestDateFrom' | translate }}</label>
        <input
          type="date"
          [(ngModel)]="filterObject.requestDateFrom"/>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 mt-2">
      <div class="dashbord-form-group">
        <label>{{ 'RequestDateTo' | translate }}</label>
        <input
          type="date"
          [(ngModel)]="filterObject.requestDateTo"
        />
      </div>
    </div>

    <ng-container *ngIf="showAdvancedSearch">
      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'CertificateNo' | translate }}</label>
          <input
            [(ngModel)]="filterObject.certificateNumber"
            placeholder="{{'Insert' | translate}} {{ 'CertificateNo' | translate }}"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'RingNo' | translate }}</label>
          <input
            [(ngModel)]="filterObject.ringNumber"
            placeholder="{{'Insert' | translate}} {{ 'RingNo' | translate }}" />
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'ConsignmentArrivalDateFrom' | translate }}</label>
          <input
            type="date"
            [(ngModel)]="filterObject.arrivalDateFrom"/>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'ConsignmentArrivalDateTo' | translate }}</label>
          <input
            type="date"
            [(ngModel)]="filterObject.arrivalDateTo"/>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'ConsignmentDepartureDateFrom' | translate }}</label>
          <input
            type="date"
            [(ngModel)]="filterObject.departureDateFrom"/>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'ConsignmentDepartureDateTo' | translate }}</label>
          <input
            type="date"
            [(ngModel)]="filterObject.departureDateTo"/>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'EntryPort' | translate }}</label>
          <ng-select
            id="entryPort"
            name="entryPort"
            [items]="lookups?.ports"
            [(ngModel)]="filterObject.entrypotyId"
            placeholder="{{'Select' | translate}} {{ 'EntryPort' | translate }}"
            [searchable]="true"
            dropdownPosition="bottom"
            [bindLabel]="defaultLookupDisplayProperty"
            bindValue="id">
          </ng-select>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 mt-2">
        <div class="dashbord-form-group">
          <label>{{ 'ExitPort' | translate }}</label>
          <ng-select
            id="exitPort"
            name="exitPort"
            [items]="lookups?.ports"
            [(ngModel)]="filterObject.exitPortId"
            placeholder="{{'Select' | translate}} {{ 'ExitPort' | translate }}"
            [searchable]="true"
            dropdownPosition="bottom"
            [bindLabel]="defaultLookupDisplayProperty"
            bindValue="id">
          </ng-select>
        </div>
      </div>
    </ng-container>

    <div class="col-lg-4 col-md-6 mt-4 pl-4 pr-4">
      <div class="row">
        <div class="col-6">
          <button type="button" class="ds-btn ds-btn-primary btn-block " (click)="onSearchClicked()">
            <i class="fa fa-search mt-2 mr-2"></i>
            <span>{{ 'Search' | translate }}</span>
          </button>
        </div>
        <div class="col-6">
          <button type="button" class="ds-btn ds-btn-secondary btn-block" (click)="onAdvancedSearchClick($event)">
            <span>{{ 'AdvancedSearch' | translate }}</span> 
          </button>
        </div>
        <div class="col-12">
          <a href="#" (click)="resetFilters($event)">
            {{ 'ClearFilters' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="color-3">
    <table class="tb-1 arabic_table" style="width: 61%">
      <tbody>
        <tr>
          <th
            class="p-1 arabic_serv"
            [innerText]="'HomeRequestData' | translate"
          ></th>
          <th [innerText]="'Status' | translate"></th>
        </tr>
      </tbody>
    </table>
  </div>

  <div
    class="card-3 pt-6"
    *ngFor="
      let workItem of requestData
        | paginate
          : {
              id: 'pagination1',
              itemsPerPage: pageSize,
              currentPage: page,
              totalItems: count
            }
    "
  >
    <request-card
      [workItem]="workItem"
      (cancelDraftRequest)="
        cancelDraftRequest($event.serviceRequestTransactionId)
      "
      (selectCertificate)="selectCertificate($event)"
      (onEsClickCommunicationMessage)="
        onEsClickCommunicationMessage($event)
      "
      (onClickCommunicationMessage)="
        onClickCommunicationMessage(
          $event.workflowStatusCode,
          $event.serviceRequestTransactionId
        )
      "
      (goToPage)="goToPage($event.actionPageURL)"
      (cancelRequest)="
        cancelRequest(
          $event.workItem.workflowTransactionId,
          $event.workItem.serviceRequestTransactionId,
          $event.action,
          $event.workItem.workflowStatusCode
        )
      "
    ></request-card>
  </div>

  <hr />

  <div class="row">
    <div class="col-lg-4 col-md-6 pagination_in">
      <div class="form-group row">
        <div class="col-lg-3 col-md-5">
          <select
            (change)="onSearchClicked()"
            class="form-control page_size"
            id="pageSize"
            placeholder="Number of records"
            name="status"
            [(ngModel)]="pageSize">
              <option [value]="5">5</option>
              <option [value]="10">10</option>
              <option [value]="15">15</option>
              <option [value]="20">20</option>
              <option [value]="25">25</option>
          </select>
        </div>
        <div class="col-lg-8 col-md-6 col-form-label">
          <label >{{'ItemsPerPage' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-6 mt-1 pagination_in text-center">
      <p class="page-color cursor-pointer">
        {{ 'Pages' | translate }} &nbsp;<span class="box-1">{{ this.page }}</span> &nbsp; 
        {{ 'MultipleStepsOf' | translate }} &nbsp;&nbsp;
        {{ this.totalPage }}&nbsp;&nbsp;
        ( {{this.count}} {{ 'Request' | translate }} )
      </p>
    </div>

    <div class="col-lg-4 col-md-12 mt-1 pagination_in">
      <div class="pagination">
        <pagination-controls
          id="pagination1"
          previousLabel="{{ 'PaginationPreviousLabel' | translate }}"
          nextLabel="{{ 'PaginationNextLabel' | translate }}"
          (pageChange)="onTableDataChange($event)"
          [autoHide]="true"
          [responsive]="true"
          [maxSize]="4"
        >
        </pagination-controls>
      </div>
    </div>
  </div>

</div>

<div
  class="modal fade"
  id="notificationMessageModalDialog"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ 'HomeCommunicationMessages' | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <div class="custom_icon">
            <span class="icon-cancel-24px-gold"></span>
          </div>
        </button>
      </div>
      <div class="modal-body">
        <app-communication-message
          [serviceRequestTransactionId]="
            currentSelectedServiceRequestTransactionId
          "
          [statusCode]="currentSelectedStatusCode"
        ></app-communication-message>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="esCommentsModalDialog"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header alignment_right">
        <h5 class="modal-title" id="exampleModalLabel">
          {{ 'HomeCommunicationMessages' | translate }}
        </h5>
        <button
          type="button"
          class="none-1 mt-2"
          data-dismiss="modal"
          aria-label="Close"
        >
          <div class="custom_icon">
            <span class="icon-cancel-24px-gold"></span>
          </div>
        </button>
      </div>
      <div class="modal-body">
        <app-es-comments
          [comments]="esComments"
          [requestId]="esCommentsProcedureInstanceId"
        ></app-es-comments>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modal_viewCertificate">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 class="modal-title">{{ 'Certificates' | translate }}</h3>
        <button type="button" class="close" data-dismiss="modal">
          <span class="icon-cancel-24px-gold"> </span>
        </button>
      </div>

      <ng-container *ngFor="let cert of currentSelectedCertificateList">
        <div class="modal-body">
          <div class="card p-3 mb-3 shadow border">
            <div class="row">
              <div class="col-md-3 my-2">
                <div class="information">
                  <span class="small bold color-1">{{
                    'CertificateNumber' | translate
                  }}</span>
                  <h5 class="default-color m-0">
                    {{ cert.certificateNumber }}
                  </h5>
                </div>
              </div>
              <div class="col-md-4 my-2">
                <div class="information">
                  <span class="small bold color-1">{{
                    'CertificateName' | translate
                  }}</span>
                  <h5 class="m-0">
                    {{
                      isEnglish
                        ? cert.certificateCatalogNameEn
                        : cert.certificateCatalogNameAr
                    }}
                  </h5>
                </div>
              </div>
              <div class="col-md-3 my-2">
                <div class="information">
                  <span class="small bold color-1">{{
                    'CertificateExpiryDate' | translate
                  }}</span>
                  <h5 class="m-0">
                    {{ cert.certificateExpiryDate | date : 'dd-MM-yyyy' }}
                  </h5>
                </div>
              </div>
              <div class="col-md-2 my-2 d-flex">
                <ul class="m-auto">
                  <li
                    class="d-inline-block default-color mx-2 FS-20 cursor-pointer"
                  >
                    <a
                      (click)="
                        utilService.downloadCertificate(
                          cert.certificateCatalogCode,
                          currentSelectedServiceRequestTransactionId,
                          cert.certificateCatalogNameEn
                        )
                      "
                      ><i class="icon-get_app-24px" style="font-size: 20px"></i
                    ></a>
                  </li>
                  <li class="d-inline-block default-color mx-2 FS-20">
                    <button
                      id="viewFile"
                      (click)="
                        utilService.viewCertificate(
                          cert.certificateCatalogCode,
                          currentSelectedServiceRequestTransactionId
                        )
                      "
                      class="border-0 bg-white default-color"
                    >
                      <i
                        class="icon-visibility-24px-1"
                        style="font-size: 20px"
                      ></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Modal body -->
    </div>
  </div>
</div>
