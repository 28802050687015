export enum ExceptionTypesCodeEnum
{
  CITESInspector='CITESInspector',
  MedicineWarehouse='MedicineWarehouse',
  ExpoUser='ExpoUser',
  ReferenceLaboratories='ReferenceLaboratories',
  ResearchCenter='ResearchCenter',
  VIP='VIP',
  Exempted='Exempted'
}
