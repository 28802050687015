import { DOCUMENT } from '@angular/common';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UtilService } from '../core/utilities/util.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _lang: string;

  get lang(): string {
    return this._lang;
  }

  constructor(
    public translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private titleService: Title,
    private utilService: UtilService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
    this.translateService.onLangChange.subscribe((a) => {
      this.updateStyles(a.lang);
      this._lang = a.lang;
    });

    this.setDefaultLangFromCookie();
  }

  private setDefaultLangFromCookie() {
    let lang = this.utilService.getEServiceLang();

    if (!lang) {
      lang = 'en';
    }

    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }

  private updateStyles(lang: string) {
    this.changeCssFile(lang);
    this.utilService.saveLang(lang);
    this.document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  }

  public changeLanguage(lang: string): void {
    if (lang) {
      const currentLang = this.translateService.currentLang;
      if (currentLang !== lang) {
        this.translateService.use(lang);
      }
    }
  }

  private changeCssFile(lang: string): void {
    const headTag = this.document.getElementsByTagName(
      'head'
    )[0] as HTMLHeadElement;
    const existingLink = this.document.getElementById(
      'langCss'
    ) as HTMLLinkElement;
    const bundleName = lang === 'ar' ? 'arabicStyle.css' : 'englishStyle.css';
    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      const newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.type = 'text/css';
      newLink.id = 'langCss';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
}
