<app-loader></app-loader>

<div
  class="container mb-4 scroll-to arabic-styling next-design"
  *ngIf="serviceMetaData$ | async as metadata"
>
  <div class="row justify-content-center">
    <div dsCol [colOptions]="breadcrumbsColumnOptions">
      <app-breadcrumbs
        [breadcrumbsDetails]="breadcrumbsDetails$ | async"
        [serviceMetaData]="serviceMetaData$ | async"
        [serviceKey]="serviceKey"
      ></app-breadcrumbs>
    </div>
    <div dsCol [colOptions]="progressBarColumnOptions">
      <app-progress-bar
        [currentStep]="workflowService.currentStep"
        [steps]="workflowService.steps"
      ></app-progress-bar>
    </div>
  </div>

  <div class="row justify-content-center" dsCol [colOptions]="mainColOptions">
    <app-steps-flow
      [flow]="flow$ | async"
      (move)="moveFlow($event)"
      [showBackArrow]="true"
    ></app-steps-flow>
  </div>

  <div
    class="row justify-content-center title-container"
    dsCol
    [colOptions]="mainColOptions"
  >
    <es-service-title [metadata]="metadata"></es-service-title>
  </div>

  <div class="row justify-content-center" dsCol [colOptions]="mainColOptions">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</div>
