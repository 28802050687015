import { Lookup } from "../common/Lookup";

export class UserDetails {
  nameEn?: string;
  nameAr?: string;
  city?: string;
  mobile?: string;
  email?: string;
  poBox?: string;
  fax?: string;
  address?: string;
  nationality?: Lookup;
  accountNumber?: string;
  participantType?: string;
}
