<div class="row">
  <div class="col-12">
    <div class="forms-container" *ngIf="config$ | async as config">
      <div class="form-section">
        <form [formGroup]="formGroup">
          <legend
            class="col-form-label"
            [innerText]="'trApplicantDetails' | translate"
          ></legend>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <bs-ng-dropdown
                formControlName="applicant"
                identifier="applicant"
                key="trApplicantFld"
                name="applicant"
                [items]="config.applicants"
                bindLabel="name"
                (change)="applicantChanged($event)"
                [disabled]="!enableApplicantManagement"
              ></bs-ng-dropdown>
            </div>
            <div class="col-md-6 col-sm-12 mt-30-px">
              <button
                type="button"
                (click)="editApplicantDetails()"
                *ngIf="isView"
                class="ds-btn ds-btn-primary mx-1"
                [innerText]="'trEditApplicant' | translate"
              ></button>
              <button
                type="button"
                (click)="addNew()"
                *ngIf="isView && enableApplicantManagement"
                class="ds-btn ds-btn-primary mx-1"
                [innerText]="'trNewApplicant' | translate"
              ></button>
              <button
                *ngIf="(isAdding || isEditing)&&!requiredUpdate"
                type="button"
                (click)="cancelEditing()"
                class="ds-btn ds-btn ds-btn-secondary"
                [innerText]="
                  (isEditing ? 'trCancelEdit' : 'trCancelAdding') | translate
                "
              ></button>
            </div>
          </div>

          <hr *ngIf="isEditing || isAdding" class="my-4" />

          <div *ngIf="isEditing || isAdding">
            <div class="row my-4">
              <div class="col-md-6 col-sm-12">
                <bs-ng-radiobox
                  formControlName="identityType"
                  identifier="emiratesIdentity"
                  key="trApplicantIdentityTypeEmiratesIdFld"
                  name="identityType"
                  set="ID"
                  (change)="identityTypeChanged($event)"
                ></bs-ng-radiobox>
              </div>
              <div class="col-md-6 col-sm-12">
                <bs-ng-radiobox
                  formControlName="identityType"
                  identifier="passport"
                  key="trApplicantIdentityTypePassportFld"
                  name="identityType"
                  set="Passport"
                  (change)="identityTypeChanged($event)"
                ></bs-ng-radiobox>
              </div>
            </div>

            <ng-template #emiratesIdForm>
              <div class="col-md-6 col-sm-12">
                <bs-ng-text
                  formControlName="emiratesId"
                  identifier="emiratesId"
                  key="trApplicantEmiratesIdFld"
                  name="emiratesId"
                  [prefix]="emiratesIdMaskPrefix"
                  [mask]="emiratesIdMask"
                  [dropSpecialCharacters]="false"
                ></bs-ng-text>
              </div>

              <div class="col-md-12" *ngIf="isWaitingOtpValidation">
                <app-bs-alert
                  [importance]="Info"
                  [alert]="otpAlert"
                ></app-bs-alert>
              </div>

              <div
                class="col-md-6 col-sm-12 mt-30-px"
                *ngIf="isEditing || isAdding"
              >
                <div class="row">
                  <div class="col-md-12" *ngIf="enableApplicantManagement && isEmiratesIdVerified == false">
                    <button
                      type="button"
                      (click)="verifyEmiratesId()"
                      class="ds-btn ds-btn-primary"
                      [innerText]="'trApplicantVerifyEmiratesId' | translate"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="col-md-12" *ngIf="isWaitingOtpValidation">
                <div class="row">
                  <div class="col-md-6">
                    <bs-ng-text
                      formControlName="otp"
                      identifier="otp"
                      key="trApplicantOtpFld"
                      name="otp"
                    ></bs-ng-text>
                  </div>
                  <div class="col-md-6 mt-30-px">
                    <button
                      type="button"
                      (click)="verifyOtp()"
                      class="ds-btn ds-btn-primary"
                      [innerText]="'trApplicantVerifyOtp' | translate"
                    ></button>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <hr *ngIf="isEditing || isAdding" class="my-4" />
              </div>
            </ng-template>

            <ng-template #passportForm>
              <div class="col-md-6 col-sm-12">
                <bs-ng-text
                  formControlName="passport"
                  identifier="passport"
                  key="trApplicantPassportFld"
                  name="passport"
                ></bs-ng-text>
              </div>
            </ng-template>

            <div class="row">
              <ng-container
                *ngIf="isPassport; then passportForm; else emiratesIdForm"
              ></ng-container>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <bs-ng-text
                  formControlName="name"
                  identifier="name"
                  key="trApplicantNameFld"
                  name="name"
                  [readonly]="isEmiratesId"
                ></bs-ng-text>
              </div>

              <div class="col-md-6 col-sm-12">
                <bs-ng-text
                  formControlName="phone"
                  identifier="phone"
                  key="trApplicantPhoneFld"
                  name="phone"
                  [minlength]="10"
                  [mask]="phoneNumberMask"
                ></bs-ng-text>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 col-sm-12">
                <bs-ng-text
                  formControlName="email"
                  identifier="email"
                  key="trApplicantEmailFld"
                  name="email"
                ></bs-ng-text>
              </div>
            </div>

            <div class="row my-2">
              <div class="col-12">
                <label
                  class="form-control-label-required"
                  [innerText]="'trPreferredLanguage.labelText' | translate"
                ></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <bs-ng-radiobox
                  formControlName="preferredLanguage"
                  identifier="preferredLanguageArabic"
                  key="trPreferredLanguageArabic"
                  name="preferredLanguage"
                  set="Arabic"
                ></bs-ng-radiobox>
              </div>
              <div class="col-md-4 col-sm-12">
                <bs-ng-radiobox
                  formControlName="preferredLanguage"
                  identifier="preferredLanguageEnglish"
                  key="trPreferredLanguageEnglish"
                  name="preferredLanguage"
                  set="English"
                ></bs-ng-radiobox>
              </div>
              <div class="col-md-4 col-sm-12">
                <bs-ng-radiobox
                  formControlName="preferredLanguage"
                  identifier="preferredLanguageUrdu"
                  key="trPreferredLanguageUrdu"
                  name="preferredLanguage"
                  set="Urdu"
                ></bs-ng-radiobox>
              </div>
              <div class="col-md-4 col-sm-12">
                <span
                  [class.is-invalid]="hasPreferredLanguageRequiredError"
                ></span>
                <span
                  class="invalid-feedback"
                  *ngIf="hasPreferredLanguageRequiredError"
                  [innerText]="
                    'trPreferredLanguage.requiredMessage' | translate
                  "
                ></span>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="form-section" *ngIf="showRequestPurposeType">
        <form [formGroup]="formGroup">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <bs-ng-dropdown
                formControlName="selectedRequestPurposeType"
                identifier="requestPurpose"
                key="trApplicantRequestPurposeFld"
                name="requestPurpose"
                [items]="requestPurposes"
                [bindLabel]="defaultLookupDisplayProperty"
              ></bs-ng-dropdown>
            </div>
          </div>
        </form>
      </div>

      <div class="form-section">
        <app-es-main-buttons
          (action)="actionHandler($event)"
          [buttons]="buttons"
        ></app-es-main-buttons>
      </div>
    </div>
  </div>
</div>
