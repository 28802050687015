import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as getLatestSubmittedRequestsResponse from '../../models/customer/GetLatestSubmittedRequestsResponse';

@Injectable({
  providedIn: 'root'
})
export class GetLatestSubmittedRequestsService {

  private REST_API_SERVER = environment.hostURL + environment.hostCustomer + 'GetLatestSubmittedRequests';

  constructor(private httpClient: HttpClient) {
  }

  public getLatestSubmittedRequests(participantId: string): Observable<getLatestSubmittedRequestsResponse.GetLatestSubmittedRequestsResponseObject> {
    let params = new HttpParams();
    params = params.append('participantid', participantId);

    return this.httpClient.get<getLatestSubmittedRequestsResponse.GetLatestSubmittedRequestsResponseObject>(this.REST_API_SERVER,
                                                                {params})
                          .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[getLatestSubmittedRequests] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[getLatestSubmittedRequests] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
