import { Component } from "@angular/core";
import { BaseComponent } from "../../base/BaseComponent";
import { EsCommonService } from "src/app/core/api-services/shared/es-common.service";
import { TranslateService } from "@ngx-translate/core";
import { UserProfileDto } from "src/app/core/models/common/UserProfileDto";
import { RegisteredUser } from "src/app/core/models/profile/RegisteredUser";
import { UtilService } from "src/app/core/utilities/util.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
})

export class ProfileComponent extends BaseComponent {
    user: RegisteredUser;
    accounts: UserProfileDto[] = [];

    constructor(
        translateService: TranslateService,
        private commonService: EsCommonService,
        private utilService: UtilService,
      ) {
        super(translateService);
               
        this.user = this.utilService.getUserProfile();
        
        if(this.user){
          this.user.encryptedUserID = this.utilService.getParticipantId();
        }

        const userAccounts = commonService.getUserAccounts().toPromise()
        .then((response)=>{
          if(response && response.IsSuccess){
            this.accounts = response.Data;
          }
        });

        document.addEventListener("visibilitychange", this.isAccountSwitched.bind(this) ,false);
    }

    switchUserAccount($event: any,userId: number){
      $event.preventDefault();
      this.commonService.switchUserAccount(userId).toPromise()
      .then((response)=>{
        if(response && response.IsSuccess){
          this.utilService.saveParticipantId(response.Data);
        }
        this.redirectToWorkspace();
      });
    }

    redirectToWorkspace(){
      window.location.href = "/home";
    }

    logout($event: any): void {
      $event.preventDefault();
      this.utilService.logout();
    }
    
    editProfile($event: any){
      $event.preventDefault();
      window.location.href = environment.editProfileUrl + '?lang=' + this.lang;
    }

    isAccountSwitched(){
      if(!document.hidden){
        if(this.utilService.isParticipantIdExists()){
          if(this.user?.encryptedUserID != this.utilService.getParticipantId()){
            this.redirectToWorkspace();
          }
        }
        else{
          this.utilService.logout();
        }
      }
    }
}

