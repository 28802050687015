<div class="row">
  <div class="col">
    <h2 [innerText]="'trFavoriteServices' | translate"></h2>
  </div>
</div>

<div class="row my-3">
  <div class="col-sm-10">
    <input
      class="form-control"
      [(ngModel)]="searchText"
      [placeholder]="'trFilter' | translate"
    />
  </div>
  <div class="col-sm-2">
    <bs-ng-switch
      [(ngModel)]="isShowAllServices"
      key="trIsShowAllServicesFld"
      identifier="isShowAllServices"
      name="isShowAllServices"
    >
    </bs-ng-switch>
  </div>
</div>

<div class="row" style="row-gap: 30px">
  <div
    [class.col-sm-12]="service.expanded == false"
    [class.col-md-6]="service.expanded == false"
    [class.col-xl-4]="service.expanded == false"
    [class.col-sm-12]="service.expanded"
    *ngFor="
      let service of $allServices
        | async
        | favoriteServicesFilter : isShowAllServices
        | orderByIsFavorite
        | searchFilter : searchText
    "
  >
    <service-card
      [service]="service"
      (action)="onServiceCardAction($event)"
    ></service-card>
  </div>
</div>
