import { Injectable } from '@angular/core';
import { AvailableThemes } from '../enums/available-themes';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public theme$ = new BehaviorSubject<AvailableThemes>(AvailableThemes.Gold);

  constructor(private cookieService: CookieService) {}

  private setCurrentTheme(theme: AvailableThemes): void {
    this.cookieService.set('Theme', theme);
    this.theme$.next(theme);
  }

  public changeColorTheme(theme: AvailableThemes): void {
    this.removeCurrentColorTheme();
    this.setCurrentTheme(theme);
    if (theme !== AvailableThemes.Gold) {
      document.querySelector('body').classList.add(theme);
    }
  }

  private removeCurrentColorTheme(): void {
    if (this.getCurrentTheme().trim() !== '') {
      document.querySelector('body').classList.remove(this.getCurrentTheme());
    }
  }

  public setDefaultColorTheme(): void {
    const currentTheme: AvailableThemes = this.getCurrentTheme();
    this.changeColorTheme(currentTheme);
  }

  public setDefaultDisplayMode(): void {
    if (this.getDarkModeTheme()) {
      document.querySelector('body').classList.add(AvailableThemes.Dark);
    } else {
      document.querySelector('body').classList.remove(AvailableThemes.Dark);
    }
  }

  public toggleDarkMode(theme: AvailableThemes): void {
    if (!this.getDarkModeTheme()) {
      this.setDarkModeTheme(true);
      document.querySelector('body').classList.add(theme);
    } else {
      document.querySelector('body').classList.remove(theme);
      this.setDarkModeTheme(false);
    }
  }

  public getCurrentTheme(): AvailableThemes {
    return this.cookieService.get('Theme') as AvailableThemes;
  }

  private setDarkModeTheme(enabled: boolean): void {
    this.cookieService.set('DarkModeTheme', enabled.toString());
  }

  private getDarkModeTheme(): boolean {
    return (
      this.cookieService.get('DarkModeTheme').toLocaleLowerCase() === 'true'
    );
  }
}
