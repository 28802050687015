import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormOptions } from 'src/app/services/es-shared/ds-col/FormOptions';
import { BaseComponent } from './BaseComponent';
import { FormMode } from './FormMode';
import { ILookup } from 'src/app/core/models/shared/Lookups';

@Component({ template: '' })
export abstract class BaseFormComponent extends BaseComponent {
  @Input()
  mode: FormMode;

  @Input()
  formGroup: FormGroup;

  @Input()
  formOptions: FormOptions;

  isEditing() {
    return this.mode === FormMode.EDITING;
  }

  isNew() {
    return this.mode === FormMode.NEW;
  }

  isView() {
    return this.mode === FormMode.VIEW;
  }

  public get fo(): FormOptions {
    return this.formOptions;
  }

  public reset(): void {
    this.formGroup.reset();
    ///because the red validation style depends on this also, so
    ///if we don't mark as untouched the red style will remain after reset
    this.formGroup.markAsUntouched();
  }

  public get isDisableClear(): boolean {
    return this.formGroup.touched == false;
  }

  public get isFormDisabled(): boolean {
    return this.formGroup.disabled;
  }
}
