import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GeneralAlertsMessageObject } from '../../models/common/GeneralAlertsMessageResponse';
import { ResponseModel } from '../../models/shared/CommonResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class GetGeneralAlertMessageService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'GeneralAlertsMessages/' + 'GetGeneralAlertMessage';

  constructor(private httpClient: HttpClient) { }

  public getGeneralAlertMessage(): Observable<ResponseModel<GeneralAlertsMessageObject>> {
    return this.httpClient
               .get<ResponseModel<GeneralAlertsMessageObject>>(this.REST_API_SERVER)
               .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<never> {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetGeneralAlertMessageService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetGeneralAlertMessageService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }
}
