import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByIdDesc',
})
export class OrderByIdDescPipe implements PipeTransform {
  public transform(value: any[]) {
    return (value || []).sort((a, b) => Number(b.id) - Number(a.id));
  }
}
