import { TranslateService } from '@ngx-translate/core';
import { AbstractValueAccessor } from './AbstractValueAccessor';
import {
  Input,
  Injector,
  Directive,
  Output,
  EventEmitter,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ValidationBaseOptions } from '../../validators/ValidationBaseOptions';
import { InputTemplateOptions } from '../../InputTemplateOptions';
import { InputSettingsService } from '../../input-settings.service';

@Directive()
export abstract class BsNgInputBaseComponent extends AbstractValueAccessor {
  @Input() labelText = '';
  @Input() placeholder = '';
  @Input() requiredMessage = '';
  @Input() identifier = '';
  @Input() name = '';
  @Input() required: string;
  @Input() key = '';
  @Input() noLabel: boolean;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() helpText: string;
  @Input() moreThanDateMessage: string;
  @Input() maxErrorMessage: string;
  @Input() patternErrorMessage: string;

  @Input() customErrorMessage: string;
  @Output() blur = new EventEmitter();

  @Input()
  templateOptions: InputTemplateOptions = { direction: 'default' };

  emiratesIdErrorMessage: string;
  minLengthErrorMessage: any = '';
  maxLengthErrorMessage: any = '';
  notInMessage: any = '';
  alreadyExistMessage: string;
  notFoundMessage: string;
  infoMessage: string;

  constructor(
    private translate: TranslateService,
    injector: Injector,
    protected defaultSettings: InputSettingsService
  ) {
    super(injector);
    this.registerLanguageChange(translate);

    this.templateOptions = defaultSettings.templateOptions;
  }

  registerLanguageChange(translate: TranslateService) {
    translate.onLangChange.subscribe((language) => {
      this.getTranslations();
    });
  }

  getTranslations() {
    if (this.key) {
      this.translate
        .get(this.key)
        .subscribe((trs) => this.assignTranslations(trs));
    }
  }

  protected assignTranslations(trs: any) {
    this.labelText = trs.labelText || this.labelText;
    this.placeholder = trs.placeholder || '';
    this.requiredMessage = trs.requiredMessage || this.requiredMessage;
    this.helpText = trs.helpText || this.helpText;
    this.customErrorMessage =
      trs.customErrorMessage || this.customErrorMessage || '';
    this.emiratesIdErrorMessage = trs.emiratesIdErrorMessage || '';
    this.minLengthErrorMessage = trs.minLengthErrorMessage || '';
    this.maxLengthErrorMessage = trs.maxLengthErrorMessage || '';
    this.notInMessage = trs.notInMessage || '';
    this.patternErrorMessage = trs.patternErrorMessage;
    this.alreadyExistMessage = trs.alreadyExistMessage || '';
    this.notFoundMessage = trs.notFoundMessage || '';
    this.infoMessage = trs.infoMessage || '';
  }

  get hasMinLengthError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.minlength
    );
  }

  get hasMaxLengthError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.maxlength
    );
  }

  get hasNotInError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.notIn
    );
  }

  get hasAlreadyExistError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.alreadyExist
    );
  }

  get hasNotFoundError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.notFound
    );
  }

  isValid() {
    return this.ngControl.control.touched && this.ngControl.control.valid;
  }

  hasRequiredError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.required
    );
  }

  get hasEmiratesIdError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.emiratesIdFormat
    );
  }

  hasRequiredValidator() {
    return this.ngControl.control.hasValidator(Validators.required);
  }

  get hasPatternError(): boolean {
    return (
      this.ngControl.control.touched &&
      this.ngControl.control.errors &&
      this.ngControl.control.errors.pattern
    );
  }

  get touched() {
    return this.ngControl.control.touched;
  }

  ///used for reactive forms
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onBlur() {
    this.blur.emit({ new: this.value });
    this.onTouched();
  }

  public get isShowErrors(): boolean {
    return (
      this.ngControl.touched &&
      !this.ngControl.valid &&
      !this.ngControl.disabled
    );
  }

  public get hasErrors(): boolean {
    if (this.ngControl.control.touched) {
      return this.ngControl.errors && true;
    }

    return false;
  }

  public trackByMessageKey(
    index: number,
    item: { key: string; messageKey: string }
  ): string {
    return item.key;
  }

  public get errors(): any[] {
    if (this.ngControl.touched && this.ngControl.errors) {
      if (this.ngControl.errors.required) {
        ///return only required error
        return [
          { key: 'required', messageKey: this.key + '.' + 'requiredMessage' },
        ];
      }

      const keys = Object.keys(this.ngControl.errors);

      return keys.map((keyError) => {
        ///nativeValidationError is the old way of handling validation without options
        ///in the case nativeValidationError is false means use the error object as options
        const nativeValidationError = this.ngControl.errors[keyError] === true;

        return nativeValidationError
          ? { key: keyError, messageKey: this.key + '.' + keyError + 'Message' }
          : this.buildOptions(
              this.ngControl.errors[keyError] as ValidationBaseOptions
            );
      });
    }

    return [];
  }

  protected buildOptions(validationOptions: ValidationBaseOptions): any {
    const errorMessageKey =
      validationOptions.keySource == 'Pure'
        ? validationOptions.key + 'Message'
        : this.key + '.' + validationOptions.key + 'Message';

    return {
      key: validationOptions.key,
      messageKey: errorMessageKey,
      messageArgs: validationOptions.messageArgs,
      keySource: validationOptions.keySource,
    };
  }
}
