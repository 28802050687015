export enum MainListActions {
  Add,
  Delete,
}

export enum RequestActions {
  Next,
  Back,
  SaveAsDraft,
  SaveTemplate,
  ShowRequestTemplateForm,
  InitiateReleaseRequest,
  Pay,
  PayLater,
  SubmitInitiatorAction,
  SubmitOwnerAction,
}
