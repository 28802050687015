<form *ngIf="item"
      [formGroup]="formGroup"
      (ngSubmit)="submit()"
      appInputComponentsInvalidScroller>
  
  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <legend
        class="col-form-label"
        [innerText]="'The Consignment Data' | translate"
      ></legend>
    </div>
  </div>

  <div class="row px-2" >

    <div dsCol [colOptions]="fo.fullCol">
      <app-bs-display
        key="trRegisterCommonNameFld"
        [valueText]="item.type | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trRegisterAppendixNoFld"
        valueText="{{lang == 'ar'? item.type?.appendixNameAr : item.type?.appendixNameEn}}">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.relatedType">
      <app-bs-display
        key="trCitesRelatedTypeFld"
        [valueText]="item.relatedType | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trRegisterSourceFld"
        [valueText]="item.source | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.fullCol" *ngIf="item.sourceReason">
      <app-bs-display
        key="trRegisterSourceReasonFld"
        [valueText]="item.sourceReason">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesAccompanyingCertificateNoFld"
        [valueText]="item.accompanyingCertificateNo">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesAccompanyingCertificateDateFld"
        [valueText]="item.accompanyingCertificateDate | date:'dd/MM/yyyy'">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trRegisterOriginCountryFld"
        [valueText]="item.originCountry | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesOriginCertificateNoFld"
        [valueText]="item.exportingCertificateNo">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesOriginCertificateDateFld"
        [valueText]="item.exportingCertificateDate | date:'dd/MM/yyyy'">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesLastReExportCountryFld"
        [valueText]="item.lastReExportCountry | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesLastReExportCertificateNumberFld"
        [valueText]="item.lastReExportCertificateNumber">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesLastReExportCertificateDateFld"
        [valueText]="item.lastReExportCertificateDate | date:'dd/MM/yyyy'">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.fullCol" *ngIf="item.specimenDescription">
      <app-bs-display
        key="trRegisterSpecimenDescriptionFld"
        [valueText]="item.specimenDescription">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.ringNumber">
      <app-bs-display
        key="trRegisterRingNumberFld"
        [valueText]="item.ringNumber">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.chipNumber">
      <app-bs-display
        key="trRegisterChipNumberFld"
        [valueText]="item.chipNumber">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.gender">
      <app-bs-display
        key="trRegisterGenderFld"
        [valueText]="item.gender | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.birthdate">
      <app-bs-display
        key="trCitesBirthdateFld"
        [valueText]="item.birthdate | date:'dd/MM/yyyy'">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.typeDescription">
      <app-bs-display
        key="trRegisterTypeDescriptionFld"
        [valueText]="item.typeDescription | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="item.unit">
      <app-bs-display
        key="trRegisterUnitFld"
        [valueText]="item.unit | lookup:lang">
      </app-bs-display>
    </div>

    <div dsCol [colOptions]="fo.halfCol" *ngIf="formGroup.controls.quantity">
      <bs-ng-number
        formControlName="quantity"
        identifier="quantity"
        key="trRegisterQuantityFld"
        name="quantity">
      </bs-ng-number>
    </div>
  </div>
  
  <div class="row">
    <div dsCol [colOptions]="fo.halfCol">
      <button *ngIf="isEditing()"
              [innerText]="'Save' | translate"
              type="submit"
              class="btn btn-primary btn-small">
      </button>
      <button *ngIf="isEditing()"
              [innerText]="'Cancel' | translate"
              class="btn btn-primary btn-small"
              (click)="cancelEditingItem()">
      </button>
    </div>
  </div>

</form>
