import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { EsBaseServiceRequest } from 'src/app/core/models/shared/EsBaseServiceRequest';
import { EsFees } from 'src/app/core/models/shared/EsFees';
import { EsServiceRequestConfiguration } from 'src/app/core/models/shared/EsServiceRequestConfiguration';
import { EsRequestStatusCodesEnum } from 'src/app/core/models/shared/MainWorkflowStepStatusCodesEnum';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { ActionEmitDataHolder } from 'src/app/shared/base/ActionEmitDataHolder';
import { BreadcrumbsDetails } from 'src/app/shared/base/BreadcrumbsDetails';
import { FlowButtons } from 'src/app/shared/base/FlowButtons';
import { FormMode } from 'src/app/shared/base/FormMode';
import { PageBaseComponent } from 'src/app/shared/base/PageBaseComponent';
import { WorkflowBaseService } from 'src/app/shared/base/WorkflowBaseService';
import {
  AppSettingsService,
  SettingsSizeEmitterData,
} from 'src/app/shared/settings/app-settings.service';
import {
  ColOptions,
  ColSpace,
  ScreenMediaSize,
} from '../ds-col/ds-col.component';
import { FormSectionOptions } from '../es-form-section-directive/FormSectionOptions';

@Component({
  selector: 'app-es-review-page',
  templateUrl: './es-review-page.component.html',
  styleUrls: ['./../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EsReviewPageComponent extends PageBaseComponent {
  public serviceMetaData$: Subject<ServiceMetaData>;
  private serviceMetadata: ServiceMetaData;

  public serviceConfig$: Observable<EsServiceRequestConfiguration>;

  public transaction$: Observable<any>;
  public fees$: Observable<EsFees>;

  public form: FormGroup;

  public buttons: FlowButtons;

  private statusCode: EsRequestStatusCodesEnum;

  public get formMode(): FormMode {
    return FormMode.VIEW;
  }

  public get serviceKey(): string {
    return this.workflowService.getServiceUrlKeyWord();
  }

  public breadcrumbsColumnOptions: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.TwoThirds,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'self',
  };

  public mainColOptions: ColOptions = {
    minimumSize: {
      colSpace: ColSpace.Full,
      screenMediaSize: ScreenMediaSize.Small,
    },
    maximumSize: {
      colSpace: ColSpace.TwoThirds,
      screenMediaSize: ScreenMediaSize.Medium,
    },
    renderAs: 'wrapper',
  };

  public get serviceRequestFormSection(): FormSectionOptions {
    let result = new FormSectionOptions();

    result.isCollapsible = true;
    result.render =
      this.workflowService.globalObject.ServiceDetailsConfiguration.id > 0;

    return result;
  }

  public get showCommentsSection(): boolean {
    return (
      this.statusCode &&
      this.statusCode == EsRequestStatusCodesEnum.InitiatorSendBack
    );
  }

  public get showTermsAndConditionsSection(): boolean {
    return this.statusCode
      ? this.statusCode == EsRequestStatusCodesEnum.NewRequest
      : true; //new request status code is undefined
  }

  public get requestId(): number {
    return this.workflowService.globalObject.ServiceDetailsConfiguration.id;
  }

  constructor(
    @Inject('WorkflowService')
    public workflowService: WorkflowBaseService,
    protected route: ActivatedRoute,
    translate: TranslateService,
    protected settingsService: AppSettingsService
  ) {
    super(translate);

    // this.parametersLoaded(this.route.snapshot.queryParamMap);

    this.serviceMetaData$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceMetaData$;

    this.serviceConfig$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceConfig$;

    this.buttons =
      this.workflowService.globalObject.ReviewConfiguration.flowButtons;

    this.serviceMetaData$.subscribe((metadata) =>
      this.metadataLoaded(metadata)
    );

    this.translateService.onLangChange.subscribe(() => {
      this.languageChanged();
    });

    this.fees$ = this.workflowService.getFees();

    this.form = this.workflowService.globalObject.ReviewConfiguration.form;

    this.transaction$ =
      this.workflowService.globalObject.ServiceDetailsConfiguration.serviceDetails$;

    this.transaction$.subscribe(async (t) => {
      if (this.workflowService.globalObject.ServiceDetailsConfiguration.id)
        this.statusCode = t.serviceRequestTransactionData?.statusCode;
    });

    this.subscribeToSizeSettings();
  }

  private languageChanged() {
    const breadcrumbsDetails: BreadcrumbsDetails = {
      serviceName: {
        labelKey: this.isArabic
          ? this.serviceMetadata.nameAr
          : this.serviceMetadata.nameEn,
      },
    };

    this.breadcrumbsDetails$.next(breadcrumbsDetails);
  }

  private metadataLoaded(serviceMetadata: ServiceMetaData): void {
    this.serviceMetadata = serviceMetadata;

    const breadcrumbsDetails: BreadcrumbsDetails = {
      serviceName: {
        labelKey: this.isArabic
          ? serviceMetadata.nameAr
          : serviceMetadata.nameEn,
      },
    };

    this.breadcrumbsDetails$.next(breadcrumbsDetails);
  }

  public isShowFeesSection(transaction: EsBaseServiceRequest): boolean {
    return false;
  }

  public isShowReceiptSection(transaction: EsBaseServiceRequest): boolean {
    return transaction.receipts?.length > 0;
  }

  public isShowOutputsSection(transaction: EsBaseServiceRequest): boolean {
    return transaction.outputs && transaction.outputs.length > 0;
  }

  protected subscribeToSizeSettings() {
    this.settingsService.setSupportResizing(true);
    this.settingsService.size$.subscribe((s) => {
      this.changeSize(s);
    });
  }

  public async actionHandler($event: ActionEmitDataHolder) {
    this.workflowService.move($event);
  }

  public changeSize(sizeSettings: SettingsSizeEmitterData): void {
    if (sizeSettings.size == 'Full') {
      this.setSizeFull();
    }

    if (sizeSettings.size == 'Normal') {
      this.setSizeNormal();
    }
  }

  protected setSizeNormal() {
    this.mainColOptions.maximumSize.colSpace = ColSpace.TwoThirds;
    this.breadcrumbsColumnOptions.maximumSize.colSpace = ColSpace.TwoThirds;
  }

  protected setSizeFull() {
    this.mainColOptions.maximumSize.colSpace = ColSpace.Full;
    this.breadcrumbsColumnOptions.maximumSize.colSpace = ColSpace.Full;
  }
}
