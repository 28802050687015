import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as dashBoardDetails from '../../../../core/models/customer/DashBoardDetails';
import { Subscription } from 'rxjs';
import * as getCertificateDetails from '../../../../core/models/customer/CertificateDetails_Portal';
import { environment } from '../../../../../environments/environment';
import { HomeComponent } from '../../home.component';
import { UtilService } from '../../../../core/utilities/util.service';
import { GetInspectionsService } from '../../../../core/api-services/Inspections/get-inspections.service';
import { GetCertificateDetailsService } from '../../../../core/api-services/customer/get-certificate-details.service';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ServiceRequestTypeCodeEnum } from '../../../../core/enums/service-request-type-code.enum';
import { PackageTypes } from '../../../../core/enums/package-types';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Router } from '@angular/router';
import {
  FrontOfficeCardPackageEnum,
  RegisteredUserCertificate,
} from '../../../../core/models/customer/RegisteredUserCertificate';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-licenses-for-practicing-activities',
  templateUrl: './licenses-for-practicing-activities.component.html',
  styleUrls: ['./licenses-for-practicing-activities.component.scss'],
})
@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
export class LicensesForPracticingActivitiesComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input('packageObject') packageObject: dashBoardDetails.Package;
  closeResult = '';
  observableSubscriptions: Subscription[] = [];
  certificateDetailsObject: getCertificateDetails.GetCertificateDetailsObject;
  certificateDetailsObjectDataList_portal: getCertificateDetails.ListItem[] =
    [];
  certificateDetailsList: RegisteredUserCertificate[] = [];

  certificateDetailPageSize = 3;
  certificateDetailPageIndex = 1;
  certificateDetailTotalItems = 0;
  certificateDetailPageSize_Portal = 50;
  certificateDetailPageIndex_Portal = 1;
  certificateDetailTotalItems_Portal = 0;
  REST_API_SERVER = environment.domainURL;
  currentLang: string;
  participantId: string;
  selectedProcedureInstanceIdForInspection: any;
  selectedCertificateNumber: any;
  constructor(
    public homeComponent: HomeComponent,
    public utilService: UtilService,
    public getInspectionsService: GetInspectionsService,
    private certificateDetailService: GetCertificateDetailsService,
    private router: Router,
    translateService: TranslateService
  ) {
    super(translateService);

    this.currentLang = translateService.currentLang;
    this.observableSubscriptions.push(
      translateService.onLangChange.subscribe(() => {
        this.currentLang = translateService.currentLang;
      })
    );
    this.participantId = this.utilService.getParticipantId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.packageObject) {
      this.certificateDetailPageIndex = 1;
      this.getCertificateDetail();
    }
  }

  viewInspection(procedureInstanceID: number, certificateNumber: string) {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/view-inspection-data'], {
        queryParams: {
          procedureInstanceID: procedureInstanceID,
          CertificateNumber: certificateNumber,
        },
      })
    );

    window.open(url, '_blank');
  }

  ngOnDestroy(): void {}

  closeServiceDetails() {
    this.homeComponent.closeServiceDetails();
  }

  ngOnInit(): void {}

  getCertificateDetail() {
    this.observableSubscriptions.push(
      this.certificateDetailService
        .GetCertificateDetails(
          FrontOfficeCardPackageEnum.MyLicensesForPracticingActivities
        )
        .subscribe((res) => {
          this.certificateDetailsList = res.responseData;

          this.certificateDetailService
            .GetCertificateDetails_Portal(
              this.utilService.getParticipantId(),
              this.packageObject.ID.toString(),
              this.certificateDetailPageSize_Portal,
              this.certificateDetailPageIndex_Portal
            )
            .subscribe(
              (response: getCertificateDetails.GetCertificateDetailsObject) => {
                this.certificateDetailsObject = response;
                this.certificateDetailsObjectDataList_portal =
                  response.Data.ListItems;
                let x = this.certificateDetailsObjectDataList_portal.map(
                  (cert) => {
                    let certificateDetail = new RegisteredUserCertificate();
                    certificateDetail.certificateId = cert.CertificateID;
                    certificateDetail.certificateNumber =
                      cert.CertificateNumber;
                    if (cert.ProfessionalName == null) {
                      certificateDetail.certificateCatalogNameEn =
                        cert.CertificateType?.NameEn;
                      certificateDetail.certificateCatalogNameAr =
                        cert.CertificateType?.NameAr;
                    } else {
                      certificateDetail.certificateCatalogNameEn =
                        cert.LicenseType?.NameEN;
                      certificateDetail.certificateCatalogNameAr =
                        cert.LicenseType?.NameAR;
                    }
                    certificateDetail.certificatePractisingDate =
                      cert.PracticingDate;
                    certificateDetail.certificateExpiryDate = cert.ExpiryDate;
                    certificateDetail.certificateIssueDate = cert.IssueDate;
                    certificateDetail.certificateIssueDate = cert.IssueDate;
                    certificateDetail.certificateUrl =
                      cert.CertificateURL != null &&
                      cert.CertificateURL.length > 0
                        ? cert.CertificateURL[0]
                        : null;
                    certificateDetail.isExpired =
                      cert.Status.toLocaleLowerCase() == 'expired'
                        ? true
                        : false;
                    certificateDetail.isPortal = true;
                    return certificateDetail;
                  }
                );
                this.certificateDetailsList.push(...x);
                this.certificateDetailTotalItems =
                  this.certificateDetailsList.length;
              }
            );
        })
    );
  }
  onCertificatePageChange(event: any) {
    this.certificateDetailPageIndex = event;
    // this.getCertificateDetail();
  }
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  viewRequest(procedureInstanceID: number) {
    // Converts the route into a string that can be used
    // with the window.open() function
    this.homeComponent.viewRequest(procedureInstanceID.toString());
  }

  get AnimalCareRequestTypeEnum(): typeof ServiceRequestTypeCodeEnum {
    return ServiceRequestTypeCodeEnum;
  }
  public get PackageTypeEnum(): typeof PackageTypes {
    return PackageTypes;
  }
  /**Enables action incase of animal care certificate */
  enableActions(certificateObject: getCertificateDetails.ListItem): boolean {
    return certificateObject.CertificateType?.Id == 2;
  }
}
