<div class="header d-print-none">
  <div data-module="56" data-module-title="Main Menu"></div>

  <div class="using-bootstrap">
    <div class="container">
      <div class="main-menu">
        <div class="row top-menu">
          <div class="col-xl-5 col-lg-4 col-md-12 col-sm-8 col-9 text-left">
            <div class="header_logo_stars">
              <h1 class="logo">
                <a
                  target="”_blank”"
                  routerLink="/home"
                  [queryParams]="{ dashboard: 'true' }"
                  title="{{ 'UAE' | translate }}"
                >
                  <img
                    [src]="
                      isArabic
                        ? 'assets/Images/siteimages/logo-new-ar.png'
                        : 'assets/Images/siteimages/logo-new-en.png'
                    "
                    alt="{{ 'UAE' | translate }}"
                  />
                </a>
              </h1>
              <div class="header_stars">
                <img src="assets/Images/header_stars.png" alt="" />
              </div>
            </div>
          </div>
          <div
            class="col-xl-7 col-lg-8 col-md-12 col-sm-4 col-3 responsive-menu-btn p-0"
            style="text-align: right"
          >
            <a target="”_blank”" class="c-mobile-menu" title="Menu"></a>
          </div>
          <div
            class="col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12 text-right respons_tool_con"
          ></div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <app-header-menu></app-header-menu>
          </div>
          <div *ngIf="isUserExist" class="col-6">
            <app-profile></app-profile>
          </div>
          <div class="col6 ds-btns-mr">
            <div class="d-flex py-2 justify-content-end ds-btns">
              <a
                (click)="decreaseFontSize()"
                class="ds-btn ds-btn-icon color-black"
                [title]="'trDecreaseFontSize' | translate"
                >A-</a
              >
              <a
                (click)="increaseFontSize()"
                class="ds-btn ds-btn-icon color-black"
                [title]="'trIncreaseFontSize' | translate"
                >A+</a
              >
              <a
                (click)="print()"
                class="ds-btn ds-btn-icon color-black"
                [title]="'trPrint' | translate"
                ><i class="icon-print ds-font-xl"></i
              ></a>
              <a
                (click)="full()"
                *ngIf="isSupportResizing && isNormalSize"
                class="ds-btn ds-btn-icon color-black d-none d-flex cutoms-tool-tip"
                [title]="'trExpandSize' | translate"
                ><i class="fa-solid fa-arrows-left-right"></i
              ></a>
              <a
                (click)="normal()"
                *ngIf="isSupportResizing && isFullSize"
                class="ds-btn ds-btn-icon color-black d-none d-flex cutoms-tool-tip"
                [title]="'trCollapseSize' | translate"
                ><i class="fa-solid fa-arrows-left-right-to-line"></i
              ></a>
              <a
                *ngIf="isSupportFormTemplates && isDefaultFormTemplate"
                (click)="horizontalForms()"
                class="ds-btn ds-btn-icon color-black d-none d-flex cutoms-tool-tip"
                [title]="'trHorizontalForm' | translate"
                ><i class="fa-solid fa-align-justify"></i
              ></a>
              <a
                *ngIf="isSupportFormTemplates && isHorizontalFormTemplate"
                (click)="defaultForms()"
                class="ds-btn ds-btn-icon color-black d-none d-flex cutoms-tool-tip"
                [title]="'trDefaultForm' | translate"
                ><i class="fa-solid fa-square-poll-horizontal"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-----------------Start_Customize_Color--------------------->
<div
  class="modal fade"
  id="colorpopup"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content rm_border">
      <div class="theme_color_popup">
        <div class="personalize_color">
          <span class="close_icon" data-dismiss="modal" aria-label="Close"
            ><img src="assets/Images/cancel.svg" alt=""
          /></span>
          <h2 [innerHTML]="'HeaderPersonalize' | translate"></h2>
          <h3 [innerHTML]="'HeaderSelectYourTheme' | translate"></h3>
          <ul class="color_com">
            <li (click)="changeColorTheme(AvailableTheme.Red)">
              <span class="red"></span>
            </li>
            <li>
              <span
                (click)="changeColorTheme(AvailableTheme.Green)"
                class="green_bg"
              ></span>
            </li>
            <li>
              <span
                (click)="changeColorTheme(AvailableTheme.Gold)"
                class="yellow_bg"
              ></span>
            </li>
            <li>
              <span
                (click)="changeColorTheme(AvailableTheme.Grey)"
                class="grey_bg"
              ></span>
            </li>
          </ul>

          <div class="translate_container">
            <div class="google-translate-c">
              <div id="google_translate_element"></div>
            </div>
          </div>

          <hr />

          <h2 [innerHTML]="'HeaderAccessibilitySettings' | translate"></h2>
          <div class="personalize-holder">
            <div class="setting-w-icons">
              <ul class="fonts-settings">
                <li class="increase-font">
                  <a
                    target="”_blank”"
                    class="inc-font-size cursor-pointer"
                    (click)="increaseFontSize()"
                    ><i>icon</i
                    ><span [innerHTML]="'HeaderFontIncrease' | translate"></span
                  ></a>
                </li>
                <li class="decrease-font">
                  <a
                    target="”_blank”"
                    class="dec-font-size cursor-pointer"
                    (click)="decreaseFontSize()"
                    ><i>icon</i
                    ><span [innerHTML]="'HeaderFontDecrease' | translate"></span
                  ></a>
                </li>
                <li
                  (click)="toggleDarkMode(AvailableTheme.Dark)"
                  class="accssibility"
                >
                  <a
                    target="”_blank”"
                    class="switch-theme cursor-pointer"
                    data-theme="Accessibility"
                    ><i>icon</i
                    ><span
                      [innerHTML]="'HeaderDisplayOption' | translate"
                    ></span
                  ></a>
                </li>
              </ul>
            </div>

            <hr class="hr-acc" />

            <!--Sign Language interpreter  -->
            <div id="sign-language-interpreter" class="signlang"></div>
            <!-- end Sign Language interpreter -->

            <!--  Read Speaker    -->
            <div class="voice links-with-logos">
              <a
                target="”_blank”"
                data
                class="vocie-me dont-go link-with-logo"
                title="Listen to this page"
              >
                <i class="ico fi-sound-icon-globalmast"></i>
                <span
                  style="display: none"
                  class="icon-text"
                  [innerHTML]="'HeaderListenToThisPage' | translate"
                ></span
              ></a>
            </div>
            <div
              id="readspeaker_button1"
              class="rs_skip rsbtn rs_preserve"
              style="margin: auto"
            >
              <a
                target="”_blank”"
                rel="nofollow"
                class="rsbtn_play dont-go"
                accesskey="L"
                title="ReadSpeaker إستمع إلى هذه الصفحةِ مستخدماً"
                href="//app-as.readspeaker.com/cgi-bin/rsent?customerid=9388&readclass=main-content-speaker-id&url={{
                  window.location.href
                }}&lang={{ isEnglish ? 'en_uk' : 'ar_ar' }}"
              >
                <i class="icon icon-audio"></i>
                <span
                  class="icon-text"
                  [innerHTML]="'HeaderListenToThisPage' | translate"
                ></span>
              </a>
            </div>
            <!--  End Read speaker  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-----------------Start_Customize_Color--------------------->

<!-----------------Start_Accessibility_Color--------------------->

<div
  class="modal fade"
  id="accessibility"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content rm_border">
      <div class="theme_color_popup">
        <div class="personalize_color">
          <a
            class="close_icon"
            #closeModalTag
            data-dismiss="modal"
            aria-label="Close"
            ><img src="assets/Images/cancel.svg" alt=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-----------------Start_Accessibility_Color--------------------->
