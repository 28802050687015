import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base/BaseComponent';
import { AlertOptions } from './AlertOptions';
import { AlertImportanceEnum } from './AlertImportanceEnum';

@Component({
  selector: 'app-bs-alert',
  templateUrl: './bs-alert.component.html',
})
export class BsAlertComponent extends BaseComponent implements OnInit {
  @Input()
  alert: AlertOptions;

  @Input()
  key: string;

  @Input()
  headingText = '';

  @Input()
  showCloseButton: boolean = false;

  @Output()
  close = new EventEmitter<any>();

  @Input()
  importance = AlertImportanceEnum.Warning;

  get renderText() {
    if (this.key) {
      return this.translateService.instant(this.key);
    }

    if (this.alert?.key) {
      return this.translateService.instant(this.alert.key, this.alert.keyArgs);
    }

    if (this.alert.text) {
      return this.alert.text;
    }

    if (this.isArabic) {
      return this.alert.textAr;
    } else {
      return this.alert.textEn;
    }
  }

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit(): void {}

  public closed($event: any) {
    this.close.emit($event);
  }
}
