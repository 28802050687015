import { Lookup } from "../../common/Lookup";
import { AlertOptions } from "../../../../shared/bs-alert/AlertOptions";
import { UserDetails } from "../../shared/UserDetails";
import { CitesOutput } from "../cites-release-species/CitesReleaseSpeciesTransaction";
import { CitesBaseServiceRequest } from "../shared/CitesBaseServiceRequest";
import { CitesExporterDetails } from "../shared/CitesExporterDetails";
import { CitesImporterDetails } from "../shared/CitesImporterDetails";
import { CitesImportExportSpeciesDetails } from "../shared/CitesImportExportSpeciesDetails";
import { CitesTransactionBaseData } from "../shared/CitesTransactionBaseData";

export class CitesImportSpeciesTransaction 
    extends CitesBaseServiceRequest<CitesImportSpeciesTransactionData> {
        userDetails?: UserDetails;
        showItemsForm?: boolean = false;
        showCountryDeclaration?: boolean = false;
        countryDeclarationURL?: string;
        consumedQuantities?: ConsumedQuantity[];
        validateQuantity?: boolean;
        disableClass?: boolean;
        maxItemCount?: number;
        disclaimers?: AlertOptions[];
        citesOutputs?: CitesOutput[];
    }

export class CitesImportSpeciesTransactionData 
    extends CitesTransactionBaseData {
        id?: number;
        procedureInstanceId?: number;

        purpose: Lookup;
        entryPort: Lookup;
        arrivalDate: Date;
        specialCases?: string;

        exporterDetails: CitesExporterDetails;
        importerDetails: CitesImporterDetails;
      
        items?: CitesImportSpeciesItem[];
}

export class CitesImportSpeciesItem extends CitesImportExportSpeciesDetails {
    id?:number;
    requestId?:number;
    
    appendix: Lookup;
    accompanyingCertificateNo: string;
    accompanyingCertificateDate: Date;
    isRelated: boolean;
    relatedType: Lookup;
}

export class ConsumedQuantity{
    itemId: number;
    quantity: number;
}