import { Component, Injector, Inject } from '@angular/core';
import { MakeProvider } from '../AbstractValueAccessor';
import { TranslateService } from '@ngx-translate/core';
import { BsNgInputBaseComponent } from '../BsNgInputTextBaseComponent';
import { NgControl } from '@angular/forms';
import { INPUT_CONFIG_OPTIONS } from '../../../input-components.module';
import { InputSettingsService } from '../../../input-settings.service';

@Component({
  selector: 'bs-ng-switch',
  templateUrl: './bs-ng-switch.component.html',
  providers: [MakeProvider(BsNgSwitchComponent)],
})
export class BsNgSwitchComponent extends BsNgInputBaseComponent {
  constructor(
    translate: TranslateService,
    injector: Injector,
    @Inject(INPUT_CONFIG_OPTIONS) defaultSettings: InputSettingsService
  ) {
    super(translate, injector, defaultSettings);
  }

  ngOnInit() {
    if (this.key !== '') {
      this.getTranslations();
    }
    this.ngControl = this.inj.get(NgControl);
  }
}
