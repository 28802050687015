import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { CitesImportSpeciesTransaction } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { EsBaseServiceRequest } from 'src/app/core/models/shared/EsBaseServiceRequest';
import { CitesViewBaseComponent } from '../../shared/base/CitesViewBaseComponent';
import { CitesImportSpeciesWorkflowService } from '../cites-import-species-workflow.service';

@Component({
  selector: 'app-cites-import-species-request-view',
  templateUrl: './cites-import-species-request-view.component.html',
  styleUrls: ['./../../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CitesImportSpeciesViewComponent extends CitesViewBaseComponent {
  transaction$: Observable<CitesImportSpeciesTransaction>;

  constructor(
    translate: TranslateService,
    route: ActivatedRoute,
    @Inject('WorkflowService')
    public workflowService: CitesImportSpeciesWorkflowService
  ) {
    super(translate, route, workflowService);
  }

  public downloadVerificationDocument($event: any) {
    this.workflowService.DownloadVerificationDocument($event);
  }

  ngOnInit(): void {
    this.subscribeToSizeSettings();
  }

  public ShowReleaseSection(transaction: EsBaseServiceRequest): boolean {
    return transaction.isValidForRelease && transaction.releaseURL?.length > 0;
  }

  public navigateToReleaseService(): void {
    this.workflowService.initiateReleaseRequest();
  }
}
