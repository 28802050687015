import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {SearchDashboardRequestsParams} from '../../models/customer/SearchDashboardRequestsParams';
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {RegisterUserRequestsData, SearchLookupsResponse} from '../../models/customer/RegisterUserRequestsData';
import { Key } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class SearchMyRequestsService {


  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Customer/' + 'GetRequests';
  private REST_API_SERVER_ES = environment.eServiceAPIHost + 'Dashboard/GetRequests';
  private GET_SEARCH_LOOKUP = environment.eServiceAPIHost + 'Dashboard/GetSearchLookup';

  constructor(private httpClient: HttpClient) {
  }

  public getSearchLookups(){
    let params = new HttpParams();
    return this.httpClient.get<SearchLookupsResponse>(this.GET_SEARCH_LOOKUP)
    .pipe(catchError(this.handleError));
  }

  public searchMyRequest(requestParam: SearchDashboardRequestsParams): Observable<ResponseModel<RegisterUserRequestsData[]>> {
    let params = new HttpParams();
    params = this.buildHttpParams(params, requestParam, '');
    return this.httpClient.get<ResponseModel<RegisterUserRequestsData[]>>(this.REST_API_SERVER, {params})
    .pipe(catchError(this.handleError));
  }
  
  public searchMyRequestEs(requestParam: SearchDashboardRequestsParams): Observable<ResponseModel<RegisterUserRequestsData[]>> {
    let params = new HttpParams();

    requestParam.requestNumber = requestParam.requestNumber ?? '';
    const url = this.getSearchRequestURL(requestParam);
    return this.httpClient.get<ResponseModel<RegisterUserRequestsData[]>>(url, {params})
    .pipe(catchError(this.handleError));
  }

  private getSearchRequestURL(requestParam: SearchDashboardRequestsParams): string{
    let url: String = `${this.REST_API_SERVER_ES}?`;

    Object.keys(requestParam).map(k =>{
      if(k != 'pagination' && requestParam[k as keyof SearchDashboardRequestsParams]){
        url = `${url}&${k}=${requestParam[k as keyof SearchDashboardRequestsParams]}`
      }
    });

    return url + `&pageSize=${requestParam.pagination.pageSize}&pageNo=${requestParam.pagination.pageNo}`
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[SearchMyRequestsService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[SearchMyRequestsService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

  private buildHttpParams(params: HttpParams, data: any, currentPath: string): HttpParams {
    Object.keys(data).forEach(key => {
      if (data[key] instanceof Object) {
        params = this.buildHttpParams(params, data[key], `${currentPath}${key}.`);
      } else {
        if (data[key] != null){
          params = params.append(`${currentPath}${key}`, data[key]);

        }
      }
    });
    return params;
  }
}
