<form [formGroup]="formGroup">
  <legend
    class="col-form-label"
    [innerText]="'ViewServiceInformation' | translate">
  </legend>
  <div class="row">
    <div dsCol [colOptions]="fo.fullCol">
      <app-bs-display
        key="trCitesPurposeFld"
        [valueText]="transaction.serviceTransaction.purpose | lookup:lang">
      </app-bs-display>
    </div>
    <div dsCol [colOptions]="fo.fullCol">
      <app-bs-display
        key="trCitesSpecialCasesFld"
        [valueText]="transaction.serviceTransaction.specialCases">
      </app-bs-display>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trCitesArrivalDateFld"
        [valueText]="transaction.serviceTransaction.arrivalDate | date:'dd/MM/yyyy'">
      </app-bs-display>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <app-bs-display
        key="trImportEntryPortFld"
        [valueText]="transaction.serviceTransaction.entryPort | lookup:lang">
      </app-bs-display>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="shippingBillNumber"
        identifier="shippingBillNumber"
        key="trCitesBillNumberFld"
        name="shippingBillNumber"
        maxlength="100">
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-dropdown
        formControlName="shippingMethod"
        identifier="shippingMethod"
        key="trCitesShippingMethodFld"
        name="shippingMethod"
        [items]="lookups.shippingMethods"
        [bindLabel]="defaultLookupDisplayProperty">
      </bs-ng-dropdown>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="shippingPolicy"
        identifier="shippingPolicy"
        key="trCitesShippingPolicyFld"
        name="shippingPolicy"
        maxlength="50">
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="tanker"
        identifier="tanker"
        key="trCitesTankerFld"
        name="tanker"
        maxlength="50">
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-text
        formControlName="exportingPortForRelease"
        identifier="exportingPortForRelease"
        key="trCitesExportingPortFld"
        name="exportingPortForRelease"
        maxlength="100">
      </bs-ng-text>
    </div>
    <div dsCol [colOptions]="fo.halfCol">
      <bs-ng-date
        formControlName="checkinDate"
        identifier="checkinDate"
        key="trCitesCheckinDateFld"
        name="checkinDate">
      </bs-ng-date>
    </div>
  </div>
</form>