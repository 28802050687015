import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthGuard } from './auth-guard.guard';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardCheckerService {
  constructor(private router: Router) {}

  hasAuthGuard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Get the route configuration
    const routeConfig = route.routeConfig;

    // Check if the route has a canActivate guard
    if (routeConfig && routeConfig.canActivate) {
      // Check if any of the canActivate guards includes AuthGuard
      return routeConfig.canActivate.some((guard) => guard === AuthGuard);
    }

    return false;
  }
}
