import { Component, Input, OnInit } from "@angular/core";
import { BaseComponent } from "../base/BaseComponent";
import { TranslateService } from "@ngx-translate/core";
import { EsCommonService } from "src/app/core/api-services/shared/es-common.service";
import { ImportantLinkDto } from "src/app/core/models/common/ImportantLinkDto";
import { environment } from "src/environments/environment";

@Component({
    selector: 'dashboard-important-links',
    templateUrl: './dashboard-important-links.component.html',
    styleUrls: ['./dashboard-important-links.component.scss']
})
  
export class DashboardImportantLinksComponent extends BaseComponent{

    @Input() closeImportantLinksCallback: () => void;

    baseEserviceUrl = environment.domainURL;
    links: ImportantLinkDto;

    constructor(
        translateService: TranslateService,
        private esCommonService: EsCommonService,)
    {
        super(translateService);

        esCommonService.getImportantLinks().subscribe((response)=>{
            if(response.IsSuccess){
                this.links = response.Data;
            }
        });
    }

    public closeImportantLinks(){
        this.closeImportantLinksCallback();
    }
}