import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  FavoriteServicesResponse,
  FavoriteServicesToggleResponse,
  FavoriteServiceToggleRequest,
} from '../export/shared/responses/FavoriteServicesResponse';
import { EsBaseTemplateService } from '../shared/es-base-template.service';

@Injectable()
export class FavoriteServicesService extends EsBaseTemplateService {
  readonly basePath = 'FavoriteServices';
  private readonly GET_FAVORITES = 'GET';
  private readonly POST_TOGGLE_FAVORITES = 'toggle';

  constructor(private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<FavoriteServicesResponse> {
    return this.httpClient
      .get<FavoriteServicesResponse>(this.buildUrl(this.GET_FAVORITES))
      .pipe(catchError(this.handleError));
  }

  public toggle(
    procedureId: string,
    isFavorite: boolean
  ): Observable<FavoriteServicesToggleResponse> {
    return this.httpClient
      .post<FavoriteServicesToggleResponse>(
        this.buildUrl(this.POST_TOGGLE_FAVORITES),
        {
          isFavorite: isFavorite,
          procedureId: procedureId,
        }
      )
      .pipe(catchError(this.handleError));
  }
}
