import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { RequestActions } from '../base/RequestActions';
import { MoveFlowStepDataHandler } from '../base/MoveFlowStepDataHandler';
import { MainStepsConfigurations } from '../base/MainStepsConfigurations';
import { WorkflowSteps } from '../base/WorkflowSteps';
import { WorkflowBaseService } from '../base/WorkflowBaseService';

@Component({
  selector: 'app-steps-flow',
  templateUrl: './steps-flow.component.html',
})
export class StepsFlowComponent<T> implements OnInit {
  constructor(
    public router: Router,
    @Inject('WorkflowService')
    public workflowService: WorkflowBaseService
  ) {
    this.flow = this.workflowService.flow();
  }

  @Input()
  flow: MainStepsConfigurations;

  @Input()
  showBackArrow: boolean;

  @Output()
  move = new EventEmitter();

  ngOnInit(): void {}

  moveToStep(step: WorkflowSteps) {
    let event: MoveFlowStepDataHandler = { step: step };
    this.move.emit(event);
  }

  moveBack() {
    let event: MoveFlowStepDataHandler = {
      side: RequestActions.Back,
    };
    this.move.emit(event);
  }

  moveForward() {
    let event: MoveFlowStepDataHandler = {
      side: RequestActions.Next,
    };
    this.move.emit(event);
  }

  public anyNextVisibleStep(stepIndex: number): boolean {
    if (this.flow && this.flow.steps) {
      const lastIndex = this.flow.steps.length - 1;

      if (stepIndex != lastIndex) {
        const nexSteps = this.flow.steps.slice(stepIndex + 1, lastIndex);

        const visibleNextSteps = nexSteps.filter((s) => s.display);

        return visibleNextSteps.length > 0;
      }
    }

    return false;
  }
}
