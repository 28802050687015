<div class="sticky-menu-container d-print-none">
  <ul class="sticky-menu">
    <li>
      <a class="poll cursor-pointer">
        <i class="fi-poll"></i>
        <span> {{ 'StickyMenuPoll' | translate }} </span>
      </a>
    </li>
    <li>
      <div class="social" title="{{ 'StickyMenuSocialMedia' | translate }}">
        <i class="fi-share-n"></i>
        <span>
          <a
            target="_blank"
            class="facebook fi-facebook-ico"
            title="فيسبوك"
            href="https://www.facebook.com/MoCCAEUAE/"
          ></a>

          <a
            target="_blank"
            class="twitter fi-twitter-n"
            href="https://twitter.com/moccaeuae"
            title="تويتر"
          ></a>

          <a
            target="_blank"
            class="youtube-me fi-youtube-n"
            href="https://www.youtube.com/user/moewmedia"
            title="يوتيوب"
          ></a>
          <a
            target="_blank"
            class="instagram fi-instagram-n"
            href="https://www.instagram.com/moccaeuae/"
            title="انستغرام"
          ></a>
        </span>
      </div>
    </li>
    <li>
      <a
        class="rss-feeds"
        href="/rss/news.ashx?lang=1"
        target="_blank"
        title="{{ 'StickyMenuRss' | translate }}"
      >
        <i class="fi-rss"></i>
        <span> {{ 'StickyMenuRss' | translate }}</span>
      </a>
    </li>
    <li class="my_sugges">
      <a
        class="tawasul"
        target="_blank"
        title="{{ 'StickyMenuRemarksAndSuggestion' | translate }}"
        [href]="'https://171.ae/ar/?lang=' + lang"
      >
        <i class="fi-tawasul"></i>
        <span> {{ 'StickyMenuRemarksAndSuggestion' | translate }}</span>
      </a>
    </li>
    <li>
      <a
        class="share addthis_button ignore-confirmaton"
        href="https://www.addthis.com/bookmark.php"
        target="_blank"
        title="{{ 'StickyMenuShare' | translate }}"
      >
        <i class="fi-share-1"></i>
        <span> {{ 'StickyMenuShare' | translate }}</span>
      </a>
    </li>
    <li class="my_happiness">
      <a
        class="happiness cursor-pointer"
        (click)="showCustomerPulseModal()"
        title="{{ 'StickyMenuHappinessMeter' | translate }}"
      >
        <i class="icon-pulse"></i>
        <span class="icon-text">
          {{ 'StickyMenuHappinessMeter' | translate }}</span
        >
      </a>
    </li>

    <li style="display: none">
      <a href="#" class="guide-tour" data-target="tour">
        <i class="fi-info"></i>
        <span class="icon-text">Tour Guide</span>
      </a>
    </li>
  </ul>
</div>
