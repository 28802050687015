import {
  AfterViewInit,
  Component,
  createNgModuleRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowStepStatusCodesEnum } from 'src/app/core/models/common/ServiceMainTransactionData';
import {
  RegisterUserRequestsData,
  URLAction,
} from 'src/app/core/models/customer/RegisterUserRequestsData';
import { UtilService } from 'src/app/core/utilities/util.service';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { RequestCardComponentResolverService } from './request-card-component-resolver.service';

@Component({
  selector: 'request-card',
  templateUrl: './request-card.component.html',
})
export class RequestCardComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  @Input()
  workItem: RegisterUserRequestsData;

  @Output()
  cancelDraftRequest = new EventEmitter<RegisterUserRequestsData>();

  @Output()
  selectCertificate = new EventEmitter<RegisterUserRequestsData>();

  @Output()
  onEsClickCommunicationMessage = new EventEmitter<RegisterUserRequestsData>();

  @Output()
  onClickCommunicationMessage = new EventEmitter<RegisterUserRequestsData>();

  @Output()
  loadRequestDetails = new EventEmitter<RegisterUserRequestsData>();

  @Output()
  goToPage = new EventEmitter<URLAction>();

  @Output()
  cancelRequest = new EventEmitter<{
    action: URLAction;
    workItem: RegisterUserRequestsData;
  }>();

  @ViewChild('requestCardExtra', { static: true, read: ViewContainerRef })
  private viewContainerRef: ViewContainerRef;

  constructor(
    translateService: TranslateService,
    public utilService: UtilService,
    private router: Router,
    private injector: Injector,
    private requestCardResolverService: RequestCardComponentResolverService
  ) {
    super(translateService);
  }

  ngAfterViewInit(): void {
    this.loadRequestModule();
  }

  private loadRequestModule() {
    try {
      if (
        this.workItem.IsEservicesRequest &&
        this.workItem.workflowStatusCode !=
          this.WorkflowStepStatusCodesEnum.Draft
      ) {
        const moduleRoute = this.getModuleRoute();

        if (moduleRoute) {
          const children$ = moduleRoute.loadChildren() as Promise<unknown>;

          children$.then((moduleType) => {
            const moduleRef = createNgModuleRef(
              moduleType as Type<unknown>,
              this.injector
            );

            if (moduleRef) {
              const componentRef = this.viewContainerRef.createComponent(
                this.requestCardResolverService.resolveComponentType(moduleRef),
                { ngModuleRef: moduleRef }
              );

              componentRef.instance.requestId =
                this.workItem.serviceRequestTransactionId;
              componentRef.instance.serviceId = this.workItem.serviceCatalogId;
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  private getModuleRoute(): Route {
    const modulePath = this.workItem.frontOfficeViewPageUrl.substring(
      0,
      this.workItem.frontOfficeViewPageUrl.indexOf('/view')
    );

    const p = this.router.config.find((a) => a.path == modulePath);

    return p;
  }

  ngOnInit(): void {}

  public get WorkflowStepStatusCodesEnum(): typeof WorkflowStepStatusCodesEnum {
    return WorkflowStepStatusCodesEnum;
  }

  public get isEServicesRequest(): boolean {
    return this.workItem.IsEservicesRequest;
  }

  public get isCompletedRequest(): boolean {
    return (
      this.workItem.workflowStatusCode == WorkflowStepStatusCodesEnum.Completed
    );
  }
}
