export class EsResponseModel<TData> {
  Code: number;
  Message: string;
  Data: TData;
  IsSuccess: boolean;
  TotalRecordsCount: number;
  Validations: null;
}

export class AppointmentResponseModel<TData> {
  status: string;
  message: string;
  data: TData;
  
}
