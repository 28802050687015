import {
  Component,
  ViewEncapsulation,
  EventEmitter,
  Output,
} from '@angular/core';
import { BaseFormComponent } from 'src/app/shared/base/BaseFormComponent';

@Component({
  selector: 'app-es-request-template-form',
  templateUrl: './es-request-template-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class EsRequestTemplateFormComponent extends BaseFormComponent {
  @Output()
  save = new EventEmitter();

  public saveRequestTemplate() {
    this.save.emit();
  }
}
