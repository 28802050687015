import { GetGeneralAlertMessageService } from './core/api-services/common/get-general-alert-message.service';
import { environment } from 'src/environments/environment';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from './core/utilities/util.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, combineLatest } from 'rxjs';
import { GetNotificationService } from './core/api-services/customer/get-notification.service';
import { AuthService } from './shared/authentication/auth.service';
import { RegisteredUserNotification } from './core/models/customer/RegisteredUserNotification';
import { SwUpdate } from '@angular/service-worker';
import { BnNgIdleService } from 'bn-ng-idle';
import { RedirectService } from './shared/authentication/redirect.service';
import { ToastrService } from 'ngx-toastr';
import { GeneralAlertsMessageObject } from './core/models/common/GeneralAlertsMessageResponse';
import { AppSettingsLocalStorageService } from './shared/settings/app-settings-local-storage.service';
import {
  FabActionDatHolder,
  FabActionEnum,
} from './shared/layout/fab/fab.component';
import {
  StickyMenuActionDataHandler,
  StickyMenuActionEnum,
} from './shared/layout/sticky-menu/sticky-menu.component';
import { BaseComponent } from './shared/base/BaseComponent';
import { AppSettingsService } from './shared/settings/app-settings.service';
import { ThemeService } from './core/utilities/theme.service';

@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  title = 'DigitalServicesHub';

  observableSubscriptions: Subscription[] = [];
  notificationList: RegisteredUserNotification[] = [];
  notificationCount = 0;
  showRedirectingSpinner = false;
  currentLang = 'en';
  window: any;
  generalAlertsMessageObject: GeneralAlertsMessageObject;

  public get isMobile$() {
    return this.settingsService.focusMode$;
  }

  constructor(
    translateService: TranslateService,
    private titleService: Title,
    private utilService: UtilService,
    private themeService: ThemeService,
    protected toastr: ToastrService,
    private serviceWorkerUpdate: SwUpdate,
    public auth: AuthService,
    private generalAlertMessageService: GetGeneralAlertMessageService,
    private bnIdle: BnNgIdleService,
    public redirectService: RedirectService,
    private getNotificationService: GetNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: AppSettingsService,
    settingsLocalStorageService: AppSettingsLocalStorageService
  ) {
    super(translateService);

    settingsLocalStorageService.initialize();

    combineLatest([
      this.translateService.onLangChange.asObservable(),
      this.isMobile$,
      this.themeService.theme$,
    ]).subscribe(([language, isMobile]) => {
      // isMobile == false added because the mobile app already has chatbot & live chat features
      if (environment.chatBotEnabled && isMobile.isFocusMode == false) {
        this.loadChatbotScripts();
      }

      if (isMobile.isFocusMode == false) {
        this.utilService.updateLiveChat();
      }

      this.setTitle(translateService.instant('DigitalServicesHub'));
    });

    
      // if (!auth.isAuthenticated() && environment.production) {
      //   this.showRedirectingSpinner = true;
      // } else if (!utilService.isUserProfileExists()) {
      //   console.log('User Profile does  not Exist');
      //   this.router.navigateByUrl('/error');
      // } else if (
      //   utilService.isEmployeeIdExists() &&
      //   !utilService.isEmployeeProfileExists()
      // ) {
      //   console.log('Employee Profile does  not Exist');
      //   this.router.navigateByUrl('/error');
      // }
    
    // to set the current selected color theme
    themeService.setDefaultColorTheme();

    // to set the current selected display mode (light/ dark)
    themeService.setDefaultDisplayMode();

    this.loadCustomerPulseScripts();

    this.window = window;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    // 20 Min
    let timeOutSeconds: number = 60;

    if (environment.production) {
      this.bnIdle
        .startWatching(timeOutSeconds * 120)
        .subscribe((isTimedOut: boolean) => {
          Swal.fire(this.translateService.instant('YourSessionIsExpired'));
          this.utilService.logout();
        });
    }

    if (this.utilService.getParticipantId()) {
      this.getNotification(false);
    }

    if (this.serviceWorkerUpdate.isEnabled) {
      console.log(' Service Worker is Enabled');
    }

    //TODO: remove the authentication check if the DS doesn't redirect anymore to ES on login
    if (this.auth.isAuthenticated()) {
      this.getGeneralAlertMessage();
    }
  }

  goToExternalPage(url: string): void {
    window.location.href = url;
  }

  goToPage(name: string): void {
    this.router.navigateByUrl('/' + name);
  }

  public handleFabActions($event: FabActionDatHolder) {
    switch ($event.type) {
      case FabActionEnum.GoToPage:
        this.goToPage($event.goToPageUrl);
        break;
      case FabActionEnum.Notifications:
        this.goToNotification();
        break;
      default:
        console.error('FabActionEnum action is not handled');
    }
  }

  public handleStickyMenuActions($event: StickyMenuActionDataHandler) {
    switch ($event.type) {
      case StickyMenuActionEnum.ShowCustomerPulse:
        this.showCustomerPulseModal();
        break;
      default:
        console.error('FabActionEnum action is not handled');
    }
  }

  private loadChatbotScripts(): void {
    const scriptUrl = document.createElement('script');
    scriptUrl.setAttribute('src', environment.chantBotURL);
    scriptUrl.setAttribute('type', 'text/javascript');
    scriptUrl.setAttribute('charset', 'UTF-8');
    document.getElementsByTagName('head')[0].appendChild(scriptUrl);

    const scriptCode = document.createElement('script');
    scriptCode.setAttribute('src', environment.chatBotScript);
    scriptCode.setAttribute('type', 'text/javascript');
    scriptCode.setAttribute('charset', 'UTF-8');
    document.getElementsByTagName('head')[0].appendChild(scriptCode);
  }

  private loadCustomerPulseScripts(): void {
    const scriptUrl = document.createElement('script');
    scriptUrl.setAttribute('src', environment.customerPulseURL);
    scriptUrl.setAttribute('type', 'text/javascript');
    scriptUrl.setAttribute('charset', 'UTF-8');
    document.getElementsByTagName('head')[0].appendChild(scriptUrl);
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  private goToNotification(): void {
    this.getNotification(true);
  }

  private getGeneralAlertMessage() {
    this.generalAlertMessageService
      .getGeneralAlertMessage()
      .subscribe((res) => {
        this.generalAlertsMessageObject = res.responseData;
      });
  }

  getNotification(redirectToNotification: boolean): void {
    const participantId = this.utilService.getParticipantId();
    if (participantId == null || participantId === '') {
      return;
    }

    this.observableSubscriptions.push(
      this.getNotificationService.getNotification().subscribe((res) => {
        this.notificationList = res.responseData;
        this.notificationCount = this.notificationList.length;
        if (this.notificationCount > 0 && redirectToNotification) {
          this.router.navigateByUrl('/' + 'home?isNotification=true');
        }
      })
    );
  }

  ngAfterViewInit() {
    const addThisScriptTag = document.createElement('script');
    addThisScriptTag.src =
      'https://eservices.moccae.gov.ae/Scripts/addthis_widget.js';
    addThisScriptTag.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(addThisScriptTag);

    this.route.queryParams.subscribe((params: Params) => {
      const mobileAppParameterExist =
        params['mobileapp'] === '' || params['mobileapp'];

      if (mobileAppParameterExist) {
        this.settingsService.enterToFocusMode();
      }

      if (params['mobileapp'] == 'reset' || params['mobileapp'] === '0') {
        this.settingsService.exitFocusMode();
      }
    });
  }

  showCustomerPulseModal() {
    this.window.CustomerPulse.render(document.getElementsByTagName('body')[0], {
      modal: true,
      link: 'E/Aj/',
    });
    this.window.CustomerPulse.openModal();
  }
}
