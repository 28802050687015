import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesFalconPassportsTransaction } from 'src/app/core/models/cites/cites-falcon-passports/CitesFalconPassportsTransaction';
import { Lookup } from 'src/app/core/models/common/Lookup';
import { CitesBaseFormComponent } from '../base/CitesBaseFormComponent';

@Component({
  selector: 'app-cites-delivery-form',
  templateUrl: './cites-delivery-form.component.html',
})
export class CitesDeliveryFormComponent extends CitesBaseFormComponent {
  @Input()
  deliveryCompanyDetails: Lookup;

  mobileMask = '000000000';
  mobileMaskPrefix = '00971';

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  public onEmirateChange($event: any): void {
    this.change.emit({
      emirateId: $event.new?.id,
      isLoadRegionByEmirateId: true,
    });
  }

  public onRegionChange($event: any): void {
    this.change.emit({
      emirateId: $event.new?.id,
      isLoadDeliveryTypes: true,
    });
  }

  public onDeliveryType($event: any) {
    this.change.emit({
      isBuildFieldsOptions: true,
    });
  }
}
