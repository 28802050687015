// tslint:disable-next-line:import-spacing
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { GetRequestDataService } from '../../../core/api-services/common/get-request-data.service';
import {
  CongratsMessages,
  WorkflowService,
} from '../../shared-workflow-object/workflow.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { ServiceRequestType } from 'src/app/core/models/common/ServiceRequestType';
import { GeneratingCustomerPulseTokenService } from 'src/app/core/api-services/common/generating-customer-pulse-token.service';
import {
  Customer,
  Employee,
  GeneratingCustomerPulseRequestObject,
  MetaData,
} from 'src/app/core/models/common/GeneratingCustomerPulseObject';
import { UtilService } from 'src/app/core/utilities/util.service';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';
import { EsCommonService } from 'src/app/core/api-services/shared/es-common.service';

const currentStep = 'Congratulations';

@Component({
  selector: 'app-congratulations',
  templateUrl: './congratulations.component.html',
  styleUrls: ['./congratulations.component.scss'],
})
export class CongratulationsComponent<T>
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  renderAutoFocusElement: boolean = true;

  // responseData: ServiceMainTransactionData;
  stateObject: any;
  currentDate: any;
  serviceRequestType: ServiceRequestType;

  public get messages(): CongratsMessages {
    return this.stateObject.messages;
  }

  window: any;
  constructor(
    private router: Router,
    public getRequestDataService: GetRequestDataService,
    @Inject('WorkflowService') public workflowService: WorkflowService<T>,
    private platform: Platform,
    private uilService: UtilService,
    private generatingCustomerPulseTokenService: GeneratingCustomerPulseTokenService,
    @Inject(DOCUMENT) private document: Document,
    translateService: TranslateService,
    private esCommonService: EsCommonService
  ) {
    super(translateService);

    this.renderAutoFocusElement = this.workflowService.renderAutoFocusElement;

    workflowService.currentStep = currentStep;
    this.serviceRequestType = workflowService.requestTypeObject;
    this.window = this.document.defaultView;
    const possibleStepAfterLastValidStep =
      workflowService.getPossibleStepAfterLastValidStep(currentStep);
    if (possibleStepAfterLastValidStep !== currentStep) {
      workflowService.goToStep(possibleStepAfterLastValidStep, router);
    }

    const responseData = workflowService.getPostDataResponseObject();

    console.log('getting transaction', responseData.id);

    if (responseData && responseData.id) {
      this.createCustomerPulse(this.workflowService.backendSystem);
    }

    this.currentDate = new Date();
    this.stateObject = workflowService.globalObject.Congratulations;
    this.stateObject.form.valid = true;
  }

  @ViewChild('inputAutofocus') myInputField: ElementRef;
  ngAfterViewInit(): void {
    if (this.renderAutoFocusElement) this.myInputField.nativeElement.focus();

    if (this.platform.IOS) {
      const element = document.getElementById('containerDivId');
      this.window.setTimeout(() => {
        element.scrollIntoView(true);
      });
    }
  }

  ngOnInit(): void {}

  submitAnotherRequest(): void {
    this.workflowService.submitNewRequest(this.router);
  }

  gotoPage(name: string): void {
    this.router.navigateByUrl('/' + name);
  }

  private showCustomerPulseModal(tokenParam: string) {
    this.window.CustomerPulse.render(document.getElementsByTagName('body')[0], {
      modal: true,
      token: tokenParam,
      lang: this.lang,
    });
    this.window.CustomerPulse.openModal();
    this.addCustomerPulseEndEvents();
  }

  private addCustomerPulseEndEvents() {
    this.window.addEventListener('so-widget-completed', async () => {
      // await this.updateTheRequest();
    });

    this.window.addEventListener('so-widget-closed', async () => {
      // await this.updateTheRequest();
    });
  }

  generatingCustomerPulseToken(
    linkingId: string,
    transactionId: string,
    registerUserId: string
  ): void {
    if (!linkingId || !transactionId || !registerUserId) {
      return;
    }

    let request = new GeneratingCustomerPulseRequestObject();
    request.linking_id = linkingId;
    request.meta_data = new MetaData();
    request.meta_data.customer = new Customer();
    request.meta_data.employee = new Employee();
    request.meta_data.employee.employee_id = '';
    request.meta_data.transaction_id = transactionId;
    request.meta_data.customer.user_id = registerUserId;

    request.meta_data.customer.user_id = registerUserId;
    request.meta_data.customer.email = this.uilService.getUserProfile().email;
    let mobileNumber: string = this.uilService.getUserProfile().mobile;
    request.meta_data.customer.mobile =
      this.uilService.getValidMobileAccordingToCustomerPulseBusiness(
        mobileNumber
      );

    this.generatingCustomerPulseTokenService
      .generatingCustomerPulseToken(request)
      .subscribe((resp) => {
        if (
          resp.responseData &&
          resp.responseData.data &&
          resp.responseData.data.token
        ) {
          this.showCustomerPulseModal(resp.responseData.data.token);
        }
      });
  }

  private async esGeneratingCustomerPulseToken(
    transactionId: string
  ): Promise<void> {
    if (!transactionId) {
      return;
    }

    const resp = await this.esCommonService
      .getCustomerPulseToken(transactionId.toString())
      .toPromise();

    if (resp.IsSuccess && resp.Data) {
      this.showCustomerPulseModal(resp.Data);
    }
  }

  private createCustomerPulse(backendSystem: BackendSystemEnum): void {
    if (this.workflowService.globalObject.Congratulations.isFeedbackDone)
      return;

    const customerPulseId =
      this.platform.ANDROID || this.platform.IOS
        ? this.workflowService.globalObject.Congratulations
            .customerPulseLinkingIdForMobile
        : this.workflowService.globalObject.Congratulations
            .customerPulseLinkingId;

    const transactionId =
      this.workflowService.globalObject.Congratulations.transactionId;
    const registerUserId =
      this.workflowService.globalObject.Congratulations.registerUserId;

    if (backendSystem == BackendSystemEnum.DigitalServices) {
      this.generatingCustomerPulseToken(
        customerPulseId,
        transactionId,
        registerUserId
      );
    } else {
      this.esGeneratingCustomerPulseToken(transactionId);
    }
  }
}

export enum BackendSystemEnum {
  EServices,
  DigitalServices,
}
