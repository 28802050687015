export class AlertOptions {
  key?: string = '';
  keyArgs?: any;
  text?: string = '';
  textAr?: string = '';
  textEn?: string = '';

  display: boolean = true;

  code?: string;
}
