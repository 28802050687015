import { Component, Input } from "@angular/core";
import { CitesExporterDetails } from "src/app/core/models/cites/shared/CitesExporterDetails";
import { CitesBaseFormComponent } from "../base/CitesBaseFormComponent";

@Component({
    selector: 'app-cites-exporter-review',
    templateUrl: './cites-exporter-review.component.html',
})
  
export class CitesExporterReviewComponent extends CitesBaseFormComponent {
  @Input()
  exporterDetails: CitesExporterDetails;
}