import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { RedirectService } from '../../shared/authentication/redirect.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Employee, RegisteredUser, RegisteredUserTypesEnum } from '../models/profile/RegisteredUser';
import { GetBinaryFileDataService } from '../api-services/common/get-binary-file-data.service';
import { GetReportingBinaryFileDataService } from '../api-services/common/get-binary-file-reporting-data.service';
import { CertificateCatalogCodesEnum } from '../models/common/ServiceMainTransactionData';
import { ExceptionTypesCodeEnum } from '../enums/exception-type-Code-enum';
import { CountryCodesEnum } from '../enums/CountryCodesEnum';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  static HYPHEN_SEPARATOR = '-';
  LOGOUT_URL = environment.logoutUrl;

  constructor(
    private cookieService: CookieService,
    private getBinaryFileDataService: GetBinaryFileDataService,
    private getReportingBinaryFileDataService: GetReportingBinaryFileDataService,
    private redirect: RedirectService
  ) {}

  getParticipantId(): string {
    return this.cookieService.get('ParticipantId');
  }

  getUUID(): string {
    return this.cookieService.get('UUID');
  }

  getEmployeeId(): string {
    return this.cookieService.get('employeeId');
  }

  isParticipantIdExists(): boolean {
    const participantId = this.cookieService.get('ParticipantId');
    return (
      participantId != null && participantId !== 'null' && participantId !== ''
    );
  }

  isEmployeeIdExists(): boolean {
    const employeeId = this.cookieService.get('employeeId');
    return employeeId != null && employeeId !== 'null' && employeeId !== '';
  }

  getToken(): string {
    return this.cookieService.get('x-moccae-token');
  }

  isTokenExists(): boolean {
    return this.cookieService.check('x-moccae-token');
  }

  deleteToken(): void {
    const path = '/';
    const domain: string = environment.parentCookieDomainMOCCae;
    const secure = true;
    this.cookieService.delete('x-moccae-token', path, domain, secure);
  }

  deleteEmployeeIdCookie(): void {
    const path = '/';
    const domain: string = environment.parentCookieDomainMOCCae;
    const secure = true;
    this.cookieService.delete('employeeId', path, domain, secure);
  }

  deleteParticipantIdCookie(): void {
    const path = '/';
    const domain: string = environment.parentCookieDomainMOCCae;
    const secure = true;
    this.cookieService.delete('ParticipantId', path, domain, secure);
  }

  getEServiceLang(): string {
    return this.cookieService.get('eservice_Lang');
  }

  saveLang(lang: string): void {
    const expiryDate: any = null;
    const path = '/';
    const domain: string = environment.parentCookieDomainMOCCae;
    const secure = true;
    if (environment.production) {
      return this.cookieService.set(
        'eservice_Lang',
        lang,
        expiryDate,
        path,
        domain,
        secure
      );
    } else {
      this.cookieService.set('eservice_Lang', lang);
    }
  }

  saveParticipantId(participantId: string): void {
    const expiryDate: any = null;
    const path: string = "/";
    const domain: string = environment.parentCookieDomainMOCCae;
    const secure = true;
    if (participantId == null) {
      return;
    }
    if (environment.production) {
      this.cookieService.set(
        'ParticipantId',
        participantId,
        expiryDate,
        path,
        domain,
        secure
      );
    } else {
      this.cookieService.set('ParticipantId', participantId);
    }
  }

  isUserProfileExists(): boolean {
    const participantId = localStorage.getItem('Profile');
    return (
      participantId != null && participantId !== 'null' && participantId !== ''
    );
  }
  isEmployeeProfileExists(): boolean {
    const employee = localStorage.getItem('EmployeeProfile');
    return employee != null && employee !== 'null' && employee !== '';
  }

  /*
   * Validate Employee exists and Has Access On The Service.
   */
  public isEmployeeHasAccessOnTheService(serviceId: number): boolean {
    //get Employee data if exists [Employee login on behalf of customer].
    let employeeObj: Employee = this.getEmployeeProfile();
    if (!employeeObj) {
      return true;
    }

    if (employeeObj) {
      if (
        employeeObj.allowedServiceIdsWithEditAccess == null ||
        employeeObj.allowedServiceIdsWithEditAccess.length == 0 ||
        !employeeObj.allowedServiceIdsWithEditAccess.includes(serviceId)
      ) {
        return false;
      }
    }

    return true;
  }

  isEmployeeAction() {
    let employee: Employee = this.getEmployeeProfile();
    if (employee) {
      return true;
    }

    return false;
  }

  getUserProfile(): RegisteredUser {
    return JSON.parse(localStorage.getItem('Profile'));
  }

  getEmployeeProfile(): Employee {
    return JSON.parse(localStorage.getItem('EmployeeProfile'));
  }

  deleteUserProfile(): void {
    localStorage.removeItem('Profile');
  }

  deleteEmployeeProfile(): void {
    localStorage.removeItem('EmployeeProfile');
  }

  isProfileHasResearchCenterType(): boolean {
    const registeredUser: RegisteredUser = this.getUserProfile();
    if (
      registeredUser == null ||
      registeredUser.exceptionTypesCodes == null ||
      registeredUser.exceptionTypesCodes.length === 0
    ) {
      return false;
    }

    const typesCodes: string[] = registeredUser.exceptionTypesCodes;
    for (const exceptionTypeCode of typesCodes) {
      if (
        exceptionTypeCode === ExceptionTypesCodeEnum.ResearchCenter.toString()
      ) {
        return true;
      }
    }

    return false;
  }

  isLocalProfile(): boolean {
    const registeredUser: RegisteredUser = this.getUserProfile();
    if (
      registeredUser == null ||
      registeredUser.people == null ||
      registeredUser.people.countryCode == null
    ) {
      return false;
    }

    if (registeredUser.people.countryCode === CountryCodesEnum.ARE) {
      return true;
    }

    return false;
  }

  saveProfileData(profile: RegisteredUser): void {
    localStorage.setItem('Profile', JSON.stringify(profile));
  }

  saveEmployeeData(profile: Employee): void {
    localStorage.setItem('EmployeeProfile', JSON.stringify(profile));
  }

  readBinaryFile(base64: string, mimeType: string): Blob {
    const data = atob(base64);
    const array = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
      array[i] = data.charCodeAt(i);
    }
    const file = new Blob([array], { type: mimeType });
    return file;
  }

  /** finds the different between two dates assuming @d1 is greater than d2 */
  dateDifference(d1: Date, d2: Date, diffType: string): number {
    switch (diffType) {
      case 'month':
        const yearDifference = (d1.getFullYear() - d2.getFullYear()) * 12;
        const monthDifference = d1.getMonth() - d2.getMonth();

        return yearDifference + monthDifference;
      case 'year':
        return d1.getFullYear() - d2.getFullYear();
      default:
        return 0;
    }
  }

  /**
   * this method is called when the live chat dynamic values like colors and language changes to be updated
   */
  updateLiveChat(): void {
    // Get live chat container tag
    const liveChatElement = document.getElementById('divChatPopup');
    // Get the old script and remove it
    const children = liveChatElement.getElementsByTagName('script');
    if (children.length > 0) {
      liveChatElement.removeChild(children[0]);
    }
    // Append the container tag with a new script element to calculate the new values
    const scriptTag = document.createElement('script');
    scriptTag.src = 'assets/scripts/live-chat.js';
    scriptTag.type = 'text/javascript';
    liveChatElement.appendChild(scriptTag);
  }

  logout(): void {
    this.deleteToken();
    this.deleteParticipantIdCookie();
    this.deleteEmployeeIdCookie();
    this.redirect.redirect(this.LOGOUT_URL).then((r) => {
      console.log('redirect finished');
    });
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`;
      }
      case 403: {
        return `Access Denied: ${error.message}`;
      }
      case 500: {
        return `Internal Server Error: ${error.message}`;
      }
      default: {
        return `Unknown Server Error: ${error.message}`;
      }
    }
  }

  viewFile(filePath: string, fileType: string): void {
    this.getBinaryFileDataService
      .getBinaryFileData(filePath)
      .subscribe((data) => {
        const binaryData = [];
        binaryData.push(data);
        const blob = new Blob(binaryData, { type: fileType });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
  }

  viewCertificate(
    certificateCatalogCode: CertificateCatalogCodesEnum,
    serviceRequestTransactionId: number
  ): void {
    this.getReportingBinaryFileDataService
      .getBinaryFileData(certificateCatalogCode, serviceRequestTransactionId)
      .subscribe((data) => {
        const binaryData = [];
        binaryData.push(data);
        const blob = new Blob(binaryData, { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
  }

  downloadCertificate(
    certificateCatalogCode: CertificateCatalogCodesEnum,
    serviceRequestTransactionId: number,
    fileName: string
  ): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    this.getReportingBinaryFileDataService
      .getBinaryFileData(certificateCatalogCode, serviceRequestTransactionId)
      .subscribe((data) => {
        const binaryData = [];
        binaryData.push(data);
        const blob = new Blob(binaryData, { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      });
  }

  getValidMobileAccordingToCustomerPulseBusiness(mobile: string): string {
    if (mobile == '' || mobile == null || mobile == undefined) {
      return '';
    }

    if (mobile.startsWith('00971')) {
      mobile = mobile.replace('00971', '+971');
    } else if (mobile.startsWith('971')) {
      mobile = mobile.replace('971', '+971');
    } else if (mobile.startsWith('05')) {
      mobile = mobile.replace('05', '+9715');
    }

    //Validate UAE mobile number using js regex. Mobile No- +971 XXXXXXXXX (+971 and 9 digits after it.)
    let reg = /\+971([\d]{9})/;
    let matches = mobile.match(reg);

    if (matches.length > 0) {
      //the number is valid
      console.log('The Number is valid mobile= ' + mobile);
      return mobile;
    }

    return '';
  }

  public setGatewayTokenCoockie(token: any) {
    this.cookieService.set('x-moccae-token', token);
  }

  public isIndividualUser(){
    let profile = JSON.parse(localStorage.getItem('Profile')) as RegisteredUser;
    return profile.registeredUserType == RegisteredUserTypesEnum.Person;
  }
}
