import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EsApplicantInformationComponent } from '../../es-shared/es-applicant-information/es-applicant-information.component';
import { RouterModule, Routes } from '@angular/router';
import { ImportAttachmentsComponent } from '../../es-shared/es-attachments-page/es-attachments-page.component';
import { PaymentComponent } from '../../shared-services-steps-module/payment/payment.component';
import { CongratulationsComponent } from '../../shared-services-steps-module/congratulations/congratulations.component';
import { EsSharedModule } from '../../es-shared/es-shared.module';
import { EsViewComponent } from '../../es-shared/es-view-page/es-view-page.component';
import { CitesSharedModule } from '../shared/cites-shared.module';
import { CitesImportSpeciesComponent } from './cites-import-species.component';
import { CitesImportSpeciesRequestComponent } from './request-details/cites-import-species-request.component';
import { CitesImportSpeciesReviewComponent } from './request-review/cites-import-species-request-review.component';
import { CitesImportSpeciesViewItemComponent } from './item-view/cites-import-species-item-view.component';
import { CitesImportSpeciesFormComponent } from './request-form/cites-import-species-request-form.component';
import { CitesImportSpeciesReviewDetailsComponent } from './request-review-details/cites-import-species-request-review-details.component';
import { CitesImportSpeciesViewComponent } from './request-view/cites-import-species-request-view.component';
import { CitesImportSpeciesItemFormComponent } from './item-form/cites-import-species-item-form.component';
import { CitesImportSpeciesItemListComponent } from './item-list/cites-import-species-item-list.component';
import { CitesImportSpeciesMapper } from './CitesImportSpeciesMapper';
import { CitesImportSpeciesService } from 'src/app/core/api-services/cites/cites-import-species/cites-import-species.service';
import { CitesImportSpeciesFooterFormComponent } from './request-footer-form/cites-import-species-footer-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CitesImportSpeciesWorkflowService } from './cites-import-species-workflow.service';
import { EsRequestPageComponent } from '../../es-shared/es-request-page/es-request-page.component';
import { EsReviewPageComponent } from '../../es-shared/es-review-page/es-review-page.component';
import { CitesImportSpeciesRequestCardExtraComponent } from './request-card-extra/cites-import-species-request-card-extra.component';

const approutes: Routes = [
  {
    path: '',
    component: CitesImportSpeciesComponent,
    children: [
      {
        path: 'applicantinformation',
        component: EsApplicantInformationComponent,
        data: { breadcrumb: { useBreadcrumbKey: 'serviceName' } },
      },
      {
        path: 'requestDetails',
        component: EsRequestPageComponent,
        children: [
          {
            path: '',
            component: CitesImportSpeciesRequestComponent,
          },
        ],
        data: { breadcrumb: { useBreadcrumbKey: 'serviceName' } },
      },
      {
        path: 'attachments',
        component: ImportAttachmentsComponent,
        data: { breadcrumb: { useBreadcrumbKey: 'serviceName' } },
      },
      {
        path: 'review',
        component: EsReviewPageComponent,
        children: [
          {
            path: '',
            component: CitesImportSpeciesReviewComponent,
          },
        ],
      },
      { path: 'payment', component: PaymentComponent },
      { path: 'congrats', component: CongratulationsComponent },
    ],
  },
  {
    path: 'view',
    component: EsViewComponent,
    children: [{ path: '', component: CitesImportSpeciesViewComponent }],
  },
];
@NgModule({
  declarations: [
    CitesImportSpeciesComponent,
    CitesImportSpeciesRequestComponent,
    CitesImportSpeciesFormComponent,
    CitesImportSpeciesReviewDetailsComponent,
    CitesImportSpeciesReviewComponent,
    CitesImportSpeciesViewComponent,
    CitesImportSpeciesItemFormComponent,
    CitesImportSpeciesItemListComponent,
    CitesImportSpeciesViewItemComponent,
    CitesImportSpeciesFooterFormComponent,
    CitesImportSpeciesRequestCardExtraComponent,
  ],
  imports: [
    CommonModule,
    EsSharedModule,
    CitesSharedModule,
    RouterModule.forChild(approutes),
    NgxPaginationModule,
  ],
  providers: [
    {
      provide: 'WorkflowService',
      useExisting: CitesImportSpeciesWorkflowService,
    },
    {
      provide: 'apiService',
      useExisting: CitesImportSpeciesService,
    },
    {
      provide: 'mapperService',
      useExisting: CitesImportSpeciesMapper,
    },
    CitesImportSpeciesWorkflowService,
    CitesImportSpeciesService,
    CitesImportSpeciesMapper,
  ],
})
export class CitesImportSpeciesModule {}
