import { Component, Input } from "@angular/core";
import { PageBaseComponent } from "src/app/shared/base/PageBaseComponent";
import { TranslateService } from "@ngx-translate/core";
import { Lookup } from "src/app/core/models/common/Lookup";

@Component({
    selector: "app-cites-codes",
    templateUrl: "./cites-codes.component.html"
})

export class CitesCodesComponent extends PageBaseComponent{

    @Input() dialogId:string;
    @Input() titleKey:string;
    @Input() items:Lookup[];

    constructor(translate: TranslateService){
        super(translate);
    }
}