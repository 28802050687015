import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { EsValidateEmiratesId } from 'src/app/core/api-services/shared/EsValidateEmiratesId';
import { EsValidateEmiratesIdResponse } from 'src/app/core/api-services/shared/EsValidateEmiratesIdResponse';
import { EsApplicantDto } from 'src/app/core/models/shared/EsApplicantDto';
import { EsOtpApplicant } from 'src/app/core/models/shared/EsOtpApplicant';
import { BaseMapper } from 'src/app/shared/base/BaseMapper';

@Injectable()
export class EsIdentityOtpMapper extends BaseMapper {
  constructor(datePipe: DatePipe) {
    super(datePipe);
  }

  public mapOtpApplicant(applicantDto: EsApplicantDto): EsOtpApplicant {
    if (applicantDto) {
      const result: EsOtpApplicant = {
        id: applicantDto.ID,
        participantId: applicantDto.ParticipantID,
        nameEn: applicantDto.Name,
        nameAr: applicantDto.NameAr,
        identityType: applicantDto.IdentityType,
        identityNumber: applicantDto.IdentityNumber,
        passport: applicantDto.Passport,
        email: applicantDto.Email,
        phone: applicantDto.Phone,
        preferredLanguage: applicantDto.PreferedLanguage,
        countryId: applicantDto.CountryId,
        affiliation: applicantDto.Affiliation,
        address: applicantDto.Address,
        birthDate: this.convertDateStringToDate(applicantDto.BirthDate),
        emirateId: applicantDto.EmirateId,
        idStatus: applicantDto.IDStatus,
      };

      return result;
    }

    return null;
  }

  public mapEmirateIdDetails(
    response: EsValidateEmiratesIdResponse,
    requestedEmiratesIdentity: string
  ): EsValidateEmiratesId {
    if (response?.Data) {
      const data = response.Data;

      const result: EsValidateEmiratesId = {
        isValid: data.IsValid,
        mobileNumber: data.MobileNumber,
        registrationId: data.RegistrationID,
        status: data.Status,
        emiratesIdentity: requestedEmiratesIdentity,
      };

      return result;
    }

    return null;
  }
}
