import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { UtilService } from 'src/app/core/utilities/util.service';
import { AvailableThemes } from 'src/app/core/enums/available-themes';
import { BaseComponent } from '../../base/BaseComponent';
import {
  FormTemplateEnum,
  AppSettingsService,
  SettingsSizeEnum,
} from '../../settings/app-settings.service';
import { ThemeService } from 'src/app/core/utilities/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  window: any;
  sendToFriendForm: FormGroup;

  fontSize = 1;
  currentSelectedTheme = '';
  darkModeTheme = '';
  size: string;
  formsTemplate: FormTemplateEnum;

  public get isNormalSize(): boolean {
    return this.size == SettingsSizeEnum.Normal;
  }

  public get isFullSize(): boolean {
    return this.size == SettingsSizeEnum.Full;
  }

  public get isSupportResizing(): boolean {
    return this.settingsService.isSupportResizing;
  }

  public get isSupportFormTemplates(): boolean {
    return this.settingsService.isSupportFormTemplates;
  }

  public get isDefaultFormTemplate(): boolean {
    return this.formsTemplate == FormTemplateEnum.Default;
  }

  public get isHorizontalFormTemplate(): boolean {
    return this.formsTemplate == FormTemplateEnum.Horizontal;
  }

  public get isUserExist(): boolean {
    return (
      this.utilitiesService.isParticipantIdExists() ||
      this.utilitiesService.isEmployeeIdExists()
    );
  }

  constructor(
    translateService: TranslateService,
    private themeService: ThemeService,
    private utilitiesService: UtilService,
    private settingsService: AppSettingsService
  ) {
    super(translateService);

    this.window = window;
  }

  ngOnInit(): void {
    this.settingsService.size$.subscribe((s) => (this.size = s.size));
    this.settingsService.formsTemplate$.subscribe(
      (s) => (this.formsTemplate = s.template)
    );
  }

  increaseFontSize() {
    if (this.fontSize >= 1.3) {
      return;
    }
    this.fontSize += 0.1;
    document.querySelector('html').style.fontSize =
      this.fontSize.toString() + 'rem';
  }

  decreaseFontSize() {
    if (this.fontSize <= 1) {
      return;
    }
    this.fontSize -= 0.1;
    document.querySelector('html').style.fontSize =
      this.fontSize.toString() + 'rem';
  }

  print() {
    this.window.print();
  }

  fireReadSpeaker(): void {
    (<HTMLAnchorElement>(
      document.getElementsByClassName('rsbtn_play')[0]
    )).click();
  }

  //begin applying readspeaker auto close popup ----->
  @ViewChild('closeModalTag') private closeModaltag: ElementRef;

  closeModal() {
    this.closeModaltag.nativeElement.click();
  }
  //end applying readspeaker auto close popup ----->

  get AvailableTheme(): typeof AvailableThemes {
    return AvailableThemes;
  }

  handleAccessibility(): void {
    //rspkr.ui.addClickEvents();
  }

  full() {
    this.settingsService.changeSizeToFull();
  }

  normal() {
    this.settingsService.changeSizeToNormal();
  }

  horizontalForms() {
    this.settingsService.changeFormTemplateToHorizontal();
  }

  defaultForms() {
    this.settingsService.changeFormTemplateToDefault();
  }

  public changeColorTheme(theme: AvailableThemes) {
    this.themeService.changeColorTheme(theme);
  }

  public toggleDarkMode(theme: AvailableThemes) {
    this.themeService.toggleDarkMode(theme);
  }
}
