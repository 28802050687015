<div class="d-print-none" id="viewport" style="opacity: 0">
  <form>
    <div id="footer" class="footer">
      <div class="get_intouch_sec">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="intouch_box">
                <div class="intouch_inner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="container">
          <div class="support-entities-c">
            <ul class="support-entities owl-carousel">
              <li class="first">
                <a
                  target="”_blank”"
                  href="https://mysteryshopperapp.ae/default/defaultd"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/UAEmysteryshopper.png"
                    alt="{{ 'HomeFooterMysteryShopper' | translate }}"
                  />
                  <i>{{ 'HomeFooterMysteryShopper' | translate }}</i>
                </a>
              </li>
              <li class="first">
                <a
                  target="”_blank”"
                  href="https://171.ae/"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/tawasl-logo.png"
                    alt="{{ 'HomeFooter171' | translate }}"
                  />
                  <i>{{ 'HomeFooter171' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="https://www.mof.gov.ae/ar/pages/default.aspx"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/MinistryofFinanceUAE.png"
                    alt="{{ 'HomeFooterMinistryOfFinance' | translate }}"
                  />
                  <i>{{ 'HomeFooterMinistryOfFinance' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="https://www.mof.gov.ae/ar/pages/default.aspx"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/AbuDhabipolice.png"
                    alt="{{ 'HomeFooterADPolice' | translate }}"
                  />
                  <i>{{ 'HomeFooterADPolice' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="http://bayanat.ae/en"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/bayanat.png"
                    alt="{{ 'HomeFooterGovernmentdata' | translate }}"
                  />

                  <i>{{ 'HomeFooterGovernmentdata' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="https://beeatna.ae/en"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/beeatna-en.png"
                    alt="{{ 'HomeFooterEnvironment' | translate }}"
                  />
                  <i>{{ 'HomeFooterEnvironment' | translate }}</i>
                </a>
              </li>

              <li class="first">
                <a
                  target="”_blank”"
                  href="https://government.ae/{{ currentLang }}/participate"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/sharik.ae.png"
                    alt="{{ 'HomeFooterParticipation' | translate }}"
                  />
                  <i>{{ 'HomeFooterParticipation' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="http://uaesdgs.ae/en"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/uaesdgs202c.png"
                    alt="{{ 'HomeFooterSDGS' | translate }}"
                  />
                  <i>{{ 'HomeFooterSDGS' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="https://workinuae.ae/en"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/working-en202c.png"
                    alt="{{ 'HomeFooterWorkInUAE' | translate }}"
                  />
                  <i>{{ 'HomeFooterWorkInUAE' | translate }}</i>
                </a>
              </li>

              <li>
                <a
                  target="”_blank”"
                  href="https://alameen.gov.ae/"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/AlAmeen.png"
                    alt="{{ 'HomeFooterAlAmeen' | translate }}"
                  />
                  <i>{{ 'HomeFooterAlAmeen' | translate }}</i>
                </a>
              </li>

              <li>
                <a
                  target="”_blank”"
                  href="https://esaad.dubaipolice.gov.ae/"
                  class="has-tool-tip"
                >
                  <img
                    src="assets/Images/siteimages/essad.png"
                    alt="{{ 'HomeFooterEsaad' | translate }}"
                  />
                  <i>{{ 'HomeFooterEsaad' | translate }}</i>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  class="has-tool-tip"
                  href="https://www.skgep.gov.ae/"
                >
                  <img
                    src="assets/Images/siteimages/sheikh-khalifa-government-excellence-logo.PNG"
                    alt="{{
                      'HomeFooterSheikhKhalifaGovernmentExcellenceProgram'
                        | translate
                    }}"
                  />
                  <i>{{
                    'HomeFooterSheikhKhalifaGovernmentExcellenceProgram'
                      | translate
                  }}</i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="footer-section-last-update container">
        <p>
          {{ 'HomeFooterWebsitelastupdatedon' | translate
          }}<span data-toggle="last-update-date" class="last-modified">{{
            'HomeFooterDate' | translate
          }}</span>
        </p>
      </div>

      <div class="footer-container">
        <div class="container">
          <div class="newsletter-bar">
            <div class="visitor-count">
              <span class="key">
                {{ 'HomeFooterVisitorsCounter' | translate }}
              </span>
              <strong class="visitors-value">{{ visitorCount }}</strong>
            </div>
            <div class="col-2">
              <div class="our-apps">
                <div class="app-seciton">
                  <h1 class="section-label">
                    {{ 'DownloadMobileApps' | translate }}
                  </h1>
                  <ul>
                    <li>
                      <a
                        class="fi-android-n lnk-tip"
                        title=""
                        href="https://play.google.com/store/apps/details?id=com.linkdev.moew.app"
                        data-original-title="      {{ 'Android' | translate }}"
                      ></a>
                    </li>
                    <li>
                      <a
                        class="fi-ios-n lnk-tip"
                        title=""
                        href="https://itunes.apple.com/ae/app/moccae/id765940884?mt=8"
                        data-original-title="{{ 'Apple' | translate }}"
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="newsletter">
                <input
                  type="text"
                  data-toggle="subscribenewsletteremail"
                  placeholder="{{ 'SubscribeNewsletterEmail' | translate }}"
                />
                <a
                  href="javascript:void(0)"
                  data-toggle="subscribenewsletterbtn"
                  >{{ 'Subscribe' | translate }} <i class="fi-submit-icon"></i
                ></a>
              </div>
            </div>
          </div>
          <div class="bar"></div>

          <div class="menu-bar">
            <div class="logos">
              <ul class="gov-logos">
                <li>
                  <a
                    href="https://beeatna.ae/ar/home.aspx"
                    class="has-tool-tip scale-hover"
                    title="{{ 'Subscribe' | translate }} "
                  >
                    <img
                      [src]="
                        isArabic
                          ? 'assets/Images/siteimages/logo-new-ar.png'
                          : 'assets/Images/siteimages/logo-new-en.png'
                      "
                      alt="{{ 'Subscribe' | translate }}"
                    />
                    <i>{{ 'Subscribe' | translate }} </i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://u.ae/ar-ae"
                    class="has-tool-tip scale-hover"
                    title=""
                  >
                    <img
                      style="max-height: 80px"
                      src="/assets/Images/siteimages/government-ae-logo-english.png?1234"
                      alt="{{ 'UAEGovernment' | translate }} "
                    />
                    <i> {{ 'UAEGovernment' | translate }}</i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://beeatna.ae/ar/home.aspx"
                    class="has-tool-tip scale-hover"
                    title="{{ 'HomeFooterEnvironment' | translate }}"
                  >
                    <img
                      style="max-height: 80px"
                      src="/assets/Images/siteimages/beeatna-en.png?v=1"
                      alt="{{ 'HomeFooterEnvironment' | translate }}"
                    />
                    <i>{{ 'HomeFooterEnvironment' | translate }}</i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="footer-menu">
              <div class="submenu">
                <label class="label">{{ 'AboutTheWebsite' | translate }}</label>
                <ul class="list">
                  <li>
                    <a
                      class="footer-link"
                      title=" {{ 'HomeFooterCopyrightNotice' | translate }}   "
                      href="ar/copyright-notice.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterCopyrightNotice' | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title=" {{ 'HomeFooterPrivacyPolicy' | translate }}   "
                      href="ar/privacy-policy.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterPrivacyPolicy' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title=" {{ 'HomeFooterTermsConditions' | translate }}   "
                      href="ar/terms-and-conditions.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterTermsConditions' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterDisclaimer' | translate }} "
                      href="ar/disclaimer.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterDisclaimer' | translate }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="submenu col-2">
                <label class="label">
                  {{ 'HomeFooterQuicklinksInfo' | translate }}
                </label>
                <ul class="list">
                  <li>
                    <a
                      class="footer-link"
                      title="  {{ 'HomeFooterNewsletterSignup' | translate }} "
                      href="/ar/newsletter-subscription.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterNewsletterSignup' | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="  {{ 'HomeFooterHome' | translate }}  "
                      href="ar/home.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterHome' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="  {{ 'HomeFooterAccessibility' | translate }}   "
                      href="ar/accessibility.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterAccessibility' | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title=" {{ 'HomeFooterEmployeesEmail' | translate }}    "
                      href="https://mail.moccae.gov.ae/owa"
                      target="_self"
                    >
                      {{ 'HomeFooterEmployeesEmail' | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterMyGate' | translate }}"
                      href="https://mygate.moccae.gov.ae"
                      target="_self"
                      >{{ 'HomeFooterMyGate' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterCareers' | translate }}"
                      href="ar/careers.aspx"
                      target="_self"
                      >{{ 'HomeFooterCareers' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title=" {{
                        'HomeFooterCustomerHappinessCharter' | translate
                      }} "
                      href="/ar/our-services/happiness_eq_englishaspx.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterCustomerHappinessCharter' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterArchive' | translate }}"
                      href="/ar/media-centre/archive.aspx"
                      target="_self"
                      >{{ 'HomeFooterArchive' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title=" {{ 'HomeFooterFAQ' | translate }}   "
                      href="ar/faq.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterFAQ' | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterHelp' | translate }} "
                      href="/ar/help/technical-support.aspx"
                      target="_self"
                      >{{ 'HomeFooterHelp' | translate }}
                    </a>
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterContactUs' | translate }}"
                      href="/ar/contact-us.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterContactUs' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title=" {{ 'HomeFooterAdvancesearch' | translate }}  "
                      href="/ar/search.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterAdvancesearch' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{ 'HomeFooterSitemap' | translate }}"
                      href="/ar/sitemap.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterSitemap' | translate }}</a
                    >
                  </li>
                  <li>
                    <a
                      class="footer-link"
                      title="{{
                        'HomeFooterCustomersHappinessCenters' | translate
                      }}"
                      href="ar/customer-service-centers.aspx"
                      target="_self"
                    >
                      {{ 'HomeFooterCustomersHappinessCenters' | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="best-viewd">
              <p [innerHTML]="'HomeFooterView' | translate"></p>

              <div class="main-section">
                <div class="sub-section">
                  <span class="title"> {{ 'Supports' | translate }} </span>

                  <div>
                    <span
                      ><i
                        class="fi-firefox-icon"
                        title="{{ 'MicrosoftInternetExplorer' | translate }}"
                      ></i>
                      10.0+
                    </span>
                    <span>
                      <i
                        class="fi-firefox-icon"
                        title="{{ 'Firefox' | translate }}  "
                      ></i>
                      48+
                    </span>
                    <span>
                      <i
                        class="fi-safari-icon"
                        title="{{ 'Safari' | translate }} "
                      ></i>
                      1.2+
                    </span>
                    <span
                      ><i
                        class="fi-opera-icon"
                        title="{{ 'Opera' | translate }} "
                      ></i>
                      39.0+
                    </span>
                    <span
                      ><i
                        class="fi-google-chrome-icon"
                        title="{{ 'GoogleChrome' | translate }}  "
                      ></i>
                      43.0+</span
                    >
                  </div>
                </div>

                <div class="sub-section">
                  <span class="title">
                    {{ 'HomeFooterGamification' | translate }}
                  </span>
                  <div>
                    <span
                      ><a
                        style="
                          margin-top: 3px;
                          display: inline-block;
                          width: 20px;
                        "
                        href="javascript:void(0)"
                        title="{{ 'HomeFooterGamification' | translate }}"
                        ngbTooltip="{{ 'HomeFooterGamification' | translate }}"
                        tooltipClass="golden_tooltip"
                        href="#"
                        target="_blank"
                        class="game-link has-tool-tip"
                        data-target="#game"
                        data-toggle="modal"
                        ><span
                          class="fi-gamification"
                          style="display: inline; margin: 0"
                          ><span
                            class="path1"
                            style="display: inline; margin: 0"
                          ></span
                          ><span
                            class="path2"
                            style="display: inline; margin: 0; font-size: 18px"
                          ></span></span
                      ></a>
                    </span>
                  </div>
                  <div class="cusom-popup" data-toggle="gamification-popup">
                    <a target="”_blank”" href="#" class="close"></a>
                    <h3>
                      {{ 'HomeFooterPleaseselectagametoplay' | translate }}
                    </h3>
                    <ul class="list-items">
                      <li>
                        <a
                          target="”_blank”"
                          href="https://www.moccae.gov.ae/games/runner/#en"
                        >
                          <span class="title">
                            {{ 'HomeFooterAhmedRunner' | translate }}
                          </span>
                          <img src="assets/Images/games/runner-ahmed1b26.png" />
                          <span class="btn">
                            {{ 'HomeFooterPlayGame' | translate }}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="”_blank”"
                          href="https://www.moccae.gov.ae/games/puzzle/#en"
                        >
                          <span class="title">
                            {{ 'HomeFooterMatchingGame' | translate }}
                          </span>
                          <img
                            src="assets/Images/games/pictures-matching1b26.png"
                          />
                          <span class="btn">
                            {{ 'HomeFooterPlayGame' | translate }}
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          target="”_blank”"
                          href="https://portal.moccae.gov.ae/ecotourism/gamewebsite/game?lang=en"
                        >
                          <span class="title">
                            {{ 'HomeFooterEcotourism' | translate }}
                          </span>
                          <img src="assets/Images/games/ecotourism1b26.png" />
                          <span class="btn">
                            {{ 'HomeFooterPlayGame' | translate }}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <script>
                    $('.game-link').click(function (e) {
                      e.preventDefault();
                      $('[data-toggle="gamification-popup"]').css(
                        'display',
                        'block'
                      );
                      $('[data-toggle="gamification-popup"]').animate(
                        {
                          opacity: '1',
                        },
                        600
                      );
                      $('#viewport').addClass('add-blur');
                      $('<div class="shaded-body"></div>').insertAfter(
                        $('[data-toggle="gamification-popup"]')
                      );
                    });

                    $('[data-toggle="gamification-popup"] .close').click(
                      function (e) {
                        e.preventDefault();
                        $('[data-toggle="accessibility"]').animate(
                          {
                            opacity: '0',
                          },
                          600
                        );
                        $('[data-toggle="gamification-popup"]').css(
                          'display',
                          'none'
                        );
                        $('.shaded-body').remove();
                        $('#viewport').removeClass('add-blur');
                      }
                    );
                  </script>
                </div>
              </div>
            </div>
          </div>
          <div class="copy-write-bar">
            <div class="bar"></div>
            <div class="text">{{ 'HomeFooterCopyRight' | translate }}</div>
            <div class="social">
              <ul class="footer-social-media">
                <li>
                  <a
                    class="fi-facebook-ico lnk-tip"
                    href="https://www.facebook.com/MoCCAEUAE/"
                    title=""
                    data-original-title="{{ 'Facebook' | translate }}"
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-twitter lnk-tip"
                    href="https://twitter.com/moccaeuae"
                    title=""
                    data-original-title="{{ 'Twitter' | translate }}"
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-youtube lnk-tip"
                    href="https://www.youtube.com/user/moewmedia"
                    title=""
                    data-original-title="{{ 'Youtube' | translate }}"
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-instagram lnk-tip"
                    href="https://www.instagram.com/moccaeuae/"
                    title=""
                    data-original-title="{{ 'Instagram' | translate }}"
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-linkedin-n lnk-tip"
                    href="https://www.linkedin.com/company/moccaeuae"
                    title=""
                    data-original-title="{{ 'Linkedin' | translate }}"
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-pdf-icon lnk-tip"
                    href="https://get.adobe.com/uk/reader/"
                    data-original-title="{{ 'AdobeReader' | translate }}"
                    title=""
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-windows-ic lnk-tip"
                    href="https://support.microsoft.com/en-us/help/14209/get-windows-media-player"
                    data-original-title="{{ 'WindowMediaPlayer' | translate }}"
                  ></a>
                </li>
                <li>
                  <a
                    class="fi-flash-player-ic lnk-tip"
                    href="https://get.adobe.com/flashplayer/"
                    data-original-title="{{ 'Flash' | translate }}"
                    title=""
                  ></a>
                </li>
              </ul>
            </div>
            <div class="tel">
              <span>{{ 'NeedHelp' | translate }}</span>
              <a href="tel:8003050"
                ><i class="fi-phone-globalmast-icon"></i><span>8003050</span></a
              >
            </div>
          </div>
        </div>
      </div>

      <!--/MOD_0e80884f-c8bf-410d-9551-f6bf5d4d30c7_157-->
    </div>
  </form>
</div>

<!-----------------Start_Game_Color--------------------->

<div
  class="modal fade"
  id="game"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content rm_border">
      <div class="theme_color_popup">
        <div class="personalize_color">
          <span class="close_icon" data-dismiss="modal" aria-label="Close"
            ><img src="assets/Images/cancel.svg" alt=""
          /></span>
          <h2>Please select a game to play</h2>
          <div class="personalize-holder">
            <ul class="list-items">
              <li>
                <a
                  target="”_blank”"
                  href="https://www.moccae.gov.ae/games/runner/#en"
                >
                  <span class="title"> Ahmed Runner </span>
                  <img src="assets/Images/runner-ahmed.png" />
                  <span class="btn"> Play Game </span>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="https://www.moccae.gov.ae/games/puzzle/#en"
                >
                  <span class="title"> Matching Game </span>
                  <img src="assets/Images/pictures-matching.png" />
                  <span class="btn"> Play Game </span>
                </a>
              </li>
              <li>
                <a
                  target="”_blank”"
                  href="https://portal.moccae.gov.ae/ecotourism/gamewebsite/game?lang=en"
                >
                  <span class="title"> Ecotourism </span>
                  <img src="assets/Images/ecotourism.png" />
                  <span class="btn"> Play Game </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-----------------Start_Game_Color--------------------->

<script
  type="text/javascript"
  src="https://stg.chatbot.moccae.gov.ae/assets/modules/channel-web/inject.js"
></script>
<script>
  if (!window.botpressWebChat.init) {
    console.log('There is an error with chatbot link ');
  }
  var botNames = { en: 'Saqer', ar: 'صقر' };
  var currentUsername = localStorage.getItem('currentUsername');

  if (extraStylesheet == '' || avatarUrl == '') {
    if ($('body').hasClass('ApplyGreenTheme')) {
      extraStylesheet = '/assets/modules/moccae/color-schemes/green.css';
      avatarUrl = '/assets/modules/moccae/bot-icons/green-avatar.jpg';
    } else if ($('body').hasClass('ApplyRedTheme')) {
      extraStylesheet = '/assets/modules/moccae/color-schemes/red.css';
      avatarUrl = '/assets/modules/moccae/bot-icons/red-avatar.jpg';
    } else if ($('body').hasClass('ApplyGrayTheme')) {
      extraStylesheet = '/assets/modules/moccae/color-schemes/grey.css';
      avatarUrl = '/assets/modules/moccae/bot-icons/grey-avatar.jpg';
    } else {
      extraStylesheet = '/assets/modules/moccae/color-schemes/gold.css';
      avatarUrl = '/assets/modules/moccae/bot-icons/gold-avatar.jpg';
    }
  }

  window.botpressWebChat.init({
    host: 'https://chatbot.moccae.gov.ae',
    botId: 'moccae-bot',
    botName: botNames[localization],
    locale: localization,
    enableReset: false,
    useSessionStorage: true,
    avatarUrl: avatarUrl,
    extraStylesheet: extraStylesheet,
    showConversationsButton: false,
    showUserName: false,
    showUserAvatar: false,
    enableTranscriptDownload: false,
    enableArrowNavigation: false,
    overrides: {
      before_container: [
        {
          module: 'custom-extensions',
          component: 'AllMessagesWrapper',
        },
      ],
    },
  });

  // window.addEventListener('message', function (message) { if (message.data.type === 'redirect-to-x') { window.location = message.data.url } });
  //window.botpressWebChat.mergeConfig({ extraStylesheet: extraStylesheet, avatarUrl: avatarUrl });
  window.addEventListener('message', function (event) {
    if (event.data.type === 'redirect-to-x') {
      window.location = event.data.url;
    }

    if (
      event !== undefined &&
      event.data !== undefined &&
      event.data.name === 'webchatOpened'
    ) {
      if (
        currentUser === null ||
        currentUser === undefined ||
        currentUser == ''
      ) {
        window.botpressWebChat.sendEvent({
          type: 'user-data-from-portal',
          isLoggedIn: false,
          username: null,
          ArabicName: null,
          EnglishName: null,
          email: null,
          logout: false,
          language: localization,
        });
      } else {
        var currentUserArabicName = localStorage.getItem(
          'CurrentUserArabicName'
        );
        var currentUserEnglishName = localStorage.getItem(
          'CurrentUserEnglishName'
        );
        var currentUserEmail = localStorage.getItem('CurrentUserEmail');
        window.botpressWebChat.sendEvent({
          type: 'user-data-from-portal',
          isLoggedIn: true,
          username: currentUsername,
          ArabicName: currentUserArabicName,
          EnglishName: currentUserEnglishName,
          email: currentUserEmail,
          language: localization,
        });
      }
    }

    if (event.data.name === 'webchatOpened') {
      window.botpressWebChat.sendEvent({
        type: 'proactive-trigger',
        channel: 'web',
        payload: { text: 'fake message' },
      });
    }
  });
</script>
