import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesImportSpeciesTransaction } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { CitesBaseFormComponent } from '../../shared/base/CitesBaseFormComponent';

@Component({
  selector: 'app-cites-import-species-footer-form',
  templateUrl: './cites-import-species-footer-form.component.html',
})
export class CitesImportSpeciesFooterFormComponent extends CitesBaseFormComponent {
  @Input()
  transaction: CitesImportSpeciesTransaction;
  
  constructor(translateService: TranslateService) {
      super(translateService);
  }

}
