import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EsFees } from 'src/app/core/models/shared/EsFees';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-fee-list',
  templateUrl: './es-fee-list.component.html',
})
export class ExportFeeListComponent extends BaseComponent {
  constructor(translateService: TranslateService) {
    super(translateService);
  }

  @Input()
  fees: EsFees;
}
