import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import * as addRequestCommentsRequest from "../../../models/common/comments/AddRequestCommentsRequest";
import * as addRequestCommentsResponse from "../../../models/common/comments/AddRequestCommentsResponse";
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {WorkflowActionsCodesEnum} from '../../../models/common/ServiceMainTransactionData';
import {UtilService} from '../../../utilities/util.service';
import {ResponseModel} from '../../../models/shared/CommonResponseDTO';
@Injectable({
  providedIn: 'root'
})
export class AddRequestCommentsService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'CustomerEmpChatMessages/' + 'AddCustomerEmpChatMessage';
  constructor(private httpClient: HttpClient, private utilService: UtilService) {
  }


  public addRequestComments(ServiceRequestTransactionId: number, Message: string, FileAttachment: File, ActionCode: WorkflowActionsCodesEnum): Observable<ResponseModel<boolean>> {
    const formData = new FormData();
    formData.append('Message', Message);
    formData.append('FileAttachment', FileAttachment);
    formData.append('ServiceRequestTransactionId', ServiceRequestTransactionId.toString());

    if (ActionCode && ActionCode != null) {
      formData.append('ActionCode', ActionCode);
    }

    formData.append('RegisteredUserName', this.utilService.getUserProfile()?.nameEn);
    return this.httpClient.post<ResponseModel<boolean>>(this.REST_API_SERVER, formData)
    .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error( '[AddRequestCommentsService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error( '[AddRequestCommentsService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }
}
