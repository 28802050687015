import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { RedirectService } from './redirect.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    public redirectService: RedirectService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (environment.production && !this.auth.isAuthenticated()) {
      const encodedLocation = encodeURIComponent(window.location.href);

      const loginUrlWithReturnUrl = `${environment.loginURL}?retUrl=${encodedLocation}`;

      return this.redirectService
        .redirect(loginUrlWithReturnUrl, '_self')
        .then(() => {
          return false;
        });
    }

    return true;
  }

  goToExternalPage(url: string) {
    window.location.href = url;
  }
}
