import {UtilService} from '../../core/utilities/util.service';
import {GetProfileService} from '../../core/api-services/customer/get-profile.service';

export function appEmployeeInitializer(utilService: UtilService, getProfileService: GetProfileService) {
  return () => new Promise(resolve => {


    if (utilService.isEmployeeIdExists()) {
      getProfileService.GetEmployeeData(utilService.getEmployeeId())
        .subscribe(value => {

          if (value.responseData != null){
            utilService.saveEmployeeData(value.responseData);
          }else {
            utilService.deleteEmployeeProfile();
          }
        }, error => {
          utilService.deleteEmployeeProfile();
        })
        .add(resolve);
    } else {
      utilService.deleteEmployeeProfile();
      resolve(true);
    }
  });

}
