import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ServiceMetaData } from 'src/app/core/models/shared/ServiceMetaData';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'app-es-terms-and-conditions',
  templateUrl: './es-terms-and-conditions.component.html',
})
export class EsTermsAndConditionsComponent extends BaseComponent {
  @Input()
  form: FormGroup;

  @Input()
  metaData: ServiceMetaData;

  constructor(translateService: TranslateService) {
    super(translateService);
  }

  get hasErrors(): boolean {
    return (
      this.form.controls.acceptTerms.errors &&
      this.form.controls.acceptTerms.touched &&
      this.form.controls.acceptTerms.errors.equalTrue == true
    );
  }

  get touched(): boolean {
    return this.form.controls.acceptTerms.touched;
  }
}
