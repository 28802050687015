export class CitesMainFormChangeEmitterData {
  isLoadFalconDetailsByRingNumber?: boolean;
  isLoadAnimalInfo?: boolean;

  isLoadIdentifiableTypes?: boolean;
  isResetIdentifiableTypesDependencies?: boolean;

  isLoadNonIdentifiableTypes?: boolean;

  isBuildFieldsOptions?: boolean;
  isResetBalanceTypeDependencies?: boolean;
  isSetAvailableType?: boolean;
  isSetQuantityValidators?: boolean;
  isLoadNewOwnerDetails?: boolean;
  isResetNonIdentifiableTypesDependencies?: boolean;
  isSetNewOwnerValidators?: boolean;
  isSetChipNumber?: boolean;
  isSetTermsAndConditionsAgreementValidators?: boolean;

  isLoadRegionByEmirateId?: boolean;
  isLoadDeliveryTypes?: boolean;
  emirateId?: number;

  isLoadRingTypesId?: boolean;
  sourceCode?: string;

  type?: CitesMainFormChangeType;
  id?:number;
  code?:string;
  value?:any;
  isResolveRingNumberErrors?: boolean;
}


export enum CitesMainFormChangeType{
  RequestTypeChange = 1,
  ClassChange = 2,
  RingNumberChange = 3,
  ChipNumberChange = 4,
  TypeDescriptionChange = 5,
  SourceChange = 6,
  ImportPermitChange = 7,
  TypeChange = 8,
  CountryChange = 9,
  AppendixChange = 10,
  IsRelatedChange = 11,
  PurposeChange = 12,
  IsSelfExported = 13,
}