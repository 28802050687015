import { Injectable } from '@angular/core';
import { Observable,throwError } from 'rxjs';
import {catchError} from "rxjs/operators";
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import * as getNotificationResponse from "../../models/customer/RegisteredUserNotification";
import {ResponseModel} from '../../models/shared/CommonResponseDTO';
import {RegisteredUserNotification} from '../../models/customer/RegisteredUserNotification';

@Injectable({
  providedIn: 'root'
})
export class GetNotificationService {

  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Customer/' + 'GetNotifications';
  private REST_API_SERVER_COUNT = environment.DigitalServicesHostURL + 'Customer/' + 'GetNotificationsCount';

  constructor(private httpClient: HttpClient) {
  }

  public getNotification(): Observable<ResponseModel<RegisteredUserNotification[]>> {
    return this.httpClient.get<ResponseModel<RegisteredUserNotification[]>>(this.REST_API_SERVER)
                          .pipe(catchError(this.handleError));
  }
  public getNotificationCount(): Observable<ResponseModel<number>> {
    return this.httpClient.get<ResponseModel<number>>(this.REST_API_SERVER_COUNT)
                          .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
      if (errorResponse.error instanceof ErrorEvent) {
        console.error('[GetNotificationService] Client Side Error' , errorResponse.error.message);
      } else {
        console.error('[GetNotificationService] Server Side Error' , errorResponse.error.message);
      }

      return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }


}
