export enum CountryCodesEnum {
  ARE = 'ARE',
  KWT = 'KWT',
  BHR = 'BHR',
  OMN = 'OMN',
  QAT = 'QAT',
  SAU = 'SAU',
  LBN = 'LBN',
  SYR = 'SYR',
  JOR = 'JOR',
  PSE = 'PSE',
  IRQ = 'IRQ',
  YEM = 'YEM',
  EGY = 'EGY',
  SDN = 'SDN',
  LBY = 'LBY',
  TUN = 'TUN',
  DZA = 'DZA',
  MAR = 'MAR',
  IRN = 'IRN',
  IND = 'IND',
  PAK = 'PAK',
  MUS = 'MUS'
}
