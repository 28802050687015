import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CompletedRequestsStatisticsData } from '../../models/customer/CompletedRequestsStatisticsResponse';
import { ResponseModel } from '../../models/shared/CommonResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class GetStatisticsForCompletedRequestsService {
  private REST_API_SERVER = environment.DigitalServicesHostURL + 'Customer/' + 'GetStatisticsForCompletedRequestsWithPayment';

  constructor(private httpClient: HttpClient) { }

  public getStatisticsForCompletedRequests(): Observable<ResponseModel<CompletedRequestsStatisticsData[]>> {
    return this.httpClient.get<ResponseModel<CompletedRequestsStatisticsData[]>>(this.REST_API_SERVER)
                          .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse) {
    if (errorResponse.error instanceof ErrorEvent) {
      console.error('[GetStatisticsForCompletedRequestsService] Client Side Error' , errorResponse.error.message);
    } else {
      console.error('[GetStatisticsForCompletedRequestsService] Server Side Error' , errorResponse.error.message);
    }

    return throwError('There is a problem with the service. we are notified & working on it. please try again later');
  }

}
