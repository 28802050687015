import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CitesImportSpeciesItem } from 'src/app/core/models/cites/cites-import-species/CitesImportSpeciesTransaction';
import { BaseItemViewComponent } from 'src/app/shared/base/BaseItemViewComponent';


@Component({
  selector: 'app-cites-release-species-item-view',
  templateUrl: './cites-release-species-item-view.component.html',
  styleUrls: ['./../../../import-release-shared/import-release.module.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class CitesReleaseSpeciesViewItemComponent extends 
                BaseItemViewComponent<CitesImportSpeciesItem> {
    constructor(translate: TranslateService) {
        super(translate);
    }
}
